import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentSecondaryPlacementNamesSelector,
} from 'merchandisingModule/selectors/secondaryPlacementSelectors';

import SecondaryPlacementChart from 'merchandisingModule/components/SecondaryPlacementChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  secondaryPlacementNames: currentSecondaryPlacementNamesSelector(state),
  secondaryPlacementData: state.merchandising.secondaryPlacementData,
}))(
  withLegend({
    keys: 'secondaryPlacementNames',
    isLegendActive: false,
  })(SecondaryPlacementChart),
);
