/* eslint-disable no-param-reassign */

import qs from 'qs';
import { isPast } from 'date-fns';
import moment from 'moment';
import {
  isBoolean, isArray, isString, isEqual,
} from 'lodash';
import produce from 'immer';

import { DATE_FORMAT } from 'common/constants/dateConstants';

const now = moment().format(DATE_FORMAT);
class AccountsFilter {
  static shouldIncudeParam(key, param) {
    if (isString(param)) {
      return param.length > 0;
    }

    if (isArray(param)) {
      return (
        param.length > 0 && param.length !== AccountsFilter.defaultParams[key].length
      );
    }

    return param || isBoolean(param);
  }

  static mapValueToApi(key, value) {
    const transfromFunc = AccountsFilter.fieldValuesToApiMap.get(key);

    return transfromFunc ? transfromFunc(value) : value;
  }

    static fieldValuesToApiMap = new Map([
      ['distributors', distributors => distributors.join()],
      ['channelTypes', channelTypes => channelTypes.join()],
      ['cities', cities => cities.join()],
      ['states', states => states.join()],
      ['chains', chains => chains.join()],
      ['representatives', representatives => representatives.join()],
      ['representativesMetric', representativesMetric => representativesMetric.join()],
      ['territories', territories => territories.join()],
      ['distributors', distributors => distributors.join()],
      ['salesSkuNames', salesSkuNames => salesSkuNames.join()],
      ['merchandisingSkuNames', merchandisingSkuNames => merchandisingSkuNames.join()],
      ['regions', regions => regions.join()],
      ['counties', counties => counties.join()],
      ['banners', banners => banners.join()],
      ['frequencies', frequencies => frequencies.join()],
    ]);

    static fieldNamesToApiMap = new Map([
      ['startDate', 'dateStart'],
      ['endDate', 'dateEnd'],
      ['channelTypes', 'channels'],
      ['numberOfSkus', 'numberOfSKUs'],
      ['representativesMetric', 'metricRepresentatives'],
    ]);

    static defaultParams = {
      startDate: null,
      endDate: now,
      brand: '',
      name: '',
      address: '',
      channelTypes: [],
      cities: [],
      states: [],
      chains: [],
      representatives: [],
      representativesMetric: [],
      territories: [],
      notVisitedIn: 0,
      accountsSource: '',
      distributors: [],
      salesSkuNames: [],
      merchandisingSkuNames: [],
      numberOfSkus: 'm0',
      numberOfFacings: 'm0',
      regions: [],
      counties: [],
      banners: [],
      frequencies: [],
      zipCode: '',
    };

    static setDefaultParams({
      defaultBrand,
      defaultStartDate,
      defaultAccountsSource,
    }) {
      AccountsFilter.defaultParams = produce(
        AccountsFilter.defaultParams,
        (draft) => {
          draft.startDate = moment(defaultStartDate).format(DATE_FORMAT);
          draft.brand = defaultBrand;
          draft.accountsSource = defaultAccountsSource;
        },
      );
    }

    static parseQueryString(query) {
      const { defaultParams } = AccountsFilter;
      const parsed = qs.parse(query, { ignoreQueryPrefix: true });

      const normalizedParams = {
        startDate: isPast(parsed.startDate)
          ? moment(parsed.startDate).format(DATE_FORMAT)
          : defaultParams.startDate,
        endDate: moment(parsed.endDate).isValid()
          ? moment(parsed.endDate).format(DATE_FORMAT)
          : defaultParams.endDate,
        brand: parsed.brand || defaultParams.brand,
        name: parsed.name || defaultParams.name,
        address: parsed.address || defaultParams.address,
        channelTypes:
          parsed.channelTypes?.split(',') || defaultParams.channelTypes,
        cities: parsed.cities?.split(',') || defaultParams.cities,
        states: parsed.states?.split(',') || defaultParams.states,
        chains: parsed.chains?.split(',') || defaultParams.chains,
        representatives:
          parsed.representatives?.split(',') || defaultParams.representatives,
        representativesMetric: parsed.representativesMetric?.split(',') || defaultParams.representativesMetric,
        territories:
          parsed.territories?.split(',') || defaultParams.territories,
        notVisitedIn:
          Number(parsed.notVisitedIn) || defaultParams.notVisitedIn,
        accountsSource:
          parsed.accountsSource || defaultParams.accountsSource,
        distributors:
          parsed.distributors?.split(',') || defaultParams.distributors,
        merchandisingSkuNames:
          parsed.merchandisingSkuNames?.split(',') || defaultParams.merchandisingSkuNames,
        salesSkuNames:
          parsed.salesSkuNames?.split(',') || defaultParams.salesSkuNames,
        numberOfSkus: parsed.numberOfSkus || defaultParams.numberOfSkus,
        numberOfFacings:
          parsed.numberOfFacings || defaultParams.numberOfFacings,
        regions: parsed.regions?.split(',') || defaultParams.regions,
        counties: parsed.counties?.split(',') || defaultParams.counties,
        banners: parsed.banners?.split(',') || defaultParams.banners,
        frequencies: parsed.frequencies?.split(',') || defaultParams.frequencies,
        zipCode: parsed.zipCode || defaultParams.zipCode,
      };

      return new AccountsFilter(normalizedParams);
    }

    constructor({
      startDate,
      endDate,
      brand,
      name,
      address,
      channelTypes,
      cities,
      states,
      chains,
      representatives,
      representativesMetric,
      territories,
      notVisitedIn,
      accountsSource,
      distributors,
      salesSkuNames,
      merchandisingSkuNames,
      numberOfSkus,
      numberOfFacings,
      regions,
      counties,
      banners,
      frequencies,
      zipCode,
    }) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.brand = brand;
      this.name = name;
      this.address = address;
      this.channelTypes = channelTypes;
      this.cities = cities;
      this.states = states;
      this.chains = chains;
      this.representatives = representatives;
      this.representativesMetric = representativesMetric;
      this.territories = territories;
      this.notVisitedIn = notVisitedIn;
      this.accountsSource = accountsSource;
      this.distributors = distributors;
      this.salesSkuNames = salesSkuNames;
      this.merchandisingSkuNames = merchandisingSkuNames;
      this.numberOfSkus = numberOfSkus;
      this.numberOfFacings = numberOfFacings;
      this.regions = regions;
      this.counties = counties;
      this.banners = banners;
      this.frequencies = frequencies;
      this.zipCode = zipCode;
    }

    getDateRange() {
      return [this.startDate, this.endDate];
    }

    getDateRangeMoment() {
      return [moment(this.startDate), moment(this.endDate)];
    }

    getQueryStringFilter() {
      const params = {};

      Object.entries(this).forEach(([key, value]) => {
        if (AccountsFilter.shouldIncudeParam(key, value)) {
          params[key] = isArray(value) ? value.join() : value;
        }
      });

      return qs.stringify(params, {
        arrayFormat: 'brackets',
        serializeDate: date => moment(date).format(DATE_FORMAT),
      });
    }

    getApiParams() {
      const params = {};

      Object.entries(this).forEach(([key, value]) => {
        if (AccountsFilter.shouldIncudeParam(key, value)) {
          const mappedKey = AccountsFilter.fieldNamesToApiMap.get(key) || key;
          const mappedValue = AccountsFilter.fieldValuesToApiMap.get(key)?.(value)
                    || value;

          params[mappedKey] = moment.isDate(mappedValue)
            ? moment(mappedValue).format(DATE_FORMAT)
            : mappedValue;
        }
      });

      return params;
    }

    getNormalizedFilter() {
      return new AccountsFilter({
        ...this,
        name: this.name.trim(),
        address: this.address.trim(),
        zipCode: this.zipCode.trim(),
      });
    }

    isModelEqualWithDefault() {
      return isEqual(this, new AccountsFilter(AccountsFilter.defaultParams));
    }
}

export default AccountsFilter;
