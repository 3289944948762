class TargetRepresentative {
  constructor({ name, id }) {
    this.ID = id;
    this.name = name;
  }

  static parse(obj) {
    return new TargetRepresentative({
      id: obj.ID,
      name: obj.Name,
    });
  }
}

export default TargetRepresentative;
