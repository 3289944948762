/* eslint-disable react/prefer-stateless-function, react/destructuring-assignment, no-nested-ternary */
import React, { PureComponent } from 'react';
import uuidv4 from 'uuid/v4';

import {
  instanceOf, func, arrayOf, string,
} from 'prop-types';

import {
  Button, Tree,
} from 'antd';

import User from 'adminModule/models/User';

import './index.scss';

import { PERMISSIONS_TREE_DATA } from './constants';

const { TreeNode } = Tree;

class PermissionsForm extends PureComponent {
  static propTypes = {
    onChangePermissions: func.isRequired,
    user: instanceOf(User).isRequired,
    permissions: arrayOf(string).isRequired,
  }

  state = {
    expandedKeys: [],
    autoExpandParent: true,
    checkedKeys: this.props.permissions,
  }

  submitForm = (e) => {
    e.preventDefault();
    const { onChangePermissions, user } = this.props;
    const { checkedKeys } = this.state;
    onChangePermissions({ login: user.login, permissions: checkedKeys });
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  }

  onCheck = (checkedKeys) => {
    this.setState({ checkedKeys });
  }

  renderTreeNodes = data => data.map((item) => {
    if (item.children) {
      return (
        <TreeNode title={item.title} key={item.key} dataRef={item}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode key={uuidv4()} {...item} />;
  })

  render() {
    const { user } = this.props;
    const { login, brandFullName } = user;

    return (
      <div className="permissions-form">
        <div className="permissions-form__info">
          <p className="permissions-form__title">
            Login:
            {' '}
            <span className="permissions-form__title-label">{login}</span>
          </p>
          <p className="permissions-form__title">
            Company:
            {' '}
            <span className="permissions-form__title-label">{brandFullName}</span>
          </p>
        </div>
        <div className="permissions-form__permissions">
          <p className="permissions-form__permissions-title">Permissions</p>
          <Tree
            checkable
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={this.state.autoExpandParent}
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
          >
            {this.renderTreeNodes(PERMISSIONS_TREE_DATA)}
          </Tree>
        </div>
        <div className="permissions-form__submit-button-container">
          <Button
            className="permissions-form__submit-button"
            icon="form"
            type="primary"
            htmlType="submit"
            onClick={this.submitForm}
          >
            Change permissions
          </Button>

        </div>
      </div>
    );
  }
}

export default PermissionsForm;
