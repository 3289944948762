import moment from 'moment';

import {
  DATE_FORMAT,
  WEEK_NUMBER_FORMAT,
  MONTH_FORMAT,
  YEAR_FORMAT,
} from 'common/constants/dateConstants';

export const tooltipFormatter = (dateInterval) => {
  const dateIntervalObject = JSON.parse(dateInterval);
  switch (dateIntervalObject.interval) {
  case 'day':
    return moment(dateIntervalObject.start).format(DATE_FORMAT);
  case 'week':
    return `Week ${moment(dateIntervalObject.start)
      .locale('en-us')
      .format(WEEK_NUMBER_FORMAT)} (${moment(
      dateIntervalObject.start,
    ).format(DATE_FORMAT)})`;
  case 'month':
    return `${moment(dateIntervalObject.start)
      .locale('en-us')
      .format(MONTH_FORMAT)} ${moment(
      dateIntervalObject.start,
    ).format(YEAR_FORMAT)}`;
  case 'year':
    return `${moment(dateIntervalObject.start).format(
      YEAR_FORMAT,
    )} - ${moment(dateIntervalObject.end).format(
      YEAR_FORMAT,
    )} `;
  default:
    return moment(dateIntervalObject.start).format(DATE_FORMAT);
  }
};

export const transformDataForTooltip = item => ({
  title: item.id,
  color: item.color,
  index: tooltipFormatter(item.indexValue),
  value: item.value,
});
