/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';

import BarChart from 'common/components/BarChart';
import SectionHeading from 'common/components/SectionHeading';

import withLegend from 'common/hocs/withLegend';

import TotalSaleBySku from 'salesModule/models/TotalSaleBySku';

import { transformDataForTooltip } from 'salesModule/tools/tooltipFormatter';
import { bottomTicksFormatter } from 'salesModule/tools/bottomTicksFormatter';

const HEADING = 'Sales By SKU';
const VALUE_SIGN = '$';

const TotalSalesBySkuChart = ({
  totalSalesBySku,
  skuNames,
  colorMap,
  disabledKeys,
  shouldRenderChart,
}) => {
  const skusData = totalSalesBySku.map(totalSaleBySku => totalSaleBySku.getSkuData());

  const labelFormatter = label => `${VALUE_SIGN}${label.toLocaleString()}`;

  return (
    shouldRenderChart
    && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <BarChart
          data={skusData.map(skuData => Object.keys(skuData)
            .filter(skuDataKey => !disabledKeys.includes(skuDataKey))
            .reduce((obj, skuDataKey) => {
              obj[skuDataKey] = skuData[skuDataKey];
              return obj;
            }, {}))}
          keys={skuNames.filter(skuName => !disabledKeys.includes(skuName))}
          indexes="dateInterval"
          colorMap={colorMap}
          valueSign={VALUE_SIGN}
          labelFormatter={labelFormatter}
          bottomTicksFormatter={bottomTicksFormatter}
          transformDataForTooltip={transformDataForTooltip}
        />
      </Fragment>
    )
  );
};

TotalSalesBySkuChart.propTypes = {
  totalSalesBySku: arrayOf(instanceOf(TotalSaleBySku)).isRequired,
  skuNames: arrayOf(string).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
  shouldRenderChart: bool.isRequired,
};

export default withLegend({ keys: 'skuNames' })(TotalSalesBySkuChart);
