import React from 'react';
import { instanceOf } from 'prop-types';
import { Table } from 'antd';
import uuidv4 from 'uuid/v4';

import User from 'adminModule/models/User';

import './index.scss';

const columns = [
  {
    title: 'Login Date',
    dataIndex: 'lastLoginDate',
    className: 'user-info-modal__table-cell',
    align: 'center',
    key: 'lastLoginDate',
    sorter: (a, b) => a.lastLoginDate.localeCompare(b.lastLoginDate),
    defaultSortOrder: 'descend',
    width: 140,
  },
  {
    title: 'IP Address',
    dataIndex: 'IPAddress',
    className: 'user-info-modal__table-cell',
    align: 'center',
    key: 'IPAddress',
    width: 170,
  },
  {
    title: 'Location',
    dataIndex: 'lastLoginLocation',
    className: 'user-info-modal__table-cell',
    align: 'center',
    key: 'lastLoginLocation',
  },
];

class UserLoginInfoModal extends React.Component {
  static propTypes = {
    user: instanceOf(User).isRequired,
  };

  state = {
    additionalTableProps: {},
  }


  componentDidMount() {
    this.updateTableWidth();
    window.addEventListener('resize', this.updateTableWidth);
  }

  updateTableWidth = () => {
    if (window.innerWidth > 992) {
      this.setState({
        additionalTableProps: {},
      });
    }
    if (window.innerWidth <= 640) {
      this.setState({
        additionalTableProps: {
          scroll: {
            x: 500,
          },
        },
      });
    }
  }

  render() {
    const { user } = this.props;
    const { additionalTableProps } = this.state;
    const {
      login, brandFullName, isAdmin, loginInfo,
    } = user;

    const loginInfoWithKeys = loginInfo.map(userLoginInfo => ({ ...userLoginInfo, key: uuidv4() }));

    return (
      <div className="user-info-modal">
        <div className="user-info-modal__info">
          <p className="user-info-modal__title">
            Login:
            {' '}
            <span className="user-info-modal__title-label">{login}</span>
          </p>
          <p className="user-info-modal__title">
            Company:
            {' '}
            <span className="user-info-modal__title-label">{brandFullName}</span>
          </p>
          <p className="user-info-modal__title">
            Role:
            {' '}
            <span className="user-info-modal__title-label">{isAdmin ? 'Admin' : 'User'}</span>
          </p>
        </div>
        <Table
          className="user-info-modal__table"
          dataSource={loginInfoWithKeys}
          columns={columns}
          pagination={false}
          rowKey={loginInfoItem => loginInfoItem.key}
          bordered
          {...additionalTableProps}
        />
      </div>
    );
  }
}

export default UserLoginInfoModal;
