import Sku from './Sku';
import Brand from './Brand';
import SecondaryPlacement from './SecondaryPlacementOnMap';

class FilterOptions {
  constructor({
    brands,
    channelTypes,
    cities,
    states,
    chains,
    representatives,
    territories,
    skus,
    accountsSources,
    secondaryPlacements,
    dublicateImages,
    representativesMetric,
    regions,
    counties,
    banners,
    frequencies,
    primary,
    secondary,
    locations,
    types,
    tags,
    prices,
    promo,
    competitors,
  }) {
    this.brands = brands;
    this.channelTypes = channelTypes;
    this.cities = cities;
    this.states = states;
    this.chains = chains;
    this.representatives = representatives;
    this.territories = territories;
    this.skus = skus;
    this.accountsSources = accountsSources;
    this.secondaryPlacements = secondaryPlacements;
    this.dublicateImages = dublicateImages;
    this.representativesMetric = representativesMetric;
    this.regions = regions;
    this.counties = counties;
    this.banners = banners;
    this.frequencies = frequencies;
    this.primary = primary;
    this.secondary = secondary;
    this.locations = locations;
    this.types = types;
    this.tags = tags;
    this.prices = prices;
    this.promo = promo;
    this.competitors = competitors;
  }

  static parse(obj) {
    return new FilterOptions({
      brands: obj.Brands.map(Brand.parse),
      channelTypes: obj.Channels,
      cities: obj.Cities,
      states: obj.States,
      chains: obj.Chains,
      representatives: obj.Representatives,
      territories: obj.Territories,
      skus: obj.SKUs.map(Sku.parse),
      accountsSources: obj.AccountSources,
      secondaryPlacements: obj.SecondaryPlacementInfo.map(
        SecondaryPlacement.parse,
      ),
      dublicateImages: obj.DuplicateImagesVariants,
      representativesMetric: obj.MetricRepresentatives,
      regions: obj.Regions,
      counties: obj.Counties,
      banners: obj.Banners,
      frequencies: obj.Frequencies.map(String),
      primary: obj.Primary,
      secondary: obj.Secondary,
      locations: obj.Locations,
      types: obj.Types,
      tags: obj.Tags,
      prices: obj.Prices,
      promo: obj.Promo,
      competitors: obj.Competitors,
    });
  }
}

export default FilterOptions;
