import React from 'react';
import {
  arrayOf,
  any,
  number,
  string,
  instanceOf,
  shape,
  func,
  oneOfType,
} from 'prop-types';

import { ResponsiveBarCanvas } from '@nivo/bar';

import {
  TEXT_COLOR,
  FONT_SIZE,
  TICK_COLOR,
  STROKE_COLOR,
  STROKE_WIDTH,
} from 'common/constants/barChartStyles';
import BarChartTooltip from './BarChartTooltip';

import './index.scss';

const BarChart = ({
  data,
  keys,
  indexes,
  colorMap,
  height,
  theme,
  valueSign,
  type,
  bottomTicksFormatter,
  labelFormatter,
  label,
  transformDataForTooltip,
}) => {
  const colorBy = ({ id }) => colorMap.get(id);

  return (
    <div style={{ height }} className="bar-chart">
      <ResponsiveBarCanvas
        theme={theme}
        data={data}
        keys={keys}
        indexBy={indexes}
        groupMode={type}
        margin={{
          top: 10,
          right: 10,
          bottom: 70,
          left: 40,
        }}
        padding={0.1}
        colorBy={colorBy}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          format: bottomTicksFormatter,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        labelFormat={labelFormatter}
        label={label}
        labelSkipWidth={45}
        labelSkipHeight={12}
        labelTextColor="inherit:darker(1.6)"
        animate
        motionStiffness={90}
        motionDamping={15}
        tooltip={item => (
          <BarChartTooltip
            {...transformDataForTooltip(item)}
            valueSign={valueSign}
          />
        )}
      />
    </div>
  );
};

BarChart.propTypes = {
  data: arrayOf(any).isRequired,
  keys: arrayOf(string).isRequired,
  indexes: string.isRequired,
  colorMap: instanceOf(Map).isRequired,
  height: string,
  theme: shape({
    axis: shape({
      textColor: string,
      fontSize: string,
      tickColor: string,
    }),
    grid: shape({
      stroke: string,
      strokeWidth: number,
    }),
  }),
  valueSign: string,
  type: string,
  bottomTicksFormatter: func,
  transformDataForTooltip: func,
  labelFormatter: func,
  label: oneOfType([func, string]),
};

BarChart.defaultProps = {
  theme: {
    axis: {
      textColor: TEXT_COLOR,
      fontSize: FONT_SIZE,
      tickColor: TICK_COLOR,
    },
    grid: {
      stroke: STROKE_COLOR,
      strokeWidth: STROKE_WIDTH,
    },
  },
  height: '400px',
  valueSign: '',
  type: 'grouped',
  bottomTicksFormatter: tick => tick,
  labelFormatter: label => label,
  label: 'value',
  transformDataForTooltip: item => ({
    title: item.id,
    color: item.color,
    index: item.indexValue,
    value: item.value,
  }),
};

export default BarChart;
