import React, { Fragment } from 'react';
import { Divider, Button } from 'antd';

import SectionHeading from 'common/components/SectionHeading';

import {
  bool, func, arrayOf, string,
} from 'prop-types';
import { reportsPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

const HEADING = 'Summary Reports';

const SummaryReportsSection = ({
  onDownloadWeeklyReport,
  onDownloadMonthlyReport,
  isWeeklyReportDownloading,
  isMonthlyReportDownloading,
  childrenPermissions,
}) => (
  <Fragment>
    <SectionHeading>{HEADING}</SectionHeading>
    <section className="summary-reports-section">
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_SUMMARY_WEEKLY_REPORT) && (
        <div className="summary-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadWeeklyReport}
            loading={isWeeklyReportDownloading}
          >
            Weekly Summary Report
          </Button>
        </div>
      )
      }
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_SUMMARY_MONTHLY_REPORT) && (
        <div className="summary-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadMonthlyReport}
            loading={isMonthlyReportDownloading}
          >
            Monthly Summary Report
          </Button>
        </div>
      )
      }
    </section>
    <Divider />
  </Fragment>
);

SummaryReportsSection.propTypes = {
  onDownloadWeeklyReport: func.isRequired,
  onDownloadMonthlyReport: func.isRequired,
  isWeeklyReportDownloading: bool.isRequired,
  isMonthlyReportDownloading: bool.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default SummaryReportsSection;
