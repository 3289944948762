import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentProductsOnPromoNamesSelector,
} from 'merchandisingModule/selectors/productsOnPromoSelectors';

import ProductsOnPromoChart from 'merchandisingModule/components/ProductsOnPromoChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  productsOnPromoNames: currentProductsOnPromoNamesSelector(state),
  productsOnPromoData: state.merchandising.productsOnPromoData,
}))(
  withLegend({
    keys: 'productsOnPromoNames',
    isLegendActive: false,
  })(ProductsOnPromoChart),
);
