import { connect } from 'react-redux';

import { permissionsSelector } from 'reportsModule/selectors/reportsPageSelectors';
import SummaryReportsSection from 'reportsModule/components/SummaryReportsSection';

export default connect(
  state => ({
    isWeeklyReportDownloading:
      state.loading.effects.reports.downloadScorecardsWeekReport,
    isMonthlyReportDownloading:
      state.loading.effects.reports.downloadScorecardsMonthReport,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadWeeklyReport: () => dispatch.reports.downloadScorecardsWeekReport(),
    onDownloadMonthlyReport: () => dispatch.reports.downloadScorecardsMonthReport(),
  }),
)(SummaryReportsSection);
