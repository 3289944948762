import React, { PureComponent } from 'react';
import {
  func, instanceOf, bool, arrayOf, string,
} from 'prop-types';
import moment from 'moment';

import AccountsFilterModel from 'accountsModule/models/AccountsFilter';
import FilterOptions from 'accountsModule/models/FilterOptions';
import FilterButtons from 'common/components/FilterButtons';

import { showWarning } from 'common/helpers/notificationManager';
import { datePeriodCreator } from 'common/utilities/datePeriodCreator';
import { incorrectDateInterval } from 'common/constants/notificationConfig';

import { DATE_FORMAT } from 'common/constants/dateConstants';
import FiltersBoard from './FiltersBoard';

import './index.scss';

class AccountsFilter extends PureComponent {
  static propTypes = {
    applyFilter: func.isRequired,
    isAdmin: bool.isRequired,
    filterOptions: instanceOf(FilterOptions).isRequired,
    initialFilterModel: instanceOf(AccountsFilterModel).isRequired, // eslint-disable-line react/no-unused-prop-types
    permissions: arrayOf(string).isRequired,
  };

  static getStateFromFilterModel(filterModel) {
    return {
      dateRange: [
        moment(filterModel.startDate),
        moment(filterModel.endDate),
      ],
      brand: filterModel.brand,
      name: filterModel.name,
      address: filterModel.address,
      channelTypes: filterModel.channelTypes,
      cities: filterModel.cities,
      states: filterModel.states,
      chains: filterModel.chains,
      representatives: filterModel.representatives,
      representativesMetric: filterModel.representativesMetric,
      territories: filterModel.territories,
      notVisitedIn: filterModel.notVisitedIn,
      noImageReasons: filterModel.noImageReasons,
      accountsSource: filterModel.accountsSource,
      regions: filterModel.regions,
      counties: filterModel.counties,
      banners: filterModel.banners,
      frequencies: filterModel.frequencies,
      zipCode: filterModel.zipCode,
      statusesNames: filterModel.statusesNames,
      distributors: filterModel.distributors,
    };
  }

  constructor(props) {
    super(props);

    const { initialFilterModel, filterOptions } = props;
    this.state = {
      ...AccountsFilter.getStateFromFilterModel(initialFilterModel),
      earliestDate: filterOptions.brands.find(
        brand => brand.name === initialFilterModel.brand,
      ).earliestDate,
      isFilterToggled: false,

      isDefaultEnabled: !initialFilterModel.isModelEqualWithDefault(),
    };
  }

  onBrandChange = (value) => {
    const { filterOptions } = this.props;
    const { earliestDate } = filterOptions.brands.find(
      b => b.name === value,
    );
    this.setState(state => ({
      earliestDate: moment(earliestDate),
      dateRange: [moment(earliestDate), state.dateRange[1]],
      isFilterToggled: true,
    }));
  };

  onPeriodChange = (value) => {
    const { brand } = this.state;
    const { filterOptions } = this.props;
    const { earliestDate } = filterOptions.brands.find(
      b => b.name === brand,
    );
    const [startDate, endDate] = datePeriodCreator(value, earliestDate);
    this.setState({
      dateRange: [startDate, endDate],
    });
  };

  onChangeField = (fieldName, value) => {
    const { brand } = this.state;

    if (fieldName === 'brand' && brand !== value) {
      this.onBrandChange(value);
    }

    if (fieldName === 'periodRange') {
      this.onPeriodChange(value);
    }

    const newState = {
      ...this.state,
      [fieldName]: value,
      isFilterToggled: true,
    };

    const newFilterModel = new AccountsFilterModel(newState);
    this.setState({
      [fieldName]: value,
      isFilterToggled: true,
      isDefaultEnabled: !newFilterModel.isModelEqualWithDefault(),
    });
  };

  onApply = () => {
    const {
      state: {
        dateRange: [startDate, endDate],
        brand,
        earliestDate,
        name,
        address,
        channelTypes,
        cities,
        states,
        chains,
        representatives,
        representativesMetric,
        territories,
        notVisitedIn,
        noImageReasons,
        accountsSource,
        regions,
        counties,
        banners,
        frequencies,
        zipCode,
        statusesNames,
        distributors,
      },
      props: { applyFilter },
    } = this;

    let checkedStartDate;
    let checkedEndDate;

    if (
      moment(startDate).isBefore(earliestDate)
      || moment(endDate).isBefore(earliestDate)
      || !startDate
      || !endDate
    ) {
      checkedStartDate = moment(earliestDate).format(DATE_FORMAT);
      checkedEndDate = moment().format(DATE_FORMAT);
      showWarning(incorrectDateInterval);
    } else {
      checkedStartDate = startDate.format(DATE_FORMAT);
      checkedEndDate = endDate.format(DATE_FORMAT);
    }

    const filterModel = new AccountsFilterModel({
      startDate: checkedStartDate,
      endDate: checkedEndDate,
      brand,
      name,
      address,
      channelTypes,
      cities,
      states,
      chains,
      representatives,
      representativesMetric,
      territories,
      notVisitedIn,
      noImageReasons,
      accountsSource,
      regions,
      counties,
      banners,
      frequencies,
      zipCode,
      statusesNames,
      distributors,
    });

    const normalizedFilter = filterModel.getNormalizedFilter();
    const newState = AccountsFilter.getStateFromFilterModel(
      normalizedFilter,
    );
    applyFilter(normalizedFilter);
    this.setState(() => ({
      ...newState,
      isFilterToggled: false,
      isDefaultEnabled: !filterModel.isModelEqualWithDefault(),
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.onApply();
  };

  onSetDefaultFilters = () => {
    const defaultFilterModel = new AccountsFilterModel(
      AccountsFilterModel.defaultParams,
    );

    const newState = AccountsFilter.getStateFromFilterModel(
      defaultFilterModel,
    );

    this.setState(() => ({
      ...newState,
      isFilterToggled: true,
      isDefaultEnabled: !defaultFilterModel.isModelEqualWithDefault(),
    }));
  };

  revertFilters = () => {
    const { initialFilterModel, filterOptions } = this.props;
    this.setState({
      ...AccountsFilter.getStateFromFilterModel(initialFilterModel),
      earliestDate: filterOptions.brands.find(
        brand => brand.name === initialFilterModel.brand,
      ).earliestDate,
      isFilterToggled: false,
      isDefaultEnabled: !initialFilterModel.isModelEqualWithDefault(),
    });
  };

  disabledDate = (current) => {
    const { earliestDate } = this.state;
    return (
      current > moment().endOf('day')
      || current < moment(earliestDate).startOf('day')
    );
  };

  render() {
    const {
      dateRange,
      brand,
      name,
      address,
      channelTypes,
      cities,
      states,
      chains,
      representatives,
      representativesMetric,
      territories,
      notVisitedIn,
      noImageReasons,
      accountsSource,
      regions,
      counties,
      banners,
      frequencies,
      zipCode,
      statusesNames,
      distributors,
      isFilterToggled,
      isDefaultEnabled,
    } = this.state;

    const { isAdmin, filterOptions, permissions } = this.props;

    return (
      <form onSubmit={this.onSubmit} className="accounts-page-filter">
        <div className="accounts-page-filter-inner">
          <div className="accounts-page-filter__filters-container">
            <FiltersBoard
              isAdmin={isAdmin}
              disabledDate={this.disabledDate}
              dateRange={dateRange}
              name={name}
              address={address}
              brand={brand}
              channelTypes={channelTypes}
              cities={cities}
              states={states}
              chains={chains}
              representatives={representatives}
              representativesMetric={representativesMetric}
              territories={territories}
              notVisitedIn={notVisitedIn}
              noImageReasons={noImageReasons}
              accountsSource={accountsSource}
              regions={regions}
              counties={counties}
              banners={banners}
              frequencies={frequencies}
              zipCode={zipCode}
              statusesNames={statusesNames}
              distributors={distributors}
              filterOptions={filterOptions}
              onChangeField={this.onChangeField}
              permissions={permissions}
            />
          </div>
          <div
            className="accounts-page-filter__buttons-container"
          >
            <FilterButtons
              isFilterToggled={isFilterToggled}
              isDefaultEnabled={isDefaultEnabled}
              revertFilters={this.revertFilters}
              onSetDefaultFilters={this.onSetDefaultFilters}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default AccountsFilter;
