import { connect } from 'react-redux';

import MetricSelect from 'salesModule/components/MetricSelect';
import { shouldRenderMetricSelect } from 'salesModule/selectors/salesPageSelectors';

export default connect(
  state => ({
    shouldRenderMetricSelect: shouldRenderMetricSelect(state),
  }),
)(MetricSelect);
