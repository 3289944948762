import axios from 'axios';

import store from 'store';

const setAuthToken = (token) => {
  axios.defaults.headers.common.Authorization = token;
};

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.request.status === 401) {
      store.dispatch({ type: 'session/logOut' });
    }

    throw error;
  },
);

export { setAuthToken };

export default axios;
