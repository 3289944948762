import { connect } from 'react-redux';

import AddedFacingsChart from 'merchandisingModule/components/AddedFacingsChart';

import { shouldRenderAddedFacingsChart } from 'merchandisingModule/selectors/addedFacingsChartSelectors';

export default connect(
  state => ({
    addedFacingsData: state.merchandising.addedFacingsData,
    shouldRenderChart: shouldRenderAddedFacingsChart(state),
  }),
)(AddedFacingsChart);
