import React, { PureComponent } from 'react';
import {
  string, arrayOf, shape, instanceOf, number, func,
} from 'prop-types';
import moment from 'moment';

import AccountImages from 'merchandisingModule/models/AccountImages';

import noPictureImage from 'common/images/no-picture.png';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import {
  PICTURE,
  STORE_PICTURE,
  PRIMARY_PICTURE,
} from 'merchandisingModule/constants/merchandising-constants';
import Modal from '../AccountPopupModal';

import './index.scss';

const noop = () => { };

class AccountPopupInfo extends PureComponent {
  static propTypes = {
    name: string.isRequired,
    address: string.isRequired,
    storePictureUrl: string.isRequired,
    picturesList: arrayOf(shape({ title: string, pictureUrl: string })),
    status: string.isRequired,
    totalVisits: number.isRequired,
    date: instanceOf(moment).isRequired,
    representative: string.isRequired,
    nextScheduledDate: instanceOf(moment).isRequired,
    authorisedDate: instanceOf(moment).isRequired,
    accountId: number.isRequired,
    secondaryPlacement: string.isRequired,
    fetchAccountMerchandisingImages: func.isRequired,
    setOpenAccountPopupId: func.isRequired,

    primaryImages: arrayOf(instanceOf(AccountImages)),
    secondaryImages: arrayOf(instanceOf(AccountImages)),
  };

  static defaultProps = {
    picturesList: [],
    primaryImages: [],
    secondaryImages: [],
  };

  state = {
    visible: false,
    modalContent: PICTURE,
    contentType: PRIMARY_PICTURE,
  };

  changeVisible = value => this.setState({ visible: value });

  changeModalContent = (content, type = null) => this.setState({ modalContent: content, contentType: type });

  setPictureVisible = (index) => {
    const {
      accountId,
      fetchAccountMerchandisingImages,
      setOpenAccountPopupId,
    } = this.props;
    setOpenAccountPopupId(accountId);
    fetchAccountMerchandisingImages(accountId);

    const { picturesList } = this.props;

    this.changeVisible(true);
    this.changeModalContent(PICTURE, picturesList[index].type);
  };

  setStorePictureVisible = () => {
    this.changeVisible(true);
    this.changeModalContent(STORE_PICTURE);
  };

  onClickPicture = index => () => this.setPictureVisible(index);

  closeModal = () => this.changeVisible(false);

  onKeyPress = index => (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      this.setPictureVisible(index);
    }
  };

  onKeyPressStore = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      this.setStorePictureVisible();
    }
  };

  onImgError = (e) => {
    e.target.src = noPictureImage;
  };

  render() {
    const {
      name,
      address,
      storePictureUrl,
      picturesList,
      date,
      authorisedDate,
      status,
      totalVisits,
      representative,
      nextScheduledDate,
      primaryImages,
      secondaryImages,
      secondaryPlacement,
    } = this.props;

    const { visible, modalContent, contentType } = this.state;

    const formattedDate = date.format(DATE_FORMAT);
    const formattedAuthorisedDate = authorisedDate.format(DATE_FORMAT);
    const formattedNextScheduledDate = nextScheduledDate.format(
      DATE_FORMAT,
    );

    return (
      <div className="merchandising-popup-info">
        <article className="merchandising-popup-info__description">
          <h1 className="merchandising-popup-info__description-heading">
              Account Info
          </h1>
          <div className="merchandising-popup-info__description-container">
            <section>
              <div
                onKeyPress={
                  storePictureUrl
                    ? this.onKeyPressStore
                    : noop
                }
                onClick={
                  storePictureUrl
                    ? this.setStorePictureVisible
                    : noop
                }
                role="button"
                tabIndex="0"
              >
                <img
                  className="merchandising-popup-info__front-picture"
                  height="75"
                  width="75"
                  src={storePictureUrl || noPictureImage}
                  alt="Storefront"
                  onError={this.onImgError}
                />
              </div>
            </section>
            <section className="merchandising-popup-info__info-container">
              <p className="merchandising-popup-info__info-item">
                <span className="merchandising-popup-info__info-label">
                    Account Name:
                  {' '}
                </span>
                {name}
              </p>
              <p className="merchandising-popup-info__info-item">
                <span className="merchandising-popup-info__info-label">
                    Address:
                  {' '}
                </span>
                {address}
              </p>
            </section>
          </div>
        </article>
        <article className="merchandising-popup-info__description">
          <h1 className="merchandising-popup-info__description-heading">
              Key Stats
          </h1>
          <section>
            <p className="merchandising-popup-info__info-item">
              <span className="merchandising-popup-info__info-label">
                  Authorized Date:
                {' '}
              </span>
              {formattedAuthorisedDate}
            </p>
            <p className="merchandising-popup-info__info-item">
              <span className="merchandising-popup-info__info-label">
                  Current Status:
                {' '}
              </span>
              {status}
            </p>
            <p className="merchandising-popup-info__info-item">
              <span className="merchandising-popup-info__info-label">
                  Rep:
                {' '}
              </span>
              {representative}
            </p>
            <p className="merchandising-popup-info__info-item">
              <span className="merchandising-popup-info__info-label">
                  Merchandising Visits:
                {' '}
              </span>
              {totalVisits}
            </p>
            <p className="merchandising-popup-info__info-item">
              <span className="merchandising-popup-info__info-label">
                  Secondary Placements:
                {' '}
              </span>
              {secondaryPlacement}
            </p>
            <p className="merchandising-popup-info__info-item">
              <span className="merchandising-popup-info__info-label">
                  Last Merchandising Visit:
                {' '}
              </span>
              {formattedDate}
            </p>
            <p className="merchandising-popup-info__info-item">
              <span className="merchandising-popup-info__info-label">
                  Next Scheduled Visit:
                {' '}
              </span>
              {formattedNextScheduledDate}
            </p>
          </section>
        </article>

        {picturesList.length > 0 && (
          <article>
            <h1 className="merchandising-popup-info__description-heading">
                Product Placement Photos
            </h1>
            <div className="merchandising-popup-info__accounts-pictures-container">
              {picturesList.map((pictureItem, index) => (
                <div
                  key={pictureItem.title}
                  role="button"
                  tabIndex="0"
                  onKeyPress={this.onKeyPress(index)}
                  onClick={this.onClickPicture(index)}
                  className="merchandising-popup-info__accounts-info-item"
                >
                  <div>{pictureItem.title}</div>
                  <img
                    className="merchandising-popup-info__accounts-info-image"
                    height="150"
                    src={
                      pictureItem.pictureUrl
                        || noPictureImage
                    }
                    alt={pictureItem.title}
                    onError={this.onImgError}
                  />
                </div>
              ))}
            </div>
          </article>
        )}

        {visible && (
          <Modal
            visible={visible}
            closeModal={this.closeModal}
            primaryImages={primaryImages}
            secondaryImages={secondaryImages}
            modalContent={modalContent}
            contentType={contentType}
            storePictureUrl={storePictureUrl}
          />
        )}
      </div>
    );
  }
}

export default AccountPopupInfo;
