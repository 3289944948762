/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import ScheduleData from 'scheduleModule/models/ScheduleData';

const asmScheduleDataSelector = state => state.schedule.asmScheduleData;
const selectedEventsSelector = state => state.schedule.selectedEvents;

export const filteredAsmScheduleDataSelector = createSelector(
  [selectedEventsSelector, asmScheduleDataSelector],
  (selectedEvents, asmScheduleData) => asmScheduleData
    .map(asmScheduleDataItem => new ScheduleData({
      ...asmScheduleDataItem,
      data: asmScheduleDataItem?.data
      .map(scheduleItem => ({
        ...scheduleItem,
        visits: scheduleItem?.visits
          .filter(scheduleAccount => !selectedEvents.includes(scheduleAccount.status)),
      })),
    })),
);
