/* eslint-disable no-unused-expressions, no-lone-blocks */
import React, { PureComponent } from 'react';
import {
  instanceOf, func, bool, arrayOf, string,
} from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { Skeleton } from 'antd';

import withNavigation from 'common/hocs/withNavigation';

import FilterOptions from 'reportsModule/models/FilterOptions';
import AccountsFilterModel from 'reportsModule/models/AccountsFilter';
import Scorecard from 'reportsModule/models/Scorecard';

import AccountsFilter from 'reportsModule/components/AccountsFilter';
import ScorecardsTables from 'reportsModule/components/ScorecardsTables';

import ChainReportsSection from 'reportsModule/containers/ChainReportsSectionContainer';
import AccountReportsSection from 'reportsModule/containers/AccountReportsSectionContainer';
import MerchandisingReportsSection from 'reportsModule/containers/MerchandisingReportsSectionContainer';
import SalesReportsSection from 'reportsModule/containers/SalesReportsSectionContainer';
import SummaryReportsSection from 'reportsModule/containers/SummaryReportsSectionContainer';

import { reportsPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class ReportsPage extends PureComponent {
  static propTypes = {
    filterOptions: instanceOf(FilterOptions).isRequired,
    filter: instanceOf(AccountsFilterModel).isRequired,
    scorecardsWeeklyData: arrayOf(instanceOf(Scorecard)).isRequired,
    scorecardsMonthlyData: arrayOf(instanceOf(Scorecard)).isRequired,
    fetchingFilterOptions: bool.isRequired,
    filterInitialized: bool.isRequired,
    location: RouterPropTypes.location.isRequired,
    history: RouterPropTypes.history.isRequired,
    setFilter: func.isRequired,
    setSelectedBrand: func.isRequired,
    fetchFilterOptions: func.isRequired,
    fetchWeekScorecards: func.isRequired,
    fetchMonthScorecards: func.isRequired,
    resetReportsStore: func.isRequired,
    childrenPermissions: arrayOf(string).isRequired,
    filterPermissions: arrayOf(string).isRequired,
  };

  componentDidMount() {
    const { fetchFilterOptions } = this.props;

    fetchFilterOptions();
  }

  componentDidUpdate(prevProps) {
    const { fetchingFilterOptions, filterOptions, location } = this.props;

    if (prevProps.fetchingFilterOptions && !fetchingFilterOptions) {
      const defaultBrandName = filterOptions.brands[0].name;
      const { earliestDate } = filterOptions.brands.find(
        brandInfo => brandInfo.name === defaultBrandName,
      );
      const defaultAccountsSource = filterOptions.accountsSources[0];

      AccountsFilterModel.setDefaultParams({
        defaultBrand: defaultBrandName,
        defaultStartDate: earliestDate,
        defaultAccountsSource,
      });
      const filterModel = AccountsFilterModel.parseQueryString(
        location.search,
      );

      this.setAccountFilters(filterModel);
    }
  }

  componentWillUnmount() {
    const { resetReportsStore } = this.props;
    resetReportsStore();
  }

  setAccountFilters = (filterModel) => {
    const {
      setFilter,
      location,
      history,
      fetchWeekScorecards,
      fetchMonthScorecards,
      childrenPermissions,
    } = this.props;

    const modelQueryString = filterModel.getQueryStringFilter();

    { childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_WEEKLY_TABLES) && fetchWeekScorecards(filterModel); }
    { childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_MONTHLY_TABLES) && fetchMonthScorecards(filterModel); }

    setFilter(filterModel);
    history.push({
      pathname: location.pathname,
      search: modelQueryString,
      hash: location.hash,
    });
  };

  render() {
    const {
      filter,
      filterOptions,
      filterInitialized,
      scorecardsWeeklyData,
      scorecardsMonthlyData,
      childrenPermissions,
      filterPermissions,
      setSelectedBrand,
    } = this.props;

    const sholudRenderScorecardsWeeklyTable = scorecardsWeeklyData && scorecardsWeeklyData.length > 0;
    const shouldRenderScorecardsMonthlyTable = scorecardsMonthlyData && scorecardsMonthlyData.length > 0;

    return (
      <div className="reports-page">
        <Skeleton
          loading={!filterInitialized}
          className="reports-page__skeleton"
        >
          <AccountsFilter
            applyFilter={this.setAccountFilters}
            initialFilterModel={filter}
            filterOptions={filterOptions}
            permissions={filterPermissions}
            setSelectedBrand={setSelectedBrand}
          />
          <div className="reports-page__inner">
            <AccountReportsSection />
            <MerchandisingReportsSection />
            <SalesReportsSection />
            <ChainReportsSection />
            <SummaryReportsSection />
            <ScorecardsTables
              scorecardsData={scorecardsWeeklyData}
              title="Weekly Summary"
              shouldRenderTables={
                sholudRenderScorecardsWeeklyTable && childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_WEEKLY_TABLES)
              }
            />
            <ScorecardsTables
              scorecardsData={scorecardsMonthlyData}
              title="Monthly Summary"
              shouldRenderTables={
                shouldRenderScorecardsMonthlyTable && childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_MONTHLY_TABLES)
              }
            />
          </div>
        </Skeleton>
      </div>
    );
  }
}

export default withNavigation()(ReportsPage);
