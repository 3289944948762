/* eslint-disable no-unused-expressions, no-lone-blocks */
import React, { PureComponent } from 'react';
import {
  instanceOf, bool, arrayOf, string,
} from 'prop-types';

import moment from 'moment';

import SectionHeading from 'common/components/SectionHeading';

import AccountsFilterModel from 'accountsModule/models/AccountsFilter';

import AccountStatusOnDateChart from 'accountsModule/containers/AccountsStatusOnDateChartContainer';
import AccountsByChannelTypeChart from 'accountsModule/containers/AccountsByChannelTypeChartContainer';
import AccountsByDistributorOnDateChart from 'accountsModule/containers/AccountsByDistributorOnDateChartContainer';
import AuthAccountsByChannelTypeChart from 'accountsModule/containers/AuthAccountsByChannelTypeChartContainer';

import { DATE_FORMAT } from 'common/constants/dateConstants';
import { accountsPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class MetricsOnDateCharts extends PureComponent {
  static propTypes = {
    fetchingFilterOptions: bool.isRequired,
    accountsFilter: instanceOf(AccountsFilterModel).isRequired,
    childrenPermissions: arrayOf(string).isRequired,
    shouldRenderAccountStatusChart: bool.isRequired,
    shouldRenderDistributorsChart: bool.isRequired,
    shouldRenderAccountsByChannelTypeChart: bool.isRequired,
    shouldRenderAuthAccountsByChannelTypeChart: bool.isRequired,
  };

  render() {
    const {
      accountsFilter,
      fetchingFilterOptions,
      childrenPermissions,
      shouldRenderAccountStatusChart,
      shouldRenderDistributorsChart,
      shouldRenderAccountsByChannelTypeChart,
      shouldRenderAuthAccountsByChannelTypeChart,
    } = this.props;

    const shouldRenderCharts = shouldRenderAccountStatusChart
    || shouldRenderDistributorsChart
    || shouldRenderAccountsByChannelTypeChart
    || shouldRenderAuthAccountsByChannelTypeChart;

    const formattedChartDate = accountsFilter.endDate
      && moment(accountsFilter.endDate).format(DATE_FORMAT);

    return (
      !fetchingFilterOptions && shouldRenderCharts && (
        <article className="metrics-pies">
          <SectionHeading>{`Metrics as of ${formattedChartDate}`}</SectionHeading>
          <section className="metrics-pies-wrapper">
            {childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_ACCOUNT_STATUS)
            && shouldRenderAccountStatusChart && (
              <div className="metrics-pies__chart">
                <AccountStatusOnDateChart />
              </div>
            )}
            {childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_CHANNELS)
            && shouldRenderAccountsByChannelTypeChart && (
              <div className="metrics-pies__chart">
                <AccountsByChannelTypeChart />
              </div>
            )}
            {childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_AUTH_CHANNELS)
            && shouldRenderAuthAccountsByChannelTypeChart && (
              <div className="metrics-pies__chart">
                <AuthAccountsByChannelTypeChart />
              </div>
            )}
            {childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_DISTRIBUTORS)
            && shouldRenderDistributorsChart && (
              <div className="metrics-pies__chart">
                <AccountsByDistributorOnDateChart />
              </div>
            )}
          </section>
        </article>
      )
    );
  }
}

export default MetricsOnDateCharts;
