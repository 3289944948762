import React, { Fragment } from 'react';
import { instanceOf, arrayOf, string } from 'prop-types';

import PieChart from 'common/components/PieChart';
import SectionHeading from 'common/components/SectionHeading';

import ShelfPlacement from 'merchandisingModule/models/ShelfPlacement';

const HEADING = 'Shelf Placement';

const ShelfPlacementChart = ({
  shelfPlacementData,
  colorMap,
  disabledKeys,
}) => {
  const pieChartData = new Array(shelfPlacementData.length)
    .fill(null)
    .map((_, i) => ({
      id: shelfPlacementData[i].placement,
      label: shelfPlacementData[i].placement,
      value: shelfPlacementData[i].amount,
      percentage: shelfPlacementData[i].percentage,
    }));

  const labelFormatter = label => `${label.value} (${label.percentage}%)`;
  const shouldRenderChart = shelfPlacementData.length > 0 && pieChartData[0].id;

  return shouldRenderChart && (
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <PieChart
        data={pieChartData.filter(
          pieData => !disabledKeys.includes(pieData.id),
        )}
        colorMap={colorMap}
        labelFormatter={labelFormatter}
      />
    </Fragment>
  );
};

ShelfPlacementChart.propTypes = {
  shelfPlacementData: arrayOf(instanceOf(ShelfPlacement)).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
};

export default ShelfPlacementChart;
