import { connect } from 'react-redux';

import AdminPage from 'adminModule/components/AdminPage';

import { permissionsSelector } from 'adminModule/selectors/adminPageSelectors';


export default connect(
  state => ({
    userInfo: state.session.userInfo,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.admin.fetchFilterOptions,
  }),
)(AdminPage);
