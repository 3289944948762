/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { reportsPagePermissionTypes } from 'userModule/constants/permissions';

const reportsPagePermissionsSelector = state => state.session.permissions[reportsPagePermissionTypes.REPORTS_PAGE];

export const permissionsSelector = createSelector(
  reportsPagePermissionsSelector,
  reportsPagePermissions => reportsPagePermissions.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  reportsPagePermissionsSelector,
  reportsPagePermissions => reportsPagePermissions.Filters || [],
);
