/* eslint-disable import/prefer-default-export, no-nested-ternary */
import { Periods } from 'common/constants/periodConstants';
import moment from 'moment';

const getToday = () => [moment(), moment()];

const getYesterday = (earliestDate) => {
  const yesterday = moment().subtract(1, 'days');
  return yesterday.isBefore(earliestDate)
    ? [moment(), moment()]
    : [yesterday, yesterday];
};

const getThisWeek = (earliestDate) => {
  const startOfWeek = moment().startOf('week');
  return startOfWeek.isBefore(earliestDate)
    ? [moment(earliestDate), moment()]
    : [startOfWeek, moment()];
};

const getLastWeek = (earliestDate) => {
  const startOfLastWeek = moment()
    .subtract(1, 'weeks')
    .startOf('week');
  const endOfLastWeek = moment(startOfLastWeek).endOf('week');
  return startOfLastWeek.isBefore(earliestDate)
    ? endOfLastWeek.isBefore(earliestDate)
      ? [moment(earliestDate), moment()]
      : [moment(earliestDate), endOfLastWeek]
    : [startOfLastWeek, endOfLastWeek];
};

const getThisMonth = (earliestDate) => {
  const startOfMonth = moment().startOf('month');
  return startOfMonth.isBefore(earliestDate)
    ? [moment(earliestDate), moment()]
    : [startOfMonth, moment()];
};

const getLastMonth = (earliestDate) => {
  const startOfLastMonth = moment()
    .subtract(1, 'months')
    .startOf('month');
  const endOfLastMonth = moment(startOfLastMonth).endOf('month');
  return startOfLastMonth.isBefore(earliestDate)
    ? endOfLastMonth.isBefore(earliestDate)
      ? [moment(earliestDate), moment()]
      : [moment(earliestDate), endOfLastMonth]
    : [startOfLastMonth, endOfLastMonth];
};

const getThisQuarter = (earliestDate) => {
  const startOfQuarter = moment().startOf('quarter');
  return startOfQuarter.isBefore(earliestDate)
    ? [moment(earliestDate), moment()]
    : [startOfQuarter, moment()];
};

const getLastQuarter = (earliestDate) => {
  const startOfLastQuarter = moment()
    .subtract(1, 'quarters')
    .startOf('quarter');
  const endOfLastQuarter = moment(startOfLastQuarter).endOf('quarter');
  return startOfLastQuarter.isBefore(earliestDate)
    ? endOfLastQuarter.isBefore(earliestDate)
      ? [moment(earliestDate), moment()]
      : [moment(earliestDate), endOfLastQuarter]
    : [startOfLastQuarter, endOfLastQuarter];
};

const getThisYear = (earliestDate) => {
  const startOfYear = moment().startOf('year');
  return startOfYear.isBefore(earliestDate)
    ? [moment(earliestDate), moment()]
    : [startOfYear, moment()];
};

const getLastYear = (earliestDate) => {
  const startOfLastYear = moment()
    .subtract(1, 'years')
    .startOf('year');
  const endOfLastYear = moment(startOfLastYear).endOf('year');
  return startOfLastYear.isBefore(earliestDate)
    ? endOfLastYear.isBefore(earliestDate)
      ? [moment(earliestDate), moment()]
      : [moment(earliestDate), endOfLastYear]
    : [startOfLastYear, endOfLastYear];
};

const getAllTime = earliestDate => [moment(earliestDate), moment()];

const dataPeriodMap = {
  [Periods.TODAY]: () => getToday(),
  [Periods.YESTERDAY]: earliestDate => getYesterday(earliestDate),
  [Periods.THIS_WEEK]: earliestDate => getThisWeek(earliestDate),
  [Periods.LAST_WEEK]: earliestDate => getLastWeek(earliestDate),
  [Periods.THIS_MONTH]: earliestDate => getThisMonth(earliestDate),
  [Periods.LAST_MONTH]: earliestDate => getLastMonth(earliestDate),
  [Periods.THIS_QUARTER]: earliestDate => getThisQuarter(earliestDate),
  [Periods.LAST_QUARTER]: earliestDate => getLastQuarter(earliestDate),
  [Periods.THIS_YEAR]: earliestDate => getThisYear(earliestDate),
  [Periods.LAST_YEAR]: earliestDate => getLastYear(earliestDate),
  [Periods.ALL_TIME]: earliestDate => getAllTime(earliestDate),
};

export const datePeriodCreator = (period, earliestDate) => dataPeriodMap[period](earliestDate);
