import { cloneDeep } from 'lodash';

export default (effects) => {
  const clonedEffects = cloneDeep(effects);

  delete clonedEffects.merchandising.fetchAccountMerchandisingImages;

  // accounts page report
  delete clonedEffects.accounts.downloadAccountsPageReport;

  // merchandising page report
  delete clonedEffects.merchandising.downloadMerchandisingPageReport;

  // sales page report
  delete clonedEffects.sales.downloadSalesPageReport;

  // admin page uploads and downloads
  delete clonedEffects.admin.downloadScorecard;
  delete clonedEffects.admin.uploadScorecards;
  delete clonedEffects.admin.deleteScorecard;
  delete clonedEffects.admin.changeTargetDate;

  // weekly/monthly reports on reports page
  delete clonedEffects.reports.downloadScorecardsWeekReport;
  delete clonedEffects.reports.downloadScorecardsMonthReport;

  // raw reports on reports page
  delete clonedEffects.reports.downloadAccountList;
  delete clonedEffects.reports.downloadAccountStatusReport;
  delete clonedEffects.reports.downloadMerchandisingReport;
  delete clonedEffects.reports.downloadScorecards;
  delete clonedEffects.reports.downloadVoidReport;
  delete clonedEffects.reports.downloadRawSalesReport;
  delete clonedEffects.reports.downloadMerchandisingAccountImagesReport;

  // sales reports on reports page
  delete clonedEffects.reports.downloadSalesReportByDistributor;
  delete clonedEffects.reports.downloadSkuSalesReport;
  delete clonedEffects.reports.downloadBrandSalesReport;
  delete clonedEffects.reports.downloadAccountSalesReport;

  // reports on management page
  delete clonedEffects.management.downloadBrandASMWeeklyReport;
  delete clonedEffects.management.downloadBrandASMMonthlyReport;
  delete clonedEffects.management.downloadMultiBrandASMWeeklyReport;
  delete clonedEffects.management.downloadMultiBrandASMMonthlyReport;
  delete clonedEffects.management.downloadDeadAccountsReport;
  delete clonedEffects.management.downloadDeadAccountsHotReport;
  delete clonedEffects.management.downloadActiveAccountsReport;
  delete clonedEffects.management.downloadAccountsTotalSalesReport;
  delete clonedEffects.management.submitEmailMonthly;
  delete clonedEffects.management.submitEmailWeekly;

  // time&efforts (uds) page
  delete clonedEffects.uds.downloadAsmUdsReport;
  delete clonedEffects.uds.downloadTimeEffortsReport;
  delete clonedEffects.uds.downloadTimeEffortsWeekReport;
  delete clonedEffects.uds.downloadTimeEffortsMonthReport;
  delete clonedEffects.uds.downloadAsmUdsBrandReport;
  delete clonedEffects.uds.downloadUdsAccountsReport;
  delete clonedEffects.uds.downloadUdsAccountsByBrandReport;
  delete clonedEffects.uds.downloadAsmUdsAllRepReport;
  delete clonedEffects.uds.downloadAsmUdsAllBrandReport;

  // schedule reports
  delete clonedEffects.schedule.downloadScheduleDailyReport;
  delete clonedEffects.schedule.downloadScheduleWeeklyReport;
  delete clonedEffects.schedule.downloadScheduleMonthlyReport;
  delete clonedEffects.schedule.downloadMetricsDailyReport;
  delete clonedEffects.schedule.downloadMetricsWeeklyReport;
  delete clonedEffects.schedule.downloadMetricsMonthlyReport;

  // accounts database page
  delete clonedEffects.database.downloadMasterList;

  return Object.values(clonedEffects).reduce(
    (acc, cur) => acc || Object.values(cur).includes(true),
    false,
  );
};
