import React from 'react';
import {
  arrayOf, number, shape, string,
} from 'prop-types';

import './index.scss';

const LineChartTooltip = ({
  tick,
  data,
}) => (
  <div className="line-chart-tooltip-container">
    <div className="line-chart-tooltip">
      <div className="line-chart-tooltip__tick-container">{tick}</div>
      <div className="line-chart-tooltip__data-container">
        {data.map(item => (
          <div className="line-chart-tooltip__item" key={item.title}>
            <div className="line-chart-tooltip__item-inner">
              <svg width="13" height="13" className="line-chart-tooltip__item-square">
                <rect width="13" height="13" fill={item.color} />
              </svg>
              <span>{item.title}</span>
            </div>
            <p className="line-chart-tooltip__value">{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

LineChartTooltip.propTypes = {
  tick: string.isRequired,
  data: arrayOf(shape({
    title: string,
    value: number,
    color: string,
  }))
    .isRequired,
};

export default LineChartTooltip;
