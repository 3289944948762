import RetailPrice from './RetailPrice';

class RetailPricesBySku {
  constructor({ name, retailPrices }) {
    this.name = name;
    this.retailPrices = retailPrices;
  }

  static parse(name, info) {
    return new RetailPricesBySku({
      name,
      retailPrices: info.map(retailPrice => RetailPrice.parse(retailPrice)),
    });
  }
}

export default RetailPricesBySku;
