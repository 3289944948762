import React from 'react';

import { Button } from 'antd';
import { bool, func } from 'prop-types';

import './index.scss';

const DownloadAccountsButton = ({
  isDownloadingAccounts,
  onDownloadAccounts,
}) => (
  <div className="accounts-report-button">
    <Button
      type="primary"
      icon="file"
      onClick={onDownloadAccounts}
      loading={isDownloadingAccounts}
    >
      Map Account List
    </Button>
  </div>
);

DownloadAccountsButton.propTypes = {
  isDownloadingAccounts: bool.isRequired,
  onDownloadAccounts: func.isRequired,
};

export default DownloadAccountsButton;
