import React from 'react';
import {
  Select, Icon, Input,
} from 'antd';
import {
  string,
  func,
  arrayOf,
  object,
  instanceOf,
} from 'prop-types';

import FieldTitle from 'common/components/FieldTitle';
import RangePicker from 'common/components/RangePicker';
import PeriodPicker from 'common/components/PeriodPicker';

import FilterOptions from 'scheduleModule/models/FilterOptions';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import { scheduleFiltersPermissionTypes } from 'userModule/constants/filterPermissions';

import './index.scss';

const { Option } = Select;

const CommonOption = option => (
  <Option key={option} value={option} title={option}>
    {option}
  </Option>
);

const searchIcon = <Icon type="search" style={{ fontSize: '12px' }} />;

const FiltersBoard = ({
  disabledDate,
  dateRange,
  name,
  address,
  channelTypes,
  cities,
  states,
  chains,
  representatives,
  territories,
  accountsSource,
  regions,
  counties,
  banners,
  frequencies,
  zipCode,
  inactiveReps,
  onChangeField,
  filterOptions,
  permissions,
}) => {
  const shouldRenderAdminFilters = permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_DATA_SOURCE)
  || permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_FREQUENCY)
  || permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REPRESENTATIVE)
  || permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REPS_INACTIVE);

  const onChangeZipCode = (value) => {
    const numRegExp = /^\d+$/;
    if (numRegExp.test(value)) {
      return onChangeField('zipCode', value);
    }
    return '';
  };

  return (
    <div className="schedule-filters-board">
      <div className="schedule-filters-board__wrapper schedule-filters-board_users-section">
        <div className="schedule-filters-board__filter schedule-filters-board__filter_date-picker">
          <RangePicker
            format={DATE_FORMAT}
            disabledDate={disabledDate}
            value={dateRange}
            onChange={value => onChangeField('dateRange', value)}
          />
        </div>
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_DATE_PERIOD) && (
          <div className="schedule-filters-board__filter">
            <FieldTitle>&nbsp;</FieldTitle>
            <PeriodPicker
              onChange={value => onChangeField('periodRange', value)}
            />
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_ACCOUNT) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_search">
            <FieldTitle>Account</FieldTitle>
            <Input
              value={name}
              onChange={e => onChangeField('name', e.target.value)}
              placeholder="Search name"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_ADDRESS) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_search">
            <FieldTitle>Address</FieldTitle>
            <Input
              value={address}
              onChange={e => onChangeField('address', e.target.value)}
              placeholder="Search address"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_CITY) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>City</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('cities', value)}
              value={cities}
              maxTagCount={2}
              placeholder="Select cities"
            >
              {filterOptions.cities.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_STATE) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>State</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('states', value)}
              value={states}
              maxTagCount={2}
              placeholder="Select states"
            >
              {filterOptions.states.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_ZIP) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_search">
            <FieldTitle>Zip Code</FieldTitle>
            <Input
              value={zipCode}
              onChange={e => onChangeZipCode(e.target.value)}
              placeholder="Search zip code"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_CHAIN) && (
        <>
          <div className="schedule-filters-board__break" />
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>Chain</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('chains', value)}
              value={chains}
              maxTagCount={2}
              placeholder="Select chains"
            >
              {filterOptions.chains.map(CommonOption)}
            </Select>
          </div>
        </>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_BANNER) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>Banner</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('banners', value)}
              value={banners}
              maxTagCount={2}
              placeholder="Select banners"
            >
              {filterOptions.banners.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_CHANNEL) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>Channel</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('channelTypes', value)
              }
              value={channelTypes}
              maxTagCount={2}
              placeholder="Select channels"
            >
              {filterOptions.channelTypes.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REGION) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>Region</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('regions', value)}
              value={regions}
              maxTagCount={2}
              placeholder="Select regions"
            >
              {filterOptions.regions.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_COUNTY) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>County</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('counties', value)}
              value={counties}
              maxTagCount={2}
              placeholder="Select counties"
            >
              {filterOptions.counties.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_AREA) && (
          <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
            <FieldTitle>Area</FieldTitle>
            <Select
              mode="multiple"
              className="schedule-filters-board__select"
              onChange={value => onChangeField('territories', value)
              }
              value={territories}
              maxTagCount={2}
              placeholder="Select area"
            >
              {filterOptions.territories.map(CommonOption)}
            </Select>
          </div>
        )}
      </div>

      {shouldRenderAdminFilters && (
        <div className="schedule-filters-board__wrapper schedule-filters-board__admins-section">
          {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_DATA_SOURCE) && (
            <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
              <FieldTitle>Data Source</FieldTitle>
              <Select
                className="schedule-filters-board__select"
                onChange={value => onChangeField('accountsSource', value)}
                value={accountsSource}
              >
                {filterOptions.accountsSources.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_FREQUENCY) && (
            <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
              <FieldTitle>Frequency</FieldTitle>
              <Select
                mode="multiple"
                className="schedule-filters-board__select"
                onChange={value => onChangeField('frequencies', value)}
                value={frequencies}
                maxTagCount={2}
                placeholder="Select frequencies"
              >
                {filterOptions.frequencies.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REPRESENTATIVE) && (
            <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
              <FieldTitle>Representatives</FieldTitle>
              <Select
                mode="multiple"
                className="schedule-filters-board__select"
                onChange={value => onChangeField('representatives', value)
                }
                value={representatives}
                maxTagCount={2}
                placeholder="Select representatives"
              >
                {filterOptions.representatives.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REPS_INACTIVE) && (
            <div className="schedule-filters-board__filter schedule-filters-board__filter_multiselect">
              <FieldTitle>Inactive Representatives</FieldTitle>
              <Select
                mode="multiple"
                className="schedule-filters-board__select"
                onChange={value => onChangeField('inactiveReps', value)
                }
                value={inactiveReps}
                maxTagCount={2}
                placeholder="Select representatives"
              >
                {filterOptions.inactiveReps.map(CommonOption)}
              </Select>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
FiltersBoard.propTypes = {
  disabledDate: func.isRequired,
  dateRange: arrayOf(object).isRequired,
  name: string.isRequired,
  address: string.isRequired,
  channelTypes: arrayOf(string).isRequired,
  cities: arrayOf(string).isRequired,
  states: arrayOf(string).isRequired,
  chains: arrayOf(string).isRequired,
  representatives: arrayOf(string).isRequired,
  territories: arrayOf(string).isRequired,
  accountsSource: string.isRequired,
  regions: arrayOf(string).isRequired,
  counties: arrayOf(string).isRequired,
  banners: arrayOf(string).isRequired,
  frequencies: arrayOf(string).isRequired,
  zipCode: string.isRequired,
  inactiveReps: arrayOf(string).isRequired,
  filterOptions: instanceOf(FilterOptions).isRequired,
  onChangeField: func.isRequired,
  permissions: arrayOf(string).isRequired,
};

export default FiltersBoard;
