class SkuCase {
  constructor({ name, amount }) {
    this.name = name;
    this.amount = amount;
  }

  static parse(obj) {
    return new SkuCase({
      name: obj.Name,
      brand: obj.Brand,
    });
  }
}

export default SkuCase;
