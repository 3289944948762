import React, { Fragment, PureComponent } from 'react';
import { Divider, Button, Select } from 'antd';
import {
  bool, func, arrayOf, string,
} from 'prop-types';

import SectionHeading from 'common/components/SectionHeading';
import FieldTitle from 'common/components/FieldTitle';

import { reportsPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

const HEADING = 'Chain Reports';

const { Option } = Select;

const ScorecardDropdownOption = scorecard => (
  <Option
    key={scorecard.scorecardID}
    value={scorecard.scorecardID}
    title={scorecard.scorecardFilename}
  >
    {scorecard.scorecardFilename}
  </Option>
);

class ChainReportsSection extends PureComponent {
  static propTypes = {
    onDownloadScorecards: func.isRequired,
    isScoreCardsDownloading: bool.isRequired,
    childrenPermissions: arrayOf(string).isRequired,
    selectedBrand: string.isRequired,
  };

  state = {
    selectedScorecardID: null,
    selectedBrand: null,
  }

  static getDerivedStateFromProps(props, state) {
    const { scorecardsByBrand, selectedBrand: propsSelectedBrand } = props;
    const availableScorecards = scorecardsByBrand[propsSelectedBrand];
    const defaultScorecardID = availableScorecards
      ? availableScorecards[0].scorecardID
      : null;

    const { selectedScorecardID: stateSelectedScorecardID, selectedBrand } = state;
    const selectedScorecardID = propsSelectedBrand === selectedBrand
      ? stateSelectedScorecardID
      : defaultScorecardID;

    return {
      ...state,
      selectedScorecardID,
      selectedBrand: propsSelectedBrand,
    };
  }

  onScorecardChange = (value) => {
    this.setState({
      selectedScorecardID: value,
    });
  }


  onReportButtonClick = () => {
    const { onDownloadScorecards } = this.props;
    const { selectedScorecardID } = this.state;
    onDownloadScorecards(selectedScorecardID);
  }

  render() {
    const {
      isScoreCardsDownloading,
      childrenPermissions,
      selectedBrand,
      scorecardsByBrand,
    } = this.props;

    const { selectedScorecardID } = this.state;

    const scorecardFilenames = scorecardsByBrand[selectedBrand] || [];

    return (
      childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_CHAIN_REPORT) && (
        <Fragment>
          <SectionHeading>{HEADING}</SectionHeading>
          <section className="chain-reports-section">
            <div className="chain-reports-section__select-containter">
              <FieldTitle>Select File</FieldTitle>
              <Select
                className="chain-reports-section__select"
                onChange={this.onScorecardChange}
                value={selectedScorecardID}
                dropdownMatchSelectWidth={false}
              >
                {scorecardFilenames.map(ScorecardDropdownOption)}
              </Select>
            </div>
            <div className="chain-reports-section__download-button-container">
              <FieldTitle>&nbsp;</FieldTitle>
              <Button
                type="primary"
                icon="file"
                onClick={this.onReportButtonClick}
                loading={isScoreCardsDownloading}
              >
                Scorecard and Promos
              </Button>
            </div>
          </section>
          <Divider />
        </Fragment>
      )
    );
  }
}

export default ChainReportsSection;
