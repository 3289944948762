class RepresentativeMetric {
  constructor({
    name, metricName, startDate, value,
  }) {
    this.name = name;
    this.metricName = metricName;
    this.startDate = startDate;
    this.value = value;
  }

  static fieldNamesMap = new Map([
    ['visits', 'Accounts Visited'],
    ['accountsVisited', 'Visits'],
    ['hours', 'Hours Worked'],
    ['miles', 'Mileage Driven'],
    ['newAccounts', 'New Accounts - UDS (All)'],
    ['newAccountsUnique', 'New Accounts - UDS (New)'],
    ['newAccountsChain', 'New Accounts - Chain (All)'],
    ['newAccountsChainUnique', 'New Accounts - Chain (New)'],
    ['hotLeads', 'Hot Leads (All)'],
    ['hotLeadsUnique', 'Hot Leads (New)'],
    ['warmLeads', 'Warm Leads (All)'],
    ['warmLeadsUnique', 'Warm Leads (New)'],
    ['discontinued', 'Discontinued (All)'],
    ['discontinuedUnique', 'Discontinued (New)'],

    ['merchForms', 'Merchandising Visits'],
    ['merchStores', 'Accounts Merchandised'],
    ['facingsAdded', 'Facings (Added)'],
    ['reorders', 'Reorders'],
    ['avgFacings', 'Facings (Avg)'],
    ['avgSkus', 'SKUs (Avg)'],
    ['secondaryPlacements', 'Secondary Placements (All)'],
    ['secondaryPlacementsUnique', 'Secondary Placement (New)'],

    ['orders', 'Orders'],
    ['totalAmount', 'Sales'],
    ['totalCases', 'Cases'],
    ['uniqueAccountOrders', 'Accounts Ordered'],
  ]);

  static metricNamesMap = new Map([
    ['AvgNumberOfFacings', 'avgFacings'],
    ['AvgNumberOfSKUs', 'avgSkus'],
    ['Discontinued', 'discontinued'],
    ['DiscontinuedUnique', 'discontinuedUnique'],
    ['FacingsAdded', 'facingsAdded'],
    ['HotLeads', 'hotLeads'],
    ['HotLeadsUnique', 'hotLeadsUnique'],
    ['HoursWorked', 'hours'],
    ['MerchFormsSubmitted', 'merchForms'],
    ['MerchStoresVisited', 'merchStores'],
    ['MileageDriven', 'miles'],
    ['NewAccounts', 'newAccounts'],
    ['NewAccountsChain', 'newAccountsChain'],
    ['NewAccountsChainUnique', 'newAccountsChainUnique'],
    ['NewAccountsUnique', 'newAccountsUnique'],
    ['OrdersSubmitted', 'orders'],
    ['ReordersPlaced', 'reorders'],
    ['SecondaryPlacements', 'secondaryPlacements'],
    ['SecondaryPlacementsUnique', 'secondaryPlacementsUnique'],
    ['StatusStoresVisited', 'visits'],
    ['StatusFormsSubmitted', 'accountsVisited'],
    ['TotalAmount', 'totalAmount'],
    ['TotalCasesCount', 'totalCases'],
    ['UniqueAccountOrders', 'uniqueAccountOrders'],
    ['WarmLeads', 'warmLeads'],
    ['WarmLeadsUnique', 'warmLeadsUnique'],
  ])

  static accountFields = [
    'accountsVisited',
    'visits',
    'newAccounts',
    'newAccountsUnique',
    'newAccountsChain',
    'newAccountsChainUnique',
    'hotLeads',
    'hotLeadsUnique',
    'warmLeads',
    'warmLeadsUnique',
    'discontinued',
    'discontinuedUnique',
  ];

  static merchandisingFields = [
    'merchForms',
    'merchStores',
    'secondaryPlacements',
    'secondaryPlacementsUnique',
    'reorders',
    'avgSkus',
    'avgFacings',
    'facingsAdded',
  ];

  static salesFields = [
    'orders',
    'uniqueAccountOrders',
    'totalAmount',
    'totalCases',
  ];

  static parse(obj, name, metricName) {
    return new RepresentativeMetric({
      name,
      metricName: RepresentativeMetric.metricNamesMap.get(metricName),
      startDate: obj.DateStart,
      value: obj.Value,
    });
  }
}

export default RepresentativeMetric;
