import { connect } from 'react-redux';

import DatabasePage from 'databaseModule/components/DatabasePage';
import { permissionsSelector, filterPermissionsSelector } from 'databaseModule/selectors/databasePageSelectors';


export default connect(
  state => ({
    userInfo: state.session.userInfo,
    fetchingFilterOptions:
      state.loading.effects.database.fetchFilterOptions,
    isFilterInitialized: state.database.initialized,
    filter: state.database.filter,
    filterOptions: state.database.filterOptions,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.database.fetchFilterOptions,
    setFilter: dispatch.database.setFilter,
    resetDatabaseStore: dispatch.database.clear,
  }),
)(DatabasePage);
