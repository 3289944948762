import jwtDecode from 'jwt-decode';

import { TOKEN } from '../constants/localStorageNames';

const saveToken = ({ token, tokenType }) => {
  localStorage.setItem(TOKEN, `${tokenType} ${token}`);
};

const clearToken = () => {
  localStorage.removeItem(TOKEN);
};

const getToken = () => {
  const token = localStorage.getItem(TOKEN);

  return token || null;
};

const getUserInfo = () => {
  const token = getToken();
  return token ? jwtDecode(token) : null;
};

const isAuthorized = () => Boolean(getToken());

export {
  saveToken, getToken, isAuthorized, clearToken, getUserInfo,
};

export default {
  saveToken, getToken, isAuthorized, clearToken, getUserInfo,
};
