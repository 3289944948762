/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const productsOnPromoDataSelector = state => state.merchandising.productsOnPromoData;

export const currentProductsOnPromoNamesSelector = createSelector(
  productsOnPromoDataSelector,
  productsOnPromoData => productsOnPromoData.map(productsOnPromo => productsOnPromo.value),
);

export const colorMapSelector = createSelector(
  currentProductsOnPromoNamesSelector,
  currentProductsOnPromoNames => colorGenerator(currentProductsOnPromoNames),
);
