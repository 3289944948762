class AsmScheduleData {
  constructor({
    name, metricName, startDate, value,
  }) {
    this.name = name;
    this.metricName = metricName;
    this.startDate = startDate;
    this.value = value;
  }

  static fieldNamesMap = new Map([
    ['storesVisited', 'Accounts Visited'],
    ['visitsMissed', 'Missed Visits'],
    ['visitsPlanned', 'Planned Visits'],
    ['visitsUnplanned', 'Unplanned Visits'],
    ['hoursWorked', 'Hours Worked'],
    ['miles', 'Mileage Driven'],
  ]);

  static metricNamesMap = new Map([
    ['AccountsVisited', 'storesVisited'],
    ['MissedVisits', 'visitsMissed'],
    ['PlannedVisits', 'visitsPlanned'],
    ['UnplannedVisits', 'visitsUnplanned'],
    ['HoursWorked', 'hoursWorked'],
    ['MileageDriven', 'miles'],
  ]);

  static parse(obj, name, metricName) {
    return new AsmScheduleData({
      name,
      metricName: AsmScheduleData.metricNamesMap.get(metricName),
      startDate: obj.DateStart,
      value: obj.Value,
    });
  }
}

export default AsmScheduleData;
