import { connect } from 'react-redux';

import Dropzone from 'common/components/Dropzone';

export default connect(
  state => ({
    files: state.admin.uploadScorecardFiles,
    isUploading: state.admin.filesLoading,
  }),
  dispatch => ({
    onAddFiles: dispatch.admin.setUploadScorecardFiles,
    onDeleteFile: dispatch.admin.deleteUploadScorecardFile,
  }),
)(Dropzone);
