/* eslint-disable import/prefer-default-export */
import { DATE_FORMAT } from 'common/constants/dateConstants';

export const reportScheduleParamsMapper = (user, params) => ({
  Login: user.login || null,

  EntireMarketplace: params.entireMarketplace,
  Chains: params.chains,
  Banners: params.banners,
  Channels: params.channels,
  Regions: params.regions,
  Counties: params.counties,
  Areas: params.areas,
  Brand: params.brand,
  AccountRepresentatives: params.accountReps,

  StartsOn: params.startsOnDate ? params.startsOnDate.format(DATE_FORMAT) : null,

  AmountOfDays: params.amountOfDays || null,
  NumberOfPeriods: params.numberOfPeriods || null,

  MarketplaceMetricsIncluded: params.checkedMarketMetrics || null,
  FilteredMetricsIncluded: params.checkedFilteredMetrics || null,

  EmailsTo: params.to || null,
  EmailsCC: params.cc || null,

  RepeatEvery: params.repeatDays || null,
  RepeatEveryType: params.repeatPeriod || null,

  WeekDayname: params.weekDay || null,

  MonthRepeat: params.monthRepetition || null,
  MonthDays: params.customMonthDayRepetition || null,
  MonthDayname: params.customMonthWeekDayRepetition || null,
  MonthDaynumber: params.customMonthWeekDayNumberRepetition || null,

  EndsOn: params.endsOnDate ? params.endsOnDate.format(DATE_FORMAT) : null,
  EndsAfter: params.endsAfterNumber || null,

  MarketplaceBeforePhotoIncluded: params.marketplaceBeforePhoto,
  MarketplaceAfterPhotoIncluded: params.marketplaceAfterPhoto,
  MarketplaceSecondaryPhotoIncluded: params.marketplaceSecondaryPhoto,
  MarketplaceSecondaryPhotoTypes: params.marketplaceSecondaryPhotoTypes,

  FilteredBeforePhotoIncluded: params.filteredBeforePhoto,
  FilteredAfterPhotoIncluded: params.filteredAfterPhoto,
  FilteredSecondaryPhotoIncluded: params.filteredSecondaryPhoto,
  FilteredSecondaryPhotoTypes: params.filteredSecondaryPhotoTypes,
});
