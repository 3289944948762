import { connect } from 'react-redux';

import TotalSalesBySkuChart from 'salesModule/components/TotalSalesBySkuChart';
import { skuNamesAndColorsSalesSelector, shouldRenderSalesChartSelector } from 'salesModule/selectors/totalSalesCasesBySkuSelector';

export default connect(
  (state) => {
    const { skuNames, skuNamesColorMap } = skuNamesAndColorsSalesSelector(state);
    const shouldRenderChart = shouldRenderSalesChartSelector(state);
    return {
      totalSalesBySku: state.sales.totalSalesBySkuData,
      shouldRenderChart,
      withDivider: shouldRenderChart,
      skuNames,
      colorMap: skuNamesColorMap,
    };
  },
)(TotalSalesBySkuChart);
