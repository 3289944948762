class FilterOptions {
  constructor({
    channels,
    cities,
    states,
    chains,
    representatives,
    territories,
    accountsSources,
    regions,
    counties,
    banners,
    frequencies,
    activeAccounts,
  }) {
    this.cities = cities;
    this.states = states;
    this.chains = chains;
    this.channels = channels;
    this.territories = territories;
    this.regions = regions;
    this.counties = counties;
    this.banners = banners;
    this.frequencies = frequencies;
    this.representatives = representatives;
    this.accountsSources = accountsSources;
    this.activeAccounts = activeAccounts;
  }

  static parse(obj) {
    return new FilterOptions({
      channels: obj.Channels,
      cities: obj.Cities,
      states: obj.States,
      chains: obj.Chains,
      representatives: obj.Representatives,
      territories: obj.Territories,
      accountsSources: obj.AccountSources,
      regions: obj.Regions,
      counties: obj.Counties,
      banners: obj.Banners,
      frequencies: obj.Frequencies.map(String),
      activeAccounts: obj.AccountActive,
    });
  }
}

export default FilterOptions;
