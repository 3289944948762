/* eslint-disable react/prefer-stateless-function, react/destructuring-assignment, no-nested-ternary */
import React, { PureComponent } from 'react';
import {
  instanceOf, func,
} from 'prop-types';

import {
  Button,
  Input,
  Icon,
} from 'antd';


import Brand from 'adminModule/models/Brand';

import './index.scss';

class DeleteBrandForm extends PureComponent {
  static propTypes = {
    onDeleteBrand: func.isRequired,
    brand: instanceOf(Brand).isRequired,
  }

  state = {
    inputText: '',
  }

  submitForm = (e) => {
    e.preventDefault();
    const { onDeleteBrand, brand } = this.props;
    onDeleteBrand({ brand: brand.brand });
  };


  onInputChange = (e) => {
    this.setState({
      inputText: e.currentTarget.value,
    });
  }

  render() {
    const { brand } = this.props.brand;

    const { inputText } = this.state;

    const brandDeleteText = `delete ${brand}`;

    return (
      <div className="delete-brand-form">
        <div className="delete-brand-form__info">
          <p className="delete-brand-form__title">
            <Icon type="warning" /> You are about to delete brand {brand}

          </p>
          <p className="delete-brand-form">
            This action cannot be undone. To confirm type <b>&quot;{brandDeleteText}&quot;</b>
          </p>

        </div>
        <Input onChange={this.onInputChange} />
        <div className="delete-brand-form__submit-button-container">
          <Button
            className="delete-brand-form__button_delete"
            icon="delete"
            type="ghost"
            htmlType="submit"
            disabled={!(brandDeleteText === inputText)}
            onClick={this.submitForm}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  }
}

export default DeleteBrandForm;
