import React, { PureComponent } from 'react';
import {
  func, instanceOf, arrayOf, string,
} from 'prop-types';
import moment from 'moment';

import { showWarning } from 'common/helpers/notificationManager';
import { datePeriodCreator } from 'common/utilities/datePeriodCreator';
import { incorrectDateInterval } from 'common/constants/notificationConfig';

import FilterOptions from 'scheduleModule/models/FilterOptions';
import AccountsFilterModel from 'scheduleModule/models/AccountsFilter';
import FilterButtons from 'common/components/FilterButtons';

import { DATE_FORMAT } from 'common/constants/dateConstants';
import FiltersBoard from './FiltersBoard';

import './index.scss';

class AccountsFilter extends PureComponent {
  static propTypes = {
    applyFilter: func.isRequired,
    filterOptions: instanceOf(FilterOptions).isRequired,
    initialFilterModel: instanceOf(AccountsFilterModel).isRequired, // eslint-disable-line react/no-unused-prop-types
    permissions: arrayOf(string).isRequired,
  };

  static getStateFromFilterModel(filterModel) {
    return {
      dateRange: [
        moment(filterModel.startDate),
        moment(filterModel.endDate),
      ],
      name: filterModel.name,
      address: filterModel.address,
      channelTypes: filterModel.channelTypes,
      cities: filterModel.cities,
      states: filterModel.states,
      chains: filterModel.chains,
      representatives: filterModel.representatives,
      territories: filterModel.territories,
      accountsSource: filterModel.accountsSource,
      numberOfSkus: filterModel.numberOfSkus,
      numberOfFacings: filterModel.numberOfFacings,
      regions: filterModel.regions,
      counties: filterModel.counties,
      banners: filterModel.banners,
      frequencies: filterModel.frequencies,
      zipCode: filterModel.zipCode,
      inactiveReps: filterModel.inactiveReps,
    };
  }

  constructor(props) {
    super(props);

    const { initialFilterModel, filterOptions } = props;

    this.state = {
      ...AccountsFilter.getStateFromFilterModel(initialFilterModel),
      earliestDate: filterOptions.earliestDate,
      isFilterToggled: false,

      isDefaultEnabled: !initialFilterModel.isModelEqualWithDefault(),
    };
  }

  goPrevWeek = () => {
    const {
      dateRange: [startDate, endDate],
    } = this.state;
    this.setState({
      dateRange: [moment(startDate).subtract(7, 'days'), moment(endDate).subtract(7, 'days')],
    }, () => this.onApply());
  }

  goNextWeek = () => {
    const {
      dateRange: [startDate, endDate],
    } = this.state;
    this.setState({
      dateRange: [moment(startDate).add(7, 'days'), moment(endDate).add(7, 'days')],
    }, () => this.onApply());
  }

  onPeriodChange = (value) => {
    const { earliestDate } = this.state;
    const [startDate, endDate] = datePeriodCreator(value, earliestDate);
    this.setState({
      dateRange: [startDate, endDate],
    });
  };

  onChangeField = (fieldName, value) => {
    if (fieldName === 'periodRange') {
      this.onPeriodChange(value);
    }

    const newState = {
      ...this.state,
      [fieldName]: value,
      isFilterToggled: true,
    };

    const newFilterModel = new AccountsFilterModel(newState);
    this.setState({
      [fieldName]: value,
      isFilterToggled: true,
      isDefaultEnabled: !newFilterModel.isModelEqualWithDefault(),
    });
  };

  onApply = () => {
    const {
      state: {
        dateRange: [startDate, endDate],
        name,
        address,
        channelTypes,
        cities,
        states,
        chains,
        representatives,
        territories,
        accountsSource,
        numberOfSkus,
        numberOfFacings,
        earliestDate,
        regions,
        counties,
        banners,
        frequencies,
        zipCode,
        inactiveReps,
      },
      props: { applyFilter },
    } = this;

    let checkedStartDate;
    let checkedEndDate;

    if (
      moment(startDate).isBefore(earliestDate)
      || moment(endDate).isBefore(earliestDate)
      || !startDate
      || !endDate
    ) {
      checkedStartDate = moment(earliestDate).format(DATE_FORMAT);
      checkedEndDate = moment().format(DATE_FORMAT);
      showWarning(incorrectDateInterval);
    } else {
      checkedStartDate = startDate.format(DATE_FORMAT);
      checkedEndDate = endDate.format(DATE_FORMAT);
    }

    const filterModel = new AccountsFilterModel({
      startDate: checkedStartDate,
      endDate: checkedEndDate,
      name,
      address,
      channelTypes,
      cities,
      states,
      chains,
      representatives,
      territories,
      accountsSource,
      numberOfSkus,
      numberOfFacings,
      regions,
      counties,
      banners,
      frequencies,
      zipCode,
      inactiveReps,
    });

    const normalizedFilter = filterModel.getNormalizedFilter();
    const newState = AccountsFilter.getStateFromFilterModel(
      normalizedFilter,
    );
    applyFilter(normalizedFilter);
    this.setState(() => ({
      ...newState,
      isFilterToggled: false,
      isDefaultEnabled: !filterModel.isModelEqualWithDefault(),
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.onApply();
  };

  onSetDefaultFilters = () => {
    const defaultFilterModel = new AccountsFilterModel(
      AccountsFilterModel.defaultParams,
    );

    const newState = AccountsFilter.getStateFromFilterModel(
      defaultFilterModel,
    );

    this.setState(() => ({
      ...newState,
      isFilterToggled: true,
      isDefaultEnabled: !defaultFilterModel.isModelEqualWithDefault(),
    }));
  };

  revertFilters = () => {
    const { initialFilterModel, filterOptions } = this.props;
    this.setState({
      ...AccountsFilter.getStateFromFilterModel(initialFilterModel),
      earliestDate: filterOptions.earliestDate,
      isFilterToggled: false,
      isDefaultEnabled: !initialFilterModel.isModelEqualWithDefault(),
    });
  };

  disabledDate = (current) => {
    const { earliestDate } = this.state;
    return (
      current > moment().add(1, 'years').endOf('day')
      || current < moment(earliestDate).startOf('day')
    );
  }

  render() {
    const {
      dateRange,
      name,
      address,
      channelTypes,
      cities,
      states,
      chains,
      representatives,
      territories,
      accountsSource,
      numberOfSkus,
      numberOfFacings,
      regions,
      counties,
      banners,
      frequencies,
      zipCode,
      inactiveReps,
      isFilterToggled,
      isDefaultEnabled,
    } = this.state;

    const { filterOptions, permissions } = this.props;

    return (
      <form onSubmit={this.onSubmit} className="schedule-page-filter">
        <div className="schedule-page-filter-inner">
          <div className="schedule-page-filter__filters-container">
            <FiltersBoard
              disabledDate={this.disabledDate}
              dateRange={dateRange}
              name={name}
              address={address}
              channelTypes={channelTypes}
              cities={cities}
              states={states}
              chains={chains}
              representatives={representatives}
              territories={territories}
              accountsSource={accountsSource}
              numberOfSkus={numberOfSkus}
              numberOfFacings={numberOfFacings}
              regions={regions}
              counties={counties}
              banners={banners}
              frequencies={frequencies}
              zipCode={zipCode}
              inactiveReps={inactiveReps}
              filterOptions={filterOptions}
              onChangeField={this.onChangeField}
              permissions={permissions}
            />
          </div>
          <div
            className="schedule-page-filter__buttons-container"
          >
            <FilterButtons
              isFilterToggled={isFilterToggled}
              isDefaultEnabled={isDefaultEnabled}
              revertFilters={this.revertFilters}
              onSetDefaultFilters={this.onSetDefaultFilters}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default AccountsFilter;
