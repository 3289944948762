class AccountByDay {
  constructor({ status, amount, percentage }) {
    this.status = status;
    this.amount = amount;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new AccountByDay({
      status: obj.Status,
      amount: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default AccountByDay;
