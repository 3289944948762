import React, { PureComponent } from 'react';
import {
  bool, func, arrayOf, instanceOf, shape, string,
} from 'prop-types';
import {
  Skeleton, Select, DatePicker, Button, InputNumber, Divider,
} from 'antd';
import moment from 'moment';

import FieldTitle from 'common/components/FieldTitle';
import SectionHeading from 'common/components/SectionHeading';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import { showError } from 'common/helpers/notificationManager';

import TargetRepresentative from 'adminModule/models/TargetRepresentative';


import './index.scss';

const CommonOption = (title, id = null) => (
  <Select.Option key={id || title} value={id || title}>
    {title}
  </Select.Option>
);
const HEADING = 'Target';

class TargetDateSection extends PureComponent {
  static propTypes = {
    isFilterOptionsFetching: bool.isRequired,
    isTargetBrandChanging: bool.isRequired,
    onChangeTargetBrands: func.isRequired,
    onChangeTargetRepresentatives: func.isRequired,
    onChangeTargetDate: func.isRequired,
    onChangeTargetNumber: func.isRequired,
    onApply: func.isRequired,
    brands: arrayOf(string).isRequired,
    representatives: arrayOf(instanceOf(TargetRepresentative)).isRequired,
    earliestDate: string,
    target: shape({
      brandName: string,
      date: string,
    }).isRequired,
  };

  static defaultProps = {
    earliestDate: '',
  }

  onChangeNumber = (value) => {
    if (!Number(value) || Number.isNaN(value)) {
      this.onTargetNumberChange(0);
      return;
    }
    this.onTargetNumberChange(Math.abs(value));
  };

  onTargetNumberChange = (number) => {
    const { onChangeTargetNumber } = this.props;
    onChangeTargetNumber(number);
  }

  onBrandChange = (brands) => {
    const { onChangeTargetBrands } = this.props;
    onChangeTargetBrands(brands);
  }

  onRepresentativeChange = (representatives) => {
    const { onChangeTargetRepresentatives } = this.props;
    onChangeTargetRepresentatives(representatives);
  }

  onDateChange = (date) => {
    const { onChangeTargetDate } = this.props;
    onChangeTargetDate(moment(date).isValid() ? moment(date).format(DATE_FORMAT) : null);
  }

  disabledDate = (current) => {
    const { earliestDate } = this.props;
    return current > moment().endOf('day') || (moment(earliestDate).isValid() && current < moment(earliestDate).startOf('day'));
  }

  onApply = () => {
    const { onApply, target } = this.props;
    if (
      !target.brands
      || !target.representatives
      || !target.date
      || !moment(target.date).isValid()
      || target.targetNumber < 0
    ) {
      showError({
        message: 'Error',
        description: 'Incorrect input values!',
      });
    } else onApply();
  }

  render() {
    const {
      isFilterOptionsFetching, brands, representatives, target, isTargetBrandChanging,
    } = this.props;
    return (
      <Skeleton loading={isFilterOptionsFetching}>
        <Divider />
        <SectionHeading>{HEADING}</SectionHeading>
        <div className="target-brand-section">
          <div className="target-brand-section__row">
            <div className="target-brand-section__select-container">
              <FieldTitle>Brands</FieldTitle>
              <Select
                mode="multiple"
                onChange={selectedBrands => this.onBrandChange(selectedBrands)}
                value={target.brands}
                className="target-brand-section__select"
                maxTagCount={2}
                placeholder="Select brands"
              >
                {brands.map(brand => CommonOption(brand))}
              </Select>
            </div>
            <div className="target-brand-section__select-container">
              <FieldTitle>Representatives</FieldTitle>
              <Select
                mode="multiple"
                onChange={selectedRepresentatives => this.onRepresentativeChange(selectedRepresentatives)}
                value={target.representatives}
                className="target-brand-section__select"
                maxTagCount={2}
                placeholder="Select representatives"
              >
                {representatives.map(representative => CommonOption(representative.name, representative.ID))}
              </Select>
            </div>
          </div>
          <div className="target-brand-section__row">
            <div
              className="target-brand-section__number-container"
            >
              <FieldTitle>Target Amount</FieldTitle>
              <InputNumber
                min={0}
                max={10}
                value={target.targetNumber}
                onChange={this.onChangeNumber}
              />
            </div>
            <div className="target-brand-section__date-picker-container">
              <FieldTitle>Target Date</FieldTitle>
              <DatePicker
                onChange={this.onDateChange}
                value={target.date ? moment(target.date) : null}
                disabledDate={this.disabledDate}
                format={DATE_FORMAT}
                showToday={false}
                placeholder="Target Date"
                suffixIcon={<div />}
              />
            </div>
            <div className="target-brand-section__button-container">
              <FieldTitle>&nbsp;</FieldTitle>
              <Button
                type="primary"
                icon="clock-circle"
                htmlType="submit"
                loading={isTargetBrandChanging}
                onClick={this.onApply}
              >
              Change
              </Button>
            </div>
          </div>
        </div>
      </Skeleton>
    );
  }
}

export default TargetDateSection;
