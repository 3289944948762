import { connect } from 'react-redux';
import { permissionsSelector } from 'managementModule/selectors/managementPageSelectors';

import ASMReportsSection from '../components/ASMReportsSection';

export default connect(
  state => ({
    isBrandASMWeeklyReportDownloading:
      state.loading.effects.management.downloadBrandASMWeeklyReport,
    isBrandASMMonthlyReportDownloading:
      state.loading.effects.management.downloadBrandASMMonthlyReport,
    isMultiBrandASMWeeklyReportDownloading:
      state.loading.effects.management.downloadMultiBrandASMWeeklyReport,
    isMultiBrandASMMonthlyReportDownloading:
      state.loading.effects.management.downloadMultiBrandASMMonthlyReport,
    isFilterOptionsFetching:
      state.loading.effects.management.fetchFilterOptions,
    brand: state.management.filter.brand,
    isAdmin: state.session.userInfo.isAdmin,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadBrandASMWeeklyReport: () => dispatch.management.downloadBrandASMWeeklyReport(),
    onDownloadBrandASMMonthlyReport: () => dispatch.management.downloadBrandASMMonthlyReport(),
    onDownloadMultiBrandASMWeeklyReport: () => dispatch.management.downloadMultiBrandASMWeeklyReport(),
    onDownloadMultiBrandASMMonthlyReport: () => dispatch.management.downloadMultiBrandASMMonthlyReport(),
    submitEmailWeekly: input => dispatch.management.submitEmailWeekly(input),
    submitEmailMonthly: input => dispatch.management.submitEmailMonthly(input),
  }),
)(ASMReportsSection);
