/* eslint-disable no-param-reassign */

import produce from 'immer';
import fileDownload from 'js-file-download';

import {
  SALES_ENDPOINT,
  SALES_FILTER_ENDPOINT,
  SALES_TOTALS_ENDPOINT,
  SALES_XLSX,
} from 'common/constants/apiRoutes';
import ajax from 'common/utilities/ajax';

import { totalSalesCasesParser } from 'salesModule/helpers/totalSalesCasesParser';

import FilterOptions from 'salesModule/models/FilterOptions';
import AccountsFilter from 'salesModule/models/AccountsFilter';
import Account from 'salesModule/models/Account';
import Summary from 'salesModule/models/Summary';

import { showError } from 'common/helpers/notificationManager';

const initialState = {
  filterOptions: new FilterOptions({
    brands: [],
    channelTypes: {},
    cities: {},
    states: {},
    chains: {},
    representatives: {},
    representativesMetric: [],
    territories: {},
    skus: [],
    distributors: [],
    dataSources: [],
    accountsSources: [],
    regions: {},
    counties: {},
    banners: {},
    frequencies: [],
  }),
  filter: new AccountsFilter({}),
  initialized: false,
  accounts: [],
  totalSalesData: [],
  totalCasesData: [],
  totalSalesBySkuData: [],
  totalCasesBySkuData: [],
  totalSalesByDistributorsData: [],
  totalCasesByDistributorsData: [],
  topAccounts: [],
  summary: new Summary({
    totalAmount: null,
    accountOrders: null,
    totalOrders: null,
  }),
};

export default {
  state: initialState,
  reducers: {
    setAccountsData: produce((state, accounts) => {
      state.accounts = accounts;
    }),
    setTotalSalesData: produce((state, totalSalesData) => {
      state.totalSalesData = totalSalesData;
    }),
    setTotalCasesData: produce((state, totalCasesData) => {
      state.totalCasesData = totalCasesData;
    }),
    setTotalSalesBySkuData: produce((state, totalSalesBySkuData) => {
      state.totalSalesBySkuData = totalSalesBySkuData;
    }),
    setTotalCasesBySkuData: produce((state, totalCasesBySkuData) => {
      state.totalCasesBySkuData = totalCasesBySkuData;
    }),
    setTotalSalesByDistributorsData: produce((state, totalSalesByDistributorsData) => {
      state.totalSalesByDistributorsData = totalSalesByDistributorsData;
    }),
    setTotalCasesByDistributorsData: produce((state, totalCasesByDistributorsData) => {
      state.totalCasesByDistributorsData = totalCasesByDistributorsData;
    }),
    setTopAccounts: produce((state, accounts) => {
      accounts.sort((a, b) => a.totalSales - b.totalSales);
      const validAccounts = accounts.filter(obj => obj.name !== 'Critical Mass Group');
      state.topAccounts = validAccounts.slice(-10).reverse();
    }),
    setSummary: produce((state, summaryData) => {
      state.summary = summaryData;
    }),
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(SALES_FILTER_ENDPOINT);
      const filterOptionsModel = FilterOptions.parse(response.data);
      dispatch.sales.setFilterOptions(filterOptionsModel);
    },

    async fetchAccountsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SALES_ENDPOINT, config);
      const accounts = response.data.Addresses.map(Account.parse);
      const summary = Summary.parse(response.data);
      dispatch.sales.setAccountsData(accounts);
      dispatch.sales.setSummary(summary);
      dispatch.sales.setTopAccounts(accounts);
    },

    async fetchTotalSales(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SALES_TOTALS_ENDPOINT, config);
      const {
        totalSales,
        totalCases,
        totalSalesBySku,
        totalCasesBySku,
        totalSalesByDistributors,
        totalCasesByDistributors,
      } = totalSalesCasesParser(response.data);
      dispatch.sales.setTotalSalesData(totalSales);
      dispatch.sales.setTotalCasesData(totalCases);
      dispatch.sales.setTotalSalesBySkuData(totalSalesBySku);
      dispatch.sales.setTotalCasesBySkuData(totalCasesBySku);
      dispatch.sales.setTotalSalesByDistributorsData(totalSalesByDistributors);
      dispatch.sales.setTotalCasesByDistributorsData(totalCasesByDistributors);
    },

    async downloadSalesPageReport(_, state) {
      const { filter } = state.sales;

      try {
        const response = await ajax.get(SALES_XLSX, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download merchandising report',
        });
      }
    },
  }),
};
