import { connect } from 'react-redux';
import { permissionsSelector, filterPermissionsSelector } from 'scheduleModule/selectors/schedulePageSelectors';

import SchedulePage from 'scheduleModule/components/SchedulePage';

export default connect(
  state => ({
    userInfo: state.session.userInfo,
    fetchingFilterOptions:
      state.loading.effects.schedule.fetchFilterOptions,
    isFilterInitialized: state.schedule.initialized,
    isAsmSchedulePeriodTablesExist:
      Boolean(state.schedule.asmScheduleDailyData.length)
      && Boolean(state.schedule.asmScheduleWeeklyData.length)
      && Boolean(state.schedule.asmScheduleMonthlyData.length),
    filter: state.schedule.filter,
    filterOptions: state.schedule.filterOptions,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.schedule.fetchFilterOptions,
    fetchAsmScheduleData: dispatch.schedule.fetchAsmScheduleData,
    fetchAsmScheduleDailyData: dispatch.schedule.fetchAsmScheduleDailyData,
    fetchAsmScheduleWeeklyData: dispatch.schedule.fetchAsmScheduleWeeklyData,
    fetchAsmScheduleMonthlyData: dispatch.schedule.fetchAsmScheduleMonthlyData,
    fetchSummary: dispatch.schedule.fetchSummary,
    fetchAsmScheduleDailyBars: dispatch.schedule.fetchAsmScheduleDailyBars,
    fetchScheduleRepresentativesMetrics: dispatch.schedule.fetchScheduleRepresentativesMetrics,
    setFilter: dispatch.schedule.setFilter,
    resetScheduleStore: dispatch.schedule.clear,
  }),
)(SchedulePage);
