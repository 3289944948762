/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { PureComponent } from 'react';
import Magnifier from 'react-magnifier';
import {
  string, arrayOf, instanceOf, func, bool,
} from 'prop-types';

import { Modal, Radio } from 'antd';

import noPictureImage from 'common/images/no-picture.png';

import AccountImages from 'merchandisingModule/models/AccountImages';

import {
  PICTURE,
  STORE_PICTURE,
  PRIMARY_PICTURE,
} from 'merchandisingModule/constants/merchandising-constants';

import './index.scss';

const defaultPictureSize = 450;
const margin = 20;
const defaultModalMargin = 70;
const storeModalSize = 700;

class AccountPopupModal extends PureComponent {
    state = {
      windowWidth: window.innerWidth,
      isZoomModeOn: false,
    };

    componentDidMount() {
      window.addEventListener('resize', this.onResizeWindow);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResizeWindow);
    }

    onResizeWindow = () => {
      this.setState({
        windowWidth: window.innerWidth,
      });
    };

    onImgError = (e) => {
      e.target.src = noPictureImage;
    };

    getModalContent(modalContent) {
      if (modalContent === STORE_PICTURE) {
        const { storePictureUrl } = this.props;

        return (
          <div className="merchandising-popup-modal__content">
            <div className="merchandising-popup-modal__front-image">
              <img
                src={storePictureUrl}
                alt="Account photo"
                className="merchandising-popup-modal__image"
              />
            </div>
          </div>
        );
      }

      if (modalContent === PICTURE) {
        return this.getModalPictureContent();
      }

      return null;
    }

    changeZoomMode = (value) => {
      if (value === 'zoomOn') {
        return this.setState({
          isZoomModeOn: true,
        });
      }
      if (value === 'zoomOff') {
        return this.setState({
          isZoomModeOn: false,
        });
      }
      return this.setState({
        isZoomModeOn: false,
      });
    }

    /* eslint-disable no-nested-ternary */
    getModalPictureContent = () => {
      const { isZoomModeOn } = this.state;
      const { primaryImages, secondaryImages, contentType } = this.props;
      const images = contentType === PRIMARY_PICTURE ? primaryImages : secondaryImages;

      const noImage = (
        <img
          width="450"
          src={noPictureImage}
          alt="Account photo"
          className="merchandising-popup-modal__image"
        />
      );

      const sortedImages = images.sort((a, b) => ((a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0)));

      return (
        <>
          <div className="merchandising-popup-modal__content">
            <div className="merchandising-popup-modal__images">
              {sortedImages.length !== 0 ? (
                sortedImages.map(img => (
                  <div
                    key={img.date}
                    className="merchandising-popup-modal__image-container"
                  >
                    {img.url
                      ? isZoomModeOn ? (
                        <Magnifier
                          width="450px"
                          src={img.url}
                          alt="Account photo"
                          zoomFactor={2.5}
                        />
                      ) : (
                        <img
                          width="450"
                          src={img.url}
                          alt="Account photo"
                          className="merchandising-popup-modal__image"
                          onError={this.onImgError}
                        />
                      )
                      : noImage
                    }
                    <div className="merchandising-popup-modal__image-date">
                      {img.date}
                    </div>
                  </div>
                ))
              ) : (
                <div className="merchandising-popup-modal__image-container">
                  {noImage}
                </div>
              )}
            </div>
          </div>
          <div className="merchandising-popup-modal__zoom-buttons">
            <Radio.Group onChange={e => this.changeZoomMode(e.target.value)} defaultValue="zoomOff">
              <Radio.Button value="zoomOn">Zoom On</Radio.Button>
              <Radio.Button value="zoomOff">Zoom Off</Radio.Button>
            </Radio.Group>
          </div>
        </>
      );
    };

    calculateWidth() {
      const {
        primaryImages,
        secondaryImages,
        contentType,
        modalContent,
      } = this.props;

      const { windowWidth } = this.state;

      if (modalContent === STORE_PICTURE) {
        return storeModalSize;
      }

      const images = contentType === PRIMARY_PICTURE ? primaryImages : secondaryImages;

      const imagesLength = images.length || 1;

      if (windowWidth < 1000) {
        return defaultPictureSize;
      }

      if (imagesLength >= 3) {
        if (windowWidth < 1500) {
          return defaultPictureSize * 2 + margin + defaultModalMargin;
        }

        return defaultPictureSize * 3 + margin * 2 + defaultModalMargin;
      }

      return (
        defaultPictureSize * imagesLength
            + margin * (imagesLength - 1)
            + defaultModalMargin
      );
    }

    render() {
      const { visible, closeModal, modalContent } = this.props;
      const modalWidth = this.calculateWidth();

      return (
        <Modal
          width={modalWidth}
          visible={Boolean(visible)}
          onCancel={closeModal}
          modalContent={modalContent}
          centered
          footer={null}
        >
          <div className="merchandising-popup-modal">
            {this.getModalContent(modalContent)}
          </div>
        </Modal>
      );
    }
}

AccountPopupModal.propTypes = {
  storePictureUrl: string.isRequired,
  contentType: string.isRequired,
  modalContent: string.isRequired,
  visible: bool.isRequired,
  closeModal: func.isRequired,

  primaryImages: arrayOf(instanceOf(AccountImages)),
  secondaryImages: arrayOf(instanceOf(AccountImages)),
};

AccountPopupModal.defaultProps = {
  primaryImages: [],
  secondaryImages: [],
};

export default AccountPopupModal;
