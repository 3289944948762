import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentTagsInstalledNamesSelector,
} from 'merchandisingModule/selectors/tagsInstalledChartSelectors';

import TagsInstalledChart from 'merchandisingModule/components/TagsInstalledChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  tagsInstalledNames: currentTagsInstalledNamesSelector(state),
  tagsInstalledData: state.merchandising.tagsInstalledData,
}))(
  withLegend({
    keys: 'tagsInstalledNames',
    isLegendActive: false,
  })(TagsInstalledChart),
);
