import { connect } from 'react-redux';
import { get } from 'lodash';

import MerchandisingPopup from '../components/MerchandisingMap/AccountPopupInfo';
import {
  PRIMARY_PICTURE,
  SECONDARY_PICTURE,
} from '../constants/merchandising-constants';

export default connect(
  (state, props) => ({
    primaryImages: get(
      state,
      [
        'merchandising',
        'merchandisingImages',
        props.accountId,
        PRIMARY_PICTURE,
      ],
      [],
    ),
    secondaryImages: get(
      state,
      [
        'merchandising',
        'merchandisingImages',
        props.accountId,
        SECONDARY_PICTURE,
      ],
      [],
    ),
  }),
  (dispatch, props) => ({
    fetchAccountMerchandisingImages: () => {
      dispatch.merchandising.fetchAccountMerchandisingImages(
        props.accountId,
      );
    },
    setOpenAccountPopupId: () => {
      dispatch.merchandising.setOpenAccountPopupId(props.accountId);
    },
    clearOpenAccountPopupId: dispatch.merchandising.clearOpenAccountPopupId,
  }),
)(MerchandisingPopup);
