import { connect } from 'react-redux';

import { shouldRenderVoidsCharts } from 'merchandisingModule/selectors/merchandisingPageSelectors';
import VoidsCharts from 'merchandisingModule/components/VoidsCharts';

export default connect(
  state => ({
    voidsData: state.merchandising.voidsData,
    shouldRenderPieCharts: shouldRenderVoidsCharts(state),
  }),
)(VoidsCharts);
