import AsmScheduleDailyBarInfo from './AsmScheduleDailyBarInfo';

class AsmScheduleDailyBar {
  constructor({ date, visitsData }) {
    this.date = date;
    this.visitsData = visitsData;
  }

  static parse(obj) {
    return new AsmScheduleDailyBar({
      date: obj.DateStart,
      visitsData: obj.VisitsInfo.map(AsmScheduleDailyBarInfo.parse),
    });
  }

  getVisitNames() {
    return this.visitsData.map(visitsDataItem => visitsDataItem.visitName);
  }

  getVisitData() {
    const visitsDataObj = this.visitsData.reduce((acc, visitsDataItem) => {
      acc[visitsDataItem.visitName] = visitsDataItem.value;
      return acc;
    }, {});

    return {
      date: this.date,
      ...visitsDataObj,
    };
  }
}

export default AsmScheduleDailyBar;
