/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { salesPagePermissionTypes } from 'userModule/constants/permissions';


const accountsSelector = state => state.sales.accounts;

const totalSalesDataSelector = state => state.sales.totalSalesData;
const totalCasesDataSelector = state => state.sales.totalCasesData;
const totalSalesBySkuDataSelector = state => state.sales.totalSalesBySkuData;
const totalCasesBySkuDataSelector = state => state.sales.totalCasesBySkuData;
const totalSalesByDistributorsDataSelector = state => state.sales.totalSalesByDistributorsData;
const totalCasesByDistributorsDataSelector = state => state.sales.totalCasesByDistributorsData;
const salesPagePermissionsSelector = state => state.session.permissions[salesPagePermissionTypes.SALES_PAGE];


export const shouldRenderMap = createSelector(
  accountsSelector,
  accounts => accounts && accounts.length > 0,
);

export const shouldRenderMetricSelect = createSelector(
  [
    totalSalesDataSelector,
    totalCasesDataSelector,
    totalSalesBySkuDataSelector,
    totalCasesBySkuDataSelector,
    totalSalesByDistributorsDataSelector,
    totalCasesByDistributorsDataSelector,
  ],
  (totalSalesData, totalCasesData, totalSalesBySkuData, totalCasesBySkuData, totalSalesByDistributorsData, totalCasesByDistributorsData) => totalSalesData.length > 0
   || totalCasesData.length > 0
    || totalSalesBySkuData.length > 0
    || totalCasesBySkuData.length > 0
    || totalSalesByDistributorsData.length > 0
    || totalCasesByDistributorsData.length > 0,
);

export const permissionsSelector = createSelector(
  salesPagePermissionsSelector,
  salesPagePermissions => salesPagePermissions.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  salesPagePermissionsSelector,
  salesPagePermissions => salesPagePermissions.Filters || [],
);
