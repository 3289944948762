/* eslint-disable import/prefer-default-export */
import {
  accountsPagePermissionTypes,
  merchandisingPagePermissionTypes,
  salesPagePermissionTypes,
  reportsPagePermissionTypes,
  managementPagePermissionTypes,
  timeEffortsPagePermissionTypes,
  schedulePagePermissionTypes,
  databasePagePermissionTypes,
  adminPagePermissionTypes,
} from 'userModule/constants/permissions';

import {
  accountsFiltersPermissionTypes,
  merchandisingFiltersPermissionTypes,
  salesFiltersPermissionTypes,
  reportsFiltersPermissionTypes,
  managementFiltersPermissionTypes,
  timeEffortsFiltersPermissionTypes,
  scheduleFiltersPermissionTypes,
  databaseFiltersPermissionTypes,
} from 'userModule/constants/filterPermissions';

export const PERMISSIONS_TREE_DATA = [{
  title: 'Accounts',
  key: accountsPagePermissionTypes.ACCOUNTS_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'Accounts Filters',
      children: [
        {
          title: 'Period',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_DATE_PERIOD,
        },
        {
          title: 'Account',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_CITY,
        },
        {
          title: 'State',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_AREA,
        },
        {
          title: 'County',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_REGION,
        },
        {
          title: 'Rep - Account',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_REP_ACCOUNT,
        },
        {
          title: 'Rep - Metric',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_REP_METRIC,
        },
        {
          title: 'Data Source',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_DATA_SOURCE,
        },
        {
          title: 'No Activity',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_NO_ACTIVITY,
        },
        {
          title: 'Frequency',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_FREQUENCY,
        },
        {
          title: 'No Photo',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_NO_PHOTO,
        },
        {
          title: 'Status',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_STATUS,
        },
        {
          title: 'Distributor',
          key: accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_DISTRIBUTORS,
        },
      ],
    },
    {
      title: 'Account Status Map & Summary',
      key: accountsPagePermissionTypes.ACCOUNTS_PAGE_MAP,
    },
    {
      title: 'Map Account List Report',
      key: accountsPagePermissionTypes.ACCOUNTS_PAGE_MAP_REPORT,
    },
    {
      title: 'Account Status Charts',
      key: accountsPagePermissionTypes.ACCOUNTS_PAGE_ACCOUNT_STATUS,
    },
    {
      title: 'Accounts by Channel - All Accounts Chart',
      key: accountsPagePermissionTypes.ACCOUNTS_PAGE_CHANNELS,
    },
    {
      title: 'Authorized Accounts by Channel Chart',
      key: accountsPagePermissionTypes.ACCOUNTS_PAGE_AUTH_CHANNELS,
    },
    {
      title: 'New Accounts by Distributor Chart',
      key: accountsPagePermissionTypes.ACCOUNTS_PAGE_DISTRIBUTORS,
    }],
},
{
  title: 'Merchandising',
  key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'Merchandising Filters',
      children: [
        {
          title: 'Period',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DATE_PERIOD,
        },
        {
          title: 'Account',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_CITY,
        },
        {
          title: 'State',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_AREA,
        },
        {
          title: 'County',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_REGION,
        },
        {
          title: 'SKUs - Merchandising',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_SKUS,
        },
        {
          title: '# of SKUs',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_SKUS_NUM,
        },
        {
          title: '# of Facings',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_FACINGS_NUM,
        },
        {
          title: 'Rep - Account',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_REP_ACCOUNT,
        },
        {
          title: 'Rep - Metric',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_REP_METRIC,
        },
        {
          title: 'Data Source',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DATA_SOURCE,
        },
        {
          title: 'No Activity',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_NO_ACTIVITY,
        },
        {
          title: 'Frequency',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_FREQUENCY,
        },
        {
          title: 'Duplicate Photo',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DUPLICATE_PHOTO,
        },
        {
          title: 'Primary',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_PRIMARY,
        },
        {
          title: 'Secondary',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_SECONDARY,
        },
        {
          title: 'Locations',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_LOCATION,
        },
        {
          title: 'Types',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_TYPE,
        },
        {
          title: 'Tags',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_TAGS,
        },
        {
          title: 'Prices',
          key: merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_PRICE,
        },
      ],
    },
    {
      title: 'Merchandising Map & Summary',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MAP,
    },
    {
      title: 'Map Account List Report',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MAP_REPORT,
    },
    {
      title: 'Merchandising Status Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MERCHANDISING_STATUS,
    },
    {
      title: 'Total Facings',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_TOTAL_FACINGS,
    },
    {
      title: 'Facings Added Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_FACINGS_ADDED,
    },
    {
      title: 'Primary Placement Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_PRIMARY_PLACEMENT,
    },
    {
      title: 'Shelf Placement Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_SHELF_PLACEMENT,
    },
    {
      title: 'Secondary Placement Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_SECONDARY_PLACEMENT,
    },

    {
      title: 'POS Placement Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_POS_PLACEMENT,
    },
    {
      title: 'Tags on Shelf Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_TAGS,
    },
    {
      title: 'Products on Promo Chart',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_PROMO,
    },
    {
      title: 'Voids Charts',
      key: merchandisingPagePermissionTypes.MERCHANDISING_PAGE_VOIDS,
    },
  ],
},
{
  title: 'Sales',
  key: salesPagePermissionTypes.SALES_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'Sales Filters',
      children: [
        {
          title: 'Period',
          key: salesFiltersPermissionTypes.SALES_FILTERS_DATE_PERIOD,
        },
        {
          title: 'Account',
          key: salesFiltersPermissionTypes.SALES_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: salesFiltersPermissionTypes.SALES_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: salesFiltersPermissionTypes.SALES_FILTERS_CITY,
        },
        {
          title: 'State',
          key: salesFiltersPermissionTypes.SALES_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: salesFiltersPermissionTypes.SALES_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: salesFiltersPermissionTypes.SALES_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: salesFiltersPermissionTypes.SALES_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: salesFiltersPermissionTypes.SALES_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: salesFiltersPermissionTypes.SALES_FILTERS_AREA,
        },
        {
          title: 'County',
          key: salesFiltersPermissionTypes.SALES_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: salesFiltersPermissionTypes.SALES_FILTERS_REGION,
        },
        {
          title: 'SKUs - Sales',
          key: salesFiltersPermissionTypes.SALES_FILTERS_SKUS,
        },
        {
          title: 'Distributors',
          key: salesFiltersPermissionTypes.SALES_FILTERS_DISTRIBUTORS,
        },
        {
          title: 'Rep - Account',
          key: salesFiltersPermissionTypes.SALES_FILTERS_REP_ACCOUNT,
        },
        {
          title: 'Rep - Metric',
          key: salesFiltersPermissionTypes.SALES_FILTERS_REP_METRIC,
        },
        {
          title: 'Data Source',
          key: salesFiltersPermissionTypes.SALES_FILTERS_DATA_SOURCE,
        },
        {
          title: 'No Activity',
          key: salesFiltersPermissionTypes.SALES_FILTERS_NO_ACTIVITY,
        },
        {
          title: 'Frequency',
          key: salesFiltersPermissionTypes.SALES_FILTERS_FREQUENCY,
        },
      ],
    },
    {
      title: 'Sales Map & Summary',
      key: salesPagePermissionTypes.SALES_PAGE_MAP,
    },
    {
      title: 'Map Account List Report',
      key: salesPagePermissionTypes.SALES_PAGE_MAP_REPORT,
    },
    {
      title: 'Cases/Dollars Chart',
      key: salesPagePermissionTypes.SALES_PAGE_TOTAL,
    },
    {
      title: 'Cases/Dollars By SKU Chart',
      key: salesPagePermissionTypes.SALES_PAGE_TOTAL_SKU,
    },
    {
      title: 'Cases/Dollars By Distributor Charts',
      key: salesPagePermissionTypes.SALES_PAGE_TOTAL_DISTRIBUTOR,
    },
    {
      title: 'Top 10 Accounts Table',
      key: salesPagePermissionTypes.SALES_PAGE_TOP_ACCOUNTS,
    },
  ],
},
{
  title: 'Reports',
  key: reportsPagePermissionTypes.REPORTS_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'Reports Filters',
      children: [
        {
          title: 'Period',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_DATE_PERIOD,
        },
        {
          title: 'Account',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_CITY,
        },
        {
          title: 'State',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_AREA,
        },
        {
          title: 'County',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_REGION,
        },
        {
          title: 'SKUs - Merchandising',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_MERCH_SKUS,
        },
        {
          title: '# of SKUs',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_SKUS_NUM,
        },
        {
          title: '# of Facings',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_FACINGS_NUM,
        },
        {
          title: 'SKUs - Sales',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_SALES_SKUS,
        },
        {
          title: 'Distributors',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_DISTRIBUTORS,
        },
        {
          title: 'Rep - Account',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_REP_ACCOUNT,
        },
        {
          title: 'Rep - Metric',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_REP_METRIC,
        },
        {
          title: 'Data Source',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_DATA_SOURCE,
        },
        {
          title: 'No Activity',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_NO_ACTIVITY,
        },
        {
          title: 'Frequency',
          key: reportsFiltersPermissionTypes.REPORTS_FILTERS_FREQUENCY,
        },
      ],
    },
    {
      title: 'Account Reports',
      key: 'Account Reports',
      children: [
        { title: 'Account List', key: reportsPagePermissionTypes.REPORTS_PAGE_ACCOUNT_LIST_REPORT },
        { title: 'Account Status Report', key: reportsPagePermissionTypes.REPORTS_PAGE_ACCOUNT_STATUS_REPORT },
      ],
    },
    {
      title: 'Merchandising Reports',
      key: 'Merchandising Reports',
      children: [
        { title: 'Merchandising Report', key: reportsPagePermissionTypes.REPORTS_PAGE_MERCHANDISING_REPORT },
        { title: 'Voids Report', key: reportsPagePermissionTypes.REPORTS_PAGE_VOIDS_REPORT },
        { title: 'Account Images', key: reportsPagePermissionTypes.REPORTS_PAGE_ACCOUNTS_IMAGES_REPORT },
      ],
    },
    {
      title: 'Sales Reports',
      key: 'Sales Reports',
      children: [
        { title: 'Sales Report', key: reportsPagePermissionTypes.REPORTS_PAGE_SALES_REPORT },
        { title: 'Brand Sales by Distributor', key: reportsPagePermissionTypes.REPORTS_PAGE_SALES_BRAND_DISTRIBUTOR_REPORT },
        { title: 'SKU Sales by Distributor', key: reportsPagePermissionTypes.REPORTS_PAGE_SALES_SKU_DISTRIBUTOR_REPORT },
        { title: 'Brand Sales by Account', key: reportsPagePermissionTypes.REPORTS_PAGE_SALES_BRAND_ACCOUNT_REPORT },
        { title: 'SKU Sales by Account', key: reportsPagePermissionTypes.REPORTS_PAGE_SALES_SKU_ACCOUNT_REPORT },
      ],
    },
    {
      title: 'Chain Reports',
      key: 'Chain Reports',
      children: [
        { title: 'Scorecard and promos', key: reportsPagePermissionTypes.REPORTS_PAGE_CHAIN_REPORT },
      ],
    },
    {
      title: 'Summary Reports',
      key: 'Summary Reports',
      children: [
        { title: 'Weekly Summary Report', key: reportsPagePermissionTypes.REPORTS_PAGE_SUMMARY_WEEKLY_REPORT },
        { title: 'Monthly Summary Report', key: reportsPagePermissionTypes.REPORTS_PAGE_SUMMARY_MONTHLY_REPORT },
      ],
    },
    {
      title: 'Weekly Summary Tables',
      key: reportsPagePermissionTypes.REPORTS_PAGE_WEEKLY_TABLES,
    },
    {
      title: 'Monthly Summary Tables',
      key: reportsPagePermissionTypes.REPORTS_PAGE_MONTHLY_TABLES,
    },
  ],
},
{
  title: 'Management',
  key: managementPagePermissionTypes.MANAGEMENT_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'Management Filters',
      children: [
        {
          title: 'Period',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_DATE_PERIOD,
        },
        {
          title: 'Account',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_CITY,
        },
        {
          title: 'State',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_AREA,
        },
        {
          title: 'County',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_REGION,
        },
        {
          title: 'SKUs - Merchandising',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_MERCH_SKUS,
        },
        {
          title: '# of SKUs',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_SKUS_NUM,
        },
        {
          title: '# of Facings',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_FACINGS_NUM,
        },
        {
          title: 'SKUs - Sales',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_SALES_SKUS,
        },
        {
          title: 'Distributors',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_DISTRIBUTORS,
        },
        {
          title: 'Rep - Account',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_REP_ACCOUNT,
        },
        {
          title: 'Rep - Metric',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_REP_METRIC,
        },
        {
          title: 'Data Source',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_DATA_SOURCE,
        },
        {
          title: 'No Activity',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_NO_ACTIVITY,
        },
        {
          title: 'Frequency',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_FREQUENCY,
        },
        {
          title: 'Inactive Representatives',
          key: managementFiltersPermissionTypes.MANAGEMENT_FILTERS_REPS_INACTIVE,
        },
      ],
    },
    {
      title: 'Management Reports',
      key: 'Management Reports',
      children: [
        { title: 'Weekly Report - <Brand>', key: managementPagePermissionTypes.MANAGEMENT_PAGE_WEEKLY_BRAND_REPORT },
        { title: 'Monthly Report - <Brand>', key: managementPagePermissionTypes.MANAGEMENT_PAGE_MONTHLY_BRAND_REPORT },
        { title: 'Weekly Report - All Brands', key: managementPagePermissionTypes.MANAGEMENT_PAGE_WEEKLY_ALL_REPORT },
        { title: 'Monthly Report - All Brands', key: managementPagePermissionTypes.MANAGEMENT_PAGE_MONTHLY_ALL_REPORT },
      ],
    },
    {
      title: 'Account Reports',
      key: 'Management Account Reports',
      children: [
        { title: 'Active Accounts', key: managementPagePermissionTypes.MANAGEMENT_PAGE_ACTIVE_REPORT },
        { title: 'Dead Accounts', key: managementPagePermissionTypes.MANAGEMENT_PAGE_DEAD_REPORT },
        { title: 'Dead Accounts w/ Hot Leads', key: managementPagePermissionTypes.MANAGEMENT_PAGE_DEAD_HOT_REPORT },
        { title: 'Accounts Total Sales', key: managementPagePermissionTypes.MANAGEMENT_PAGE_TOTAL_SALES_REPORT },
      ],
    },
  ],
},
{
  title: 'Time & Efforts',
  key: timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'T&E Filters',
      children: [
        {
          title: 'Period',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_DATE_PERIOD,
        },
        {
          title: 'Account',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_CITY,
        },
        {
          title: 'State',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_AREA,
        },
        {
          title: 'County',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_REGION,
        },
        {
          title: 'Data Source',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_DATA_SOURCE,
        },
        {
          title: 'No Activity',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_NO_ACTIVITY,
        },
        {
          title: 'Frequency',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_FREQUENCY,
        },
        {
          title: 'Representative',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_REPRESENTATIVE,
        },
        {
          title: 'Inactive Representatives',
          key: timeEffortsFiltersPermissionTypes.TIME_EFFORTS_FILTERS_REPS_INACTIVE,
        },
      ],
    },
    {
      title: 'Time & Efforts Reports',
      key: 'Time & Efforts Reports',
      children: [
        { title: 'Time & Efforts Report - Daily', key: timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE_DAILY_REPORT },
        { title: 'Time & Efforts Report - Weekly', key: timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE_WEEKLY_REPORT },
        { title: 'Time & Efforts Report - Monthly', key: timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE_MONTHLY_REPORT },
      ],
    },
  ],
},
{
  title: 'Schedule',
  key: schedulePagePermissionTypes.SCHEDULE_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'Schedule Filters',
      children: [
        {
          title: 'Period',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_DATE_PERIOD,
        },
        {
          title: 'Account',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_CITY,
        },
        {
          title: 'State',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_AREA,
        },
        {
          title: 'County',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REGION,
        },
        {
          title: 'Data Source',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_DATA_SOURCE,
        },
        {
          title: 'Frequency',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_FREQUENCY,
        },
        {
          title: 'Representative',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REPRESENTATIVE,
        },
        {
          title: 'Inactive Representatives',
          key: scheduleFiltersPermissionTypes.SCHEDULE_FILTERS_REPS_INACTIVE,
        },
      ],
    },
    {
      title: 'Schedule Reports',
      key: 'Schedule Reports',
      children: [
        {
          title: 'Schedule Report - Daily',
          key: schedulePagePermissionTypes.SCHEDULE_PAGE_DAILY_REPORT,
        },
        {
          title: 'Schedule Report - Weekly',
          key: schedulePagePermissionTypes.SCHEDULE_PAGE_WEEKLY_REPORT,
        },
        {
          title: 'Schedule Report - Monthly',
          key: schedulePagePermissionTypes.SCHEDULE_PAGE_MONTHLY_REPORT,
        },
      ],
    },
    {
      title: 'Schedule Metric Reports',
      key: 'Schedule Metric Reports',
      children: [
        {
          title: 'Schedule Metrics - Daily',
          key: schedulePagePermissionTypes.SCHEDULE_PAGE_METRICS_DAILY_REPORT,
        },
        {
          title: 'Schedule Metrics - Weekly',
          key: schedulePagePermissionTypes.SCHEDULE_PAGE_METRICS_WEEKLY_REPORT,
        },
        {
          title: 'Schedule Metrics - Monthly',
          key: schedulePagePermissionTypes.SCHEDULE_PAGE_METRICS_MONTHLY_REPORT,
        },
      ],
    },
    {
      title: 'Summary',
      key: schedulePagePermissionTypes.SCHEDULE_PAGE_SUMMARY,
    },
    {
      title: 'ASM Schedule Table',
      key: schedulePagePermissionTypes.SCHEDULE_PAGE_ASM_SCHEDULE,
    },
    {
      title: 'ASM Daily Visits',
      key: schedulePagePermissionTypes.SCHEDULE_PAGE_DAILY_BARS,
    },
  ],
},
{
  title: 'Database',
  key: databasePagePermissionTypes.DATABASE_PAGE,
  children: [
    {
      title: 'Filters',
      key: 'Database Filters',
      children: [
        {
          title: 'Account',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_ACCOUNT,
        },
        {
          title: 'Address',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_ADDRESS,
        },
        {
          title: 'City',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_CITY,
        },
        {
          title: 'State',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_STATE,
        },
        {
          title: 'Zip Code',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_ZIP,
        },
        {
          title: 'Chain',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_CHAIN,
        },
        {
          title: 'Banner',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_BANNER,
        },
        {
          title: 'Channel',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_CHANNEL,
        },
        {
          title: 'Area',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_AREA,
        },
        {
          title: 'County',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_COUNTY,
        },
        {
          title: 'Region',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_REGION,
        },
        {
          title: 'Account Source',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_ACCOUNT_SOURCE,
        },
        {
          title: 'Data Source',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_DATA_SOURCE,
        },
        {
          title: 'Frequency',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_FREQUENCY,
        },
        {
          title: 'Representative',
          key: databaseFiltersPermissionTypes.DATABASE_FILTERS_REPRESENTATIVE,
        },
      ],
    },
    {
      title: 'Master Accounts List',
      key: databasePagePermissionTypes.DATABASE_PAGE_MASTER_ACCOUNTS_LIST,
    },
  ],
},
{
  title: 'Admin',
  key: adminPagePermissionTypes.ADMIN_PAGE,
  children: [
    {
      title: 'Users Table',
      key: adminPagePermissionTypes.ADMIN_PAGE_USERS,
    },
    {
      title: 'Create Users',
      key: adminPagePermissionTypes.ADMIN_PAGE_USERS_CREATE,
    },
    {
      title: 'Edit Users',
      key: adminPagePermissionTypes.ADMIN_PAGE_USERS_EDIT,
    },
    {
      title: 'Delete Users',
      key: adminPagePermissionTypes.ADMIN_PAGE_USERS_DELETE,
    },
    {
      title: 'Show User Login Info',
      key: adminPagePermissionTypes.ADMIN_PAGE_USERS_INFO,
    },
    {
      title: 'Change User permissions',
      key: adminPagePermissionTypes.ADMIN_PAGE_USERS_PERMISSIONS,
    },
    {
      title: 'Chain Scorecard',
      key: adminPagePermissionTypes.ADMIN_PAGE_CHAIN,
    },
    {
      title: 'Report Mail',
      key: adminPagePermissionTypes.ADMIN_PAGE_USERS_AUTOREPORT,
    },
    {
      title: 'Target',
      key: adminPagePermissionTypes.ADMIN_PAGE_TARGET,
    },
    {
      title: 'Brands Table',
      key: adminPagePermissionTypes.ADMIN_PAGE_BRANDS,
    },
    {
      title: 'Archive Brands',
      key: adminPagePermissionTypes.ADMIN_PAGE_BRANDS_ARCHIVE,
    },
    {
      title: 'Delete Brands',
      key: adminPagePermissionTypes.ADMIN_PAGE_BRANDS_DELETE,
    },
  ],
},
];
