import { connect } from 'react-redux';

import TotalCasesBySkuChart from 'salesModule/components/TotalCasesBySkuChart';
import { skuNamesAndColorsCasesSelector, shouldRenderCasesChartSelector } from 'salesModule/selectors/totalSalesCasesBySkuSelector';

export default connect(
  (state) => {
    const { skuNames, skuNamesColorMap } = skuNamesAndColorsCasesSelector(state);
    const shouldRenderChart = shouldRenderCasesChartSelector(state);
    return {
      totalCasesBySku: state.sales.totalCasesBySkuData,
      shouldRenderChart,
      withDivider: shouldRenderChart,
      skuNames,
      colorMap: skuNamesColorMap,
    };
  },
)(TotalCasesBySkuChart);
