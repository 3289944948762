class Summary {
  constructor({
    stores,
    forms,
    facings,
    reorders,
    reordersPercentage,
    secondaryPlacements,
    secondaryPlacementPercentage,
    avgFacings,
    avgSkus,
    facingsTotal,
  }) {
    this.stores = stores;
    this.forms = forms;
    this.facings = facings;
    this.reorders = reorders;
    this.reordersPercentage = reordersPercentage;
    this.secondaryPlacements = secondaryPlacements;
    this.secondaryPlacementPercentage = secondaryPlacementPercentage;
    this.avgFacings = avgFacings;
    this.avgSkus = avgSkus;
    this.facingsTotal = facingsTotal;
  }

  static parse(obj) {
    return new Summary({
      stores: obj.StoresVisited,
      forms: obj.FormsSubmitted,
      facings: obj.FacingsAdded,
      reorders: obj.ReordersPlaced,
      reordersPercentage: obj.ReordersPercentage,
      secondaryPlacements: obj.SecondaryPlacements,
      secondaryPlacementPercentage: obj.SecondaryPlacementsPercentage,
      avgFacings: obj.AvgNumberOfFacings,
      avgSkus: obj.AvgNumberOfSKUs,
      facingsTotal: obj.FacingsTotal,
    });
  }
}

export default Summary;
