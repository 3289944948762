/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorMapper } from 'common/utilities/colorMapper';
import AccountsByDay from 'accountsModule/models/AccountsByDay';


const filterOptionsSelector = state => state.accounts.filterOptions;

const accountStatusesDataSelector = state => state.accounts.accountStatusesData;

const accountStatusesSelector = state => state.accounts.accountStatuses;

const sortedFilterOptionStatusesSelector = state => state.accounts.filterOptions.statuses.sort(
  (statusFirst, statusNext) => statusFirst.sortOrder - statusNext.sortOrder,
);

export const sortedAccountStatusesSelector = createSelector(
  [sortedFilterOptionStatusesSelector, accountStatusesSelector],
  (filterOptionStatuses, accountStatuses) => filterOptionStatuses.reduce((acc, filterOptionStatus) => {
    if (accountStatuses.includes(filterOptionStatus.name)) {
      acc.push(filterOptionStatus.name);
    }
    return acc;
  }, []),
);

export const colorMapSelector = createSelector(
  [sortedAccountStatusesSelector, filterOptionsSelector],
  (statuses, filterOptions) => {
    const colorScheme = filterOptions.createColorScheme();
    return colorMapper(statuses, colorScheme);
  },
);

export const accountStatusesOnDateDataSelector = createSelector(
  accountStatusesDataSelector,
  accountStatusesData => accountStatusesData[accountStatusesData.length - 1] || new AccountsByDay({ date: '', accounts: [] }),
);
