class AccountsByDistributorOnDate {
  constructor({ distributor, amount, percentage }) {
    this.distributor = distributor;
    this.amount = amount;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new AccountsByDistributorOnDate({
      distributor: obj.Distributor,
      amount: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default AccountsByDistributorOnDate;
