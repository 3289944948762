import { connect } from 'react-redux';
import { permissionsSelector } from 'udsModule/selectors/udsPageSelectors';

import TimeEffortsReportsSection from 'udsModule/components/TimeEffortsReportsSection';

export default connect(
  state => ({
    isTimeEffortsReportDownloading:
      state.loading.effects.uds.downloadTimeEffortsReport,
    isTimeEffortsWeekReportDownloading:
      state.loading.effects.uds.downloadTimeEffortsWeekReport,
    isTimeEffortsMonthReportDownloading:
      state.loading.effects.uds.downloadTimeEffortsMonthReport,
    shouldRenderReportsSection: !state.loading.effects.uds.fetchFilterOptions,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadTimeAndEffortsReport: () => dispatch.uds.downloadTimeEffortsReport(),
    onDownloadTimeEffortsWeekReport: () => dispatch.uds.downloadTimeEffortsWeekReport(),
    onDownloadTimeEffortsMonthReport: () => dispatch.uds.downloadTimeEffortsMonthReport(),
  }),
)(TimeEffortsReportsSection);
