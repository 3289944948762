import React, { PureComponent } from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';
import { Popup, Marker } from 'react-leaflet';
import moment from 'moment';
import L from 'leaflet';

import { Spin } from 'antd';

import LeafletMap from 'common/components/LeafletMap';
import DownloadAccountsButton from 'accountsModule/containers/DownloadAccountsButtonContainer';

import FilterOptions from 'accountsModule/models/FilterOptions';
import Account from 'accountsModule/models/Account';

import { accountsPagePermissionTypes } from 'userModule/constants/permissions';

import AccountPopupInfo from './AccountPopupInfo';

import './index.scss';

class AccountsMap extends PureComponent {
    static propTypes = {
      accounts: arrayOf(instanceOf(Account)).isRequired,
      disabledKeys: arrayOf(string).isRequired,
      filterOptions: instanceOf(FilterOptions).isRequired,
      isLoading: bool.isRequired,
      childrenPermissions: arrayOf(string).isRequired,
      shouldRenderMap: bool.isRequired,
    };

    getAccountImages = (account) => {
      const images = [];
      if (account.coolerPictureUrl) {
        images.push({
          title: 'Cooler Set #1',
          pictureUrl: account.coolerPictureUrl,
        });
      }
      if (account.secondCoolerPictureUrl) {
        images.push({
          title: 'Cooler Set #2',
          pictureUrl: account.secondCoolerPictureUrl,
        });
      }
      if (account.snacksPictureUrl) {
        images.push({
          title: 'Snacks',
          pictureUrl: account.snacksPictureUrl,
        });
      }

      return images;
    };

    getMarkers = () => {
      const { accounts, disabledKeys, filterOptions } = this.props;

      const markerMap = filterOptions.statuses.reduce(
        (markersMap, status) => markersMap.set(status.name, status.image),
        new Map(),
      );

      return accounts
        .filter(account => !disabledKeys.includes(account.status))
        .map(account => (
          <Marker
            key={account.id}
            icon={L.icon({
              iconUrl: markerMap.get(account.status),
              iconSize: [36, 36],
              iconAnchor: [18, 36],
              popupAnchor: [0, -25],
            })}
            position={account.getCoordinates()}
          >
            <Popup>
              <AccountPopupInfo
                name={account.name}
                address={account.address}
                storePictureUrl={account.storePictureUrl}
                lastVisitDate={moment(account.lastDate)}
                status={account.status}
                totalVisits={account.totalVisits}
                discontinuedDate={moment(account.discontinuedDate)}
                representative={account.representative}
                nextScheduledDate={moment(
                  account.nextScheduledDate,
                )}
                picturesList={this.getAccountImages(account)}
              />
            </Popup>
          </Marker>
        ));
    };

    render() {
      const { isLoading, childrenPermissions, shouldRenderMap } = this.props;

      return (
        shouldRenderMap && (
          <div className="accounts-map">
            <h2 className="accounts-map__heading">
              <span>Account Status Map</span>
              {childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_MAP_REPORT) && <DownloadAccountsButton />}
            </h2>
            {isLoading && (
              <Spin spinning={isLoading}>
                <div className="accounts-map__spinner-inner" />
              </Spin>
            )}
            {!isLoading && <LeafletMap>{this.getMarkers()}</LeafletMap>}
          </div>
        )
      );
    }
}

export default AccountsMap;
