class PosPlacement {
  constructor({ placement, amount, percentage }) {
    this.placement = placement;
    this.amount = amount;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new PosPlacement({
      placement: obj.Placement,
      amount: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default PosPlacement;
