import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  disabledStatusesSelector,
  shouldRenderMapSelector,
} from 'accountsModule/selectors/accountsMapSelectors';
import { permissionsSelector } from 'accountsModule/selectors/accountsPageSelectors';
import AccountsMap from 'accountsModule/components/AccountsMap';

export default connect(state => ({
  accounts: state.accounts.accounts,
  accountStatuses: state.accounts.accountStatuses,
  filterOptions: state.accounts.filterOptions,
  colorMap: colorMapSelector(state),
  disabledFieldsByDefault: disabledStatusesSelector(state),
  childrenPermissions: permissionsSelector(state),
  shouldRenderMap: shouldRenderMapSelector(state),
}))(
  withLegend({
    keys: 'accountStatuses',
    shouldRenderLegend: 'shouldRenderMap',
    withDivider: 'shouldRenderMap',
  })(AccountsMap),
);
