/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const accountsByDistributorOnDateSelector = state => state.accounts.accountsByDistributorOnDate;

export const currentDistributorsOnDateSelector = createSelector(
  accountsByDistributorOnDateSelector,
  accountsByDistributorOnDate => accountsByDistributorOnDate.map(
    distributorAccounts => distributorAccounts.distributor,
  ),
);

export const colorMapSelector = createSelector(
  currentDistributorsOnDateSelector,
  currentDistributorNames => colorGenerator(currentDistributorNames),
);

export const shouldRenderChartSelector = createSelector(
  accountsByDistributorOnDateSelector,
  accountsByDistributorOnDate => accountsByDistributorOnDate && accountsByDistributorOnDate.length > 0,
);
