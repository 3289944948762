import React from 'react';
import { instanceOf, arrayOf, string } from 'prop-types';

import PieChart from 'common/components/PieChart';
import withLegend from 'common/hocs/withLegend';
import withHeading from 'common/hocs/withHeading';

import Voids from 'merchandisingModule/models/Voids';

const VoidsChart = ({ voidsBySku, colorMap, disabledKeys }) => {
  const pieChartData = new Array(voidsBySku.length)
    .fill(null)
    .map((_, i) => ({
      id: voidsBySku[i].status,
      label: voidsBySku[i].status,
      value: voidsBySku[i].amount,
      percentage: voidsBySku[i].percentage,
    }));

  const labelFormatter = label => `${label.value} (${label.percentage}%)`;

  return pieChartData[0].id ? (
    <PieChart
      data={pieChartData.filter(
        pieData => !disabledKeys.includes(pieData.id),
      )}
      minWidth="300px"
      colorMap={colorMap}
      labelFormatter={labelFormatter}
    />
  ) : null;
};

/* eslint-disable react/no-unused-prop-types */
VoidsChart.propTypes = {
  voidsBySku: arrayOf(instanceOf(Voids)).isRequired,
  title: string.isRequired,
  voidsTitle: arrayOf(string).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
};

export default withHeading({ isScrollable: true })(
  withLegend({ keys: 'voidsTitle', isLegendActive: false })(VoidsChart),
);
