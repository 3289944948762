class MerchandisingStatus {
  constructor({ date, facings, skus }) {
    this.date = date;
    this.facings = facings;
    this.skus = skus;
  }

  static fieldNames = ['facings', 'skus'];

  static labelsMap = new Map([['facings', 'Facings'], ['skus', 'SKUs']]);

  static parse(obj) {
    return new MerchandisingStatus({
      date: obj.Date,
      facings: obj.AvgFacings,
      skus: obj.AvgSkus,
    });
  }
}

export default MerchandisingStatus;
