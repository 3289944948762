import { connect } from 'react-redux';

import { permissionsSelector } from 'merchandisingModule/selectors/merchandisingPageSelectors';
import MetricsOnDateCharts from '../components/MetricsOnDateCharts';

export default connect(
  state => ({
    fetchingFilterOptions:
      state.loading.effects.merchandising.fetchFilterOptions,
    filter: state.merchandising.filter,
    childrenPermissions: permissionsSelector(state),
  }),
)(MetricsOnDateCharts);
