class WorkStatsPeriod {
  constructor({
    name, metricName, startDate, value,
  }) {
    this.name = name;
    this.metricName = metricName;
    this.startDate = startDate;
    this.value = value;
  }

  static fieldNamesMap = new Map([
    ['hours', 'Hours Worked'],
    ['miles', 'Mileage Driven'],
  ]);

  static metricNamesMap = new Map([
    ['HoursWorked', 'hours'],
    ['MileageDriven', 'miles'],
  ]);

  static parse(obj, name, metricName) {
    return new WorkStatsPeriod({
      name,
      metricName: WorkStatsPeriod.metricNamesMap.get(metricName),
      startDate: obj.DateStart,
      value: obj.Value,
    });
  }
}

export default WorkStatsPeriod;
