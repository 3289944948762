/* eslint-disable react/prefer-stateless-function, react/destructuring-assignment, no-nested-ternary */
import React, { PureComponent } from 'react';
import {
  instanceOf, func,
} from 'prop-types';

import {
  Button,
  Input,
  Icon,
} from 'antd';


import User from 'adminModule/models/User';

import './index.scss';

class DeleteUserForm extends PureComponent {
  static propTypes = {
    onDeleteUser: func.isRequired,
    user: instanceOf(User).isRequired,
  }

  state = {
    inputText: '',
  }

  submitForm = (e) => {
    e.preventDefault();
    const { onDeleteUser, user } = this.props;
    onDeleteUser({ user: user.login });
  };


  onInputChange = (e) => {
    this.setState({
      inputText: e.currentTarget.value,
    });
  }

  render() {
    const { login } = this.props.user;

    const { inputText } = this.state;

    const userDeleteText = 'delete';

    return (
      <div className="delete-user-form">
        <div className="delete-user-form__info">
          <p className="delete-user-form__title">
            <Icon type="warning" /> You are about to delete user {login}

          </p>
          <p className="delete-user-form">
            This action cannot be undone. To confirm type <b>&quot;{userDeleteText}&quot;</b>
          </p>

        </div>
        <Input onChange={this.onInputChange} />
        <div className="delete-user-form__submit-button-container">
          <Button
            className="delete-user-form__button_delete"
            icon="delete"
            type="ghost"
            htmlType="submit"
            disabled={!(userDeleteText === inputText)}
            onClick={this.submitForm}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  }
}

export default DeleteUserForm;
