import React, { PureComponent } from 'react';
import {
  func, instanceOf, arrayOf, string,
} from 'prop-types';

import withNavigation from 'common/hocs/withNavigation';
import UserInfo from 'userModule/models/UserInfo';

import ScorecardsSection from 'adminModule/containers/ScorecardsContainer';
import UsersList from 'adminModule/containers/UsersListContainer';
import BrandsList from 'adminModule/containers/BrandsListContainer';
import TargetDateSection from 'adminModule/containers/TargetDateSectionContainer';
import { adminPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class AdminPage extends PureComponent {
    static propTypes = {
      userInfo: instanceOf(UserInfo).isRequired,
      fetchFilterOptions: func.isRequired,
      childrenPermissions: arrayOf(string).isRequired,
    };

    componentDidMount() {
      const { fetchFilterOptions } = this.props;
      fetchFilterOptions();
    }

    render() {
      const {
        userInfo: { isSuperAdmin, isAdmin },
        childrenPermissions,
      } = this.props;

      return (
        <div className="admin-page">
          <div className="admin-page__inner">
            {childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_USERS) && <UsersList />}
            {childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_BRANDS) && <BrandsList />}
            {isAdmin && childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_CHAIN) && <ScorecardsSection />}
            {isSuperAdmin && childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_TARGET) && <TargetDateSection />}
          </div>
        </div>
      );
    }
}

export default withNavigation()(AdminPage);
