import ScheduleAccount from './ScheduleAccount';

class ScheduleData {
  constructor({ name, data }) {
    this.name = name;
    this.data = data;
  }

  static parse(asmName, asmScheduleData) {
    return new ScheduleData({
      name: asmName,
      data: asmScheduleData.map(visitsOnDate => ({
        visitDate: visitsOnDate.Date,
        visits: visitsOnDate.ScheduledVisits.map(ScheduleAccount.parse),
      })),
    });
  }
}
export default ScheduleData;
