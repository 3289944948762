import React, { PureComponent, Fragment } from 'react';
import {
  arrayOf, instanceOf, string,
} from 'prop-types';

import PieChart from 'common/components/PieChart';
import SectionHeading from 'common/components/SectionHeading';

import AccountsByDay from 'accountsModule/models/AccountsByDay';

const HEADING = 'Account Status - All Accounts';

class AccountStatusOnDateChart extends PureComponent {
  render() {
    const {
      accountStatusesOnDateData,
      colorMap,
      disabledKeys,
    } = this.props;

    const pieChartData = new Array(
            accountStatusesOnDateData?.accounts?.length,
    )
      .fill(null)
      .map((_, i) => ({
        id: accountStatusesOnDateData?.accounts[i].status,
        label: accountStatusesOnDateData?.accounts[i].status,
        value: accountStatusesOnDateData?.accounts[i].amount,
        percentage: accountStatusesOnDateData?.accounts[i].percentage,
      }));

    const labelFormatter = label => `${label.value} (${label.percentage}%)`;

    return (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <div className="accounts-pie-chart">
          <PieChart
            data={pieChartData.filter(
              pieData => !disabledKeys.includes(pieData.id),
            )}
            colorMap={colorMap}
            labelFormatter={labelFormatter}
          />
        </div>
      </Fragment>
    );
  }
}

AccountStatusOnDateChart.propTypes = {
  accountStatusesOnDateData: instanceOf(AccountsByDay).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
};

export default AccountStatusOnDateChart;
