import React from 'react';
import {
  Select, Input, InputNumber, Icon,
} from 'antd';
import {
  number,
  string,
  func,
  arrayOf,
  object,
  instanceOf,
} from 'prop-types';

import FieldTitle from 'common/components/FieldTitle';
import RangePicker from 'common/components/RangePicker';
import PeriodPicker from 'common/components/PeriodPicker';

import FilterOptions from 'accountsModule/models/FilterOptions';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import { accountsFiltersPermissionTypes } from 'userModule/constants/filterPermissions';

import './index.scss';

const { Option } = Select;

const CommonOption = option => (
  <Option key={option} value={option} title={option}>
    <span title={option}>{option}</span>
  </Option>
);

const searchIcon = <Icon type="search" style={{ fontSize: '12px' }} />;

const FiltersBoard = ({
  disabledDate,
  dateRange,
  name,
  address,
  brand,
  channelTypes,
  cities,
  states,
  chains,
  representatives,
  representativesMetric,
  territories,
  notVisitedIn,
  noImageReasons,
  accountsSource,
  regions,
  counties,
  banners,
  frequencies,
  zipCode,
  statusesNames,
  distributors,
  onChangeField,
  filterOptions,
  permissions,
}) => {
  const onChangeNumber = (value) => {
    if (!Number(value) || Number.isNaN(value)) {
      return onChangeField('notVisitedIn', 0);
    }

    return onChangeField('notVisitedIn', Math.abs(value));
  };

  const onChangeZipCode = (value) => {
    const numRegExp = /^\d+$/;
    if (numRegExp.test(value)) {
      return onChangeField('zipCode', value);
    }
    return '';
  };

  const shouldRenderAdminFilters = permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_DATA_SOURCE)
    || permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_NO_ACTIVITY)
    || permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_FREQUENCY)
    || permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_NO_PHOTO);


  return (
    <div className="accounts-filters-board">
      <div className="accounts-filters-board__wrapper accounts-filters-board_users-section">
        <div className="accounts-filters-board__filter accounts-filters-board__filter_dropdown">
          <FieldTitle>Brand</FieldTitle>
          <Select
            className="accounts-filters-board__select"
            onChange={value => onChangeField('brand', value)}
            value={brand}
          >
            {filterOptions.brands
              .map(b => b.name)
              .map(CommonOption)}
          </Select>
        </div>
        <div className="accounts-filters-board__filter accounts-filters-board__filter_date-picker">
          <RangePicker
            format={DATE_FORMAT}
            disabledDate={disabledDate}
            value={dateRange}
            onChange={value => onChangeField('dateRange', value)}
          />
        </div>
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_DATE_PERIOD) && (
          <div className="accounts-filters-board__filter">
            <FieldTitle>&nbsp;</FieldTitle>
            <PeriodPicker
              onChange={value => onChangeField('periodRange', value)}
            />
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_ACCOUNT) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_search">
            <FieldTitle>Account</FieldTitle>
            <Input
              value={name}
              onChange={e => onChangeField('name', e.target.value)}
              placeholder="Search name"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_ADDRESS) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_search">
            <FieldTitle>Address</FieldTitle>
            <Input
              value={address}
              onChange={e => onChangeField('address', e.target.value)}
              placeholder="Search address"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_CITY) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>City</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('cities', value)}
              value={cities}
              maxTagCount={2}
              placeholder="Select cities"
            >
              {filterOptions.cities[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_STATE) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>State</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('states', value)}
              value={states}
              maxTagCount={2}
              placeholder="Select states"
            >
              {filterOptions.states[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_ZIP) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_search">
            <FieldTitle>Zip Code</FieldTitle>
            <Input
              value={zipCode}
              onChange={e => onChangeZipCode(e.target.value)}
              placeholder="Search zip code"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_CHAIN) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Chain</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('chains', value)}
              value={chains}
              maxTagCount={2}
              placeholder="Select chains"
            >
              {filterOptions.chains[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_BANNER) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Banner</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('banners', value)
              }
              value={banners}
              maxTagCount={2}
              placeholder="Select banners"
            >
              {filterOptions.banners[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_CHANNEL) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Channel</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('channelTypes', value)
              }
              value={channelTypes}
              maxTagCount={2}
              placeholder="Select channels"
            >
              {filterOptions.channelTypes[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_REGION) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Region</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('regions', value)
              }
              value={regions}
              maxTagCount={2}
              placeholder="Select regions"
            >
              {filterOptions.regions[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_COUNTY) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>County</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('counties', value)
              }
              value={counties}
              maxTagCount={2}
              placeholder="Select counties"
            >
              {filterOptions.counties[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_AREA) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Area</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('territories', value)
              }
              value={territories}
              maxTagCount={2}
              placeholder="Select areas"
            >
              {filterOptions.territories[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_REP_ACCOUNT)
          && (
          <>
            <div className="accounts-filters-board__break" />
            <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
              <FieldTitle>Rep - Account</FieldTitle>
              <Select
                mode="multiple"
                className="accounts-filters-board__select"
                onChange={value => onChangeField('representatives', value)
                }
                value={representatives}
                maxTagCount={2}
                placeholder="Select representatives"
              >
                {filterOptions.representatives[brand].map(CommonOption)}
              </Select>
            </div>
          </>
          )
        }
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_REP_METRIC) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Rep - Metric</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('representativesMetric', value)
              }
              value={representativesMetric}
              maxTagCount={2}
              placeholder="Select representatives"
            >
              {filterOptions.representativesMetric.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_STATUS) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Status</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('statusesNames', value)
              }
              value={statusesNames}
              maxTagCount={2}
              placeholder="Select statuses"
            >
              {filterOptions.statusesNames[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_DISTRIBUTORS) && (
          <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
            <FieldTitle>Distributor</FieldTitle>
            <Select
              mode="multiple"
              className="accounts-filters-board__select"
              onChange={value => onChangeField('distributors', value)
              }
              value={distributors}
              maxTagCount={2}
              placeholder="Select distributors"
            >
              {filterOptions.distributors[brand].map(CommonOption)}
            </Select>
          </div>
        )}
      </div>
      {shouldRenderAdminFilters && (
        <div className="accounts-filters-board__wrapper accounts-filters-board__admins-section">
          {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_DATA_SOURCE) && (
            <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
              <FieldTitle>Data Source</FieldTitle>
              <Select
                className="accounts-filters-board__select"
                onChange={value => onChangeField('accountsSource', value)
                }
                value={accountsSource}
              >
                {filterOptions.accountsSources.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_NO_ACTIVITY) && (
            <div
              className="accounts-filters-board__filter accounts-filters-board__filter_multiselect"
            >
              <FieldTitle>No Activity</FieldTitle>
              <InputNumber
                min={0}
                value={notVisitedIn}
                onChange={value => onChangeNumber(value)}
                formatter={value => `${value} days`}
                parser={value => value.replace('days', '').trim()}
              />
            </div>
          )}
          {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_FREQUENCY) && (
            <div className="accounts-filters-board__filter accounts-filters-board__filter_multiselect">
              <FieldTitle>Frequency</FieldTitle>
              <Select
                mode="multiple"
                className="accounts-filters-board__select"
                onChange={value => onChangeField('frequencies', value)
                }
                value={frequencies}
                maxTagCount={2}
                placeholder="Select frequencies"
              >
                {filterOptions.frequencies.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(accountsFiltersPermissionTypes.ACCOUNTS_FILTERS_NO_PHOTO) && (
            <div
              className="accounts-filters-board__filter accounts-filters-board__filter_multiselect"
            >
              <FieldTitle>No Photo</FieldTitle>
              <Select
                mode="multiple"
                className="accounts-filters-board__select"
                onChange={value => onChangeField('noImageReasons', value)
                }
                value={noImageReasons}
                maxTagCount={2}
                placeholder="Select photos"
              >
                {filterOptions.noImageReasons.map(CommonOption)}
              </Select>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FiltersBoard.propTypes = {
  disabledDate: func.isRequired,
  dateRange: arrayOf(object).isRequired,
  name: string.isRequired,
  address: string.isRequired,
  brand: string.isRequired,
  channelTypes: arrayOf(string).isRequired,
  cities: arrayOf(string).isRequired,
  states: arrayOf(string).isRequired,
  chains: arrayOf(string).isRequired,
  representatives: arrayOf(string).isRequired,
  representativesMetric: arrayOf(string).isRequired,
  territories: arrayOf(string).isRequired,
  notVisitedIn: number.isRequired,
  noImageReasons: arrayOf(string).isRequired,
  regions: arrayOf(string).isRequired,
  counties: arrayOf(string).isRequired,
  banners: arrayOf(string).isRequired,
  frequencies: arrayOf(string).isRequired,
  zipCode: string.isRequired,
  statusesNames: arrayOf(string).isRequired,
  distributors: arrayOf(string).isRequired,
  accountsSource: string.isRequired,
  filterOptions: instanceOf(FilterOptions).isRequired,
  onChangeField: func.isRequired,
  permissions: arrayOf(string).isRequired,
};

export default FiltersBoard;
