import {
  accountsPagePermissionTypes,
  merchandisingPagePermissionTypes,
  salesPagePermissionTypes,
  reportsPagePermissionTypes,
  managementPagePermissionTypes,
  timeEffortsPagePermissionTypes,
  schedulePagePermissionTypes,
  databasePagePermissionTypes,
  adminPagePermissionTypes,
} from 'userModule/constants/permissions';

class Permissions {
  constructor({
    accounts,
    merchandising,
    sales,
    reports,
    management,
    timeEfforts,
    schedule,
    database,
    admin,
  }) {
    this[accountsPagePermissionTypes.ACCOUNTS_PAGE] = accounts;
    this[merchandisingPagePermissionTypes.MERCHANDISING_PAGE] = merchandising;
    this[salesPagePermissionTypes.SALES_PAGE] = sales;
    this[reportsPagePermissionTypes.REPORTS_PAGE] = reports;
    this[managementPagePermissionTypes.MANAGEMENT_PAGE] = management;
    this[timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE] = timeEfforts;
    this[schedulePagePermissionTypes.SCHEDULE_PAGE] = schedule;
    this[databasePagePermissionTypes.DATABASE_PAGE] = database;
    this[adminPagePermissionTypes.ADMIN_PAGE] = admin;
  }

  static parse(obj) {
    return new Permissions({
      accounts: obj[accountsPagePermissionTypes.ACCOUNTS_PAGE],
      merchandising: obj[merchandisingPagePermissionTypes.MERCHANDISING_PAGE],
      sales: obj[salesPagePermissionTypes.SALES_PAGE],
      reports: obj[reportsPagePermissionTypes.REPORTS_PAGE],
      management: obj[managementPagePermissionTypes.MANAGEMENT_PAGE],
      timeEfforts: obj[timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE],
      schedule: obj[schedulePagePermissionTypes.SCHEDULE_PAGE],
      database: obj[databasePagePermissionTypes.DATABASE_PAGE],
      admin: obj[adminPagePermissionTypes.ADMIN_PAGE],
    });
  }
}

export default Permissions;
