export const DAY_PERIOD = 'day';
export const WEEK_PERIOD = 'week';
export const MONTH_PERIOD = 'month';

export const PERIODS_MAP = new Map([
  [DAY_PERIOD, 'days'],
  [WEEK_PERIOD, 'weeks'],
  [MONTH_PERIOD, 'months'],
]);

export const ENDS_NEVER = 'never';
export const ENDS_ON_DATE = 'on';
export const ENDS_AFTER = 'after';

export const LAST_MONTH_DAY = 'last';

const AUTOREPORT_ACCOUNTS_VISITED = 'AUTOREPORT_ACCOUNTS_VISITED';
const AUTOREPORT_ACCOUNTS_VISITS = 'AUTOREPORT_ACCOUNTS_VISITS';
const AUTOREPORT_ACCOUNTS_HOT_LEADS = 'AUTOREPORT_ACCOUNTS_HOT_LEADS';
const AUTOREPORT_ACCOUNTS_OPENED = 'AUTOREPORT_ACCOUNTS_OPENED';
const AUTOREPORT_ACCOUNTS_DISCONTINUED = 'AUTOREPORT_ACCOUNTS_DISCONTINUED';

const AUTOREPORT_MERCHANDISING_VISITS = 'AUTOREPORT_MERCHANDISING_VISITS';
const AUTOREPORT_MERCHANDISING_ACCOUNTS = 'AUTOREPORT_MERCHANDISING_ACCOUNTS';
const AUTOREPORT_MERCHANDISING_FACINGS_CHAIN = 'AUTOREPORT_MERCHANDISING_FACINGS_CHAIN';
const AUTOREPORT_MERCHANDISING_FACINGS_UDS = 'AUTOREPORT_MERCHANDISING_FACINGS_UDS';
const AUTOREPORT_MERCHANDISING_FACINGS_TOTAL = 'AUTOREPORT_MERCHANDISING_FACINGS_TOTAL';
const AUTOREPORT_MERCHANDISING_SEC_PLACE_CHAIN = 'AUTOREPORT_MERCHANDISING_SEC_PLACE_CHAIN';
const AUTOREPORT_MERCHANDISING_SEC_PLACE_UDS = 'AUTOREPORT_MERCHANDISING_SEC_PLACE_UDS';
const AUTOREPORT_MERCHANDISING_SEC_PLACE_TOTAL = 'AUTOREPORT_MERCHANDISING_SEC_PLACE_TOTAL';
const AUTOREPORT_MERCHANDISING_REORDER = 'AUTOREPORT_MERCHANDISING_REORDER';

const AUTOREPORT_SALES_DISTRIBUTORS = 'AUTOREPORT_SALES_DISTRIBUTORS';
const AUTOREPORT_SALES_TOTAL_VOLUME = 'AUTOREPORT_SALES_TOTAL_VOLUME';
const AUTOREPORT_SALES_TOTAL_DOLLARS = 'AUTOREPORT_SALES_TOTAL_DOLLARS';
const AUTOREPORT_SALES_TOP = 'AUTOREPORT_SALES_TOP';

const AUTOREPORT_TE_HOURS = 'AUTOREPORT_TE_HOURS';
const AUTOREPORT_TE_MILES = 'AUTOREPORT_TE_MILES';

export const REPORT_METRICS_TREE_DATA = [
  {
    title: 'Accounts Metrics',
    key: 'Accounts Metrics',
    children: [
      {
        title: 'Visits',
        key: AUTOREPORT_ACCOUNTS_VISITS,
      },
      {
        title: 'Accounts Visited',
        key: AUTOREPORT_ACCOUNTS_VISITED,
      },
      {
        title: 'Hot Leads',
        key: AUTOREPORT_ACCOUNTS_HOT_LEADS,
      },
      {
        title: 'Opened Accounts',
        key: AUTOREPORT_ACCOUNTS_OPENED,
      },
      {
        title: 'Accounts Discontinued',
        key: AUTOREPORT_ACCOUNTS_DISCONTINUED,
      },
      {
        title: 'Field Hours Worked',
        key: AUTOREPORT_TE_HOURS,
      },
      {
        title: 'Field Mileage Driven',
        key: AUTOREPORT_TE_MILES,
      },
    ],
  },
  {
    title: 'Merchandising Metrics',
    key: 'Merchandising Metrics',
    children: [
      {
        title: 'Merchandising Visits',
        key: AUTOREPORT_MERCHANDISING_VISITS,
      },
      {
        title: 'Accounts Merchandised',
        key: AUTOREPORT_MERCHANDISING_ACCOUNTS,
      },
      {
        title: 'New Facings - Chains',
        key: AUTOREPORT_MERCHANDISING_FACINGS_CHAIN,
      },
      {
        title: 'New Facings - UDS',
        key: AUTOREPORT_MERCHANDISING_FACINGS_UDS,
      },
      {
        title: 'New Facings - Total',
        key: AUTOREPORT_MERCHANDISING_FACINGS_TOTAL,
      },
      {
        title: 'New Secondary Placements - Chains',
        key: AUTOREPORT_MERCHANDISING_SEC_PLACE_CHAIN,
      },
      {
        title: 'New Secondary Placements - UDS',
        key: AUTOREPORT_MERCHANDISING_SEC_PLACE_UDS,
      },
      {
        title: 'New Secondary Placements - Total',
        key: AUTOREPORT_MERCHANDISING_SEC_PLACE_TOTAL,
      },
      {
        title: 'Reorder Rate - Average',
        key: AUTOREPORT_MERCHANDISING_REORDER,
      },
    ],
  },
  {
    title: 'Sales Metrics',
    key: 'Sales Metrics',
    children: [
      {
        title: 'Distributor Case Sales',
        key: AUTOREPORT_SALES_DISTRIBUTORS,
      },
      {
        title: 'Total Case Sales - Volume',
        key: AUTOREPORT_SALES_TOTAL_VOLUME,
      },
      {
        title: 'Total Case Sales - Dollars',
        key: AUTOREPORT_SALES_TOTAL_DOLLARS,
      },
      {
        title: 'Top Selling SKU',
        key: AUTOREPORT_SALES_TOP,
      },
    ],
  },
];
