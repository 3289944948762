class Status {
  constructor({
    name, color, sortOrder, disabled, disabledOnMap, image,
  }) {
    this.name = name;
    this.color = color;
    this.sortOrder = sortOrder;
    this.disabled = disabled;
    this.disabledOnMap = disabledOnMap;
    this.image = image;
  }

  static parse(obj) {
    return new Status({
      name: obj.Name,
      color: obj.Color,
      sortOrder: obj.SortOrder,
      disabled: obj.Disabled,
      disabledOnMap: obj.DisabledOnMap,
      image: obj.Base64MarkerImage,
    });
  }
}

export default Status;
