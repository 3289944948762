export const DARK_BLUE = '#4285f4';
export const BLUE_GREEN = '#0c4247';
export const GREEN = '#47A025';
export const ORANGE = '#e58c1d';
export const YELLOW = '#f1c40f';
export const LIGHT_RED = '#ff8bc9';
export const DARK_RED = '#b91919';
export const DARKER_RED = '#6f1d32';
export const LIGHT_PURPLE = '#b866da';
export const DARK_PURPLE = '#662182';
export const LIGHT_BLUE = '#56daff';
export const LIGHT_GREY_BLUE = '#a7d9dd';
export const GREY = '#b8bec2';

export const COLOR_SET = [
  DARK_BLUE,
  GREEN,
  YELLOW,
  DARK_PURPLE,
  LIGHT_RED,
  LIGHT_PURPLE,
  ORANGE,
  DARK_RED,
  BLUE_GREEN,
  LIGHT_GREY_BLUE,
  DARKER_RED,
  LIGHT_BLUE,
  GREY,
];
