import Status from './Status';
import Brand from './Brand';

class FilterOptions {
  constructor({
    brands,
    channelTypes,
    cities,
    states,
    chains,
    representatives,
    representativesMetric,
    statuses,
    noImageReasons,
    accountsSources,
    regions,
    counties,
    territories,
    banners,
    frequencies,
    statusesNames,
    distributors,
  }) {
    this.brands = brands;
    this.channelTypes = channelTypes;
    this.cities = cities;
    this.states = states;
    this.chains = chains;
    this.representatives = representatives;
    this.representativesMetric = representativesMetric;
    this.territories = territories;
    this.statuses = statuses;
    this.noImageReasons = noImageReasons;
    this.accountsSources = accountsSources;
    this.regions = regions;
    this.counties = counties;
    this.banners = banners;
    this.frequencies = frequencies;
    this.statusesNames = statusesNames;
    this.distributors = distributors;
  }

  static parse(obj) {
    return new FilterOptions({
      brands: obj.Brands.map(Brand.parse),
      channelTypes: obj.Channels,
      cities: obj.Cities,
      states: obj.States,
      chains: obj.Chains,
      representatives: obj.Representatives,
      representativesMetric: obj.MetricRepresentatives,
      territories: obj.Territories,
      statuses: obj.Statuses.map(Status.parse),
      noImageReasons: obj.NoImagesVariants,
      accountsSources: obj.AccountSources,
      regions: obj.Regions,
      counties: obj.Counties,
      banners: obj.Banners,
      frequencies: obj.Frequencies.map(String),
      statusesNames: obj.StatusesNames,
      distributors: obj.Distributors,
    });
  }

  createColorScheme() {
    return (
      this.statuses.reduce(
        (colorMap, status) => colorMap.set(status.name, status.color),
        new Map(),
      ) || new Map()
    );
  }
}

export default FilterOptions;
