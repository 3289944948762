/* eslint-disable no-param-reassign */

import qs from 'qs';
import {
  isBoolean, isArray, isString, isEqual,
} from 'lodash';
import produce from 'immer';

class AccountsFilter {
  static shouldIncudeParam(key, param) {
    if (isString(param)) {
      return param.length > 0;
    }

    if (isArray(param)) {
      return (
        param.length > 0
        && param.length !== AccountsFilter.defaultParams[key].length
      );
    }

    return param || isBoolean(param);
  }

  static mapValueToApi(key, value) {
    const transfromFunc = AccountsFilter.fieldValuesToApiMap.get(key);

    return transfromFunc ? transfromFunc(value) : value;
  }

  static fieldValuesToApiMap = new Map([
    ['channels', channels => channels.join()],
    ['cities', cities => cities.join()],
    ['states', states => states.join()],
    ['chains', chains => chains.join()],
    ['representatives', representatives => representatives.join()],
    ['territories', territories => territories.join()],
    ['regions', regions => regions.join()],
    ['counties', counties => counties.join()],
    ['banners', banners => banners.join()],
    ['frequencies', frequencies => frequencies.join()],
  ]);

  static fieldNamesToApiMap = new Map([
    ['activeAccounts', 'accountsActive'],
  ]);

  static defaultParams = {
    name: '',
    address: '',
    channels: [],
    cities: [],
    states: [],
    chains: [],
    representatives: [],
    territories: [],
    accountsSource: '',
    regions: [],
    counties: [],
    banners: [],
    frequencies: [],
    activeAccounts: '',
    zipCode: '',
  };

  static setDefaultParams({ defaultAccountsSource, defaultActiveAccounts }) {
    AccountsFilter.defaultParams = produce(
      AccountsFilter.defaultParams,
      (draft) => {
        draft.accountsSource = defaultAccountsSource;
        draft.activeAccounts = defaultActiveAccounts;
      },
    );
  }

  static parseQueryString(query) {
    const { defaultParams } = AccountsFilter;
    const parsed = qs.parse(query, { ignoreQueryPrefix: true });

    const normalizedParams = {
      name: parsed.name || defaultParams.name,
      address: parsed.address || defaultParams.address,
      channels: parsed.channels ?.split(',') || defaultParams.channels,
      cities: parsed.cities ?.split(',') || defaultParams.cities,
      states: parsed.states ?.split(',') || defaultParams.states,
      chains: parsed.chains ?.split(',') || defaultParams.chains,
      representatives: parsed.representatives ?.split(',') || defaultParams.representatives,
      territories: parsed.territories ?.split(',') || defaultParams.territories,
      accountsSource: parsed.accountsSource || defaultParams.accountsSource,
      activeAccounts: parsed.activeAccounts || defaultParams.activeAccounts,
      regions: parsed.regions?.split(',') || defaultParams.regions,
      counties: parsed.counties?.split(',') || defaultParams.counties,
      banners: parsed.banners?.split(',') || defaultParams.banners,
      frequencies: parsed.frequencies?.split(',') || defaultParams.frequencies,
      zipCode: parsed.zipCode || defaultParams.zipCode,
    };

    return new AccountsFilter(normalizedParams);
  }

  constructor({
    name,
    address,
    channels,
    cities,
    states,
    chains,
    representatives,
    territories,
    accountsSource,
    activeAccounts,
    regions,
    counties,
    banners,
    frequencies,
    zipCode,
  }) {
    this.name = name;
    this.address = address;
    this.channels = channels;
    this.cities = cities;
    this.states = states;
    this.chains = chains;
    this.representatives = representatives;
    this.territories = territories;
    this.accountsSource = accountsSource;
    this.activeAccounts = activeAccounts;
    this.regions = regions;
    this.counties = counties;
    this.banners = banners;
    this.frequencies = frequencies;
    this.zipCode = zipCode;
  }

  getQueryStringFilter(ignore = []) {
    const params = {};

    Object.entries(this).forEach(([key, value]) => {
      if (
        !ignore.includes(key) && AccountsFilter.shouldIncudeParam(key, value)
      ) {
        params[key] = isArray(value) ? value.join() : value;
      }
    });

    return qs.stringify(params, {
      arrayFormat: 'brackets',
    });
  }

  getApiParams() {
    const params = {};

    Object.entries(this).forEach(([key, value]) => {
      if (AccountsFilter.shouldIncudeParam(key, value)) {
        const mappedKey = AccountsFilter.fieldNamesToApiMap.get(key) || key;
        const mappedValue = AccountsFilter.fieldValuesToApiMap.get(key)?.(value) || value;
        params[mappedKey] = mappedValue;
      }
    });

    return params;
  }

  getNormalizedFilter() {
    return new AccountsFilter({
      ...this,
      name: this.name.trim(),
      address: this.address.trim(),
      zipCode: this.zipCode.trim(),
    });
  }

  isModelEqualWithDefault() {
    return isEqual(this, new AccountsFilter(AccountsFilter.defaultParams));
  }
}

export default AccountsFilter;
