import { connect } from 'react-redux';

import TotalFacingsChart from 'merchandisingModule/components/TotalFacingsChart';

import TotalFacing from 'merchandisingModule/models/TotalFacing';

import { shouldRenderTotalFacingsChart } from 'merchandisingModule/selectors/totalFacingsChartSelectors';
import { colorGenerator } from 'common/utilities/colorGenerator';

const colorMap = colorGenerator(TotalFacing.fieldNames);

export default connect(
  state => ({
    totalFacingsData: state.merchandising.totalFacingsData,
    colorMap,
    shouldRenderChart: shouldRenderTotalFacingsChart(state),
  }),
)(TotalFacingsChart);
