import { connect } from 'react-redux';

import TotalCasesByDistributorsChart from 'salesModule/components/TotalCasesByDistributorsChart';
import { distributorsAndColorsCasesSelector, shouldRenderCasesChartSelector } from 'salesModule/selectors/totalSalesCasesByDistributorSelector';

export default connect(
  (state) => {
    const { distributors, distributorsColorMap } = distributorsAndColorsCasesSelector(state);
    const shouldRenderChart = shouldRenderCasesChartSelector(state);

    return {
      totalCasesByDistributors: state.sales.totalCasesByDistributorsData,
      distributors,
      colorMap: distributorsColorMap,
      shouldRenderChart,
      withDivider: shouldRenderChart,
    };
  },
)(TotalCasesByDistributorsChart);
