/* eslint-disable no-param-reassign */

import produce from 'immer';
import fileDownload from 'js-file-download';

import {
  ASM_UDS,
  ASM_UDS_BRAND,
  ASM_UDS_REPORT,
  TIME_EFFORTS_FILTERS_ENDPOINT,
  TIME_EFFORTS_REPORT,
  TIME_EFFORTS,
  TIME_EFFORTS_REPORT_WEEK,
  TIME_EFFORTS_REPORT_MONTH,
  ASM_UDS_REPORT_BRAND,
  TIME_EFFORTS_WEEK,
  TIME_EFFORTS_MONTH,
  UDS_ACCOUNTS_REPORT,
  UDS_ACCOUNTS_BY_BRAND_REPORT,
  ASM_UDS_ALL_BRAND_REPORT,
  ASM_UDS_ALL_REP_REPORT,
} from 'common/constants/apiRoutes';

import ajax from 'common/utilities/ajax';

import AsmUds from 'udsModule/models/AsmUds';
import FilterOptions from 'udsModule/models/FilterOptions';
import AccountsFilter from 'udsModule/models/AccountsFilter';
import WorkStats from 'udsModule/models/WorkStats';
import WorkStatsPeriod from 'udsModule/models/WorkStatsPeriod';

import { showError } from 'common/helpers/notificationManager';

const initialState = {
  filter: new AccountsFilter({}),
  initialized: false,
  filterOptions: new FilterOptions({
    brands: [],
    channelTypes: {},
    cities: {},
    states: {},
    chains: {},
    representatives: {},
    territories: {},
    accountsSources: [],
    distributors: [],
    salesSkus: [],
    merchandisingSkus: [],
    regions: {},
    counties: {},
    banners: {},
    frequencies: [],
    inactiveReps: [],
  }),
  asmUdsData: {},
  brandUdsData: [],
  timeEffortsData: [],
  timeEffortsWeeklyData: [],
  timeEffortsMonthlyData: [],

  timeEffortsWeeklyFilteredMetrics: [],
  timeEffortsMonthlyFilteredMetrics: [],
  brandUdsDataFilteredMetrics: [],
  asmUdsDataFilteredMetrics: {},
};

export default {
  state: initialState,
  reducers: {
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    setAsmUdsData: produce((state, asmUdsData) => {
      state.asmUdsData = asmUdsData;
    }),
    setBrandUdsData: produce((state, brandUdsData) => {
      state.brandUdsData = brandUdsData;
    }),
    setTimeEffortsData: produce((state, timeEffortsData) => {
      state.timeEffortsData = timeEffortsData;
    }),
    setTimeEffortsWeeklyData: produce((state, timeEffortsWeeklyData) => {
      state.timeEffortsWeeklyData = timeEffortsWeeklyData;
    }),
    setTimeEffortsMonthlyData: produce((state, timeEffortsMonthlyData) => {
      state.timeEffortsMonthlyData = timeEffortsMonthlyData;
    }),

    setTimeEffortsWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.timeEffortsWeeklyFilteredMetrics = filteredMetrics;
    }),
    setTimeEffortsMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.timeEffortsMonthlyFilteredMetrics = filteredMetrics;
    }),
    setBrandUdsDataFilteredMetrics: produce((state, filteredMetrics) => {
      state.brandUdsDataFilteredMetrics = filteredMetrics;
    }),
    setDefaultAsmUdsDataFilteredMetrics: produce((state, filteredMetrics) => {
      state.asmUdsDataFilteredMetrics = filteredMetrics;
    }),
    setAsmUdsDataFilteredMetrics: produce((state, filteredMetrics, asmName) => {
      state.asmUdsDataFilteredMetrics[asmName] = filteredMetrics;
    }),

    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(TIME_EFFORTS_FILTERS_ENDPOINT);
      const filterOptionsModel = FilterOptions.parse(response.data);
      dispatch.uds.setFilterOptions(filterOptionsModel);
    },

    async fetchTimeEffortsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(TIME_EFFORTS, config);
      const timeEffortsData = response.data.map(WorkStats.parse);
      dispatch.uds.setTimeEffortsData(timeEffortsData);
    },

    async downloadAsmUdsReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(ASM_UDS_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM UDS report',
        });
      }
    },

    async fetchAsmUdsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(ASM_UDS, config);
      const asmUdsData = Object.keys(response.data)
        .reduce((asmUdsDataObject, asmName) => {
          asmUdsDataObject[asmName] = Object.keys(response.data[asmName])
            .sort((_, b) => (b === 'TOTAL' ? -1 : 0))
            .map((name) => {
              const metrics = response.data[asmName][name];
              const metricsNames = Object.keys(metrics);
              return metricsNames.map((metricName) => {
                const metricsData = metrics[metricName];
                return metricsData
                  .map(metricsDataItem => AsmUds.parse(metricsDataItem, name, metricName));
              });
            }).reduce((acc, cur) => [...acc, cur], []);
          return asmUdsDataObject;
        }, {});
      const asmUdsDataFilteredMetrics = Object.keys(response.data)
        .reduce((acc, asmName) => {
          acc[asmName] = [];
          return acc;
        }, {});
      dispatch.uds.setDefaultAsmUdsDataFilteredMetrics(asmUdsDataFilteredMetrics);
      dispatch.uds.setAsmUdsData(asmUdsData);
    },

    async fetchBrandUdsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(ASM_UDS_BRAND, config);
      const brandUdsData = Object.keys(response.data)
        .sort((_, b) => (b === 'TOTAL' ? -1 : 0))
        .map((name) => {
          const metrics = response.data[name];
          const metricsNames = Object.keys(metrics);
          return metricsNames.map((metricName) => {
            const metricsData = metrics[metricName];
            return metricsData
              .map(metricsDataItem => AsmUds.parse(metricsDataItem, name, metricName));
          });
        }).reduce((acc, cur) => [...acc, cur], []);
      dispatch.uds.setBrandUdsData(brandUdsData);
    },

    async downloadTimeEffortsReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(TIME_EFFORTS_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download time and efforts report',
        });
      }
    },

    async downloadTimeEffortsWeekReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(TIME_EFFORTS_REPORT_WEEK, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download time and efforts report',
        });
      }
    },

    async downloadTimeEffortsMonthReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(TIME_EFFORTS_REPORT_MONTH, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download time and efforts report',
        });
      }
    },

    async downloadAsmUdsBrandReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(ASM_UDS_REPORT_BRAND, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM UDS report',
        });
      }
    },

    async downloadUdsAccountsReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(UDS_ACCOUNTS_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download UDS Accounts List',
        });
      }
    },

    async downloadUdsAccountsByBrandReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(UDS_ACCOUNTS_BY_BRAND_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download UDS Accounts by Brand List',
        });
      }
    },

    async fetchTimeEffortsWeekData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(TIME_EFFORTS_WEEK, config);
      const timeEffortsWeeklyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData.map(metricsDataItem => WorkStatsPeriod.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);
      dispatch.uds.setTimeEffortsWeeklyData(timeEffortsWeeklyData);
    },

    async fetchTimeEffortsMonthData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(TIME_EFFORTS_MONTH, config);
      const timeEffortsMonthlyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData.map(metricsDataItem => WorkStatsPeriod.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);
      dispatch.uds.setTimeEffortsMonthlyData(timeEffortsMonthlyData);
    },

    async downloadAsmUdsAllBrandReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(ASM_UDS_ALL_BRAND_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM Tracker - UDS - By Brand (All)',
        });
      }
    },

    async downloadAsmUdsAllRepReport(_, state) {
      const { filter } = state.uds;
      try {
        const response = await ajax.get(ASM_UDS_ALL_REP_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM Tracker - UDS - By Rep (All)',
        });
      }
    },
  }),
};
