/* eslint-disable no-param-reassign */

import produce from 'immer';
import fileDownload from 'js-file-download';

import { ACCOUNTS_ENDPOINT, ACCOUNTS_FILTERS_ENDPOINT, ACCOUNTS_XLSX } from 'common/constants/apiRoutes';

import ajax from 'common/utilities/ajax';

import AccountsFilter from 'accountsModule/models/AccountsFilter';
import { showError } from 'common/helpers/notificationManager';

import FilterOptions from 'accountsModule/models/FilterOptions';
import Account from 'accountsModule/models/Account';
import Summary from 'accountsModule/models/Summary';
import AccountsByDay from 'accountsModule/models/AccountsByDay';
import AmountByChannel from 'accountsModule/models/AmountByChannel';
import AccountsByDistributorOnDate from 'accountsModule/models/AccountsByDistributorOnDate';

const initialState = {
  accounts: [],
  summary: new Summary({
    stores: null,
    forms: null,
    totalAccountsPercentage: null,
  }),
  accountStatuses: [],
  accountStatusesData: [],
  accountsByChannelType: [],
  authAccountsByChannelType: [],
  activeAccountStatuses: [],
  accountsByDistributorOnDate: [],
  filterOptions: new FilterOptions({
    brands: [],
    channelTypes: {},
    cities: {},
    states: {},
    chains: {},
    representatives: {},
    representativesMetric: [],
    statuses: [],
    noImageReasons: [],
    accountsSources: [],
    regions: {},
    counties: {},
    territories: {},
    banners: {},
    frequencies: [],
    statusesNames: {},
    distributors: {},
  }),
  filter: new AccountsFilter({}),
  initialized: false,
};

export default {
  state: initialState,
  reducers: {
    setAccounts: produce((state, accounts) => {
      state.accounts = accounts;
    }),
    setAccountStatusesData: produce((state, accountStatusesData) => {
      state.accountStatusesData = accountStatusesData;
    }),
    setAccountsByChannelType: produce((state, accountsByChannelType) => {
      state.accountsByChannelType = accountsByChannelType;
    }),
    setAccountStatuses: produce((state, accountStatuses) => {
      state.accountStatuses = accountStatuses;
    }),
    setActiveAccountStatuses: produce((state, activeAccountStatuses) => {
      state.activeAccountStatuses = activeAccountStatuses;
    }),
    setAccountsByDistributorOnDate: produce(
      (state, accountsByDistributorOnDate) => {
        state.accountsByDistributorOnDate = accountsByDistributorOnDate;
      },
    ),
    setSummary: produce((state, summaryData) => {
      state.summary = summaryData;
    }),
    setAuthAccountsByChannelType: produce((state, authAccountsByChannelType) => {
      state.authAccountsByChannelType = authAccountsByChannelType;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchAccounts(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(ACCOUNTS_ENDPOINT, config);
      const accounts = response.data.General.Accounts.map(Account.parse);
      const summaryData = Summary.parse(response.data.General);
      dispatch.accounts.setAccounts(accounts);
      dispatch.accounts.setSummary(summaryData);

      const accountStatusesData = response.data.Statuses.AccountsByDate.map(
        AccountsByDay.parse,
      );
      dispatch.accounts.setAccountStatusesData(accountStatusesData);
      dispatch.accounts.setAccountStatuses(
        response.data.Statuses.AccountStatusesNames,
      );
      dispatch.accounts.setActiveAccountStatuses(
        response.data.Statuses.ActiveAccountStatusesNames,
      );

      const accountsByChannelType = response.data.AllChannelSums.map(
        AmountByChannel.parse,
      );
      dispatch.accounts.setAccountsByChannelType(accountsByChannelType);

      const authAccountsByChannelType = response.data.AuthorisedChannelSums.map(
        AmountByChannel.parse,
      );
      dispatch.accounts.setAuthAccountsByChannelType(authAccountsByChannelType);

      const accountsByDistributorOnDate = response.data.DistributorSums.map(
        AccountsByDistributorOnDate.parse,
      );
      dispatch.accounts.setAccountsByDistributorOnDate(
        accountsByDistributorOnDate,
      );
    },
    async fetchFilterOptions() {
      const response = await ajax.get(ACCOUNTS_FILTERS_ENDPOINT);
      const filterOptions = FilterOptions.parse(response.data);
      dispatch.accounts.setFilterOptions(filterOptions);
    },

    async downloadAccountsPageReport(_, state) {
      const { filter } = state.accounts;

      try {
        const response = await ajax.get(ACCOUNTS_XLSX, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download accounts report',
        });
      }
    },
  }),
};
