import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentPosPlacementNamesSelector,
} from 'merchandisingModule/selectors/posPlacementSelectors';

import PosPlacementChart from 'merchandisingModule/components/PosPlacementChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  posPlacementNames: currentPosPlacementNamesSelector(state),
  posPlacementData: state.merchandising.posPlacementData,
}))(
  withLegend({
    keys: 'posPlacementNames',
    isLegendActive: false,
  })(PosPlacementChart),
);
