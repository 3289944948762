import React, { PureComponent } from 'react';
import {
  Switch, Route, Redirect, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, instanceOf } from 'prop-types';

import {
  LOGIN,
  SALES,
  ACCOUNTS,
  MERCHANDISING,
  REPORTS,
  ADMIN,
  MANAGEMENT,
  UDS,
  SCHEDULE,
  DATABASE,
} from 'common/constants/routes';

import getLoadingGlobalState from 'common/utilities/loading';

import { Spin } from 'antd';

import UserInfo from 'userModule/models/UserInfo';

import Header from 'common/components/Header';
import Page from 'common/components/Page';
import Footer from 'common/components/Footer';

import SalesPageContainer from 'salesModule/containers/SalesPageContainer';
import LoginPageContainer from 'userModule/containers/LoginPageContainer';
import AccountsPageContainer from 'accountsModule/containers/AccountsPageContainer';
import MerchandisingPageContainer from 'merchandisingModule/containers/MerchandisingPageContainer';
import ReportsPageContainer from 'reportsModule/containers/ReportsPageContainer';
import AdminPageContainer from 'adminModule/containers/AdminPageContainer';
import ManagementPageContainer from 'managementModule/containers/ManagementPageContainer';
import UdsPageContainer from 'udsModule/containers/UdsPageContainer';
import SchedulePageContainer from 'scheduleModule/containers/SchedulePageContainer';
import DatabasePageContainer from 'databaseModule/containers/DatabasePageContainer';

import {
  accountsPagePermissionTypes,
  merchandisingPagePermissionTypes,
  salesPagePermissionTypes,
  reportsPagePermissionTypes,
  managementPagePermissionTypes,
  timeEffortsPagePermissionTypes,
  schedulePagePermissionTypes,
  databasePagePermissionTypes,
  adminPagePermissionTypes,
} from 'userModule/constants/permissions';

import Permissions from 'userModule/models/Permissions';

import './index.scss';

const routesPermissionsMap = new Map([
  [accountsPagePermissionTypes.ACCOUNTS_PAGE, ACCOUNTS],
  [merchandisingPagePermissionTypes.MERCHANDISING_PAGE, MERCHANDISING],
  [salesPagePermissionTypes.SALES_PAGE, SALES],
  [reportsPagePermissionTypes.REPORTS_PAGE, REPORTS],
  [managementPagePermissionTypes.MANAGEMENT_PAGE, MANAGEMENT],
  [timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE, UDS],
  [schedulePagePermissionTypes.SCHEDULE_PAGE, SCHEDULE],
  [databasePagePermissionTypes.DATABASE_PAGE, DATABASE],
  [adminPagePermissionTypes.ADMIN_PAGE, ADMIN],
]);

const guestRoutes = (
  <Switch>
    <Route path={LOGIN} component={LoginPageContainer} />
    <Redirect to={LOGIN} />
  </Switch>
);

const userRoutes = (permissions) => {
  const availablePages = Object.keys(permissions).filter(pageName => !!permissions[pageName].Permissions);
  return (
    <Switch>
      {permissions[accountsPagePermissionTypes.ACCOUNTS_PAGE].Permissions && <Route path={ACCOUNTS} component={AccountsPageContainer} /> }
      {permissions[merchandisingPagePermissionTypes.MERCHANDISING_PAGE].Permissions && <Route path={MERCHANDISING} component={MerchandisingPageContainer} /> }
      {permissions[salesPagePermissionTypes.SALES_PAGE].Permissions && <Route path={SALES} component={SalesPageContainer} /> }
      {permissions[reportsPagePermissionTypes.REPORTS_PAGE].Permissions && <Route path={REPORTS} component={ReportsPageContainer} /> }
      {permissions[managementPagePermissionTypes.MANAGEMENT_PAGE].Permissions && <Route path={MANAGEMENT} component={ManagementPageContainer} /> }
      {permissions[timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE].Permissions && <Route path={UDS} component={UdsPageContainer} /> }
      {permissions[schedulePagePermissionTypes.SCHEDULE_PAGE].Permissions && <Route path={SCHEDULE} component={SchedulePageContainer} /> }
      {permissions[databasePagePermissionTypes.DATABASE_PAGE].Permissions && <Route path={DATABASE} component={DatabasePageContainer} /> }
      {permissions[adminPagePermissionTypes.ADMIN_PAGE].Permissions && <Route path={ADMIN} component={AdminPageContainer} /> }
      <Redirect to={routesPermissionsMap.get(availablePages[0])} />
    </Switch>
  );
};

class App extends PureComponent {
  static propTypes = {
    authorized: bool.isRequired,
    userInfo: instanceOf(UserInfo),
    loading: bool.isRequired,
    permissions: instanceOf(Permissions),
    authenticate: func.isRequired,
    isLoadingToken: bool.isRequired,
  };

  static defaultProps = {
    userInfo: null,
    permissions: null,
  };

  componentDidMount() {
    const { authenticate } = this.props;
    authenticate();
  }

  render() {
    const {
      authorized, userInfo, loading, permissions, isLoadingToken,
    } = this.props;

    const routes = authorized ? userRoutes(permissions) : guestRoutes;

    return (
      <div className="app">
        <Spin spinning={loading}>
          <Header
            authorized={authorized}
            brandFullName={userInfo ? userInfo.brandFullName : null}
          />
          <div className="app__page-container">
            <Page>{!isLoadingToken ? routes : null}</Page>
          </div>
          <Footer />
        </Spin>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      authorized: state.session.authorized,
      userInfo: state.session.userInfo,
      permissions: state.session.permissions,
      loading: getLoadingGlobalState(state.loading.effects),
      isLoadingToken: state.session.isLoadingToken,
    }),
    dispatch => ({
      authenticate: dispatch.session.authenticate,
    }),
  )(App),
);
