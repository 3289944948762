import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import SectionHeading from 'common/components/SectionHeading';

import { DATE_FORMAT } from 'common/constants/dateConstants';

/* eslint-disable react/destructuring-assignment */
import './index.scss';

const withHeading = ({
  heading,
  title = 'title',
  isScrollable = false,
  headingDate,
}) => (WrappedComponent) => {
  const WithHeading = (props) => {
    const formattedChartDate = headingDate && moment(props[headingDate]).format(DATE_FORMAT);

    return (
      <div
        className={classnames({
          'scrollable-chart': isScrollable,
        })}
      >
        <SectionHeading>
          {heading || ''}
          {' '}
          {props[title] || ''}
          {formattedChartDate}
        </SectionHeading>
        <WrappedComponent {...props} />
      </div>
    );
  };

  WithHeading.displayName = `WithHeading(${WrappedComponent.displayName
        || WrappedComponent.name
        || 'Component'})`;

  return WithHeading;
};

export default withHeading;
