import { connect } from 'react-redux';

import TargetDateSection from 'adminModule/components/TargetDateSection';


export default connect(
  state => ({
    isFilterOptionsFetching: state.loading.effects.admin.fetchFilterOptions,
    brands: state.admin.filterOptions.brands,
    representatives: state.admin.filterOptions.representatives,
    earliestDate: state.admin.filterOptions.targetEarliestDate,
    target: state.admin.target,
    isTargetBrandChanging: state.loading.effects.admin.changeTarget,
  }),
  dispatch => ({
    onChangeTargetBrands: dispatch.admin.setTargetBrands,
    onChangeTargetRepresentatives: dispatch.admin.setTargetRepresentatives,
    onChangeTargetDate: dispatch.admin.setTargetDate,
    onChangeTargetNumber: dispatch.admin.setTargetNumber,
    onApply: dispatch.admin.changeTarget,
  }),
)(TargetDateSection);
