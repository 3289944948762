import { connect } from 'react-redux';
import { permissionsSelector } from 'managementModule/selectors/managementPageSelectors';

import ActiveAccountsReportsSection from '../components/ActiveAccountsReportsSection';

export default connect(
  state => ({
    isActiveAccountsReportDownloading:
      state.loading.effects.management.downloadActiveAccountsReport,
    isDeadAccountsReportDownloading:
      state.loading.effects.management.downloadDeadAccountsReport,
    isDeadAccountsHotReportDownloading:
      state.loading.effects.management.downloadDeadAccountsHotReport,
    isAccountsTotalSalesReportDownloading: state.loading.effects.management.downloadAccountsTotalSalesReport,
    isFilterOptionsFetching:
      state.loading.effects.management.fetchFilterOptions,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadActiveAccountsReport: () => dispatch.management.downloadActiveAccountsReport(),
    onDownloadDeadAccountsReport: () => dispatch.management.downloadDeadAccountsReport(),
    onDownloadDeadAccountsHotReport: () => dispatch.management.downloadDeadAccountsHotReport(),
    onDownloadAccountsTotalSalesReport: () => dispatch.management.downloadAccountsTotalSalesReport(),
  }),
)(ActiveAccountsReportsSection);
