import TargetRepresentative from './TargetRepresentative';
import BrandScorecard from './BrandScorecard';

class FilterOptions {
  constructor({
    brands,
    representatives,
    targetEarliestDate,
    scorecardsByBrand,
    chainsByBrand,
    bannersByBrand,
    channelsByBrand,
    regionsByBrand,
    countiesByBrand,
    areasByBrand,
    accountRepsByBrand,
    placements,
  }) {
    this.brands = brands;
    this.representatives = representatives;
    this.targetEarliestDate = targetEarliestDate;
    this.scorecardsByBrand = scorecardsByBrand;
    this.chainsByBrand = chainsByBrand;
    this.bannersByBrand = bannersByBrand;
    this.channelsByBrand = channelsByBrand;
    this.regionsByBrand = regionsByBrand;
    this.countiesByBrand = countiesByBrand;
    this.areasByBrand = areasByBrand;
    this.accountRepsByBrand = accountRepsByBrand;
    this.placements = placements;
  }

  static parseScorecardsByBrand(scorecardsInfo) {
    return scorecardsInfo.reduce((acc, brandScorecardsObj) => {
      acc[brandScorecardsObj.Brand] = brandScorecardsObj.ScorecardsInfo.map(BrandScorecard.parse);
      return acc;
    }, {});
  }

  static parse(obj) {
    return new FilterOptions({
      brands: obj.Brands,
      placements: obj.Placements,
      representatives: obj.Representatives.map(TargetRepresentative.parse),
      targetEarliestDate: obj.TargetEarliestDate,
      scorecardsByBrand: FilterOptions.parseScorecardsByBrand(obj.ScorecardFilenames),
      chainsByBrand: obj.BrandChains,
      bannersByBrand: obj.BrandBanners,
      channelsByBrand: obj.BrandChannels,
      regionsByBrand: obj.BrandRegions,
      countiesByBrand: obj.BrandCounties,
      areasByBrand: obj.BrandAreas,
      accountRepsByBrand: obj.BrandAccountRepresentatives,
    });
  }
}

export default FilterOptions;
