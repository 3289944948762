import React, { Fragment, PureComponent } from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';

import { TOTAL_ACCOUNTS_FIELD_NAME } from 'accountsModule/constants/chartConstants';

import SectionHeading from 'common/components/SectionHeading';

import AccountsByDay from 'accountsModule/models/AccountsByDay';
import AccountByDay from 'accountsModule/models/AccountByDay';
import TimeLineChart from 'common/components/TimeLineChart';

const HEADING = 'Account Status';
class TotalAccountsChart extends PureComponent {
  render() {
    const {
      accountStatusesData,
      activeAccountStatuses,
      totalStatuses,
      colorMap,
      disabledKeys,
      shouldRenderChart,
    } = this.props;

    const lineChartData = new Array(totalStatuses.length)
      .fill(null)
      .map((_, i) => ({
        id: totalStatuses[i],
        color: colorMap.get(totalStatuses[i]),
        data: [],
      }));

    const totalStatusesData = accountStatusesData.map((accountsByDay) => {
      const activeAndNewAcconts = accountsByDay.accounts.filter(
        accountByDay => activeAccountStatuses.includes(accountByDay.status),
      );
      return new AccountsByDay({
        date: accountsByDay.date,
        accounts: [
          ...accountsByDay.accounts,
          new AccountByDay({
            status: TOTAL_ACCOUNTS_FIELD_NAME,
            amount: activeAndNewAcconts.reduce(
              (total, accountByDay) => total + accountByDay.amount,
              0,
            ),
          }),
        ],
      });
    });

    totalStatusesData.forEach(accountsByDay => accountsByDay.accounts.forEach((accountByDay) => {
      const index = lineChartData.findIndex(
        a => a.id === accountByDay.status,
      );
      if (index >= 0) {
        lineChartData[index].data.push({
          x: accountsByDay.date,
          y: accountByDay.amount,
        });
      }
    }));

    return (
      shouldRenderChart && (
        <Fragment>
          <SectionHeading>{HEADING}</SectionHeading>
          <TimeLineChart
            data={lineChartData.filter(
              lineData => !disabledKeys.includes(lineData.id),
            )}
            colorMap={colorMap}
          />
        </Fragment>
      )
    );
  }
}

TotalAccountsChart.propTypes = {
  accountStatusesData: arrayOf(instanceOf(AccountsByDay)).isRequired,
  activeAccountStatuses: arrayOf(string).isRequired,
  totalStatuses: arrayOf(string).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
  shouldRenderChart: bool.isRequired,
};

export default TotalAccountsChart;
