import React from 'react';
import { Select, InputNumber, Input } from 'antd';
import { func, string } from 'prop-types';

const { Option } = Select;
const { Group } = Input;

/* eslint-disable react/destructuring-assignment */
class NumberRange extends React.PureComponent {
    static propTypes = {
      onChange: func.isRequired,
      value: string.isRequired,
    };

    getSign = () => this.props.value.slice(0, 1);

    getValue = () => Number(this.props.value.slice(1));

    onChangeSelect = (signValue) => {
      const { onChange } = this.props;
      onChange(`${signValue}${this.getValue()}`);
    };

    onChangeNumber = (value) => {
      const { onChange } = this.props;
      onChange(`${this.getSign()}${value}`);
    };

    render() {
      return (
        <Group compact>
          <Select
            value={this.getSign()}
            onChange={this.onChangeSelect}
            style={{ width: '65%' }}
          >
            <Option value="m">More Than</Option>
            <Option value="l">Less Than</Option>
            <Option value="e">Equal To</Option>
          </Select>
          <InputNumber
            min={0}
            value={this.getValue()}
            onChange={this.onChangeNumber}
            style={{ width: '35%' }}
          />
        </Group>
      );
    }
}

export default NumberRange;
