class Brand {
  constructor({ name, earliestDate }) {
    this.name = name;
    this.earliestDate = earliestDate;
  }

  static parse(obj) {
    return new Brand({
      name: obj.Name,
      earliestDate: obj.EarliestDate,
    });
  }
}

export default Brand;
