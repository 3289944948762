import React, { Fragment, PureComponent } from 'react';
import { arrayOf, instanceOf, bool } from 'prop-types';
import moment from 'moment';
import { Divider } from 'antd';
import window from 'global';

import {
  WEEK_NUMBER_FORMAT,
  DATE_FORMAT,
  DAY_FORMAT,
} from 'common/constants/dateConstants';

import BarChart from 'common/components/BarChart';
import SectionHeading from 'common/components/SectionHeading';

import { colorGenerator } from 'common/utilities/colorGenerator';

import AddedFacings from 'merchandisingModule/models/AddedFacings';

const HEADING = 'Facings Added';

class AddedFacingsChart extends PureComponent {
  static propTypes = {
    addedFacingsData: arrayOf(instanceOf(AddedFacings)).isRequired,
    shouldRenderChart: bool.isRequired,
  };

  state = {
    maxTicks: 0,
  }

  componentDidMount() {
    this.updateMaxTicks();
    window.addEventListener('resize', this.updateMaxTicks);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMaxTicks);
  }

  updateMaxTicks = () => {
    if (window.innerWidth > 768) {
      return this.setState({
        maxTicks: 30,
      });
    }

    if (window.innerWidth <= 768 && window.innerWidth > 640) {
      return this.setState({
        maxTicks: 20,
      });
    }

    if (window.innerWidth <= 640 && window.innerWidth > 480) {
      return this.setState({
        maxTicks: 12,
      });
    }

    return this.setState({
      maxTicks: 5,
    });
  }

  render() {
    const { addedFacingsData, shouldRenderChart } = this.props;
    const { maxTicks } = this.state;

    const addedFacingsKeys = AddedFacings.getKeys();

    const addedFacingsColorMap = colorGenerator(addedFacingsKeys);

    let bottomTicksFormatter = tick => moment(tick).format(DATE_FORMAT);

    const allSundayTicks = addedFacingsData.filter(
      tickData => moment(tickData.date).format(DAY_FORMAT) === 'Sunday',
    );

    const numberOfSundayTicksInterval = Math.ceil(
      allSundayTicks.length / maxTicks,
    );

    const allTicks = allSundayTicks
      .filter((_, i) => i % numberOfSundayTicksInterval === 0)
      .map(tickData => tickData.date);

    bottomTicksFormatter = (tick) => {
      if (allTicks.includes(tick)) {
        return moment(tick).format(DATE_FORMAT);
      }

      return '';
    };

    const tooltipDataFormatter = date => `Week ${moment(date)
      .locale('en-us')
      .format(WEEK_NUMBER_FORMAT)} (${moment(date).format(DATE_FORMAT)})`;

    const transformDataForTooltip = item => ({
      title: item.id,
      color: item.color,
      index: tooltipDataFormatter(item.indexValue),
      value: item.value,
    });

    return (
      shouldRenderChart && (
        <Fragment>
          <SectionHeading>{HEADING}</SectionHeading>
          <BarChart
            data={addedFacingsData}
            keys={addedFacingsKeys}
            indexes="date"
            colorMap={addedFacingsColorMap}
            bottomTicksFormatter={bottomTicksFormatter}
            transformDataForTooltip={transformDataForTooltip}
          />
          <Divider />
        </Fragment>
      )
    );
  }
}

export default AddedFacingsChart;
