import React, { PureComponent } from 'react';
import {
  bool, string, func, instanceOf, arrayOf,
} from 'prop-types';
import Dropzone from 'react-dropzone';
import { Empty, Icon } from 'antd';
import classnames from 'classnames';
import uuidv4 from 'uuid/v4';

import { showError } from 'common/helpers/notificationManager';

import './index.scss';

class DropzoneComponent extends PureComponent {
    static propTypes = {
      accept: string,
      files: arrayOf(instanceOf(File)),
      onAddFiles: func.isRequired,
      onDeleteFile: func.isRequired,
      isUploading: bool.isRequired,
    };

    static defaultProps = {
      accept: '.xlsx',
      files: [],
    };

    onDrop = (droppedFiles) => {
      const { onAddFiles, files } = this.props;
      const allFiles = files.concat(droppedFiles);
      onAddFiles(allFiles);
    };

    onDropRejected = () => {
      showError({
        message: 'Wrong file format',
        description: 'The file must be .xsls',
      });
    };

    onDelete = (filename) => {
      const { onDeleteFile } = this.props;
      onDeleteFile(filename);
    };

    render() {
      const {
        accept, files, isUploading,
      } = this.props;

      return (
        <React.Fragment>
          <Dropzone
            onDrop={this.onDrop}
            accept={accept}
            onDropRejected={this.onDropRejected}
            disabled={isUploading}
            multiple
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={classnames('dropzone', {
                  'dropzone_with-file': !!files.length,
                  'dropzone--disabled': isUploading,
                })}
              >
                <div className="dropzone__wrapper">
                  <input {...getInputProps()} />
                  <div className="dropzone__icon">
                    <Empty description={(
                      <span className="dropzone__title">
                      Click or drag file to this area to upload
                      </span>
                    )}
                    />
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
          {files.map(file => (
            <div className="dropzone-file" key={uuidv4()}>
              <div className="dropzone-file__container">
                <div className="dropzone-file__name" title={file.name}>
                  {file.name}
                </div>
                {isUploading
                  ? (
                    <div className="dropzone-file__icon">
                      <Icon type="loading" />
                    </div>
                  )
                  : (
                    <div className="dropzone-file__icon dropzone-file__icon_delete">
                      <Icon
                        theme="twoTone"
                        type="close-circle"
                        twoToneColor="#ff1c1c"
                        onClick={() => this.onDelete(file.name)}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          ))}
        </React.Fragment>
      );
    }
}

export default DropzoneComponent;
