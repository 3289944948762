/* eslint-disable react/prefer-stateless-function, react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { instanceOf, func } from 'prop-types';
import {
  Input, Button, Icon, Form, Select, Switch,
} from 'antd';

import FilterOptions from 'adminModule/models/FilterOptions';

import './index.scss';

const { Option } = Select;

const FormItem = Form.Item;

const iconStyle = { color: 'rgba(0,0,0,.25)' };

const ownerRoleValues = [
  {
    value: 1,
    label: 'Admin',
  },
  {
    value: 0,
    label: 'User',
  },
];

const adminRoleValues = [
  {
    value: 0,
    label: 'User',
  },
];

const CommonOption = ({ key, value, label }) => (
  <Option key={key} value={value}>
    {label}
  </Option>
);


const formItemLayout = {
  colon: false,
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 18 },
  },
};

const submitButtonLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 8 },
    md: { span: 18, offset: 6 },
  },
};

class RegistrationForm extends PureComponent {
  static propTypes = {
    filterOptions: instanceOf(FilterOptions).isRequired,
    onRegister: func.isRequired,
  };

  state = {
    confirmDirty: false,
    isAllBrandsSelected: false,
  };

  compareToFirstPassword = (_, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback("The passwords don't match");
    } else {
      callback();
    }
  };

  validateToNextPassword = (_, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  onSwitchToAllBrands = (isChecked) => {
    const {
      form: { validateFields },
    } = this.props;
    this.setState(
      {
        isAllBrandsSelected: isChecked,
      },
      () => {
        validateFields(['brands'], { force: true });
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();

    const {
      form: { validateFields, resetFields },
      onRegister,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        onRegister({
          username: values.username,
          password: values.password,
          allBrands: values.allBrands,
          brands: values.brands,
          brandFullName: values.brandFullName,
          isAdmin: Boolean(values.isAdmin),
        });
        resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { filterOptions, isSuperAdmin, isAdmin } = this.props;

    const { isAllBrandsSelected } = this.state;

    return (
      <Form onSubmit={this.submitForm}>
        <FormItem {...formItemLayout} label="Username">
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: 'Username is required!',
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={iconStyle} />}
              placeholder="Please input a username"
            />,
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Password">
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Password is required!',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={iconStyle} />}
              type="password"
              placeholder="Please input a password"
            />,
          )}
        </FormItem>
        <Form.Item {...formItemLayout} label="Confirm Password">
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={iconStyle} />}
              type="password"
              placeholder="Please confirm your password"
              onBlur={this.handleConfirmBlur}
            />,
          )}
        </Form.Item>
        {isSuperAdmin && (
          <FormItem {...formItemLayout} label="Role">
            {getFieldDecorator('isAdmin', {
              initialValue: 1,
            })(
              <Select>
                {ownerRoleValues.map(role => CommonOption({
                  key: role.label,
                  value: role.value,
                  label: role.label,
                }))}
              </Select>,
            )}
          </FormItem>
        )}
        {isAdmin
          && !isSuperAdmin && (
          <FormItem {...formItemLayout} label="Role">
            {getFieldDecorator('isAdmin', {
              initialValue: 0,
            })(
              <Select>
                {adminRoleValues.map(role => CommonOption({
                  key: role.label,
                  value: role.value,
                  label: role.label,
                }))}
              </Select>,
            )}
          </FormItem>
        )}
        <FormItem {...formItemLayout} label="Company">
          {getFieldDecorator('brandFullName', {
            rules: [
              {
                required: true,
                message: 'Company name is required!',
              },
            ],
          })(
            <Input
              prefix={<Icon type="team" style={iconStyle} />}
              placeholder="Please input a company name"
            />,
          )}
        </FormItem>
        <Form.Item {...formItemLayout} label="All Brands">
          {getFieldDecorator('allBrands', {
            valuePropName: 'checked',
            initialValue: isAllBrandsSelected,
          })(<Switch onChange={this.onSwitchToAllBrands} />)}
        </Form.Item>
        <FormItem {...formItemLayout} label="Brands">
          {filterOptions.brands
            && getFieldDecorator('brands', {
              rules: [
                {
                  required: !isAllBrandsSelected,
                  message: 'Brands are required!',
                  type: 'array',
                },
              ],
            })(
              <Select
                placeholder="Please select brands"
                mode="multiple"
                disabled={isAllBrandsSelected}
              >
                {filterOptions.brands.map(brand => CommonOption({
                  key: brand,
                  value: brand,
                  label: brand,
                }))}
              </Select>,
            )}
        </FormItem>
        <FormItem {...submitButtonLayout}>
          <Button
            className="registration-form__register-button"
            icon="user-add"
            type="primary"
            htmlType="submit"
          >
            Create user
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(RegistrationForm);

export default WrappedForm;
