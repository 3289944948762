import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import {
  string, number, instanceOf, arrayOf, shape,
} from 'prop-types';
import moment from 'moment';

import noPictureImage from 'common/images/no-picture.png';
import { DATE_FORMAT } from 'common/constants/dateConstants';

import './index.scss';

class AccountPopupInfo extends PureComponent {
  state = {
    visible: false,
    modalPictureUrl: null,
  };

  changeVisible = value => this.setState({ visible: value });

  changeModalPictureUrl = url => this.setState({ modalPictureUrl: url });

  setPictureVisible = (index) => {
    const { picturesList } = this.props;

    this.changeVisible(true);
    this.changeModalPictureUrl(picturesList[index].pictureUrl);
  };

  setStorePictureVisible = () => {
    const { storePictureUrl } = this.props;

    this.changeVisible(true);
    this.changeModalPictureUrl(storePictureUrl);
  };

  onClickPicture = index => () => this.setPictureVisible(index);

  closeModal = () => this.changeVisible(false);

  onKeyPress = index => (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      this.setPictureVisible(index);
    }
  };

  onKeyPressStore = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      this.setStorePictureVisible();
    }
  };

  render() {
    const {
      name,
      address,
      storePictureUrl,
      totalSales,
      totalOrders,
      totalCases,
      lastOrderDate,
      authorisedDate,
      visits,
      status,
      representative,
      nextScheduledDate,
    } = this.props;

    const { visible, modalPictureUrl } = this.state;

    const formattedLastOrderDate = lastOrderDate.format(DATE_FORMAT);
    const formattedAuthorisedDate = authorisedDate.format(DATE_FORMAT);
    const formattedNextScheduledDate = nextScheduledDate.format(
      DATE_FORMAT,
    );

    return (
      <div className="sales-popup-info">
        <article className="sales-popup-info__description">
          <h1 className="sales-popup-info__description-heading">
            Account Info
          </h1>
          <div className="sales-popup-info__description-container">
            <section>
              <div
                onKeyPress={
                  storePictureUrl && this.onKeyPressStore
                }
                onClick={
                  storePictureUrl
                  && this.setStorePictureVisible
                }
                role="button"
                tabIndex="0"
              >
                <img
                  className="sales-popup-info__front-picture"
                  height="75"
                  width="75"
                  src={storePictureUrl || noPictureImage}
                  alt="Account front"
                />
              </div>
            </section>
            <section className="sales-popup-info__info-container">
              <p className="sales-popup-info__info-item">
                <span className="sales-popup-info__info-label">
                  Account Name:
                  {' '}
                </span>
                {name}
              </p>
              <p className="sales-popup-info__info-item">
                <span className="sales-popup-info__info-label">
                  Address:
                  {' '}
                </span>
                {address}
              </p>
            </section>
          </div>
        </article>
        <article className="sales-popup-info__description">
          <h1 className="sales-popup-info__description-heading">
            Key Stats
          </h1>
          <section>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Authorized Date:
                {' '}
              </span>
              {formattedAuthorisedDate}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Current Status:
                {' '}
              </span>
              {status}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Last Order Date:
                {' '}
              </span>
              {formattedLastOrderDate}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Next Scheduled Visit:
                {' '}
              </span>
              {formattedNextScheduledDate}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Rep:
                {' '}
              </span>
              {representative}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Merchandising Visits:
                {' '}
              </span>
              {visits}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Orders:
                {' '}
              </span>
              {totalOrders}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Sales:
                {' '}
              </span>
              $
              {totalSales}
            </p>
            <p className="sales-popup-info__info-item">
              <span className="sales-popup-info__info-label">
                Cases:
                {' '}
              </span>
              {totalCases}
            </p>
          </section>
        </article>

        <Modal
          width={700}
          visible={Boolean(visible)}
          onCancel={this.closeModal}
          centered
          footer={null}
        >
          <div className="sales-popup-info__modal-content">
            <div className="sales-popup-info__modal-picture-container">
              <img
                width="600"
                src={modalPictureUrl}
                alt="Account front"
                className="sales-popup-info__modal-picture"
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

AccountPopupInfo.propTypes = {
  name: string.isRequired,
  address: string.isRequired,
  storePictureUrl: string.isRequired,
  totalSales: number.isRequired,
  totalOrders: string.isRequired,
  totalCases: number.isRequired,
  lastOrderDate: instanceOf(moment).isRequired,
  authorisedDate: instanceOf(moment).isRequired,
  visits: number.isRequired,
  status: string.isRequired,
  representative: string.isRequired,
  nextScheduledDate: instanceOf(moment).isRequired,
  picturesList: arrayOf(
    shape({
      pictureUrl: string,
    }),
  ),
};

AccountPopupInfo.defaultProps = {
  picturesList: [],
};

export default AccountPopupInfo;
