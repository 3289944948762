import { connect } from 'react-redux';

import { permissionsSelector, filterPermissionsSelector } from 'accountsModule/selectors/accountsPageSelectors';
import AccountsPage from '../components/AccountsPage';

export default connect(
  state => ({
    accountsFilter: state.accounts.filter,
    accountsFilterInitialized: state.accounts.initialized,
    loading: state.loading.models.accounts,
    filterOptions: state.accounts.filterOptions,
    fetchingFilterOptions:
      state.loading.effects.accounts.fetchFilterOptions,
    userInfo: state.session.userInfo,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    setAccountFilters: dispatch.accounts.setFilter,
    fetchAccounts: dispatch.accounts.fetchAccounts,
    fetchAccountsStatuses: dispatch.accounts.fetchAccountsStatuses,
    fetchAccountsStatusesByChannel:
      dispatch.accounts.fetchAccountsStatusesByChannel,
    fetchFilterOptions: dispatch.accounts.fetchFilterOptions,
    fetchAccountsByDistributorOnDate:
      dispatch.accounts.fetchAccountsByDistributorOnDate,
    fetchAuthAccountsStatusesByChannel: dispatch.accounts.fetchAuthAccountsStatusesByChannel,
    resetAccountsStore: dispatch.accounts.clear,
  }),
)(AccountsPage);
