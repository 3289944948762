/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const tagsInstalledDataSelector = state => state.merchandising.tagsInstalledData;

export const currentTagsInstalledNamesSelector = createSelector(
  tagsInstalledDataSelector,
  tagsInstalledData => tagsInstalledData.map(tagInstalled => tagInstalled.value),
);

export const colorMapSelector = createSelector(
  currentTagsInstalledNamesSelector,
  currentTagsInstalledNames => colorGenerator(currentTagsInstalledNames),
);
