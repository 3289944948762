/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const accountsByChannelTypeSelector = state => state.accounts.accountsByChannelType;
const authAccountsByChannelTypeSelector = state => state.accounts.authAccountsByChannelType;

export const currentChannelTypesSelector = createSelector(
  accountsByChannelTypeSelector,
  accountsByChannelType => accountsByChannelType.map(
    accountByChannelType => accountByChannelType.channel,
  ),
);

export const colorMapSelector = createSelector(
  currentChannelTypesSelector,
  currentChannelTypes => colorGenerator(currentChannelTypes),
);

export const shouldRenderAccountsByChannelTypeChartSelector = createSelector(
  [accountsByChannelTypeSelector, currentChannelTypesSelector],
  (accountByChannelType, currentChannelTypes) => currentChannelTypes
  && currentChannelTypes.length > 0
  && accountByChannelType
  && accountByChannelType.length > 0,
);

export const shouldRenderAuthAccountsByChannelTypeChartSelector = createSelector(
  [authAccountsByChannelTypeSelector, currentChannelTypesSelector],
  (authAccountsByChannelType, currentChannelTypes) => currentChannelTypes
  && currentChannelTypes.length > 0
  && authAccountsByChannelType
  && authAccountsByChannelType.length > 0,
);
