/* eslint-disable no-return-assign, no-param-reassign, no-nested-ternary, react/display-name */

import React, { PureComponent, Fragment } from 'react';
import {
  arrayOf, instanceOf, func, bool, string,
} from 'prop-types';
import {
  Table, Icon, Divider, Button, Modal, Skeleton,
} from 'antd';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';

import SectionHeading from 'common/components/SectionHeading';

import RegistrationForm from 'adminModule/components/RegistrationForm';
import EditForm from 'adminModule/components/EditForm';
import UserLoginInfoModal from 'adminModule/components/UserLoginInfoModal';
import PermissionsForm from 'adminModule/components/PermissionsForm';
import AutoreportForm from 'adminModule/components/AutoreportForm';

import User from 'adminModule/models/User';
import FilterOptions from 'adminModule/models/FilterOptions';

import { adminPagePermissionTypes } from 'userModule/constants/permissions';
import './index.scss';
import DeleteUserForm from 'adminModule/components/DeleteUserForm';

const HEADING = 'Users';

const activeIcon = (
  <Icon
    type="check-circle"
    theme="twoTone"
    twoToneColor="#52c41a"
  />
);

const nonActiveIcon = (
  <Icon
    type="stop"
    theme="twoTone"
    twoToneColor="#ff1c1c"
  />
);

const columns = [
  {
    title: 'Login',
    dataIndex: 'login',
    align: 'center',
    key: 'login',
    sorter: (a, b) => a.login.localeCompare(b.login),
    defaultSortOrder: 'descend',
    render: text => <div className="users-list__cell_name" title={text}>{text}</div>,
    className: 'users-list__cell users-list__cell_fixed',
  },
  {
    title: 'Role',
    dataIndex: 'isAdmin',
    align: 'center',
    render: (_, { isAdmin }) => (isAdmin ? 'Admin' : 'User'),
    key: 'isAdmin',
    sorter: (a, b) => a.isAdmin - b.isAdmin,
    className: 'users-list__cell',
  },
  {
    title: 'Active',
    dataIndex: 'isActive',
    align: 'center',
    render: (_, { isActive }) => (isActive ? activeIcon : nonActiveIcon),
    key: 'isActive',
    className: 'users-list__cell',
  },
  {
    title: 'Password',
    dataIndex: 'password',
    align: 'center',
    key: 'password',
    className: 'users-list__cell',
  },
  {
    title: 'Company',
    dataIndex: 'brandFullName',
    align: 'center',
    key: 'brandFullName',
    sorter: (a, b) => a.brandFullName.localeCompare(b.brandFullName),
    className: 'users-list__cell',
  },
  {
    title: 'Brands',
    dataIndex: 'brands',
    align: 'center',
    render: (brands, user) => (user.isAllBrandsAvaliable ? 'All Brands' : brands.sort().join(', ')),
    key: 'brands',
    className: 'users-list__cell',
  },
];

const emptyFunction = () => { };

const getNewUser = () => new User({
  login: null,
  brands: null,
  password: null,
  brandFullName: null,
  isAllBrandsAvaliable: null,
  isAdmin: null,
  loginInfo: null,
  permissionsInfo: {},
  autoReportInfo: {},
});

class UsersList extends PureComponent {
  static propTypes = {
    users: arrayOf(instanceOf(User)).isRequired,
    registerUser: func.isRequired,
    editUser: func.isRequired,
    changePermissions: func.isRequired,
    changeReportSchedule: func.isRequired,
    getUsers: func.isRequired,
    filterOptions: instanceOf(FilterOptions).isRequired,
    deleteUser: func.isRequired,
    isUsersListFetching: bool.isRequired,
    childrenPermissions: arrayOf(string).isRequired,
    testScheduledReport: func.isRequired,
    isScheduledReportDownloading: bool.isRequired,
  };

  state = {
    isRegistrationFormVisible: false,
    isEditFormVisible: false,
    isLoginInfoModalVisible: false,
    isPermissionsModalVisible: false,
    isAutoreportsModalVisible: false,
    isDeleteUserModalVisible: false,

    selectedUser: null,
    selectedLoginInfoUser: getNewUser(),
    selectedPermissionsUser: getNewUser(),
    selectedAutoreportsUser: getNewUser(),
    selectedDeleteUser: getNewUser(),

    additionalTableProps: {},
    iconSize: '16px',
  };

  componentDidMount() {
    const { getUsers } = this.props;
    getUsers();
    this.updateTableWidth();
    window.addEventListener('resize', this.updateTableWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTableWidth);
  }

  updateTableWidth = () => {
    if (window.innerWidth > 992) {
      this.setState({
        additionalTableProps: {},
        iconSize: '16px',
      });
    }
    if (window.innerWidth <= 992 && window.innerWidth > 640) {
      this.setState({
        additionalTableProps: {
          scroll: {
            x: 1100,
          },
        },
        iconSize: '14px',
      });
    }
    if (window.innerWidth <= 640) {
      this.setState({
        additionalTableProps: {
          scroll: {
            x: 1000,
          },
        },
      });
    }
  }

  showRegistrationForm = () => {
    this.setState({
      isRegistrationFormVisible: true,
    });
  };

  showEditForm = (user) => {
    this.setState({
      selectedUser: user,
      isEditFormVisible: true,
    });
  };

  onRegister = (values) => {
    const { registerUser } = this.props;
    this.closeModal('isRegistrationFormVisible');

    registerUser(values);
  };

  onEdit = (values) => {
    const { editUser } = this.props;
    this.closeModal('isEditFormVisible');

    editUser(values);
  };

  onChangePermissions = (values) => {
    const { changePermissions } = this.props;
    this.closeModal('isPermissionsModalVisible');

    changePermissions(values);
  };

  onChangeReportSchedule = (values) => {
    const { selectedAutoreportsUser } = this.state;
    const { changeReportSchedule } = this.props;
    this.closeModal('isAutoreportsModalVisible');

    changeReportSchedule({ user: selectedAutoreportsUser, params: values });
  }

  onDeleteUser = (values) => {
    const { deleteUser } = this.props;
    this.closeModal('isDeleteUserModalVisible');

    deleteUser(values);
  };

  testScheduledReport = (values) => {
    const { selectedAutoreportsUser } = this.state;
    const { testScheduledReport } = this.props;

    testScheduledReport({ user: selectedAutoreportsUser, params: values });
  }

  showLoginInfoModal = (user) => {
    this.setState({
      selectedLoginInfoUser: user,
      isLoginInfoModalVisible: true,
    });
  };

  showPermissionsModal = (user) => {
    this.setState({
      selectedPermissionsUser: user,
      isPermissionsModalVisible: true,
    });
  };

  showDeleteModal = (user) => {
    this.setState({
      selectedDeleteUser: user,
      isDeleteUserModalVisible: true,
    });
  };

  showAutoreportsModal = (user) => {
    this.setState({
      selectedAutoreportsUser: user,
      isAutoreportsModalVisible: true,
    });
  }

  closeModal = (isModalOpen) => {
    this.setState({
      [isModalOpen]: false,
    });
  }

  getColumn = (title, iconType, shouldRenderIcon, onIconClick) => {
    const { iconSize } = this.state;

    return {
      title,
      className: 'users-list__cell users-list__cell_action',
      align: 'center',
      key: uuidv4(),
      width: 80,
      render: user => (
        <Icon
          className={classNames('users-list__icon', {
            'users-list__icon_disabled': !shouldRenderIcon,
          })}
          style={{ fontSize: iconSize }}
          type={iconType}
          onClick={
            shouldRenderIcon
              ? () => onIconClick(user)
              : emptyFunction
          }
        />
      ),
    };
  };

  render() {
    const {
      users,
      filterOptions,
      isUsersListFetching,
      childrenPermissions,
      isScheduledReportDownloading,
    } = this.props;

    const {
      isRegistrationFormVisible,
      isEditFormVisible,
      isLoginInfoModalVisible,
      selectedUser,
      selectedLoginInfoUser,
      isPermissionsModalVisible,
      selectedPermissionsUser,
      isAutoreportsModalVisible,
      selectedAutoreportsUser,
      isDeleteUserModalVisible,
      selectedDeleteUser,
      additionalTableProps,
    } = this.state;

    const shouldRenderAutoreportIcon = childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_USERS_AUTOREPORT);
    const shouldRenderInfoIcon = childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_USERS_INFO);
    const shouldRenderPermissionsIcon = childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_USERS_PERMISSIONS);
    const shouldRenderEditIcon = childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_USERS_EDIT);
    const shouldRenderDeleteIcon = childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_USERS_DELETE);

    const tableColumns = [
      ...columns,
      this.getColumn('Login History', 'info-circle', shouldRenderInfoIcon, this.showLoginInfoModal),
      this.getColumn('Permissions', 'security-scan', shouldRenderPermissionsIcon, this.showPermissionsModal),
      this.getColumn('Email Schedule', 'mail', shouldRenderAutoreportIcon, this.showAutoreportsModal),
      this.getColumn('Edit User', 'form', shouldRenderEditIcon, this.showEditForm),
      this.getColumn('Delete User', 'delete', shouldRenderDeleteIcon, this.showDeleteModal),
    ];

    const shouldRenderUsersList = users.length > 0;
    const checkedPermissions = Object.keys(selectedPermissionsUser.permissionsInfo)
      .reduce((acc, pageName) => [
        ...acc,
        ...(selectedPermissionsUser.permissionsInfo[pageName].Permissions || []),
        ...(selectedPermissionsUser.permissionsInfo[pageName].Filters || []),
      ], []);

    return (
      <Skeleton loading={isUsersListFetching} paragraph={{ rows: 4 }}>
        {shouldRenderUsersList && (
          <Fragment>
            <SectionHeading>{HEADING}</SectionHeading>
            <div className="users-list">
              {childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_USERS_CREATE) && (
                <div className="users-list__button-wrapper">
                  <Button
                    type="primary"
                    onClick={this.showRegistrationForm}
                  >
                    <Icon type="user-add" />
                    {' '}
                  Create User
                  </Button>
                </div>
              )}
              <Modal
                title="Registration"
                visible={isRegistrationFormVisible}
                onCancel={() => this.closeModal('isRegistrationFormVisible')}
                destroyOnClose
                centered
                width={570}
                footer={null}
              >
                <RegistrationForm
                  onRegister={this.onRegister}
                  filterOptions={filterOptions}
                />
              </Modal>
              <Modal
                title="Edit"
                visible={isEditFormVisible}
                onCancel={() => this.closeModal('isEditFormVisible')}
                destroyOnClose
                centered
                width={570}
                footer={null}
              >
                <EditForm
                  onEdit={this.onEdit}
                  currentUser={selectedUser}
                  filterOptions={filterOptions}
                />
              </Modal>
              <Modal
                title="Login Info"
                visible={isLoginInfoModalVisible}
                onCancel={() => this.closeModal('isLoginInfoModalVisible')}
                destroyOnClose
                centered
                width={570}
                footer={null}
              >
                <UserLoginInfoModal
                  user={selectedLoginInfoUser}
                />
              </Modal>
              <Modal
                title="Permissions"
                visible={isPermissionsModalVisible}
                onCancel={() => this.closeModal('isPermissionsModalVisible')}
                destroyOnClose
                centered
                width={570}
                footer={null}
              >
                <PermissionsForm
                  onChangePermissions={this.onChangePermissions}
                  user={selectedPermissionsUser}
                  permissions={checkedPermissions}
                />
              </Modal>
              <Modal
                title="Email Schedule"
                visible={isAutoreportsModalVisible}
                onCancel={() => this.closeModal('isAutoreportsModalVisible')}
                destroyOnClose
                centered
                width={600}
                footer={null}
              >
                <AutoreportForm
                  onChangeReportSchedule={this.onChangeReportSchedule}
                  testScheduledReport={this.testScheduledReport}
                  user={selectedAutoreportsUser}
                  filterOptions={filterOptions}
                  isScheduledReportDownloading={isScheduledReportDownloading}
                />
              </Modal>
              <Modal
                title="Confirm Delete"
                visible={isDeleteUserModalVisible}
                onCancel={() => this.closeModal('isDeleteUserModalVisible')}
                destroyOnClose
                centered
                width={570}
                footer={null}
              >
                <DeleteUserForm
                  onDeleteUser={this.onDeleteUser}
                  user={selectedDeleteUser}
                />
              </Modal>
              <Table
                dataSource={users}
                columns={tableColumns}
                pagination={false}
                rowKey={user => user.login}
                bordered
                {...additionalTableProps}
              />
              <Divider />
            </div>
          </Fragment>
        )}
      </Skeleton>
    );
  }
}

export default UsersList;
