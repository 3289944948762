import React from 'react';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';

import { ACCOUNTS } from 'common/constants/routes';

import logoImage from './images/cmg-logo.png';

import './index.scss';

const Logo = ({ link }) => (
  <div className="logo">
    <Link to={link}>
      <img className="logo__image" src={logoImage} alt="CMG Logo" />
    </Link>
    <span className="logo__name">Critical Mass Group</span>
  </div>
);

Logo.propTypes = {
  link: string,
};

Logo.defaultProps = {
  link: ACCOUNTS,
};

export default Logo;
