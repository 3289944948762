import React from 'react';
import { ResponsiveLineCanvas } from '@nivo/line';
import {
  arrayOf, any, string, instanceOf, func,
} from 'prop-types';

import LineChartTooltip from './LineChartTooltip';

import './index.scss';

const transformDataForTooltip = (data, item) => ({
  tick: item.point.data.x,
  data: data.map(dataProp => ({
    color: dataProp.color,
    title: dataProp.id,
    value: dataProp.data[parseInt(item.point.id.match(/\d+/)[0], 10)].y,
  })),
});

const LineChart = ({
  data, height, colorMap, getTick,
}) => {
  const colorBy = ({ id }) => colorMap.get(id);
  const maxY = data.reduce((acc, line) => {
    const lineMax = line.data.reduce(
      (lineAcc, linePoint) => (linePoint.y > lineAcc ? linePoint.y : lineAcc),
      0,
    );
    if (lineMax > acc) {
      return lineMax;
    }

    return acc;
  }, 0);
  const minY = data.reduce((acc, line) => {
    const lineMin = line.data.reduce(
      (lineAcc, linePoint) => (linePoint.y < lineAcc ? linePoint.y : lineAcc),
      1000,
    );
    if (lineMin < acc) {
      return lineMin;
    }

    return acc;
  }, 1000);
  const radius = Math.ceil((maxY - minY) * 0.1);
  return (
    <div style={{ height }} className="line-chart">
      <ResponsiveLineCanvas
        data={data}
        colors={colorBy}
        margin={{
          top: 10,
          right: 20,
          bottom: 70,
          left: 50,
        }}
        xScale={{
          type: 'point',
        }}
        yScale={{
          type: 'linear',
          min: minY - radius,
          max: maxY + radius,
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          format: getTick,
        }}
        tooltip={item => (
          <LineChartTooltip {...transformDataForTooltip(data, item)} />
        )}
        enableSlices="y"
        minY="auto"
        maxY="auto"
        pointSize={0}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

LineChart.propTypes = {
  data: arrayOf(any).isRequired,
  height: string,
  colorMap: instanceOf(Map).isRequired,
  getTick: func,
};

LineChart.defaultProps = {
  getTick: tick => tick,
  height: '600px',
};

export default LineChart;
