import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import AverageFacingsChart from 'merchandisingModule/components/AverageFacingsChart';

import MerchandisingStatus from 'merchandisingModule/models/MerchandisingStatus';

import { shouldRenderAverageFacingsChart } from 'merchandisingModule/selectors/averageFacingsChartSelectors';
import { colorGenerator } from 'common/utilities/colorGenerator';

const averageFacingsTitles = [
  ...MerchandisingStatus.labelsMap.values(),
];

const colorMap = colorGenerator(averageFacingsTitles);

export default connect(
  state => ({
    averageFacingsData: state.merchandising.averageFacingsData,
    averageFacingsTitles,
    colorMap,
    shouldRenderChart: shouldRenderAverageFacingsChart(state),
  }),
)(withLegend({
  keys: 'averageFacingsTitles',
  shouldRenderLegend: 'shouldRenderChart',
  withDivider: 'shouldRenderChart',
})(AverageFacingsChart));
