import { connect } from 'react-redux';

import MasterAccountsListSection from 'databaseModule/components/MasterAccountsListSection';

export default connect(
  state => ({
    isDownloading: state.loading.effects.database.downloadMasterList,
    isFilterOptionsFetching: state.loading.effects.database.fetchFilterOptions,
  }),
  dispatch => ({
    onDownload: () => dispatch.database.downloadMasterList(),
  }),
)(MasterAccountsListSection);
