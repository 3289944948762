import React, { Fragment } from 'react';
import { arrayOf, instanceOf, string } from 'prop-types';
import { Marker, Popup } from 'react-leaflet';
import moment from 'moment';
import L from 'leaflet';

import LeafletMap from 'common/components/LeafletMap';
import darkBlueMarker from 'common/images/location-pointer-dark-blue.png';
import DownloadAccountsButton from 'salesModule/containers/DownloadAccountsButtonContainer';

import Account from 'salesModule/models/Account';
import { salesPagePermissionTypes } from 'userModule/constants/permissions';
import AccountPopupInfo from './AccountPopupInfo';


import './index.scss';

const SalesMap = ({ accounts, childrenPermissions }) => {
  const shouldRenderMap = accounts.length > 0;
  return shouldRenderMap && (
    <Fragment>
      <h2 className="sales-map__heading">
        <span>Sales Map</span>
        {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_MAP_REPORT) && <DownloadAccountsButton /> }
      </h2>
      <div className="sales-map">
        <LeafletMap>
          {accounts.map(account => (
            <Marker
              icon={L.icon({
                iconUrl: darkBlueMarker,
                iconSize: [36, 36],
                iconAnchor: [18, 36],
                popupAnchor: [0, -25],
              })}
              key={account.id}
              position={account.getCoordinates()}
            >
              <Popup>
                <AccountPopupInfo
                  name={account.name}
                  address={account.address}
                  storePictureUrl={account.storePictureUrl}
                  totalSales={account.totalSales}
                  totalOrders={account.totalOrders}
                  totalCases={account.totalCases}
                  lastOrderDate={moment(account.lastOrderDate)}
                  authorisedDate={moment(account.authorisedDate)}
                  visits={account.visits}
                  status={account.status}
                  representative={account.representative}
                  nextScheduledDate={moment(
                    account.nextScheduledDate,
                  )}
                />
              </Popup>
            </Marker>
          ))}
        </LeafletMap>
      </div>
    </Fragment>
  );
};

SalesMap.propTypes = {
  accounts: arrayOf(instanceOf(Account)).isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default SalesMap;
