class UserLoginInfo {
  constructor({ lastLoginDate, IPAddress, lastLoginLocation }) {
    this.lastLoginDate = lastLoginDate;
    this.IPAddress = IPAddress;
    this.lastLoginLocation = lastLoginLocation;
  }

  static parse(obj) {
    return new UserLoginInfo({
      lastLoginDate: obj.LoginTime,
      IPAddress: obj.IPAddress,
      lastLoginLocation: obj.Location,
    });
  }
}

export default UserLoginInfo;
