/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import { func, instanceOf, bool } from 'prop-types';
import uuidv4 from 'uuid/v4';
import {
  InputNumber, Button, Select, Radio, DatePicker, Form, Tree, Switch, Checkbox,
} from 'antd';
import moment from 'moment';

import User from 'adminModule/models/User';
import FilterOptions from 'adminModule/models/FilterOptions';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import {
  DAY_PERIOD,
  PERIODS_MAP,
  ENDS_NEVER,
  ENDS_ON_DATE,
  ENDS_AFTER,
  REPORT_METRICS_TREE_DATA,
} from './constants';

import './index.scss';

const { TreeNode } = Tree;

const { Option } = Select;
const { Group: RadioGroup } = Radio;

const FormItem = Form.Item;

const CommonOption = (title, id = null) => (
  <Select.Option key={id || title} value={id || title}>
    {title}
  </Select.Option>
);

const defaultState = {
  selectedRepeatPeriod: DAY_PERIOD,
  selectedEndValue: ENDS_NEVER,

  expandedMarketKeys: [],
  autoExpandParentMarketMetrics: true,
  checkedMarketKeys: [],

  expandedFilteredKeys: [],
  autoExpandParentFilteredMetrics: true,
  checkedFilteredKeys: [],

  selectedBrand: [],
  selectedChains: [],
  selectedBanners: [],
  selectedChannels: [],
  selectedRegions: [],
  selectedCounties: [],
  selectedAreas: [],
  selectedAccountReps: [],
  selectedEntireMarketplace: false,

  amountOfDays: 1,
  startsOnDate: moment(),
  to: [],
  cc: [],
  repeatDays: 0,
  endsOnDate: null,
  endsAfterNumber: 0,
  numberOfPeriods: 0,

  marketplaceBeforePhoto: false,
  marketplaceAfterPhoto: false,
  marketplaceSecondaryPhoto: false,
  marketplaceSecondaryPhotoTypes: [],
  filteredBeforePhoto: false,
  filteredAfterPhoto: false,
  filteredSecondaryPhoto: false,
  filteredSecondaryPhotoTypes: [],

};

class AutoreportForm extends PureComponent {
  static propTypes = {
    isScheduledReportDownloading: bool.isRequired,
    onChangeReportSchedule: func.isRequired,
    testScheduledReport: func.isRequired,
    user: instanceOf(User).isRequired,
    filterOptions: instanceOf(FilterOptions).isRequired,
  }

  constructor(props) {
    super(props);

    const { user } = this.props;

    const { brands } = user;

    const {
      repeatPeriod,
      endsOnDate,
      endsAfterNumber,
      chains,
      banners,
      channels,
      regions,
      counties,
      areas,
      accountReps,
      entireMarketplace,
      marketMetrics,
      filteredMetrics,
      amountOfDays,
      startsOnDate,
      to,
      cc,
      repeatDays,
      numberOfPeriods,
      marketplaceBeforePhoto,
      marketplaceAfterPhoto,
      marketplaceSecondaryPhoto,
      marketplaceSecondaryPhotoTypes,
      filteredBeforePhoto,
      filteredAfterPhoto,
      filteredSecondaryPhoto,
      filteredSecondaryPhotoTypes,
    } = user.autoReportInfo;

    this.state = {
      selectedRepeatPeriod: repeatPeriod || DAY_PERIOD,
      selectedEndValue: endsOnDate ? ENDS_ON_DATE : endsAfterNumber ? ENDS_AFTER : ENDS_NEVER,

      // metrics tree
      expandedMarketKeys: [],
      autoExpandParentMarketMetrics: true,
      checkedMarketKeys: marketMetrics,

      expandedFilteredKeys: [],
      autoExpandParentFilteredMetrics: true,
      checkedFilteredKeys: filteredMetrics,

      selectedBrand: brands[0],
      selectedChains: chains,
      selectedBanners: banners,
      selectedChannels: channels,
      selectedRegions: regions,
      selectedCounties: counties,
      selectedAreas: areas,
      selectedAccountReps: accountReps,
      selectedEntireMarketplace: entireMarketplace,

      amountOfDays,
      startsOnDate,
      to,
      cc,
      repeatDays,

      endsOnDate,
      endsAfterNumber,
      numberOfPeriods,

      marketplaceBeforePhoto,
      marketplaceAfterPhoto,
      marketplaceSecondaryPhoto,
      marketplaceSecondaryPhotoTypes,
      filteredBeforePhoto,
      filteredAfterPhoto,
      filteredSecondaryPhoto,
      filteredSecondaryPhotoTypes,
    };
  }

  testScheduledReportClicked = () => {
    const {
      form: { validateFields },
      testScheduledReport,
    } = this.props;

    const {
      selectedBrand,
      selectedChains,
      selectedBanners,
      selectedChannels,
      selectedRegions,
      selectedCounties,
      selectedAreas,
      selectedAccountReps,
      selectedEntireMarketplace,
      checkedMarketKeys,
      checkedFilteredKeys,

      marketplaceBeforePhoto,
      marketplaceAfterPhoto,
      marketplaceSecondaryPhoto,
      marketplaceSecondaryPhotoTypes,
      filteredBeforePhoto,
      filteredAfterPhoto,
      filteredSecondaryPhoto,
      filteredSecondaryPhotoTypes,
    } = this.state;


    validateFields((err, values) => {
      if (!err) {
        testScheduledReport({
          ...values,
          brand: selectedBrand,
          chains: selectedChains,
          banners: selectedBanners,
          channels: selectedChannels,
          regions: selectedRegions,
          counties: selectedCounties,
          areas: selectedAreas,
          accountReps: selectedAccountReps,
          entireMarketplace: selectedEntireMarketplace,
          checkedMarketMetrics: checkedMarketKeys,
          checkedFilteredMetrics: checkedFilteredKeys,
          marketplaceBeforePhoto,
          marketplaceAfterPhoto,
          marketplaceSecondaryPhoto,
          marketplaceSecondaryPhotoTypes,
          filteredBeforePhoto,
          filteredAfterPhoto,
          filteredSecondaryPhoto,
          filteredSecondaryPhotoTypes,
        });
      }
    });
  };

  onMarketMetricsExpand = (expandedMarketKeys) => {
    this.setState({
      expandedMarketKeys,
      autoExpandParentMarketMetrics: false,
    });
  }

  onFilteredMetricsExpand = (expandedFilteredKeys) => {
    this.setState({
      expandedFilteredKeys,
      autoExpandParentFilteredMetrics: false,
    });
  }

  onMarketMetricsCheck = (checkedMarketKeys) => {
    this.setState({ checkedMarketKeys });
  }

  onFilteredMetricsCheck = (checkedFilteredKeys) => {
    this.setState({ checkedFilteredKeys });
  }

  renderTreeNodes = data => data.map((item) => {
    if (item.children) {
      return (
        <TreeNode title={item.title} key={item.key} dataRef={item}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode key={uuidv4()} {...item} />;
  })

  submitForm = (e) => {
    e.preventDefault();

    const {
      form: { validateFields, resetFields },
      onChangeReportSchedule,
    } = this.props;

    const {
      selectedBrand,
      selectedChains,
      selectedBanners,
      selectedChannels,
      selectedRegions,
      selectedCounties,
      selectedAreas,
      selectedAccountReps,
      selectedEntireMarketplace,
      checkedMarketKeys,
      checkedFilteredKeys,

      marketplaceBeforePhoto,
      marketplaceAfterPhoto,
      marketplaceSecondaryPhoto,
      marketplaceSecondaryPhotoTypes,
      filteredBeforePhoto,
      filteredAfterPhoto,
      filteredSecondaryPhoto,
      filteredSecondaryPhotoTypes,
    } = this.state;

    validateFields((err, values) => {
      if (!err) {
        onChangeReportSchedule({
          ...values,
          brand: selectedBrand,
          chains: selectedChains,
          banners: selectedBanners,
          channels: selectedChannels,
          regions: selectedRegions,
          counties: selectedCounties,
          areas: selectedAreas,
          accountReps: selectedAccountReps,
          entireMarketplace: selectedEntireMarketplace,
          checkedMarketMetrics: checkedMarketKeys,
          checkedFilteredMetrics: checkedFilteredKeys,
          marketplaceBeforePhoto,
          marketplaceAfterPhoto,
          marketplaceSecondaryPhoto,
          marketplaceSecondaryPhotoTypes,
          filteredBeforePhoto,
          filteredAfterPhoto,
          filteredSecondaryPhoto,
          filteredSecondaryPhotoTypes,
        });
        resetFields();
      }
    });
  };

  changeSelectedRepeatPeriod = (period) => {
    this.setState({
      selectedRepeatPeriod: period,
    });
  }

  changeSelectedEndValue = (e) => {
    const { form } = this.props;
    this.setState({
      selectedEndValue: e.target.value,
    },
    () => {
      form.validateFields([
        'endsOnDate',
        'endsAfterNumber',
      ], { force: true });

      form.setFieldsValue({
        endsOnDate: null,
        endsAfterNumber: 0,
      });
    });
  }

  onBrandsChange = (selectedBrand) => {
    this.setState({
      selectedBrand,
    });
  }

  onChainsChange = (selectedChains) => {
    this.setState({
      selectedChains,
    });
  }

  onBannersChange = (selectedBanners) => {
    this.setState({
      selectedBanners,
    });
  }

  onChannelsChange = (selectedChannels) => {
    this.setState({
      selectedChannels,
    });
  }

  onRegionsChange = (selectedRegions) => {
    this.setState({
      selectedRegions,
    });
  }

  onCountiesChange = (selectedCounties) => {
    this.setState({
      selectedCounties,
    });
  }

  onAreasChange = (selectedAreas) => {
    this.setState({
      selectedAreas,
    });
  }

  onAccountRepsChange = (selectedAccountReps) => {
    this.setState({
      selectedAccountReps,
    });
  }

  onEntireMarketplaceChange = (selectedEntireMarketplace) => {
    this.setState({
      selectedEntireMarketplace,
    });
  }

  onSetDefaultFields = () => {
    const { user } = this.props;

    const { brands } = user;

    this.setState({
      ...defaultState,
      selectedBrand: brands[0],
    });
  }

  onPhotoFilterChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  }

  onMarketPhotoTypesChange = (marketplaceSecondaryPhotoTypes) => {
    this.setState({
      marketplaceSecondaryPhotoTypes,
    });
  }

  onFilteredOptionsPhotoTypesChange = (filteredSecondaryPhotoTypes) => {
    this.setState({
      filteredSecondaryPhotoTypes,
    });
  }

  disabledDate = current => current < moment().subtract(1, 'days').endOf('day')

  render() {
    const {
      selectedRepeatPeriod,
      selectedEndValue,

      expandedMarketKeys,
      checkedMarketKeys,
      autoExpandParentMarketMetrics,

      expandedFilteredKeys,
      checkedFilteredKeys,
      autoExpandParentFilteredMetrics,

      selectedBrand,
      selectedChains,
      selectedBanners,
      selectedChannels,
      selectedRegions,
      selectedCounties,
      selectedAreas,
      selectedAccountReps,
      selectedEntireMarketplace,

      amountOfDays,
      startsOnDate,
      to,
      cc,
      repeatDays,
      endsOnDate,
      endsAfterNumber,
      numberOfPeriods,

      marketplaceBeforePhoto,
      marketplaceAfterPhoto,
      marketplaceSecondaryPhoto,
      marketplaceSecondaryPhotoTypes,
      filteredBeforePhoto,
      filteredAfterPhoto,
      filteredSecondaryPhoto,
      filteredSecondaryPhotoTypes,
    } = this.state;

    const {
      user,
      form,
      isScheduledReportDownloading,
      filterOptions: {
        chainsByBrand,
        bannersByBrand,
        channelsByBrand,
        regionsByBrand,
        countiesByBrand,
        areasByBrand,
        accountRepsByBrand,
        placements,
      },
    } = this.props;

    const { brands } = user;

    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.submitForm} className="autoreport-form">
        <div className="autoreport-form__info">
          <p className="autoreport-form__info-title">
            <span className="autoreport-form__info-title-label">Login:</span>
            {user.login}
          </p>
          <p className="autoreport-form__info-title">
            <span className="autoreport-form__info-title-label">Company:</span>
            {user.brandFullName}
          </p>
        </div>
        <div className="autoreport-form__entire-market">
          <p className="autoreport-form__entire-market-title">Entire Market:</p>
          <Switch
            checked={selectedEntireMarketplace}
            onChange={this.onEntireMarketplaceChange}
          />
        </div>
        <div className="autoreport-form__filters">
          <p>Brand:</p>
          <Select
            onChange={values => this.onBrandsChange(values)}
            value={selectedBrand}
            className="autoreport-form__brand-select"
            dropdownMatchSelectWidth
          >
            {brands.map(brand => CommonOption(brand))}
          </Select>
          <p>Chains:</p>
          <Select
            mode="multiple"
            onChange={values => this.onChainsChange(values)}
            value={selectedChains}
            className="autoreport-form__filters-select"
            maxTagCount={3}
            placeholder="Select chains"
            allowClear
          >
            {chainsByBrand[selectedBrand].map(chain => CommonOption(chain))}
          </Select>
          <p>Banners:</p>
          <Select
            mode="multiple"
            onChange={values => this.onBannersChange(values)}
            value={selectedBanners}
            className="autoreport-form__filters-select"
            maxTagCount={3}
            placeholder="Select banners"
            allowClear
          >
            {bannersByBrand[selectedBrand].map(banner => CommonOption(banner))}
          </Select>
          <p>Channels:</p>
          <Select
            mode="multiple"
            onChange={values => this.onChannelsChange(values)}
            value={selectedChannels}
            className="autoreport-form__filters-select"
            maxTagCount={3}
            placeholder="Select channels"
            allowClear
          >
            {channelsByBrand[selectedBrand].map(channel => CommonOption(channel))}
          </Select>
          <p>Regions:</p>
          <Select
            mode="multiple"
            onChange={values => this.onRegionsChange(values)}
            value={selectedRegions}
            className="autoreport-form__filters-select"
            maxTagCount={3}
            placeholder="Select regions"
            allowClear
          >
            {regionsByBrand[selectedBrand].map(region => CommonOption(region))}
          </Select>
          <p>Counties:</p>
          <Select
            mode="multiple"
            onChange={values => this.onCountiesChange(values)}
            value={selectedCounties}
            className="autoreport-form__filters-select"
            maxTagCount={3}
            placeholder="Select counties"
            allowClear
          >
            {countiesByBrand[selectedBrand].map(county => CommonOption(county))}
          </Select>
          <p>Areas:</p>
          <Select
            mode="multiple"
            onChange={values => this.onAreasChange(values)}
            value={selectedAreas}
            className="autoreport-form__filters-select"
            maxTagCount={3}
            placeholder="Select areas"
            allowClear
          >
            {areasByBrand[selectedBrand].map(area => CommonOption(area))}
          </Select>
          <p>Rep - Account:</p>
          <Select
            mode="multiple"
            onChange={values => this.onAccountRepsChange(values)}
            value={selectedAccountReps}
            className="autoreport-form__filters-select"
            maxTagCount={3}
            placeholder="Select representatives"
            allowClear
          >
            {accountRepsByBrand[selectedBrand].map(accountRep => CommonOption(accountRep))}
          </Select>
        </div>
        <div className="autoreport-form__metrics">
          <p className="autoreport-form__metrics-title">All Market Metrics:</p>
          <Tree
            checkable
            onExpand={this.onMarketMetricsExpand}
            expandedKeys={expandedMarketKeys}
            autoExpandParent={autoExpandParentMarketMetrics}
            onCheck={this.onMarketMetricsCheck}
            checkedKeys={checkedMarketKeys}
            disabled={!selectedEntireMarketplace}
          >
            {this.renderTreeNodes(REPORT_METRICS_TREE_DATA)}
          </Tree>
        </div>
        <div className="autoreport-form__photos">
          <p className="autoreport-form__photos-title">Photos:</p>
          <Checkbox
            checked={marketplaceBeforePhoto}
            name="marketplaceBeforePhoto"
            onChange={this.onPhotoFilterChange}
          >
            Before Merchandising
          </Checkbox>
          <Checkbox
            checked={marketplaceAfterPhoto}
            name="marketplaceAfterPhoto"
            onChange={this.onPhotoFilterChange}
          >
            Primary Placement
          </Checkbox>
          <Checkbox
            checked={marketplaceSecondaryPhoto}
            name="marketplaceSecondaryPhoto"
            onChange={this.onPhotoFilterChange}
          >
            Secondary Placement
          </Checkbox>
          {marketplaceSecondaryPhoto && (
            <div className="autoreport-form__photos-types">
              <p>Secondary Placement Types:</p>
              <Select
                mode="multiple"
                onChange={values => this.onMarketPhotoTypesChange(values)}
                value={marketplaceSecondaryPhotoTypes}
                className="autoreport-form__photos-select"
                placeholder="Select types"
                allowClear
              >
                {placements.map(placement => CommonOption(placement))}
              </Select>
            </div>
          )}
        </div>
        <div className="autoreport-form__metrics">
          <p className="autoreport-form__metrics-title">Filtered Options Metrics:</p>
          <Tree
            checkable
            onExpand={this.onFilteredMetricsExpand}
            expandedKeys={expandedFilteredKeys}
            autoExpandParent={autoExpandParentFilteredMetrics}
            onCheck={this.onFilteredMetricsCheck}
            checkedKeys={checkedFilteredKeys}
          >
            {this.renderTreeNodes(REPORT_METRICS_TREE_DATA)}
          </Tree>
        </div>
        <div className="autoreport-form__photos">
          <p className="autoreport-form__photos-title">Filtered Options Photos:</p>
          <Checkbox
            checked={filteredBeforePhoto}
            name="filteredBeforePhoto"
            onChange={this.onPhotoFilterChange}
          >
            Before Merchandising
          </Checkbox>
          <Checkbox
            checked={filteredAfterPhoto}
            name="filteredAfterPhoto"
            onChange={this.onPhotoFilterChange}
          >
            Primary Placement
          </Checkbox>
          <Checkbox
            checked={filteredSecondaryPhoto}
            name="filteredSecondaryPhoto"
            onChange={this.onPhotoFilterChange}
          >
            Secondary Placement
          </Checkbox>
          {filteredSecondaryPhoto && (
            <div className="autoreport-form__photos-types">
              <p>Secondary Placement Types:</p>
              <Select
                mode="multiple"
                onChange={values => this.onFilteredOptionsPhotoTypesChange(values)}
                value={filteredSecondaryPhotoTypes}
                className="autoreport-form__photos-select"
                placeholder="Select types"
                allowClear
              >
                {placements.map(placement => CommonOption(placement))}
              </Select>
            </div>
          )}
        </div>
        <div className="autoreport-form__amount">
          <FormItem label="Calendar Days in One Period">
            {getFieldDecorator('amountOfDays', {
              initialValue: amountOfDays,
              rules: [
                {
                  required: true,
                  message: 'Amount of days is required!',
                },
              ],
            })(
              <InputNumber
                min={1}
                onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                className="autoreport-form__input-number"
              />,
            )}
          </FormItem>
          <FormItem label="# of Periods (up to 6)">
            {getFieldDecorator('numberOfPeriods', {
              initialValue: numberOfPeriods,
              rules: [
                {
                  required: true,
                  message: 'Number of periods is required!',
                },
              ],
            })(
              <InputNumber
                min={0}
                max={6}
                onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                className="autoreport-form__input-number"
              />,
            )}
          </FormItem>
        </div>
        <div className="autoreport-form__send">
          <FormItem label="To">
            {getFieldDecorator('to', {
              initialValue: to,
            })(
              <Select
                mode="tags"
                maxTagCount={3}
                tokenSeparators={[',', ' ']}
                allowClear
              >
                {to.map(option => (
                  <Option key={option}>{option}</Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem label="CC">
            {getFieldDecorator('cc', {
              initialValue: cc,
            })(
              <Select
                mode="tags"
                maxTagCount={3}
                tokenSeparators={[',', ' ']}
                allowClear
              >
                {cc.map(option => (
                  <Option key={option}>{option}</Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </div>
        <div className="autoreport-form__start-section">
          <p className="autoreport-form__start-section-label">Report Start Date:</p>
          <div className="autoreport-form__start-section-wrapper">
            <FormItem>
              {getFieldDecorator('startsOnDate', {
                initialValue: startsOnDate,
                rules: [
                  {
                    required: true,
                    message: 'Set an end date!',
                  },
                ],
              })(
                <DatePicker
                  disabledDate={this.disabledDate}
                  format={DATE_FORMAT}
                  showToday={false}
                  placeholder="Start Date"
                />,
              )}
            </FormItem>
          </div>
        </div>
        <div className="autoreport-form__repeat-section">
          <p className="autoreport-form__repeat-section-label">Repeat every:</p>
          <div className="autoreport-form__repeat-section-wrapper">
            <FormItem>
              {getFieldDecorator('repeatDays', {
                initialValue: repeatDays,
                rules: [
                  {
                    required: true,
                    message: 'Repetition is required!',
                  },
                ],
              })(
                <InputNumber
                  min={0}
                  onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                  className="autoreport-form__input-number"
                />,
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('repeatPeriod', {
                initialValue: selectedRepeatPeriod,
              })(
                <Select onChange={this.changeSelectedRepeatPeriod}>
                  {
                    [...PERIODS_MAP.keys()].map(period => (
                      <Option value={period} key={period}>{PERIODS_MAP.get(period)}</Option>
                    ))
                  }
                </Select>,
              )}
            </FormItem>
          </div>
        </div>
        <div className="autoreport-form__ends-section">
          <p className="autoreport-form__ends-section-label">Ends:</p>
          <div className="autoreport-form__ends-section-choices">
            <RadioGroup
              value={selectedEndValue}
              onChange={this.changeSelectedEndValue}
            >
              <Radio value={ENDS_NEVER}>Never</Radio>
              <Radio value={ENDS_ON_DATE}>
                <div className="autoreport-form__ends-section-on">
                  <p className="autoreport-form__ends-section-on-label">On</p>
                  <div className="autoreport-form__ends-section-on-calendar">
                    <FormItem>
                      {getFieldDecorator('endsOnDate', {
                        initialValue: endsOnDate,
                        rules: [
                          {
                            required: selectedEndValue === ENDS_ON_DATE,
                            message: 'Set an end date!',
                          },
                        ],
                      })(
                        <DatePicker
                          disabledDate={this.disabledDate}
                          format={DATE_FORMAT}
                          showToday={false}
                          placeholder="End Date"
                          disabled={selectedEndValue !== ENDS_ON_DATE}
                        />,
                      )}
                    </FormItem>
                  </div>
                </div>
              </Radio>
              <Radio value={ENDS_AFTER}>
                <div className="autoreport-form__ends-section-after">
                  <p className="autoreport-form__ends-section-after_label-before">After</p>
                  <FormItem>
                    {getFieldDecorator('endsAfterNumber', {
                      initialValue: endsAfterNumber,
                      rules: [
                        {
                          required: selectedEndValue === ENDS_AFTER,
                          message: 'Set an amount!',
                        },
                      ],
                    })(
                      <InputNumber
                        min={0}
                        placeholder="Amount"
                        onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                        disabled={selectedEndValue !== ENDS_AFTER}
                        className="autoreport-form__input-number"
                      />,
                    )}
                  </FormItem>
                  <p className="autoreport-form__ends-section-after_label-after">occurrences</p>
                </div>
              </Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="autoreport-form__buttons-section">
          <FormItem>
            <Button
              className="autoreport-form__button_submit"
              icon="save"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </FormItem>
          <FormItem>
            <Button
              className="autoreport-form__button_default"
              icon="file"
              type="ghost"
              onClick={this.testScheduledReportClicked}
              loading={isScheduledReportDownloading}
            >
              Test
            </Button>
          </FormItem>
          <FormItem>
            <Button
              className="autoreport-form__button_delete"
              icon="delete"
              type="ghost"
              onClick={this.onSetDefaultFields}
            >
              Default
            </Button>
          </FormItem>
        </div>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(AutoreportForm);

export default WrappedForm;
