/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import {
  string, arrayOf, shape, instanceOf, number,
} from 'prop-types';
import moment from 'moment';

import noPictureImage from 'common/images/no-picture.png';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import './index.scss';

class AccountPopupInfo extends PureComponent {
  state = {
    visible: false,
    modalPictureUrl: null,
  };

  changeVisible = value => this.setState({ visible: value });

  changeModalPictureUrl = url => this.setState({ modalPictureUrl: url });

  setPictureVisible = (index) => {
    const { picturesList } = this.props;

    this.changeVisible(true);
    this.changeModalPictureUrl(picturesList[index].pictureUrl);
  };

  setStorePictureVisible = () => {
    const { storePictureUrl } = this.props;
    if (storePictureUrl) {
      this.changeVisible(true);
      this.changeModalPictureUrl(storePictureUrl);
    }
  };

  onClickPicture = index => () => this.setPictureVisible(index);

  closeModal = () => this.changeVisible(false);

  onKeyPress = index => (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      this.setPictureVisible(index);
    }
  };

  onKeyPressStore = (e) => {
    const { storePictureUrl } = this.props;
    if (storePictureUrl && (e.key === 'Enter' || e.key === 'Space')) {
      this.setStorePictureVisible();
    }
  };

  onImgError = (e) => {
    e.target.src = noPictureImage;
  };

  render() {
    const {
      name,
      address,
      storePictureUrl,
      picturesList,
      lastVisitDate,
      status,
      totalVisits,
      discontinuedDate,
      representative,
      nextScheduledDate,
    } = this.props;

    const { visible, modalPictureUrl } = this.state;

    const formattedLastVisitDate = lastVisitDate.format(DATE_FORMAT);

    const isDiscontinued = discontinuedDate.isValid();

    const formattedDiscontinuedDate = isDiscontinued
      ? discontinuedDate.format(DATE_FORMAT)
      : null;
    const formattedNextScheduledDate = nextScheduledDate.format(
      DATE_FORMAT,
    );

    return (
      <div className="account-popup-info">
        <article className="account-popup-info__description">
          <h1 className="account-popup-info__description-heading">
            Account Info
          </h1>
          <div className="account-popup-info__description-container">
            <section>
              <div
                onKeyPress={this.onKeyPressStore}
                onClick={this.setStorePictureVisible}
                role="button"
                tabIndex="0"
              >
                <img
                  className="account-popup-info__front-picture"
                  height="75"
                  width="75"
                  src={storePictureUrl || noPictureImage}
                  alt="Storefront"
                  onError={this.onImgError}
                />
              </div>
            </section>
            <section className="account-popup-info__info-container">
              <p className="account-popup-info__info-item">
                <span className="account-popup-info__info-label">
                  Account Name:
                  {' '}
                </span>
                {name}
              </p>
              <p className="account-popup-info__info-item">
                <span className="account-popup-info__info-label">
                  Address:
                  {' '}
                </span>
                {address}
              </p>
            </section>
          </div>
        </article>
        <article className="account-popup-info__description">
          <h1 className="account-popup-info__description-heading">
            Key Stats
          </h1>
          <section>
            <p className="account-popup-info__info-item">
              <span className="account-popup-info__info-label">
                Rep:
                {' '}
              </span>
              {representative}
            </p>
            <p className="account-popup-info__info-item">
              <span className="account-popup-info__info-label">
                Visits:
                {' '}
              </span>
              {totalVisits}
            </p>
            <p className="account-popup-info__info-item">
              <span className="account-popup-info__info-label">
                Last Visit:
                {' '}
              </span>
              {formattedLastVisitDate}
            </p>
            <p className="account-popup-info__info-item">
              <span className="account-popup-info__info-label">
                Next Scheduled Visit:
                {' '}
              </span>
              {formattedNextScheduledDate}
            </p>

            <p className="account-popup-info__info-item">
              <span className="account-popup-info__info-label">
                Current Status:
                {' '}
              </span>
              {status}
            </p>
            {isDiscontinued && (
              <p className="account-popup-info__info-item">
                <span className="account-popup-info__info-label">
                  Discontinued Date:
                  {' '}
                </span>
                {formattedDiscontinuedDate}
              </p>
            )}
          </section>
        </article>

        {picturesList.length > 0 && (
          <article>
            <h1 className="account-popup-info__description-heading">
              Account Photos
            </h1>
            <div className="account-popup-info__accounts-pictures-container">
              {picturesList.map(
                (pictureItem, index) => pictureItem.pictureUrl && (
                  <div
                    key={pictureItem.title}
                    role="button"
                    tabIndex="0"
                    onKeyPress={this.onKeyPress(index)}
                    onClick={this.onClickPicture(index)}
                    className="account-popup-info__accounts-picture-wrapper"
                  >
                    <h3 className="account-popup-info__accounts-picture-heading">
                      {pictureItem.title}
                    </h3>
                    <img
                      height="150"
                      className="account-popup-info__accounts-picture"
                      src={pictureItem.pictureUrl}
                      alt={pictureItem.title}
                      onError={this.onImgError}
                    />
                  </div>
                ),
              )}
            </div>
          </article>
        )}

        <Modal
          width={700}
          visible={Boolean(visible)}
          onCancel={this.closeModal}
          centered
          footer={null}
        >
          <div className="account-popup-info__modal-content">
            <div className="account-popup-info__modal-picture-container">
              <img
                width="600"
                src={modalPictureUrl}
                alt="Account photo"
                className="account-popup-info__modal-picture"
                onError={this.onImgError}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

AccountPopupInfo.propTypes = {
  name: string.isRequired,
  address: string.isRequired,
  storePictureUrl: string.isRequired,
  picturesList: arrayOf(shape({ title: string, pictureUrl: string })),
  lastVisitDate: instanceOf(moment).isRequired,
  status: string.isRequired,
  totalVisits: number.isRequired,
  representative: string.isRequired,
  nextScheduledDate: instanceOf(moment).isRequired,
  discontinuedDate: instanceOf(moment).isRequired,
};

AccountPopupInfo.defaultProps = {
  picturesList: [],
};

export default AccountPopupInfo;
