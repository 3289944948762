import { connect } from 'react-redux';

import Menu from 'common/components/Menu';

export default connect(
  state => ({
    userInfo: state.session.userInfo,
    permissions: state.session.permissions,
  }),
  dispatch => ({
    logOut: dispatch.session.logOut,
  }),
)(Menu);
