class AsmScheduleDailyBarInfo {
  constructor({ visitName, value, percentage }) {
    this.visitName = visitName;
    this.value = value;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new AsmScheduleDailyBarInfo({
      visitName: obj.Name,
      value: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default AsmScheduleDailyBarInfo;
