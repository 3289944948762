/* eslint-disable no-param-reassign */

import produce from 'immer';
import fileDownload from 'js-file-download';

import {
  DATABASE_FILTERS_ENDPOINT,
  MASTER_LIST_REPORT,
} from 'common/constants/apiRoutes';

import ajax from 'common/utilities/ajax';

import FilterOptions from 'databaseModule/models/FilterOptions';
import AccountsFilter from 'databaseModule/models/AccountsFilter';

import { showError } from 'common/helpers/notificationManager';

const initialState = {
  filter: new AccountsFilter({}),
  initialized: false,
  filterOptions: new FilterOptions({
    brands: [],
  }),
};

export default {
  state: initialState,
  reducers: {
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(DATABASE_FILTERS_ENDPOINT);
      const filterOptionsModel = FilterOptions.parse(response.data);
      dispatch.database.setFilterOptions(filterOptionsModel);
    },

    async downloadMasterList(_, state) {
      const { filter } = state.database;
      try {
        const response = await ajax.get(MASTER_LIST_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download Master Accounts List',
        });
      }
    },
  }),
};
