class AsmUds {
  constructor({
    name, metricName, date, value, isValid, isSummary,
  }) {
    this.name = name;
    this.metricName = metricName;
    this.date = date;
    this.value = value;
    this.isValid = isValid;
    this.isSummary = isSummary;
  }

  static fieldNames = ['Target', 'Count', 'Net']

  static fieldNamesMap = new Map([
    ['Target', 'Target'],
    ['Count', 'Count'],
    ['Net', 'Net'],
  ]);


  static parse(obj, name, metricName) {
    return new AsmUds({
      name,
      metricName,
      date: obj.DateStart,
      value: obj.Value,
      isValid: obj.Valid,
      isSummary: !!obj.IsSummary,
    });
  }
}

export default AsmUds;
