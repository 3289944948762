/* eslint-disable no-param-reassign */
import SkuSale from 'salesModule/models/SkuSale';

class TotalSaleBySku {
  constructor({ dateInterval, skus }) {
    this.dateInterval = dateInterval;
    this.skus = skus;
  }

  static parse(dateInterval, obj) {
    const { TotalsBySKU } = obj;
    const skus = Object.keys(TotalsBySKU).map(
      skuName => new SkuSale({
        name: skuName,
        amount: Math.round(TotalsBySKU[skuName]) || 0,
      }),
    );
    return new TotalSaleBySku({
      dateInterval,
      skus,
    });
  }

  getSkuNames() {
    return this.skus.map(sku => sku.name);
  }

  getSkuData() {
    return this.skus.reduce((skuData, sku) => {
      skuData[sku.name] = sku.amount;
      skuData.dateInterval = this.dateInterval;
      return skuData;
    }, {});
  }
}

export default TotalSaleBySku;
