import React, { Fragment, PureComponent } from 'react';
import {
  Divider,
  Button,
  Skeleton, Modal,
} from 'antd';

import SectionHeading from 'common/components/SectionHeading';

import {
  bool, func, string, arrayOf,
} from 'prop-types';

import { managementPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';
import DownloadEmailReportForm from 'managementModule/components/DownloadEmailReportForm';

const HEADING = 'Management Reports';

class ASMReportsSection extends PureComponent {
  static propTypes = {
    onDownloadBrandASMWeeklyReport: func.isRequired,
    isBrandASMWeeklyReportDownloading: bool.isRequired,
    onDownloadBrandASMMonthlyReport: func.isRequired,
    isBrandASMMonthlyReportDownloading: bool.isRequired,
    onDownloadMultiBrandASMWeeklyReport: func.isRequired,
    isMultiBrandASMWeeklyReportDownloading: bool.isRequired,
    onDownloadMultiBrandASMMonthlyReport: func.isRequired,
    isMultiBrandASMMonthlyReportDownloading: bool.isRequired,
    isFilterOptionsFetching: bool.isRequired,
    submitEmailWeekly: func.isRequired,
    submitEmailMonthly: func.isRequired,
    brand: string,
    childrenPermissions: arrayOf(string).isRequired,
  };

  static defaultProps = {
    brand: '',
  };

  state = {
    isDownloadWeeklyModalVisible: false,
    isDownloadMonthlyModalVisible: false,
    // isRegistrationFormVisible: false,
    // isEditFormVisible: false,
    // isLoginInfoModalVisible: false,
    // isPermissionsModalVisible: false,
    // isAutoreportsModalVisible: false,
    // isDeleteUserModalVisible: false,
    //
    // selectedUser: null,
    // selectedLoginInfoUser: getNewUser(),
    // selectedPermissionsUser: getNewUser(),
    // selectedAutoreportsUser: getNewUser(),
    // selectedDeleteUser: getNewUser(),
    //
    // additionalTableProps: {},
    // iconSize: '16px',
  };

  showMonthlyEmailModal = () => {
    this.setState({
      isDownloadMonthlyModalVisible: true,
    });
  };

  showWeeklyEmailModal = () => {
    this.setState({
      isDownloadWeeklyModalVisible: true,
    });
  };

  onSubmitEmailMonthly = (values) => {
    const { submitEmailMonthly } = this.props;
    this.closeModal('isDownloadMonthlyModalVisible');

    submitEmailMonthly(values);
  };

  onSubmitEmailWeekly = (values) => {
    const { submitEmailWeekly } = this.props;
    this.closeModal('isDownloadWeeklyModalVisible');

    submitEmailWeekly(values);
  };

  closeModal = (isModalOpen) => {
    this.setState({
      [isModalOpen]: false,
    });
  }

  render() {
    const {
      onDownloadBrandASMWeeklyReport,
      isBrandASMWeeklyReportDownloading,
      onDownloadBrandASMMonthlyReport,
      isBrandASMMonthlyReportDownloading,
      onDownloadMultiBrandASMWeeklyReport,
      isMultiBrandASMWeeklyReportDownloading,
      onDownloadMultiBrandASMMonthlyReport,
      isMultiBrandASMMonthlyReportDownloading,
      isFilterOptionsFetching,
      brand,
      childrenPermissions,
    } = this.props;

    const {
      isDownloadMonthlyModalVisible,
      isDownloadWeeklyModalVisible,
    } = this.state;

    return (
      <Skeleton loading={isFilterOptionsFetching}>
        <Fragment>
          <SectionHeading>{HEADING}</SectionHeading>
          <section className="management-reports-section">
            {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_WEEKLY_BRAND_REPORT) && (
              <div className="management-reports-section__download-button-container">
                <Button
                  type="primary"
                  icon="file"
                  onClick={onDownloadBrandASMWeeklyReport}
                  loading={isBrandASMWeeklyReportDownloading}
                >
                  {`Weekly Report - ${brand}`}
                </Button>
              </div>
            )}
            {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_MONTHLY_BRAND_REPORT) && (
              <div className="management-reports-section__download-button-container">
                <Button
                  type="primary"
                  icon="file"
                  onClick={onDownloadBrandASMMonthlyReport}
                  loading={isBrandASMMonthlyReportDownloading}
                >
                  {`Monthly Report - ${brand}`}
                </Button>
              </div>
            )}
            {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_WEEKLY_ALL_REPORT) && (
              <div className="management-reports-section__download-button-container">
                <Button
                  type="primary"
                  icon="file"
                  onClick={onDownloadMultiBrandASMWeeklyReport}
                  loading={isMultiBrandASMWeeklyReportDownloading}
                >
                  Weekly Report - All Brands
                </Button>
              </div>
            )}
            {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_MONTHLY_ALL_REPORT) && (
              <div className="management-reports-section__download-button-container">
                <Button
                  type="primary"
                  icon="file"
                  onClick={onDownloadMultiBrandASMMonthlyReport}
                  loading={isMultiBrandASMMonthlyReportDownloading}
                >
                  Monthly Report - All Brands
                </Button>
              </div>
            )}
            {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_WEEKLY_ALL_REPORT) && (
              <div className="management-reports-section__download-button-container">
                <Button
                  type="primary"
                  icon="form"
                  onClick={this.showWeeklyEmailModal}
                >
                  Management Report - Weekly
                </Button>
              </div>
            )}
            {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_MONTHLY_ALL_REPORT) && (
              <div className="management-reports-section__download-button-container">
                <Button
                  type="primary"
                  icon="form"
                  onClick={this.showMonthlyEmailModal}
                >
                  Management Report - Monthly
                </Button>
              </div>
            )}
            <Modal
              title="Download Monthly Report"
              visible={isDownloadMonthlyModalVisible}
              onCancel={() => this.closeModal('isDownloadMonthlyModalVisible')}
              destroyOnClose
              centered
              width={570}
              footer={null}
            >
              <DownloadEmailReportForm
                onSubmitEmail={this.onSubmitEmailMonthly}
              />
            </Modal>
            <Modal
              title="Download Weekly Report"
              visible={isDownloadWeeklyModalVisible}
              onCancel={() => this.closeModal('isDownloadWeeklyModalVisible')}
              destroyOnClose
              centered
              width={570}
              footer={null}
            >
              <DownloadEmailReportForm
                onSubmitEmail={this.onSubmitEmailWeekly}
              />
            </Modal>
          </section>
          <Divider />
        </Fragment>
      </Skeleton>
    );
  }
}

export default ASMReportsSection;
