import Permissions from 'userModule/models/Permissions';
import UserLoginInfo from './UserLoginInfo';
import ReportMetrics from './ReportMetrics';

class User {
  constructor({
    login,
    brands,
    isActive,
    password,
    brandFullName,
    isAllBrandsAvaliable,
    isAdmin,
    loginInfo,
    permissionsInfo,
    autoReportInfo,
  }) {
    this.login = login;
    this.isActive = isActive;
    this.password = password;
    this.brands = brands;
    this.brandFullName = brandFullName;
    this.isAllBrandsAvaliable = isAllBrandsAvaliable;
    this.isAdmin = isAdmin;
    this.loginInfo = loginInfo;
    this.permissionsInfo = permissionsInfo;
    this.autoReportInfo = autoReportInfo;
  }

  static parse(obj) {
    return new User({
      login: obj.Login,
      isActive: obj.Active,
      password: obj.Password,
      brands: obj.Brands.sort(),
      brandFullName: obj.BrandName,
      isAllBrandsAvaliable: obj.AllBrandsAccess,
      isAdmin: obj.IsAdmin,
      loginInfo: obj.UserLoginInfo.map(UserLoginInfo.parse),
      permissionsInfo: Permissions.parse(obj.Permissions),
      autoReportInfo: ReportMetrics.parse(obj.ReportsSchedule),
    });
  }
}

export default User;
