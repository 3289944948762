import { connect } from 'react-redux';
import { permissionsSelector } from 'reportsModule/selectors/reportsPageSelectors';

import SalesReportsSection from 'reportsModule/components/SalesReportsSection';

export default connect(
  state => ({
    isRawSalesReportDownloading:
      state.loading.effects.reports.downloadRawSalesReport,
    isSalesReportByDistributorDownloading:
      state.loading.effects.reports.downloadSalesReportByDistributor,
    isSkuSalesReportDownloading:
      state.loading.effects.reports.downloadSkuSalesReport,
    isBrandSalesReportDownloading:
      state.loading.effects.reports.downloadBrandSalesReport,
    isAccountSalesReportDownloading:
      state.loading.effects.reports.downloadAccountSalesReport,
    childrenPermissions: permissionsSelector(state),

  }),
  dispatch => ({
    onDownloadRawSalesReport: () => dispatch.reports.downloadRawSalesReport(),
    onDownloadSalesReportByDistributor: () => dispatch.reports.downloadSalesReportByDistributor(),
    onDownloadSkuSalesReport: () => dispatch.reports.downloadSkuSalesReport(),
    onDownloadBrandSalesReports: () => dispatch.reports.downloadBrandSalesReport(),
    onDownloadAccountSalesReports: () => dispatch.reports.downloadAccountSalesReport(),
  }),
)(SalesReportsSection);
