/* eslint-disable no-param-reassign */

import produce from 'immer';
import fileDownload from 'js-file-download';

import {
  MANAGEMENT_FILTERS_ENDPOINT,
  ASM_BRAND_WEEKLY_REPORT,
  ASM_BRAND_MONTHLY_REPORT,
  ASM_ALL_WEEKLY_REPORT,
  ASM_ALL_MONTHLY_REPORT,
  REPRESENTATIVES_BRAND_WEEKLY,
  REPRESENTATIVES_BRAND_MONTHLY,
  REPRESENTATIVES_ALL_BRANDS_WEEKLY,
  REPRESENTATIVES_ALL_BRANDS_MONTHLY,
  DEAD_ACCOUNTS,
  DEAD_ACCOUNTS_HOT,
  ACTIVE_ACCOUNTS_REPORT,
  ACCOUNTS_TOTAL_SALES_REPORT,
  ASM_ALL_EMAIL_WEEKLY_REPORT,
  ASM_ALL_EMAIL_MONTHLY_REPORT,
} from 'common/constants/apiRoutes';

import ajax from 'common/utilities/ajax';

import FilterOptions from 'managementModule/models/FilterOptions';
import AccountsFilter from 'managementModule/models/AccountsFilter';
import RepresentativeMetric from 'managementModule/models/RepresentativeMetric';

import { showError, showSuccess } from 'common/helpers/notificationManager';

const initialState = {
  filter: new AccountsFilter({}),
  initialized: false,
  filterOptions: new FilterOptions({
    brands: [],
    channelTypes: {},
    cities: {},
    states: {},
    chains: {},
    representatives: {},
    representativesMetric: [],
    territories: {},
    accountsSources: [],
    distributors: [],
    salesSkus: [],
    merchandisingSkus: [],
    regions: {},
    counties: {},
    banners: {},
    frequencies: [],
    inactiveReps: [],
  }),
  representativesBrandWeeklyData: [],
  representativesBrandMonthlyData: [],
  representativesAllBrandsWeeklyData: [],
  representativesAllBrandsMonthlyData: [],

  accountStatusByBrandWeeklyFilteredMetrics: [],
  merchandisingByBrandWeeklyFilteredMetrics: [],
  salesByBrandWeeklyFilteredMetrics: [],

  accountStatusAllBrandsWeeklyFilteredMetrics: [],
  merchandisingAllBrandsWeeklyFilteredMetrics: [],
  salesAllBrandsWeeklyFilteredMetrics: [],

  accountStatusByBrandMonthlyFilteredMetrics: [],
  merchandisingByBrandMonthlyFilteredMetrics: [],
  salesByBrandMonthlyFilteredMetrics: [],

  accountStatusAllBrandsMonthlyFilteredMetrics: [],
  merchandisingAllBrandsMonthlyFilteredMetrics: [],
  salesAllBrandsMonthlyFilteredMetrics: [],
};

export default {
  state: initialState,
  reducers: {
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    setRepresentativesBrandWeeklyData: produce(
      (state, representativesBrandWeeklyData) => {
        state.representativesBrandWeeklyData = representativesBrandWeeklyData;
      },
    ),
    setRepresentativesBrandMonthlyData: produce(
      (state, representativesBrandMonthlyData) => {
        state.representativesBrandMonthlyData = representativesBrandMonthlyData;
      },
    ),
    setRepresentativesAllBrandsWeeklyData: produce(
      (state, representativesAllBrandsWeeklyData) => {
        state.representativesAllBrandsWeeklyData = representativesAllBrandsWeeklyData;
      },
    ),
    setRepresentativesAllBrandsMonthlyData: produce(
      (state, representativesAllBrandsMonthlyData) => {
        state.representativesAllBrandsMonthlyData = representativesAllBrandsMonthlyData;
      },
    ),
    setAccountStatusByBrandWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.accountStatusByBrandWeeklyFilteredMetrics = filteredMetrics;
    }),
    setMerchandisingByBrandWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.merchandisingByBrandWeeklyFilteredMetrics = filteredMetrics;
    }),
    setSalesByBrandWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.salesByBrandWeeklyFilteredMetrics = filteredMetrics;
    }),

    setAccountStatusAllBrandsWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.accountStatusAllBrandsWeeklyFilteredMetrics = filteredMetrics;
    }),
    setMerchandisingAllBrandsWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.merchandisingAllBrandsWeeklyFilteredMetrics = filteredMetrics;
    }),
    setSalesAllBrandsWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.salesAllBrandsWeeklyFilteredMetrics = filteredMetrics;
    }),

    setAccountStatusByBrandMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.accountStatusByBrandMonthlyFilteredMetrics = filteredMetrics;
    }),
    setMerchandisingByBrandMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.merchandisingByBrandMonthlyFilteredMetrics = filteredMetrics;
    }),
    setSalesByBrandMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.salesByBrandMonthlyFilteredMetrics = filteredMetrics;
    }),

    setAccountStatusAllBrandsMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.accountStatusAllBrandsMonthlyFilteredMetrics = filteredMetrics;
    }),
    setMerchandisingAllBrandsMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.merchandisingAllBrandsMonthlyFilteredMetrics = filteredMetrics;
    }),
    setSalesAllBrandsMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.salesAllBrandsMonthlyFilteredMetrics = filteredMetrics;
    }),
    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(MANAGEMENT_FILTERS_ENDPOINT);
      const filterOptionsModel = FilterOptions.parse(response.data);
      dispatch.management.setFilterOptions(filterOptionsModel);
    },

    async downloadBrandASMWeeklyReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(ASM_BRAND_WEEKLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM weekly report',
        });
      }
    },

    async downloadBrandASMMonthlyReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(ASM_BRAND_MONTHLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM monthly report',
        });
      }
    },

    async downloadMultiBrandASMWeeklyReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(ASM_ALL_WEEKLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM weekly report',
        });
      }
    },

    async downloadMultiBrandASMMonthlyReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(ASM_ALL_MONTHLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download ASM monthly report',
        });
      }
    },

    async submitEmailWeekly(email, state) {
      const { filter } = state.management;
      try {
        const params = filter.getApiParams();
        params.email = email;
        ajax.get(ASM_ALL_EMAIL_WEEKLY_REPORT, {
          params,
        });
        showSuccess({
          message: 'Success',
          description: `Report will be sent to ${email} soon.`,
        });
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download weekly report',
        });
      }
    },

    async submitEmailMonthly(email, state) {
      const { filter } = state.management;
      const params = filter.getApiParams();
      params.email = email;
      try {
        ajax.get(ASM_ALL_EMAIL_MONTHLY_REPORT, {
          params,
        });
        showSuccess({
          message: 'Success',
          description: `Report will be sent to ${email} soon.`,
        });
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download monthly report',
        });
      }
    },

    async downloadDeadAccountsReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(DEAD_ACCOUNTS, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download dead accounts report',
        });
      }
    },

    async downloadDeadAccountsHotReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(DEAD_ACCOUNTS_HOT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download dead accounts w/ hot report',
        });
      }
    },

    async fetchBrandRespresentativesWeekly(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(
        REPRESENTATIVES_BRAND_WEEKLY,
        config,
      );
      const representativesBrandWeeklyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData.map(metricsDataItem => RepresentativeMetric.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);

      dispatch.management.setRepresentativesBrandWeeklyData(
        representativesBrandWeeklyData,
      );
    },

    async fetchBrandRespresentativesMonthly(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(
        REPRESENTATIVES_BRAND_MONTHLY,
        config,
      );

      const representativesBrandMonthlyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData.map(metricsDataItem => RepresentativeMetric.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);

      dispatch.management.setRepresentativesBrandMonthlyData(
        representativesBrandMonthlyData,
      );
    },

    async fetchAllBrandsRespresentativesWeekly(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(
        REPRESENTATIVES_ALL_BRANDS_WEEKLY,
        config,
      );

      const representativesAllBrandsWeeklyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData.map(metricsDataItem => RepresentativeMetric.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);

      dispatch.management.setRepresentativesAllBrandsWeeklyData(
        representativesAllBrandsWeeklyData,
      );
    },

    async fetchAllBrandsRespresentativesMonthly(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(
        REPRESENTATIVES_ALL_BRANDS_MONTHLY,
        config,
      );

      const representativesAllBrandsMonthlyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData.map(metricsDataItem => RepresentativeMetric.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);

      dispatch.management.setRepresentativesAllBrandsMonthlyData(
        representativesAllBrandsMonthlyData,
      );
    },

    async downloadActiveAccountsReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(ACTIVE_ACCOUNTS_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download active accounts report',
        });
      }
    },

    async downloadAccountsTotalSalesReport(_, state) {
      const { filter } = state.management;

      try {
        const response = await ajax.get(ACCOUNTS_TOTAL_SALES_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download accounts total sales report',
        });
      }
    },
  }),
};
