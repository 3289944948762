class ProductsOnPromo {
  constructor({ value, amount, percentage }) {
    this.value = value;
    this.amount = amount;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new ProductsOnPromo({
      value: obj.Value,
      amount: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default ProductsOnPromo;
