/* eslint-disable no-unused-expressions, no-lone-blocks */
import React, { PureComponent } from 'react';
import {
  instanceOf, func, bool, arrayOf, string,
} from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { Skeleton } from 'antd';

import withNavigation from 'common/hocs/withNavigation';

import FilterOptions from 'accountsModule/models/FilterOptions';
import AccountsFilterModel from 'accountsModule/models/AccountsFilter';

import UserInfo from 'userModule/models/UserInfo';

import AccountsFilter from 'accountsModule/components/AccountsFilter';
import SummarySection from 'accountsModule/containers/SummaryContainer';
import AccountsMap from 'accountsModule/containers/AccountsMapContainer';
import TotalAccountsChart from 'accountsModule/containers/TotalAccountsChartContainer';
import MetricsOnDateCharts from 'accountsModule/containers/MetricsOnDateChartsContainer';


import { accountsPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class AccountsPage extends PureComponent {
  static propTypes = {
    userInfo: instanceOf(UserInfo).isRequired,
    filterOptions: instanceOf(FilterOptions).isRequired,
    fetchAccounts: func.isRequired,
    fetchFilterOptions: func.isRequired,
    fetchingFilterOptions: bool.isRequired,
    setAccountFilters: func.isRequired,
    accountsFilter: instanceOf(AccountsFilterModel).isRequired,
    accountsFilterInitialized: bool.isRequired,
    location: RouterPropTypes.location.isRequired,
    history: RouterPropTypes.history.isRequired,
    resetAccountsStore: func.isRequired,
    childrenPermissions: arrayOf(string).isRequired,
    filterPermissions: arrayOf(string).isRequired,
  };

  componentDidMount() {
    const { fetchFilterOptions } = this.props;

    fetchFilterOptions();
  }

  componentDidUpdate(prevProps) {
    const { fetchingFilterOptions, filterOptions, location } = this.props;

    if (prevProps.fetchingFilterOptions && !fetchingFilterOptions) {
      const defaultBrandName = filterOptions.brands[0].name;
      const { earliestDate } = filterOptions.brands.find(
        brandInfo => brandInfo.name === defaultBrandName,
      );
      const defaultAccountsSource = filterOptions.accountsSources[0];

      AccountsFilterModel.setDefaultParams({
        defaultBrand: defaultBrandName,
        defaultStartDate: earliestDate,
        defaultAccountsSource,
      });
      const filterModel = AccountsFilterModel.parseQueryString(
        location.search,
      );

      this.setAccountFilters(filterModel);
    }
  }

  componentWillUnmount() {
    const { resetAccountsStore } = this.props;
    resetAccountsStore();
  }

  setAccountFilters = (filterModel) => {
    const {
      setAccountFilters,
      fetchAccounts,
      location,
      history,
      childrenPermissions,
    } = this.props;

    const modelQueryString = filterModel.getQueryStringFilter();
    { childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_MAP,
      accountsPagePermissionTypes.ACCOUNTS_PAGE_ACCOUNT_STATUS,
      accountsPagePermissionTypes.ACCOUNTS_PAGE_CHANNELS,
      accountsPagePermissionTypes.ACCOUNTS_PAGE_NO_INTEREST,
      accountsPagePermissionTypes.ACCOUNTS_PAGE_DISTRIBUTORS,
      accountsPagePermissionTypes.ACCOUNTS_PAGE_AUTH_CHANNELS) && fetchAccounts(filterModel); }
    setAccountFilters(filterModel);
    history.push({
      pathname: location.pathname,
      search: modelQueryString,
      hash: location.hash,
    });
  };

  render() {
    const {
      accountsFilterInitialized,
      userInfo,
      accountsFilter,
      filterOptions,
      childrenPermissions,
      filterPermissions,
    } = this.props;

    return (
      <div className="accounts-page">
        <Skeleton
          loading={!accountsFilterInitialized}
          className="accounts-page__skeleton"
        >
          <AccountsFilter
            isAdmin={userInfo.isAdmin}
            applyFilter={this.setAccountFilters}
            initialFilterModel={accountsFilter}
            filterOptions={filterOptions}
            permissions={filterPermissions}
          />
        </Skeleton>
        <div className="accounts-page__inner">
          { childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_MAP) && <AccountsMap /> }
          { childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_MAP) && <SummarySection /> }
          { childrenPermissions.includes(accountsPagePermissionTypes.ACCOUNTS_PAGE_ACCOUNT_STATUS)
          && (
            <div className="accounts-page__chart">
              <TotalAccountsChart />
            </div>
          )
          }
          <MetricsOnDateCharts />
        </div>
      </div>
    );
  }
}

export default withNavigation()(AccountsPage);
