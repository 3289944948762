/* eslint-disable import/prefer-default-export */
import { COLOR_SET } from 'common/constants/colorPalette';

export const colorGenerator = namesToMap => namesToMap.reduce(
  (colorMap, name, index) => colorMap.set(
    name,
    COLOR_SET[index] || COLOR_SET[index % COLOR_SET.length],
  ),
  new Map(),
);
