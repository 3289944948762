export const API_URL = process.env.API_URL; // eslint-disable-line prefer-destructuring
// export const API_URL = 'https://o98p1c5nj5.execute-api.us-west-1.amazonaws.com/dev';
export const LOG_IN_ENDPOINT = `${API_URL}/authenticate`;
export const AUTH_TEST = `${API_URL}/authtest`;

// admin endpoints
export const USERS_ENDPOINT = `${API_URL}/users`;
export const REGISTRATION_ENDPOINT = `${USERS_ENDPOINT}/register`;
export const DELETE_ENDPOINT = `${USERS_ENDPOINT}/delete`;
export const REGISTRATION_FILTERS_ENDPOINT = `${USERS_ENDPOINT}/filters`;
export const USERS_LIST_ENDPOINT = `${USERS_ENDPOINT}/list`;
export const EDIT_ENDPOINT = `${USERS_ENDPOINT}/edit`;
export const TARGET_DATE_ENDPOINT = `${API_URL}/brands/target-date`;
export const BRANDS_LIST_ENDPOINT = `${API_URL}/brands`;
export const BRANDS_ARCHIVE_ENDPOINT = `${API_URL}/brands/archive`;
export const BRANDS_DELETE_ENDPOINT = `${API_URL}/brands/delete`;
export const PERMISSIONS_ENPOINT = `${USERS_ENDPOINT}/permissions`;
export const REPORT_SCHEDULE_ENDPOINT = `${USERS_ENDPOINT}/autoreports`;
export const REPORT_SCHEDULE_TEST_ENDPOINT = `${USERS_ENDPOINT}/autoreports/test`;

// sales
export const SALES_ENDPOINT = `${API_URL}/sales`;
export const SALES_FILTER_ENDPOINT = `${SALES_ENDPOINT}/filters`;
export const SALES_TOTALS_ENDPOINT = `${SALES_ENDPOINT}/totals`;

export const SALES_XLSX = `${SALES_ENDPOINT}/xlsx`;

// accounts
export const ACCOUNTS_ENDPOINT = `${API_URL}/accounts`;
export const ACCOUNTS_FILTERS_ENDPOINT = `${ACCOUNTS_ENDPOINT}/filters`;

export const ACCOUNTS_XLSX = `${ACCOUNTS_ENDPOINT}/xlsx`;

// merchandising
export const MERCHANDISING_ENDPOINT = `${API_URL}/merchandising`;
export const MERCHANDISING_FILTERS_ENDPOINT = `${MERCHANDISING_ENDPOINT}/filters`;
export const AVERAGE_FACINGS_ENDPOINT = `${MERCHANDISING_ENDPOINT}/average-facings`;
export const ADDED_FACINGS_ENDPOINT = `${MERCHANDISING_ENDPOINT}/facings-added`;
export const VOID_REPORTS_ENDPOINT = `${MERCHANDISING_ENDPOINT}/sku`;
export const PRIMARY_PLACEMENT_ENDPOINT = `${MERCHANDISING_ENDPOINT}/primary-placements`;
export const SHELF_PLACEMENT_ENDPOINT = `${MERCHANDISING_ENDPOINT}/shelf-placements`;
export const SECONDARY_PLACEMENT_ENDPOINT = `${MERCHANDISING_ENDPOINT}/secondary-placements`;
export const POS_PLACEMENT_ENDPOINT = `${MERCHANDISING_ENDPOINT}/pos-placements`;
export const COMPETITORS_ENDPOINT = `${MERCHANDISING_ENDPOINT}/competitors`;
export const MERCHANDISING_ACCOUNT_PICTURES = `${MERCHANDISING_ENDPOINT}/account-pictures`;
export const SKU_RETAIL_PRICES = `${MERCHANDISING_ENDPOINT}/sku-retail-prices`;
export const SKU_VOIDS = `${MERCHANDISING_ENDPOINT}/sku-voids`;
export const TAGS_INSTALLED_ENDPOINT = `${MERCHANDISING_ENDPOINT}/tags-installed`;
export const PRODUCTS_ON_PROMO_ENDPOINT = `${MERCHANDISING_ENDPOINT}/products-on-promotion`;
export const TOTAL_FACINGS_ENDPOINT = `${MERCHANDISING_ENDPOINT}/total-facings`;

export const MERHCHANDISING_XLSX = `${MERCHANDISING_ENDPOINT}/xlsx`;
export const MERHCHANDISING_ACCOUNT_IMAGES_REPORT = `${MERCHANDISING_ENDPOINT}/reports/account-images`;

// reports page scorecards
export const SCORECARDS_WEEK = `${API_URL}/scorecard/week`;
export const SCORECARDS_MONTH = `${API_URL}/scorecard/month`;

export const REPORTS_ENDPOINT = `${API_URL}/reports`;
export const REPORTS_FILTERS_ENDPOINT = `${REPORTS_ENDPOINT}/filters`;

// weekly/monthly reports
export const SCORECARDS_WEEK_REPORT = `${REPORTS_ENDPOINT}/scorecard/week`;
export const SCORECARDS_MONTH_REPORT = `${REPORTS_ENDPOINT}/scorecard/month`;

// raw data reports
export const MERCHANDISING_REPORTS = `${MERCHANDISING_ENDPOINT}/reports`;
export const REPORT_ALL_VISITS = `${MERCHANDISING_REPORTS}/all-visits`;
export const REPORT_VOID_REPORTS = `${MERCHANDISING_REPORTS}/voids-report`;

export const ACCOUNTS_REPORSTS = `${ACCOUNTS_ENDPOINT}/reports`;
export const REPORT_ALL_STATUSES_ENDPOINT = `${ACCOUNTS_REPORSTS}/all-statuses`;
export const REPORT_ACTIVE_ACCOUNTS = `${ACCOUNTS_REPORSTS}/active-accounts`;

export const SALES_REPORT = `${SALES_ENDPOINT}/reports/orders`;

// sales reports
const SALES_REPORTS_ENDPOINT = `${REPORTS_ENDPOINT}/sales`;
export const SALES_DISTRIBUTOR_ALL_REPORT = `${SALES_REPORTS_ENDPOINT}/all-by-distributor`;
export const SALES_BY_DISTRIBUTOR_BY_SKU = `${SALES_REPORTS_ENDPOINT}/by-distributor-by-sku`;
export const SALES_BY_BRAND = `${SALES_REPORTS_ENDPOINT}/all-by-account`;
export const SALES_BY_ACCOUNT_BY_SKU = `${SALES_REPORTS_ENDPOINT}/by-account-by-sku`;

// management page
const MANAGEMENT_ENDPOINT = `${API_URL}/management`;
export const MANAGEMENT_FILTERS_ENDPOINT = `${MANAGEMENT_ENDPOINT}/filters`;
export const REPRESENTATIVES_BRAND_WEEKLY = `${MANAGEMENT_ENDPOINT}/one-brand/week`;
export const REPRESENTATIVES_BRAND_MONTHLY = `${MANAGEMENT_ENDPOINT}/one-brand/month`;
export const REPRESENTATIVES_ALL_BRANDS_WEEKLY = `${MANAGEMENT_ENDPOINT}/all-brands/week`;
export const REPRESENTATIVES_ALL_BRANDS_MONTHLY = `${MANAGEMENT_ENDPOINT}/all-brands/month`;

const MANAGEMENT_REPORTS_ENDPOINT = `${MANAGEMENT_ENDPOINT}/reports`;
export const ASM_BRAND_WEEKLY_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/one-brand/week`;
export const ASM_BRAND_MONTHLY_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/one-brand/month`;
export const ASM_ALL_WEEKLY_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/all-brands/week`;
export const ASM_ALL_MONTHLY_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/all-brands/month`;
export const ASM_ALL_EMAIL_WEEKLY_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/all-brands-totals/week`;
export const ASM_ALL_EMAIL_MONTHLY_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/all-brands-totals/month`;
export const DEAD_ACCOUNTS = `${MANAGEMENT_REPORTS_ENDPOINT}/dead-accounts`;
export const DEAD_ACCOUNTS_HOT = `${MANAGEMENT_REPORTS_ENDPOINT}/dead-accounts-hot`;
export const ACTIVE_ACCOUNTS_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/active-accounts`;
export const ACCOUNTS_TOTAL_SALES_REPORT = `${MANAGEMENT_REPORTS_ENDPOINT}/sales-accounts`;

// time&efforts (uds) page
const TIME_EFFORTS_ENDPOINT = `${API_URL}/efforts`;
export const TIME_EFFORTS_FILTERS_ENDPOINT = `${TIME_EFFORTS_ENDPOINT}/filters`;
export const TIME_EFFORTS = `${TIME_EFFORTS_ENDPOINT}/workingstats`;
export const TIME_EFFORTS_WEEK = `${TIME_EFFORTS_ENDPOINT}/workingstats/week`;
export const TIME_EFFORTS_MONTH = `${TIME_EFFORTS_ENDPOINT}/workingstats/month`;
export const ASM_UDS = `${TIME_EFFORTS_ENDPOINT}/uds-stats`;
export const ASM_UDS_BRAND = `${TIME_EFFORTS_ENDPOINT}/uds-stats-by-brand`;

const TIME_EFFORTS_REPORTS_ENDPOINT = `${TIME_EFFORTS_ENDPOINT}/reports`;
export const ASM_UDS_REPORT = `${TIME_EFFORTS_REPORTS_ENDPOINT}/uds-stats`;
export const TIME_EFFORTS_REPORT = `${TIME_EFFORTS_REPORTS_ENDPOINT}/workingstats`;
export const TIME_EFFORTS_REPORT_WEEK = `${TIME_EFFORTS_REPORTS_ENDPOINT}/workingstats/week`;
export const TIME_EFFORTS_REPORT_MONTH = `${TIME_EFFORTS_REPORTS_ENDPOINT}/workingstats/month`;
export const ASM_UDS_REPORT_BRAND = `${TIME_EFFORTS_REPORTS_ENDPOINT}/uds-stats-by-brand`;
export const UDS_ACCOUNTS_REPORT = `${TIME_EFFORTS_REPORTS_ENDPOINT}/new-uds-accounts`;
export const UDS_ACCOUNTS_BY_BRAND_REPORT = `${TIME_EFFORTS_REPORTS_ENDPOINT}/new-uds-accounts-by-brand`;
export const ASM_UDS_ALL_BRAND_REPORT = `${TIME_EFFORTS_REPORTS_ENDPOINT}/uds-stats-by-brand/all`;
export const ASM_UDS_ALL_REP_REPORT = `${TIME_EFFORTS_REPORTS_ENDPOINT}/uds-stats/all`;

// schedule page
const SCHEDULE_ENDPOINT = `${API_URL}/schedules`;
export const SCHEDULE_FILTERS_ENDPOINT = `${SCHEDULE_ENDPOINT}/filters`;
export const SCHEDULE_ASM_ENDPOINT = `${SCHEDULE_ENDPOINT}/list`;
export const SCHEDULE_ASM_DAILY_ENDPOINT = `${SCHEDULE_ENDPOINT}/summary/day`;
export const SCHEDULE_ASM_WEEKLY_ENDPOINT = `${SCHEDULE_ENDPOINT}/summary/week`;
export const SCHEDULE_ASM_MONTHLY_ENDPOINT = `${SCHEDULE_ENDPOINT}/summary/month`;
export const SCHEDULE_SUMMARY_ENDPOINT = `${SCHEDULE_ENDPOINT}/summary/total`;
export const SCHEDULE_DAILY_BARS_ENDPOINT = `${SCHEDULE_ENDPOINT}/count`;
export const SCHEDULE_REPRESENTATIVES_METRICS_ENDPOINT = `${SCHEDULE_ENDPOINT}/representatives`;

const SCHEDULE_REPORTS_ENDPOINT = `${SCHEDULE_ENDPOINT}/reports`;
export const SCHEDULE_DAILY_REPORT = `${SCHEDULE_REPORTS_ENDPOINT}/list/day`;
export const SCHEDULE_WEEKLY_REPORT = `${SCHEDULE_REPORTS_ENDPOINT}/list/week`;
export const SCHEDULE_MONTHLY_REPORT = `${SCHEDULE_REPORTS_ENDPOINT}/list/month`;
export const METRICS_DAILY_REPORT = `${SCHEDULE_REPORTS_ENDPOINT}/summary/day`;
export const METRICS_WEEKLY_REPORT = `${SCHEDULE_REPORTS_ENDPOINT}/summary/week`;
export const METRICS_MONTHLY_REPORT = `${SCHEDULE_REPORTS_ENDPOINT}/summary/month`;

// account database page
const ACCOUNT_DATABASE_ENDPOINT = `${API_URL}/masterlist`;
export const MASTER_LIST_REPORT = `${ACCOUNT_DATABASE_ENDPOINT}/download`;
export const DATABASE_FILTERS_ENDPOINT = `${ACCOUNT_DATABASE_ENDPOINT}/filters`;

// master list report

// chain scorecards
export const SCORECARDS_DOWNLOAD = `${API_URL}/chain-scorecard/download`;
export const SCORECARDS_UPLOAD = `${API_URL}/chain-scorecard/upload`;
export const SCORECARD_DELETE = `${API_URL}/chain-scorecard/delete`;
