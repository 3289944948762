import { connect } from 'react-redux';

import SalesMap from 'salesModule/components/SalesMap';
import { permissionsSelector } from 'salesModule/selectors/salesPageSelectors';

export default connect(
  state => ({
    accounts: state.sales.accounts,
    childrenPermissions: permissionsSelector(state),
  }),
)(SalesMap);
