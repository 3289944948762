import React, { PureComponent } from 'react';
import { bool, func } from 'prop-types';
import { Divider, Button, Skeleton } from 'antd';

import SectionHeading from 'common/components/SectionHeading';

import './index.scss';

const HEADING = 'Master Accounts List';

class MasterAccountsListSection extends PureComponent {
  static propTypes = {
    onDownload: func.isRequired,
    isDownloading: bool.isRequired,
    isFilterOptionsFetching: bool.isRequired,
  };

  render() {
    const {
      onDownload,
      isDownloading,
      isFilterOptionsFetching,
    } = this.props;

    return (
      <Skeleton loading={isFilterOptionsFetching}>
        <SectionHeading>{HEADING}</SectionHeading>
        <div className="master-accounts-list-container">
          <div className="master-accounts-list-container__buttons">
            <Button
              type="primary"
              icon="file"
              onClick={onDownload}
              loading={isDownloading}
            >
              Master Accounts List
            </Button>
          </div>
          <Divider />
        </div>
      </Skeleton>
    );
  }
}

export default MasterAccountsListSection;
