class TotalSale {
  constructor({ dateInterval, CMG }) {
    this.dateInterval = dateInterval;
    this.CMG = CMG;
  }

  static parse(dateInterval, obj) {
    return new TotalSale({
      dateInterval,
      CMG: Math.round(obj.TotalAmount.repsly) || 0,
    });
  }

  static getTotalSalesKeys() {
    return ['CMG'];
  }
}

export default TotalSale;
