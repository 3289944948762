import React from 'react';
import moment from 'moment';

import { YEAR_FORMAT } from 'common/constants/dateConstants';

import './index.scss';

const currentYear = moment().format(YEAR_FORMAT);

const Footer = () => (
  <footer className="footer">
    <p className="footer__info">
            Critical Mass Group LLC &#169;
      {' '}
      {currentYear}
    </p>
  </footer>
);

export default Footer;
