/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { colorMapper } from 'common/utilities/colorMapper';

import { visitEventsColorsHexMap } from 'scheduleModule/constants/visitColors';


const asmScheduleDailyBarsSelector = state => state.schedule.asmScheduleDailyBars;

export const asmVisitsAndColorsSelector = createSelector(
  asmScheduleDailyBarsSelector,
  (asmScheduleDailyBars) => {
    const currentVisits = asmScheduleDailyBars.length > 0 ? asmScheduleDailyBars[0].getVisitNames() : [];
    const currentVisitsColorMap = colorMapper(currentVisits, visitEventsColorsHexMap);

    return {
      visits: currentVisits,
      visitsColorMap: currentVisitsColorMap,
    };
  },
);


export const shouldRenderAsmScheduleDailyBarsChartSelector = createSelector(
  asmScheduleDailyBarsSelector,
  asmScheduleDailyBars => asmScheduleDailyBars && asmScheduleDailyBars.length > 0,
);
