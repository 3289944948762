class DateInterval {
  constructor({ start, end, interval }) {
    this.start = start;
    this.end = end;
    this.interval = interval;
  }

  static parse(obj, interval) {
    return new DateInterval({
      start: obj.DateStart,
      end: obj.DateEnd,
      interval,
    });
  }

  stringify() {
    return JSON.stringify(this);
  }
}

export default DateInterval;
