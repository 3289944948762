/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const sortedFilterOptionStatusesSelector = state => state.accounts.filterOptions.statuses.sort(
  (statusFirst, statusNext) => statusFirst.sortOrder - statusNext.sortOrder,
);

const accountStatusesSelector = state => state.accounts.accountStatuses;

const accountsSelector = state => state.accounts.accounts;

export const disabledStatusesSelector = createSelector(
  [sortedFilterOptionStatusesSelector, accountStatusesSelector],
  (filterOptionStatuses, accountStatuses) => {
    const currentStatuses = filterOptionStatuses.filter(
      filterOptionStatus => accountStatuses.includes(filterOptionStatus.name),
    );
    return currentStatuses
      .filter(status => status.disabledOnMap)
      .map(status => status.name);
  },
);

export const colorMapSelector = createSelector(
  [sortedFilterOptionStatusesSelector, accountStatusesSelector],
  (filterOptionStatuses, accountStatuses) => {
    const currentStatuses = filterOptionStatuses.filter(
      filterOptionStatus => accountStatuses.includes(filterOptionStatus.name),
    );
    return new Map(currentStatuses.reduce(
      (statusColorMap, filterOptionStatus) => {
        statusColorMap.push([
          filterOptionStatus.name,
          filterOptionStatus.color,
        ]);
        return statusColorMap;
      },
      [],
    ));
  },
);

export const shouldRenderMapSelector = createSelector(
  accountsSelector,
  accounts => accounts && accounts.length > 0,
);
