import React from 'react';
import { NavLink } from 'react-router-dom';
import { string, node, func } from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const NavigationLink = ({
  to, children, className, activeClassName, onClick,
}) => (
  <NavLink
    className={classnames(className, 'navigation-link')}
    activeClassName={classnames(activeClassName, 'navigation-link--active')}
    to={to}
  >
    <div className="navigation-link__inner" role="button" tabIndex={0} onClick={onClick} onKeyPress={onClick}>{children}</div>
  </NavLink>
);

NavigationLink.propTypes = {
  to: string.isRequired,
  children: node.isRequired,
  className: string,
  activeClassName: string,
  onClick: func,
};

NavigationLink.defaultProps = {
  className: null,
  activeClassName: null,
  onClick: () => {},
};

export default NavigationLink;
