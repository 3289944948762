import React, { Fragment } from 'react';
import { instanceOf, arrayOf, string } from 'prop-types';

import PieChart from 'common/components/PieChart';
import SectionHeading from 'common/components/SectionHeading';

import TagsOnShelf from 'merchandisingModule/models/TagsOnShelf';

const HEADING = 'Tags on Shelf';

const TagsInstalledChart = ({ tagsInstalledData, colorMap, disabledKeys }) => {
  const pieChartData = new Array(tagsInstalledData.length)
    .fill(null)
    .map((_, i) => ({
      id: tagsInstalledData[i].value,
      label: tagsInstalledData[i].value,
      value: tagsInstalledData[i].amount,
      percentage: tagsInstalledData[i].percentage,
    }));

  const labelFormatter = label => `${label.value} (${label.percentage}%)`;
  const shouldRenderChart = tagsInstalledData.length > 0 && pieChartData[0].id;

  return shouldRenderChart && (
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <PieChart
        data={pieChartData.filter(
          pieData => !disabledKeys.includes(pieData.id),
        )}
        colorMap={colorMap}
        labelFormatter={labelFormatter}
      />
    </Fragment>
  );
};

TagsInstalledChart.propTypes = {
  tagsInstalledData: arrayOf(instanceOf(TagsOnShelf)).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
};

export default TagsInstalledChart;
