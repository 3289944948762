class TotalCase {
  constructor({ dateInterval, CMG }) {
    this.dateInterval = dateInterval;
    this.CMG = CMG;
  }

  static parse(dateInterval, obj) {
    return new TotalCase({
      dateInterval,
      CMG: Math.round(obj.TotalCases.repsly) || 0,
    });
  }

  static getTotalCasesKeys() {
    return ['CMG'];
  }
}

export default TotalCase;
