import React, { Fragment } from 'react';
import { instanceOf, arrayOf, string } from 'prop-types';

import PieChart from 'common/components/PieChart';
import SectionHeading from 'common/components/SectionHeading';

import PrimaryPlacement from 'merchandisingModule/models/PrimaryPlacement';

const HEADING = 'Primary Placement';

const PrimaryPlacementChart = ({
  primaryPlacementData,
  colorMap,
  disabledKeys,
}) => {
  const pieChartData = new Array(primaryPlacementData.length)
    .fill(null)
    .map((_, i) => ({
      id: primaryPlacementData[i].placement,
      label: primaryPlacementData[i].placement,
      value: primaryPlacementData[i].amount,
      percentage: primaryPlacementData[i].percentage,
    }));

  const labelFormatter = label => `${label.value} (${label.percentage}%)`;

  const shouldRenderChart = primaryPlacementData.length > 0 && pieChartData[0].id;

  return shouldRenderChart && (
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <PieChart
        data={pieChartData.filter(
          pieData => !disabledKeys.includes(pieData.id),
        )}
        colorMap={colorMap}
        labelFormatter={labelFormatter}
      />
    </Fragment>
  );
};

PrimaryPlacementChart.propTypes = {
  primaryPlacementData: arrayOf(instanceOf(PrimaryPlacement)).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
};

export default PrimaryPlacementChart;
