/* eslint-disable no-return-assign, no-param-reassign, no-nested-ternary, react/display-name */

import React, { Fragment, PureComponent } from 'react';
import {
  arrayOf, func, instanceOf, string,
} from 'prop-types';
import {
  Divider, Icon, Modal, Skeleton, Switch, Table,
} from 'antd';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';

import SectionHeading from 'common/components/SectionHeading';


import Brand from 'adminModule/models/Brand';

import { adminPagePermissionTypes } from 'userModule/constants/permissions';
import './index.scss';
import DeleteBrandForm from 'adminModule/components/DeleteBrandForm';

const HEADING = 'Brands';

const emptyFunction = () => {
};

const columns = [
  {
    title: 'Brand',
    dataIndex: 'brand',
    align: 'center',
    key: 'brand',
    sorter: (a, b) => b.brand.localeCompare(a.brand),
    defaultSortOrder: 'descend',
    render: text => <div className="brands-list__cell_name" title={text}>{text}</div>,
    className: 'brands-list__cell brands-list__cell_fixed',
  },
];

class BrandsList extends PureComponent {
  static propTypes = {
    brands: arrayOf(instanceOf(Brand)).isRequired,
    archiveBrand: func.isRequired,
    deleteBrand: func.isRequired,
    childrenPermissions: arrayOf(string).isRequired,
  };

  state = {
    isDeleteModalVisible: false,

    selectedBrand: null,
    additionalTableProps: {},
    iconSize: '16px',
  };

  componentDidMount() {
    const { getBrands } = this.props;
    getBrands();
    this.updateTableWidth();
    window.addEventListener('resize', this.updateTableWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTableWidth);
  }

  updateTableWidth = () => {
    if (window.innerWidth > 992) {
      this.setState({
        additionalTableProps: {},
        iconSize: '16px',
      });
    }
    if (window.innerWidth <= 992 && window.innerWidth > 640) {
      this.setState({
        additionalTableProps: {
          scroll: {
            x: 1100,
          },
        },
        iconSize: '14px',
      });
    }
    if (window.innerWidth <= 640) {
      this.setState({
        additionalTableProps: {
          scroll: {
            x: 1000,
          },
        },
      });
    }
  }

  showDeleteModal = (brand) => {
    this.setState({
      selectedBrand: brand,
      isDeleteModalVisible: true,
    });
  };

  closeModal = (isModalOpen) => {
    this.setState({
      [isModalOpen]: false,
    });
  }

  onDeleteBrand = (values) => {
    const { deleteBrand } = this.props;
    this.closeModal('isDeleteModalVisible');

    deleteBrand(values);
  };

  getArchiveColumn = archiveBrand => ({
    title: 'Active',
    align: 'center',
    key: 'isActive',
    className: 'brands-list__cell',
    render: brand => (
      <Switch
        defaultChecked={brand.isActive}
        checked={brand.isActive}
        onChange={
          () => archiveBrand(brand)
        }
      />
    ),
  });

  getColumn = (title, iconType, shouldRenderIcon, onIconClick) => {
    const { iconSize } = this.state;

    return {
      title,
      className: 'brands-list__cell brands-list__cell_action',
      align: 'center',
      key: uuidv4(),
      width: 80,
      render: brand => (
        <Icon
          className={classNames('brands-list__icon', {
            'brands-list__icon_disabled': !shouldRenderIcon,
          })}
          style={{ fontSize: iconSize }}
          type={iconType}
          onClick={
            shouldRenderIcon
              ? () => onIconClick(brand)
              : emptyFunction
          }
        />
      ),
    };
  };

  render() {
    const {
      brands,
      isBrandsFetching,
      childrenPermissions,
      // deleteBrand,
      archiveBrand,
    } = this.props;

    const {
      additionalTableProps,
      isDeleteModalVisible,
      selectedBrand,
    } = this.state;

    const shouldRenderDeleteIcon = childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_BRANDS_DELETE);
    const shouldRenderArchiveSwitch = childrenPermissions.includes(adminPagePermissionTypes.ADMIN_PAGE_BRANDS_ARCHIVE);

    let tableColumns = [...columns];
    if (shouldRenderArchiveSwitch) {
      tableColumns = [
        ...columns,
        this.getArchiveColumn(archiveBrand),
      ];
    }

    if (shouldRenderDeleteIcon) {
      tableColumns = [
        ...tableColumns,
        this.getColumn('Delete Brand', 'delete', shouldRenderDeleteIcon, this.showDeleteModal),
      ];
    }

    return (
      <Skeleton loading={isBrandsFetching} paragraph={{ rows: 4 }}>

        <Fragment>
          <SectionHeading>{HEADING}</SectionHeading>
          <div className="brands-list">
            <Table
              dataSource={brands}
              columns={tableColumns}
              pagination={false}
              rowKey={brand => brand.brand}
              bordered
              {...additionalTableProps}
            />
            <Divider />
          </div>
          <Modal
            title="Confirm Delete"
            visible={isDeleteModalVisible}
            onCancel={() => this.closeModal('isDeleteModalVisible')}
            destroyOnClose
            centered
            width={570}
            footer={null}
          >
            <DeleteBrandForm
              onDeleteBrand={this.onDeleteBrand}
              brand={selectedBrand}
            />
          </Modal>
        </Fragment>
      </Skeleton>
    );
  }
}

export default BrandsList;
