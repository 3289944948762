/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { colorGenerator } from 'common/utilities/colorGenerator';


const totalSalesByDistributorsSelector = state => state.sales.totalSalesByDistributorsData;
const totalCasesByDistributorsSelector = state => state.sales.totalCasesByDistributorsData;

export const distributorsAndColorsSalesSelector = createSelector(
  totalSalesByDistributorsSelector,
  (totalSalesByDistributors) => {
    const currentDistributorsNames = totalSalesByDistributors.length > 0 ? totalSalesByDistributors[0].getDistributorsNames() : [];
    const currentDistributorsNamesColorMap = colorGenerator(currentDistributorsNames);

    return {
      distributors: currentDistributorsNames,
      distributorsColorMap: currentDistributorsNamesColorMap,
    };
  },
);

export const distributorsAndColorsCasesSelector = createSelector(
  totalCasesByDistributorsSelector,
  (totalCasesByDistributors) => {
    const currentDistributorsNames = totalCasesByDistributors.length > 0 ? totalCasesByDistributors[0].getDistributorsNames() : [];
    const currentDistributorsNamesColorMap = colorGenerator(currentDistributorsNames);

    return {
      distributors: currentDistributorsNames,
      distributorsColorMap: currentDistributorsNamesColorMap,
    };
  },
);

export const shouldRenderSalesChartSelector = createSelector(
  totalSalesByDistributorsSelector,
  totalSalesByDistributors => totalSalesByDistributors && totalSalesByDistributors.length > 0,
);

export const shouldRenderCasesChartSelector = createSelector(
  totalCasesByDistributorsSelector,
  totalCasesByDistributors => totalCasesByDistributors && totalCasesByDistributors.length > 0,
);
