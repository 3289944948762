/* eslint-disable react/display-name */
import React, { Fragment, PureComponent } from 'react';
import {
  arrayOf, instanceOf, func, string,
} from 'prop-types';
import uuidv4 from 'uuid/v4';
import moment from 'moment';
import classnames from 'classnames';

import {
  Divider, Tag, Tooltip, Button, Icon, Table,
} from 'antd';

import ScheduleData from 'scheduleModule/models/ScheduleData';

import SectionHeading from 'common/components/SectionHeading';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import './index.scss';

const HEADING = 'ASM Schedule';
const ButtonGroup = Button.Group;

const dateFormatter = date => (
  <Fragment>
    <p className="schedule-table__head-cell-row">
      {moment(date)
        .locale('en-us')
        .format('dddd')}
    </p>
    <p className="schedule-table__head-cell-row">
      {moment(date).format(DATE_FORMAT)}
    </p>
  </Fragment>
);

class AsmScheduleTable extends PureComponent {
  static propTypes = {
    asmScheduleData: arrayOf(instanceOf(ScheduleData)).isRequired,
    visitEventsColorsMap: instanceOf(Map).isRequired,
    onEventSelect: func.isRequired,
    selectedEvents: arrayOf(string).isRequired,
    goPrevWeek: func.isRequired,
    goNextWeek: func.isRequired,
  };

  state = {
    repColumnWidth: 180,
    tableWidth: 1800,
  }

  componentDidMount() {
    this.updateColumns();
    window.addEventListener('resize', this.updateColumns);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateColumns);
  }

  updateColumns = () => {
    if (window.innerWidth > 768) {
      return this.setState({
        repColumnWidth: 180,
        tableWidth: 1800,
      });
    }

    if (window.innerWidth <= 768 && window.innerWidth > 480) {
      return this.setState({
        repColumnWidth: 160,
        tableWidth: 1500,
      });
    }
    return this.setState({
      repColumnWidth: 160,
      tableWidth: 1500,
    });
  }

  render() {
    const {
      asmScheduleData,
      visitEventsColorsMap,
      goPrevWeek,
      goNextWeek,
      onEventSelect,
      selectedEvents,
    } = this.props;

    const { repColumnWidth, tableWidth } = this.state;

    const shouldRenderTable = asmScheduleData.length > 0;
    if (!shouldRenderTable) {
      return null;
    }

    const eventsLegend = [...visitEventsColorsMap.keys()].map(status => (
      <Tag
        key={uuidv4()}
        color={visitEventsColorsMap.get(status)}
        onClick={() => onEventSelect(status)}
        className={classnames({ 'schedule-table-legend__item_off': selectedEvents.includes(status) })}
      >
        {status}
      </Tag>
    ));

    const filters = asmScheduleData ?.map(({ name }) => ({
      text: name,
      value: name,
    }));

    const columns = [{
      title: 'ASM',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: repColumnWidth,
      className:
        'schedule-table__cell schedule-table__cell_name',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
    }];

    asmScheduleData[0].data.forEach((asmData) => {
      const newColumn = {
        title: dateFormatter(asmData.visitDate),
        dataIndex: asmData.visitDate,
        key: asmData.visitDate,
        className:
          'schedule-table__cell schedule-table__cell_tags',
        render: scheduleAccounts => (
          <div className="schedule-table__cells-inner">
            {scheduleAccounts.map(scheduleAccount => (
              <Tooltip
                key={uuidv4()}
                placement="bottom"
                title={() => (
                  <Fragment>
                    <p>{scheduleAccount.name}</p>
                    <p>{scheduleAccount.address}</p>
                  </Fragment>
                )}
              >
                <Tag key={uuidv4()} color={visitEventsColorsMap.get(scheduleAccount.status)}>
                  {scheduleAccount.name}
                </Tag>
              </Tooltip>
            ))}

          </div>
        ),
      };
      columns.push(newColumn);
    });

    const dataSource = asmScheduleData.map(({ name, data }) => ({
      name,
      ...data.reduce((acc, visitsOnDate) => {
        acc[visitsOnDate.visitDate] = visitsOnDate.visits;
        return acc;
      }, {}),
    }));

    return (
      shouldRenderTable && (
        <Fragment>
          <SectionHeading>{HEADING}</SectionHeading>
          <div className="schedule-table-nav">
            <div className="schedule-table-legend">
              <p className="schedule-table-legend__title">Scheduled Visit Statuses: </p>
              {eventsLegend}
            </div>
            <div className="schedule-table-paging">
              <ButtonGroup>
                <Button type="default" onClick={goPrevWeek}>
                  <Icon type="left" />
                  Prev Week
                </Button>
                <Button type="default" onClick={goNextWeek}>
                  Next Week
                  <Icon type="right" />
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <Table className="schedule-table" dataSource={dataSource} columns={columns} pagination={false} bordered scroll={{ x: tableWidth/* , y: 600 */ }} />
          <Divider />
        </Fragment>
      )
    );
  }
}

export default AsmScheduleTable;
