import Sku from './Sku';
import Distributor from './Distributor';
import Brand from './Brand';
import BrandScorecard from './BrandScorecard';

class FilterOptions {
  constructor({
    brands,
    channelTypes,
    cities,
    states,
    chains,
    representatives,
    representativesMetric,
    territories,
    accountsSources,
    distributors,
    salesSkus,
    merchandisingSkus,
    regions,
    counties,
    banners,
    frequencies,
    scorecardsByBrand,
  }) {
    this.brands = brands;
    this.channelTypes = channelTypes;
    this.cities = cities;
    this.states = states;
    this.chains = chains;
    this.representatives = representatives;
    this.representativesMetric = representativesMetric;
    this.territories = territories;
    this.accountsSources = accountsSources;
    this.distributors = distributors;
    this.salesSkus = salesSkus;
    this.merchandisingSkus = merchandisingSkus;
    this.regions = regions;
    this.counties = counties;
    this.banners = banners;
    this.frequencies = frequencies;
    this.scorecardsByBrand = scorecardsByBrand;
  }

  static parse(obj) {
    return new FilterOptions({
      brands: obj.Brands.map(Brand.parse),
      channelTypes: obj.Channels,
      cities: obj.Cities,
      states: obj.States,
      chains: obj.Chains,
      representatives: obj.Representatives,
      representativesMetric: obj.MetricRepresentatives,
      territories: obj.Territories,
      accountsSources: obj.AccountSources,
      distributors: obj.Distributors.map(Distributor.parse),
      salesSkus: obj.SalesSKUs.map(Sku.parse),
      merchandisingSkus: obj.MerchSKUs.map(Sku.parse),
      regions: obj.Regions,
      counties: obj.Counties,
      banners: obj.Banners,
      frequencies: obj.Frequencies.map(String),
      scorecardsByBrand: obj.ScorecardFilenames.reduce((acc, brandScorecardsObj) => {
        acc[brandScorecardsObj.Brand] = brandScorecardsObj.ScorecardsInfo.map(BrandScorecard.parse);
        return acc;
      }, {}),
    });
  }
}

export default FilterOptions;
