import React, { Fragment } from 'react';
import {
  arrayOf, instanceOf, bool,
} from 'prop-types';
import { Divider } from 'antd';

import SectionHeading from 'common/components/SectionHeading';

import TotalFacing from 'merchandisingModule/models/TotalFacing';
import TimeLineChart from 'common/components/TimeLineChart';

const HEADING = 'Total Facings';

const TotalFacings = ({ totalFacingsData, colorMap, shouldRenderChart }) => {
  const lines = TotalFacing.fieldNames;

  const lineChartData = lines.map(line => ({
    id: line,
    color: colorMap.get(line),
    data: totalFacingsData.map(totalFacing => ({
      x: totalFacing.date,
      y: totalFacing[line],
    })),
  }));

  return (
    shouldRenderChart && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <TimeLineChart
          data={lineChartData}
          colorMap={colorMap}
        />
        <Divider />
      </Fragment>
    )
  );
};

/* eslint-disable react/no-unused-prop-types */
TotalFacings.propTypes = {
  totalFacingsData: arrayOf(instanceOf(TotalFacing)).isRequired,
  colorMap: instanceOf(Map).isRequired,
  shouldRenderChart: bool.isRequired,
};

export default TotalFacings;
