import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentShelfPlacementNamesSelector,
} from 'merchandisingModule/selectors/shelfPlacementSelectors';

import ShelfPlacementChart from 'merchandisingModule/components/ShelfPlacementChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  shelfPlacementNames: currentShelfPlacementNamesSelector(state),
  shelfPlacementData: state.merchandising.shelfPlacementData,
}))(
  withLegend({
    keys: 'shelfPlacementNames',
    isLegendActive: false,
  })(ShelfPlacementChart),
);
