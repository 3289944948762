/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';

import BarChart from 'common/components/BarChart';
import SectionHeading from 'common/components/SectionHeading';

import withLegend from 'common/hocs/withLegend';

import AsmScheduleDailyBar from 'scheduleModule/models/AsmScheduleDailyBar';

import { bottomTicksFormatter } from 'scheduleModule/tools/bottomTicksFormatter';

const HEADING = 'ASM Daily Visits';

const AsmScheduleDailyBars = ({
  asmScheduleDailyBars,
  visits,
  colorMap,
  disabledKeys,
  shouldRenderChart,
}) => {
  if (!shouldRenderChart) {
    return null;
  }

  const asmScheduleDailyBarsData = asmScheduleDailyBars.map(asmScheduleDailyBar => asmScheduleDailyBar.getVisitData());

  const label = ({ id, indexValue }) => {
    const visitsDataBar = asmScheduleDailyBars
      .find(asmScheduleDailyBarsItem => asmScheduleDailyBarsItem.date === indexValue);
    const visitInfo = visitsDataBar ? visitsDataBar.visitsData.find(visitsDataItem => visitsDataItem.visitName === id) : {};
    return `${visitInfo.value}${visitInfo.percentage ? ` (${visitInfo.percentage}%)` : ''}`;
  };

  return (
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <BarChart
        data={asmScheduleDailyBarsData.map(asmScheduleDailyBar => Object.keys(asmScheduleDailyBar)
          .filter(asmScheduleDailyBarKey => !disabledKeys.includes(asmScheduleDailyBarKey))
          .reduce((obj, asmScheduleDailyBarKey) => {
            obj[asmScheduleDailyBarKey] = asmScheduleDailyBar[asmScheduleDailyBarKey];
            return obj;
          }, {}))}
        keys={visits.filter(visit => !disabledKeys.includes(visit))}
        indexes="date"
        colorMap={colorMap}
        type="stacked"
        bottomTicksFormatter={bottomTicksFormatter}
        label={label}
      />
    </Fragment>
  );
};

AsmScheduleDailyBars.propTypes = {
  asmScheduleDailyBars: arrayOf(instanceOf(AsmScheduleDailyBar)).isRequired,
  visits: arrayOf(string).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
  shouldRenderChart: bool.isRequired,
};

export default withLegend({ keys: 'visits' })(AsmScheduleDailyBars);
