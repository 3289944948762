/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';

import BarChart from 'common/components/BarChart';
import SectionHeading from 'common/components/SectionHeading';
import withLegend from 'common/hocs/withLegend';

import { transformDataForTooltip } from 'salesModule/tools/tooltipFormatter';
import { bottomTicksFormatter } from 'salesModule/tools/bottomTicksFormatter';

import TotalCaseByDistributors from 'salesModule/models/TotalCaseByDistributors';

const HEADING = 'Cases By Distributor';

const TotalCasesByDistributorsChart = ({
  totalCasesByDistributors,
  distributors,
  colorMap,
  disabledKeys,
  shouldRenderChart,
}) => {
  const distributorsData = totalCasesByDistributors.map(
    totalCaseByDistributor => totalCaseByDistributor.getDistributorsData(),
  );
  return (
    shouldRenderChart
    && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <BarChart
          data={distributorsData.map(skuData => Object.keys(skuData)
            .filter(skuDataKey => !disabledKeys.includes(skuDataKey))
            .reduce((obj, skuDataKey) => {
              obj[skuDataKey] = skuData[skuDataKey];
              return obj;
            }, {}))}
          keys={distributors.filter(
            distributorName => !disabledKeys.includes(distributorName),
          )}
          indexes="dateInterval"
          colorMap={colorMap}
          bottomTicksFormatter={bottomTicksFormatter}
          transformDataForTooltip={transformDataForTooltip}
        />
      </Fragment>
    )
  );
};

TotalCasesByDistributorsChart.propTypes = {
  totalCasesByDistributors: arrayOf(instanceOf(TotalCaseByDistributors))
    .isRequired,
  distributors: arrayOf(string).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
  shouldRenderChart: bool.isRequired,
};

export default withLegend({ keys: 'distributors' })(TotalCasesByDistributorsChart);
