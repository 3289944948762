import { connect } from 'react-redux';

import { permissionsSelector } from 'accountsModule/selectors/accountsPageSelectors';

import { shouldRenderChartSelector as shouldRenderAccountStatusChart } from 'accountsModule/selectors/totalAccountsChartSelectors';
import { shouldRenderChartSelector as shouldRenderDistributorsChart } from 'accountsModule/selectors/accountsByDistributorOnDateSelectors';
import {
  shouldRenderAccountsByChannelTypeChartSelector as shouldRenderAccountsByChannelTypeChart,
  shouldRenderAuthAccountsByChannelTypeChartSelector as shouldRenderAuthAccountsByChannelTypeChart,
} from 'accountsModule/selectors/accountsByChannelTypeChartSelectors';

import MetricsOnDateCharts from '../components/MetricsOnDateCharts';


export default connect(
  state => ({
    accountsFilter: state.accounts.filter,
    fetchingFilterOptions:
      state.loading.effects.accounts.fetchFilterOptions,
    childrenPermissions: permissionsSelector(state),
    shouldRenderAccountStatusChart: shouldRenderAccountStatusChart(state),
    shouldRenderDistributorsChart: shouldRenderDistributorsChart(state),
    shouldRenderAccountsByChannelTypeChart: shouldRenderAccountsByChannelTypeChart(state),
    shouldRenderAuthAccountsByChannelTypeChart: shouldRenderAuthAccountsByChannelTypeChart(state),
  }),
)(MetricsOnDateCharts);
