import React, { memo } from 'react';
import { instanceOf } from 'prop-types';

import {
  ACCOUNTS,
  MERCHANDISING,
  SALES,
  REPORTS,
  MANAGEMENT,
  UDS,
  SCHEDULE,
  DATABASE,
} from 'common/constants/routes';

import Permissions from 'userModule/models/Permissions';

import {
  accountsPagePermissionTypes,
  merchandisingPagePermissionTypes,
  salesPagePermissionTypes,
  reportsPagePermissionTypes,
  managementPagePermissionTypes,
  timeEffortsPagePermissionTypes,
  schedulePagePermissionTypes,
  databasePagePermissionTypes,
} from 'userModule/constants/permissions';

import NavigationLink from './NavigationLink';

import './index.scss';

const Navigation = ({ permissions }) => permissions && (
  <nav className="navigation">
    {permissions[accountsPagePermissionTypes.ACCOUNTS_PAGE].Permissions && (
      <div className="navigation__item">
        <NavigationLink to={ACCOUNTS}>Accounts</NavigationLink>
      </div>
    )}
    {permissions[merchandisingPagePermissionTypes.MERCHANDISING_PAGE].Permissions && (
      <div className="navigation__item">
        <NavigationLink to={MERCHANDISING}>Merchandising</NavigationLink>
      </div>
    )}
    {permissions[salesPagePermissionTypes.SALES_PAGE].Permissions && (
      <div className="navigation__item">
        <NavigationLink to={SALES}>Sales</NavigationLink>
      </div>
    )}
    {permissions[reportsPagePermissionTypes.REPORTS_PAGE].Permissions && (
      <div className="navigation__item">
        <NavigationLink to={REPORTS}>Reports</NavigationLink>
      </div>
    )}
    {permissions[managementPagePermissionTypes.MANAGEMENT_PAGE].Permissions && (
      <div className="navigation__item navigation__item_admin">
        <NavigationLink to={MANAGEMENT}>Management</NavigationLink>
      </div>
    )}
    {permissions[timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE].Permissions && (
      <div className="navigation__item navigation__item_admin">
        <NavigationLink to={UDS}>Time &#38; Efforts</NavigationLink>
      </div>
    )}
    {permissions[schedulePagePermissionTypes.SCHEDULE_PAGE].Permissions && (
      <div className="navigation__item navigation__item_admin">
        <NavigationLink to={SCHEDULE}>Schedule</NavigationLink>
      </div>
    )}
    {permissions[databasePagePermissionTypes.DATABASE_PAGE].Permissions && (
      <div className="navigation__item navigation__item_admin">
        <NavigationLink to={DATABASE}>Database</NavigationLink>
      </div>
    )}
  </nav>
);

Navigation.propTypes = {
  permissions: instanceOf(Permissions),
};

Navigation.defaultProps = {
  permissions: new Permissions({
    accounts: {
      Permissions: [],
    },
    merchandising: {
      Permissions: [],
    },
    sales: {
      Permissions: [],
    },
    reports: {
      Permissions: [],
    },
    management: {
      Permissions: [],
    },
    timeEfforts: {
      Permissions: [],
    },
    schedule: {
      Permissions: [],
    },
    database: {
      Permissions: [],
    },
    admin: {
      Permissions: [],
    },
  }),
};

export default memo(Navigation);
