import React, { PureComponent } from 'react';
import {
  instanceOf, func, bool, arrayOf, string,
} from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { Skeleton } from 'antd';

import withNavigation from 'common/hocs/withNavigation';

import FilterOptions from 'databaseModule/models/FilterOptions';
import AccountsFilterModel from 'databaseModule/models/AccountsFilter';

import AccountsFilter from 'databaseModule/components/AccountsFilter';
import MasterAccountsListSection from 'databaseModule/containers/MasterAccountsSectionContainer';

import { databasePagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class ManagementPage extends PureComponent {
    static propTypes = {
      filterOptions: instanceOf(FilterOptions).isRequired,
      filter: instanceOf(AccountsFilterModel).isRequired,
      fetchingFilterOptions: bool.isRequired,
      isFilterInitialized: bool.isRequired,
      location: RouterPropTypes.location.isRequired,
      history: RouterPropTypes.history.isRequired,
      setFilter: func.isRequired,
      fetchFilterOptions: func.isRequired,
      resetDatabaseStore: func.isRequired,
      childrenPermissions: arrayOf(string).isRequired,
      filterPermissions: arrayOf(string).isRequired,
    };

    componentDidMount() {
      const { fetchFilterOptions } = this.props;
      fetchFilterOptions();
    }

    componentDidUpdate(prevProps) {
      const { fetchingFilterOptions, filterOptions, location } = this.props;

      if (prevProps.fetchingFilterOptions && !fetchingFilterOptions) {
        const defaultAccountsSource = filterOptions.accountsSources[0];
        const defaultActiveAccounts = filterOptions.activeAccounts[0];

        AccountsFilterModel.setDefaultParams({
          defaultAccountsSource,
          defaultActiveAccounts,
        });
        const filterModel = AccountsFilterModel.parseQueryString(
          location.search,
        );

        this.setAccountFilters(filterModel);
      }
    }

    componentWillUnmount() {
      const { resetDatabaseStore } = this.props;
      resetDatabaseStore();
    }

    setAccountFilters = (filterModel) => {
      const {
        setFilter,
        location,
        history,
      } = this.props;
      const ignore = [];

      const modelQueryString = filterModel.getQueryStringFilter(ignore);

      setFilter(filterModel);
      history.push({
        pathname: location.pathname,
        search: modelQueryString,
        hash: location.hash,
      });
    };

    render() {
      const {
        filter,
        filterOptions,
        isFilterInitialized,
        childrenPermissions,
        filterPermissions,
      } = this.props;

      return (
        <div className="database-page">
          <Skeleton
            loading={!isFilterInitialized}
            className="database-page__skeleton"
          >
            {filterPermissions.length > 0 && (
              <AccountsFilter
                isAdmin
                applyFilter={this.setAccountFilters}
                initialFilterModel={filter}
                filterOptions={filterOptions}
                permissions={filterPermissions}
              />
            )}
          </Skeleton>
          <div className="database-page__inner">
            {childrenPermissions.includes(databasePagePermissionTypes.DATABASE_PAGE_MASTER_ACCOUNTS_LIST) && <MasterAccountsListSection />}
          </div>
        </div>
      );
    }
}

export default withNavigation()(ManagementPage);
