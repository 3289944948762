import React, { Fragment } from 'react';
import { Divider, Button, Skeleton } from 'antd';

import SectionHeading from 'common/components/SectionHeading';
import {
  bool, func, arrayOf, string,
} from 'prop-types';
import { schedulePagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

const HEADING = 'Schedule Metric Reports';

const SummaryReportsSection = ({
  isMetricsDailyReportDownloading,
  onDownloadMetricsDailyReport,
  isMetricsWeeklyReportDownloading,
  onDownloadMetricsWeeklyReport,
  isMetricsMonthlyReportDownloading,
  onDownloadMetricsMonthlyReport,
  isFilterOptionsFetching,
  childrenPermissions,
}) => (
  <Skeleton loading={isFilterOptionsFetching}>
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <section className="schedule-summary-reports-section">
        {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_METRICS_DAILY_REPORT) && (
          <div className="schedule-summary-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadMetricsDailyReport}
              loading={isMetricsDailyReportDownloading}
            >
              Schedule Metrics - Daily
            </Button>
          </div>
        )}
        {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_METRICS_WEEKLY_REPORT) && (
          <div className="schedule-summary-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadMetricsWeeklyReport}
              loading={isMetricsWeeklyReportDownloading}
            >
              Schedule Metrics - Weekly
            </Button>
          </div>
        )}
        {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_METRICS_MONTHLY_REPORT) && (
          <div className="schedule-summary-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadMetricsMonthlyReport}
              loading={isMetricsMonthlyReportDownloading}
            >
              Schedule Metrics - Monthly
            </Button>
          </div>
        )}
      </section>
      <Divider />
    </Fragment>
  </Skeleton>
);

SummaryReportsSection.propTypes = {
  isMetricsDailyReportDownloading: bool.isRequired,
  onDownloadMetricsDailyReport: func.isRequired,
  isMetricsWeeklyReportDownloading: bool.isRequired,
  onDownloadMetricsWeeklyReport: func.isRequired,
  isMetricsMonthlyReportDownloading: bool.isRequired,
  onDownloadMetricsMonthlyReport: func.isRequired,
  isFilterOptionsFetching: bool.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default SummaryReportsSection;
