import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentChannelTypesSelector,
} from 'accountsModule/selectors/accountsByChannelTypeChartSelectors';

import AccountsByChannelTypeChart from 'accountsModule/components/AccountsByChannelTypeChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  channelTypes: currentChannelTypesSelector(state),
  accountsByChannelType: state.accounts.accountsByChannelType,
  heading: 'Accounts by Channel - All Accounts',
}))(
  withLegend({
    keys: 'channelTypes',
    isLegendActive: false,
  })(AccountsByChannelTypeChart),
);
