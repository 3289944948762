class Account {
  constructor({
    id,
    address,
    latitude,
    longitude,
    name,
    lastDate,
    status,
    totalVisits,
    storePictureUrl,
    coolerPictureUrl,
    secondCoolerPictureUrl,
    snacksPictureUrl,
    representative,
    nextScheduledDate,
    discontinuedDate,
  }) {
    this.id = id;
    this.address = address;
    this.latitude = latitude;
    this.longitude = longitude;
    this.name = name;
    this.lastDate = lastDate;
    this.status = status;
    this.totalVisits = totalVisits;
    this.storePictureUrl = storePictureUrl;
    this.coolerPictureUrl = coolerPictureUrl;
    this.secondCoolerPictureUrl = secondCoolerPictureUrl;
    this.snacksPictureUrl = snacksPictureUrl;
    this.representative = representative;
    this.nextScheduledDate = nextScheduledDate;
    this.discontinuedDate = discontinuedDate;
  }

  static parse(obj) {
    return new Account({
      id: obj.ID,
      name: obj.Name,
      address: obj.Address,
      latitude: obj.Latitude,
      longitude: obj.Longitude,
      lastDate: obj.AccountStatus.Date,
      status: obj.AccountStatus.Status,
      totalVisits: obj.AccountStatus.TotalVisits,
      storePictureUrl: obj.StorePicLink,
      coolerPictureUrl: obj.AccountStatus.CoolerImageLink,
      secondCoolerPictureUrl: obj.AccountStatus.SecondCoolerImageLink,
      snacksPictureUrl: obj.AccountStatus.SnacksImageLink,
      discontinuedDate: obj.AccountStatus.DateDiscontinued,
      representative: obj.RepresentativeName,
      nextScheduledDate: obj.NextScheduledVisit,
    });
  }

  getCoordinates() {
    return [this.latitude, this.longitude];
  }
}

export default Account;
