/* eslint-disable react/destructuring-assignment */
import React, { Fragment } from 'react';
import Navigation from 'common/components/Navigation';
import { instanceOf } from 'prop-types';

import Permissions from 'userModule/models/Permissions';

const withNavigation = () => (WrappedComponent) => {
  const WithNavigation = (props) => {
    const { permissions } = props;
    return (
      <Fragment>
        <Navigation permissions={permissions} />
        <WrappedComponent {...props} />
      </Fragment>
    );
  };

  WithNavigation.displayName = `WithNavigation(${WrappedComponent.displayName
    || WrappedComponent.name
    || 'Component'})`;

  WithNavigation.propTypes = {
    permissions: instanceOf(Permissions).isRequired,
  };

  return WithNavigation;
};

export default withNavigation;
