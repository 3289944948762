import React, { Fragment } from 'react';
import { instanceOf } from 'prop-types';
import { Divider } from 'antd';
import { isNil } from 'lodash';

import Summary from 'merchandisingModule/models/Summary';

import SectionHeading from 'common/components/SectionHeading';

import './index.scss';

const HEADING = 'Summary';

const SummarySection = ({ summary }) => {
  const {
    stores,
    forms,
    facings,
    reorders,
    reordersPercentage,
    secondaryPlacements,
    secondaryPlacementPercentage,
    avgFacings,
    avgSkus,
    facingsTotal,
  } = summary;

  const shouldRenderSummary = !isNil(stores)
      && !isNil(forms)
      && !isNil(facings)
      && !isNil(reorders)
      && !isNil(reordersPercentage)
      && !isNil(secondaryPlacements)
      && !isNil(secondaryPlacementPercentage)
      && !isNil(avgFacings)
      && !isNil(avgSkus)
      && !isNil(facingsTotal);

  const storesAmount = stores?.toLocaleString();
  const formsAmount = forms?.toLocaleString();
  const facingsAmount = facings?.toLocaleString();
  const reoredersAmount = `${reorders?.toLocaleString()} (${String(
    reordersPercentage,
  )}%)`;
  const secondaryPlacementAmount = `${String(secondaryPlacements)} (${String(
    secondaryPlacementPercentage,
  )}%)`;
  const avgFacingsAmount = avgFacings?.toLocaleString();
  const avgSkusAmount = avgSkus?.toLocaleString();
  const facingsTotalAmount = facingsTotal?.toLocaleString();

  return (
    shouldRenderSummary && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <div className="merchandising-summary">
          <div className="merchandising-summary__inner">
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {formsAmount}
              </div>
              <div className="merchandising-summary__card-body">
              Merchandising Visits
              </div>
            </div>
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {storesAmount}
              </div>
              <div className="merchandising-summary__card-body">
              Accounts Merchandised
              </div>
            </div>
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {secondaryPlacementAmount}
              </div>
              <div className="merchandising-summary__card-body">
              Secondary Placements
              </div>
            </div>
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {reoredersAmount}
              </div>
              <div className="merchandising-summary__card-body">
              Reorders
              </div>
            </div>
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {avgSkusAmount}
              </div>
              <div className="merchandising-summary__card-body">
              SKUs (Avg)
              </div>
            </div>
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {avgFacingsAmount}
              </div>
              <div className="merchandising-summary__card-body">
              Facings (Avg)
              </div>
            </div>
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {facingsTotalAmount}
              </div>
              <div className="merchandising-summary__card-body">
              Facings (Total)
              </div>
            </div>
            <div className="merchandising-summary__card">
              <div className="merchandising-summary__card-head">
                {facingsAmount}
              </div>
              <div className="merchandising-summary__card-body">
              Facings (Added)
              </div>
            </div>
          </div>
        </div>
        <Divider />
      </Fragment>
    )
  );
};

SummarySection.propTypes = {
  summary: instanceOf(Summary).isRequired,
};

export default SummarySection;
