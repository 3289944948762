/* eslint-disable no-param-reassign */

import produce from 'immer';
import moment from 'moment';
import fileDownload from 'js-file-download';

import {
  MERCHANDISING_ENDPOINT,
  MERCHANDISING_FILTERS_ENDPOINT,
  AVERAGE_FACINGS_ENDPOINT,
  ADDED_FACINGS_ENDPOINT,
  PRIMARY_PLACEMENT_ENDPOINT,
  SHELF_PLACEMENT_ENDPOINT,
  SECONDARY_PLACEMENT_ENDPOINT,
  POS_PLACEMENT_ENDPOINT,
  COMPETITORS_ENDPOINT,
  MERCHANDISING_ACCOUNT_PICTURES,
  SKU_RETAIL_PRICES,
  SKU_VOIDS,
  TAGS_INSTALLED_ENDPOINT,
  PRODUCTS_ON_PROMO_ENDPOINT,
  MERHCHANDISING_XLSX,
  TOTAL_FACINGS_ENDPOINT,
} from 'common/constants/apiRoutes';

import ajax from 'common/utilities/ajax';

import FilterOptions from 'merchandisingModule/models/FilterOptions';
import AccountsFilter from 'merchandisingModule/models/AccountsFilter';
import Account from 'merchandisingModule/models/Account';
import Summary from 'merchandisingModule/models/Summary';
import MerchandisingStatus from 'merchandisingModule/models/MerchandisingStatus';
import AddedFacings from 'merchandisingModule/models/AddedFacings';
import PrimaryPlacement from 'merchandisingModule/models/PrimaryPlacement';
import ShelfPlacement from 'merchandisingModule/models/ShelfPlacement';
import SecondaryPlacement from 'merchandisingModule/models/SecondaryPlacement';
import PosPlacement from 'merchandisingModule/models/PosPlacement';
import CompetitorInfo from 'merchandisingModule/models/CompetitorInfo';
import RetailPricesBySku from 'merchandisingModule/models/RetailPricesBySku';
import VoidsBySku from 'merchandisingModule/models/VoidsBySku';
import ProductsOnPromo from 'merchandisingModule/models/ProductsOnPromo';
import TagsOnShelf from 'merchandisingModule/models/TagsOnShelf';
import TotalFacing from 'merchandisingModule/models/TotalFacing';

import AccountImages from 'merchandisingModule/models/AccountImages';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import { showError } from 'common/helpers/notificationManager';

import {
  PRIMARY_PICTURE,
  SECONDARY_PICTURE,
} from 'merchandisingModule/constants/merchandising-constants';

const initialState = {
  filter: new AccountsFilter({}),
  initialized: false,
  accounts: [],
  summary: new Summary({
    stores: null,
    forms: null,
    facings: null,
    reorders: null,
    reordersPercentage: null,
  }),
  averageFacingsData: [],
  addedFacingsData: [],
  totalFacingsData: [],
  primaryPlacementData: [],
  shelfPlacementData: [],
  secondaryPlacementData: [],
  posPlacementData: [],
  competitorsData: [],
  retailPricesData: [],
  voidsData: [],
  tagsInstalledData: [],
  productsOnPromoData: [],
  filterOptions: new FilterOptions({
    brands: [],
    channelTypes: {},
    cities: {},
    states: {},
    chains: {},
    representatives: {},
    territories: {},
    skus: [],
    accountsSources: [],
    secondaryPlacements: [],
    dublicateImages: [],
    representativesMetric: [],
    regions: {},
    counties: {},
    banners: {},
    frequencies: [],
    primary: [],
    secondary: [],
    locations: [],
    types: [],
    tags: [],
    prices: {},
    promo: [],
    competitors: {},
  }),

  merchandisingImages: {},
  openAccountPopupId: null,
};

export default {
  state: initialState,
  reducers: {
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    setAccounts: produce((state, accountsData) => {
      state.accounts = accountsData;
    }),
    setSummary: produce((state, summaryData) => {
      state.summary = summaryData;
    }),
    setAverageFacingsData: produce((state, averageFacingsData) => {
      state.averageFacingsData = averageFacingsData;
    }),
    setTotalFacingsData: produce((state, totalFacingsData) => {
      state.totalFacingsData = totalFacingsData;
    }),
    setAddedFacingsData: produce((state, addedFacingsData) => {
      state.addedFacingsData = addedFacingsData;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    setPrimaryPlacementData: produce((state, primaryPlacementData) => {
      state.primaryPlacementData = primaryPlacementData;
    }),
    setShelfPlacementData: produce((state, shelfPlacementData) => {
      state.shelfPlacementData = shelfPlacementData;
    }),
    setSecondaryPlacementData: produce((state, secondaryPlacementData) => {
      state.secondaryPlacementData = secondaryPlacementData;
    }),
    setPosPlacementData: produce((state, posPlacementData) => {
      state.posPlacementData = posPlacementData;
    }),
    setCompetitorsData: produce((state, competitorsData) => {
      state.competitorsData = competitorsData;
    }),
    setRetailPricesData: produce((state, retailPricesData) => {
      state.retailPricesData = retailPricesData;
    }),
    setVoidsData: produce((state, voidsData) => {
      state.voidsData = voidsData;
    }),
    setTagsInstalledData: produce((state, tagsInstalledData) => {
      state.tagsInstalledData = tagsInstalledData;
    }),
    setProductsOnPromoData: produce((state, productsOnPromoData) => {
      state.productsOnPromoData = productsOnPromoData;
    }),
    setMerchandisingImages: produce((state, images, accountId) => {
      state.merchandisingImages[accountId] = images;
    }),
    setOpenAccountPopupId: produce((state, accountId) => {
      state.openAccountPopupId = accountId;
    }),
    clearOpenAccountPopupId: produce((state) => {
      state.openAccountPopupId = null;
    }),
    clearMerchandisingImages: produce((state) => {
      state.merchandisingImages = {};
    }),
    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(MERCHANDISING_FILTERS_ENDPOINT);
      const filterOptionsModel = FilterOptions.parse(response.data);
      dispatch.merchandising.setFilterOptions(filterOptionsModel);
    },
    async fetchAccounts(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(MERCHANDISING_ENDPOINT, config);
      const accountsData = response.data.Accounts.map(Account.parse);
      const summaryData = Summary.parse(response.data);
      dispatch.merchandising.setAccounts(accountsData);
      dispatch.merchandising.setSummary(summaryData);
    },
    async fetchAverageFacingsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(AVERAGE_FACINGS_ENDPOINT, config);
      const averageFacingsData = response.data.map(
        MerchandisingStatus.parse,
      );
      dispatch.merchandising.setAverageFacingsData(averageFacingsData);
    },
    async fetchTotalFacingsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(TOTAL_FACINGS_ENDPOINT, config);
      const totalFacingsData = response.data.map(TotalFacing.parse);
      dispatch.merchandising.setTotalFacingsData(totalFacingsData);
    },
    async fetchAddedFacingsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(ADDED_FACINGS_ENDPOINT, config);
      const addedFacingsData = response.data.map(AddedFacings.parse);
      dispatch.merchandising.setAddedFacingsData(addedFacingsData);
    },
    async fetchPrimaryPlacementData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(PRIMARY_PLACEMENT_ENDPOINT, config);
      const primaryPlacementData = response.data.map(
        PrimaryPlacement.parse,
      );
      dispatch.merchandising.setPrimaryPlacementData(
        primaryPlacementData,
      );
    },
    async fetchShelfPlacementData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SHELF_PLACEMENT_ENDPOINT, config);
      const shelfPlacementData = response.data.map(ShelfPlacement.parse);
      dispatch.merchandising.setShelfPlacementData(shelfPlacementData);
    },
    async fetchSecondaryPlacementData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(
        SECONDARY_PLACEMENT_ENDPOINT,
        config,
      );
      const secondaryPlacementData = response.data.map(
        SecondaryPlacement.parse,
      );
      dispatch.merchandising.setSecondaryPlacementData(
        secondaryPlacementData,
      );
    },
    async fetchPosPlacementData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(POS_PLACEMENT_ENDPOINT, config);
      const posPlacementData = response.data.map(PosPlacement.parse);
      dispatch.merchandising.setPosPlacementData(posPlacementData);
    },
    async fetchCompetitorsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(COMPETITORS_ENDPOINT, config);
      const competitorsData = response.data.map(CompetitorInfo.parse);
      dispatch.merchandising.setCompetitorsData(competitorsData);
    },
    async fetchRetailPricesData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SKU_RETAIL_PRICES, config);
      const skuNames = Object.keys(response.data);
      const retailPricesData = skuNames.map(skuName => RetailPricesBySku.parse(skuName, response.data[skuName]));
      dispatch.merchandising.setRetailPricesData(retailPricesData);
    },
    async fetchVoidsData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SKU_VOIDS, config);
      const skuNames = Object.keys(response.data);
      const voidsData = skuNames.map(skuName => VoidsBySku.parse(skuName, response.data[skuName]));
      dispatch.merchandising.setVoidsData(voidsData);
    },
    async fetchTagsInstalledData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(TAGS_INSTALLED_ENDPOINT, config);
      const tagsInstalledData = response.data.map(TagsOnShelf.parse);
      dispatch.merchandising.setTagsInstalledData(tagsInstalledData);
    },
    async fetchProductsOnPromoData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(PRODUCTS_ON_PROMO_ENDPOINT, config);
      const productsOnPromoData = response.data.map(
        ProductsOnPromo.parse,
      );
      dispatch.merchandising.setProductsOnPromoData(productsOnPromoData);
    },
    async fetchAccountMerchandisingImages(accountId, state) {
      if (!state.merchandising.merchandisingImages[accountId]) {
        try {
          const config = {
            params: {
              id:
                accountId
                || state.merchandising.openAccountPopupId,
              ...state.merchandising.filter.getApiParams(),
            },
          };

          const response = await ajax.get(
            MERCHANDISING_ACCOUNT_PICTURES,
            config,
          );

          dispatch.merchandising.setMerchandisingImages(
            response.data.reverse().reduce(
              (acc, cur) => {
                const date = moment(cur.Date).format(
                  DATE_FORMAT,
                );

                acc[PRIMARY_PICTURE].push(
                  new AccountImages({
                    url: cur.PrimaryPictureLink,
                    date,
                  }),
                );
                acc[SECONDARY_PICTURE].push(
                  new AccountImages({
                    url: cur.SecondaryPictureLink,
                    date,
                  }),
                );
                return acc;
              },
              {
                [PRIMARY_PICTURE]: [],
                [SECONDARY_PICTURE]: [],
              },
            ),
            accountId,
          );
        } catch {
          showError({
            message: 'Error',
            description: 'Failed to load account images',
          });
        }
      }
    },

    async downloadMerchandisingPageReport(_, state) {
      const { filter } = state.merchandising;

      try {
        const response = await ajax.get(MERHCHANDISING_XLSX, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download merchandising report',
        });
      }
    },
  }),
};
