import { connect } from 'react-redux';

import DownloadAccountsButton from 'common/components/DownloadAccountsButton';

export default connect(
  state => ({
    isDownloadingAccounts: state.loading.effects.sales.downloadSalesPageReport,
  }),
  dispatch => ({
    onDownloadAccounts: () => dispatch.sales.downloadSalesPageReport(),
  }),
)(DownloadAccountsButton);
