class Summary {
  constructor({
    planned,
    visited,
    visitedPercentage,
    missed,
    missedPercentage,
    unplanned,
    hours,
    miles,
  }) {
    this.planned = planned;
    this.visited = visited;
    this.visitedPercentage = visitedPercentage;
    this.missed = missed;
    this.missedPercentage = missedPercentage;
    this.unplanned = unplanned;
    this.hours = hours;
    this.miles = miles;
  }

  static parse(obj) {
    return new Summary({
      planned: obj.PlannedVisits,
      visited: obj.AccountsVisited,
      visitedPercentage: obj.AccountsVisitedPercentage,
      missed: obj.MissedVisits,
      missedPercentage: obj.MissedVisitsPercentage,
      unplanned: obj.UnplannedVisits,
      hours: obj.HoursWorked,
      miles: obj.MileageDriven,
    });
  }
}

export default Summary;
