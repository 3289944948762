import AccountByDay from './AccountByDay';

class AccountsByDay {
  constructor({ date, accounts }) {
    this.date = date;
    this.accounts = accounts;
  }

  static parse(obj) {
    return new AccountsByDay({
      date: obj.Date,
      accounts: obj.Accounts.map(AccountByDay.parse),
    });
  }
}

export default AccountsByDay;
