import React, { Fragment } from 'react';
import { instanceOf } from 'prop-types';
import { Divider } from 'antd';
import { isNil } from 'lodash';

import Summary from 'accountsModule/models/Summary';
import SectionHeading from 'common/components/SectionHeading';

import './index.scss';

const SummarySection = ({ summary }) => {
  const {
    stores,
    forms,
    totalAccounts,
    totalAccountsPercentage,
    hours,
    mileage,
  } = summary;

  const shouldRenderSummary = !isNil(stores)
    && !isNil(forms)
    && !isNil(totalAccountsPercentage)
    && !isNil(totalAccounts)
    && !isNil(hours)
    && !isNil(mileage);

  const storesAmount = stores ?.toLocaleString();
  const formsAmount = forms ?.toLocaleString();
  const totalAccountsPercentageAmount = `${String(totalAccounts)} (${String(
    totalAccountsPercentage,
  )}%)`;
  const hoursAmount = hours ?.toLocaleString();
  const mileageAmount = mileage ?.toLocaleString();

  return (
    shouldRenderSummary && (
      <Fragment>
        <SectionHeading>Summary</SectionHeading>
        <div className="accounts-summary">
          <div className="accounts-summary__inner">
            <div className="accounts-summary__card">
              <div className="accounts-summary__card-head">
                {formsAmount}
              </div>
              <div className="accounts-summary__card-body">
              Visits
              </div>
            </div>
            <div className="accounts-summary__card">
              <div className="accounts-summary__card-head">
                {storesAmount}
              </div>
              <div className="accounts-summary__card-body">
              Accounts Visited
              </div>
            </div>
            <div className="accounts-summary__card">
              <div className="accounts-summary__card-head">
                {totalAccountsPercentageAmount}
              </div>
              <div className="accounts-summary__card-body">
              Authorized Accounts
              </div>
            </div>
            <div className="accounts-summary__card">
              <div className="accounts-summary__card-head">
                {hoursAmount}
              </div>
              <div className="accounts-summary__card-body">
              Hours Worked
              </div>
            </div>
            <div className="accounts-summary__card">
              <div className="accounts-summary__card-head">
                {mileageAmount}
              </div>
              <div className="accounts-summary__card-body">
              Mileage Driven
              </div>
            </div>
          </div>
          <Divider />
        </div>
      </Fragment>
    )
  );
};

SummarySection.propTypes = {
  summary: instanceOf(Summary).isRequired,
};

export default SummarySection;
