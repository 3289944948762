/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';

import BarChart from 'common/components/BarChart';
import SectionHeading from 'common/components/SectionHeading';

import withLegend from 'common/hocs/withLegend';

import { transformDataForTooltip } from 'salesModule/tools/tooltipFormatter';
import { bottomTicksFormatter } from 'salesModule/tools/bottomTicksFormatter';

import TotalSaleByDistributors from 'salesModule/models/TotalSaleByDistributors';

const HEADING = 'Sales By Distributor';
const VALUE_SIGN = '$';
const CHART_TYPE = 'stacked';

const TotalSalesByDistributorsChart = ({
  totalSalesByDistributors,
  distributors,
  colorMap,
  disabledKeys,
  shouldRenderChart,
}) => {
  const distributorsData = totalSalesByDistributors.map(
    totalSaleByDistributor => totalSaleByDistributor.getDistributorsData(),
  );
  const labelFormatter = label => `${VALUE_SIGN}${label.toLocaleString()}`;

  return (
    shouldRenderChart
    && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <BarChart
          data={distributorsData.map(skuData => Object.keys(skuData)
            .filter(skuDataKey => !disabledKeys.includes(skuDataKey))
            .reduce((obj, skuDataKey) => {
              obj[skuDataKey] = skuData[skuDataKey];
              return obj;
            }, {}))}
          keys={distributors.filter(
            distributorName => !disabledKeys.includes(distributorName),
          )}
          indexes="dateInterval"
          colorMap={colorMap}
          valueSign={VALUE_SIGN}
          type={CHART_TYPE}
          labelFormatter={labelFormatter}
          bottomTicksFormatter={bottomTicksFormatter}
          transformDataForTooltip={transformDataForTooltip}
        />
      </Fragment>
    )
  );
};

TotalSalesByDistributorsChart.propTypes = {
  totalSalesByDistributors: arrayOf(instanceOf(TotalSaleByDistributors))
    .isRequired,
  distributors: arrayOf(string).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
  shouldRenderChart: bool.isRequired,
};

export default withLegend({ keys: 'distributors' })(TotalSalesByDistributorsChart);
