import { connect } from 'react-redux';
import {
  colorMapSelector,
  totalStatusesSelector,
  disabledStatusesSelector,
  shouldRenderChartSelector,
} from 'accountsModule/selectors/totalAccountsChartSelectors';

import withLegend from 'common/hocs/withLegend';

import TotalAccountsChart from 'accountsModule/components/TotalAccountsChart';

export default connect(state => ({
  accountStatusesData: state.accounts.accountStatusesData,
  totalStatuses: totalStatusesSelector(state),
  colorMap: colorMapSelector(state),
  activeAccountStatuses: state.accounts.activeAccountStatuses,
  disabledFieldsByDefault: disabledStatusesSelector(state),
  shouldRenderChart: shouldRenderChartSelector(state),
}))(
  withLegend({
    keys: 'totalStatuses',
    shouldRenderLegend: 'shouldRenderChart',
    disabledKeysByDefault: 'disabledFieldsByDefault',
    withDivider: 'shouldRenderChart',
  })(TotalAccountsChart),
);
