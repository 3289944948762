import { connect } from 'react-redux';

import AsmScheduleDailyBars from 'scheduleModule/components/AsmScheduleDailyBars';

import {
  asmVisitsAndColorsSelector,
  shouldRenderAsmScheduleDailyBarsChartSelector,
} from 'scheduleModule/selectors/asmScheduleDailyBarsSelectors';

export default connect(
  (state) => {
    const { visits, visitsColorMap } = asmVisitsAndColorsSelector(state);
    const shouldRenderChart = shouldRenderAsmScheduleDailyBarsChartSelector(state);

    return {
      asmScheduleDailyBars: state.schedule.asmScheduleDailyBars,
      shouldRenderChart,
      withDivider: shouldRenderChart,
      visits,
      colorMap: visitsColorMap,
    };
  },
)(AsmScheduleDailyBars);
