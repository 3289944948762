import { connect } from 'react-redux';

import SalesPage from 'salesModule/components/SalesPage';
import { permissionsSelector, filterPermissionsSelector } from 'salesModule/selectors/salesPageSelectors';

export default connect(
  state => ({
    accountsFilter: state.sales.filter,
    accountsFilterInitialized: state.sales.initialized,
    loading: state.loading.models.sales,
    filterOptions: state.sales.filterOptions,
    fetchingFilterOptions: state.loading.effects.sales.fetchFilterOptions,
    userInfo: state.session.userInfo,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    setAccountFilters: dispatch.sales.setFilter,
    fetchAccountsData: dispatch.sales.fetchAccountsData,
    fetchTotalSales: dispatch.sales.fetchTotalSales,
    fetchFilterOptions: dispatch.sales.fetchFilterOptions,
    resetSalesStore: dispatch.sales.clear,
  }),
)(SalesPage);
