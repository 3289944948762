import { connect } from 'react-redux';

import BrandScorecardsList from 'adminModule/components/BrandScorecardsList';

export default connect(
  state => ({
    selectedBrand: state.admin.scorecardsBrand,
    scorecardsByBrand: state.admin.filterOptions.scorecardsByBrand,

    isUploading: state.loading.effects.admin.uploadScorecards,
    isDownLoading: state.loading.effects.admin.downloadScorecard,
    isDeleting: state.loading.effects.admin.deleteScorecard,
  }),
  dispatch => ({
    onDelete: scorecardID => dispatch.admin.deleteScorecard(scorecardID),
    onDownload: scorecardID => dispatch.admin.downloadScorecard(scorecardID),
  }),
)(BrandScorecardsList);
