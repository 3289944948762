/* eslint-disable no-unused-expressions, no-lone-blocks */
import React, { PureComponent } from 'react';
import {
  instanceOf, func, bool, arrayOf, number, string,
} from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';

import { Skeleton } from 'antd';

import withNavigation from 'common/hocs/withNavigation';

import FilterOptions from 'merchandisingModule/models/FilterOptions';
import AccountsFilterModel from 'merchandisingModule/models/AccountsFilter';

import MerchandisingMap from 'merchandisingModule/containers/MerchandisingMapContainer';
import SummarySection from 'merchandisingModule/containers/SummarySectionContainer';
import AddedFacingsChart from 'merchandisingModule/containers/AddedFacingsChartContainer';
import AverageFacingsChart from 'merchandisingModule/containers/AverageFacingsChartContainer';
import VoidsCharts from 'merchandisingModule/containers/VoidsChartsContainer';
import MetricsOnDateCharts from 'merchandisingModule/containers/MetricsOnDateChartsContainer';
import TotalFacingsChart from 'merchandisingModule/containers/TotalFacingsChartContainer';

import AccountsFilter from 'merchandisingModule/components/AccountsFilter';

import { merchandisingPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class MerchandisingPage extends PureComponent {
  static propTypes = {
    filterOptions: instanceOf(FilterOptions).isRequired,
    filter: instanceOf(AccountsFilterModel).isRequired,
    fetchingFilterOptions: bool.isRequired,
    filterInitialized: bool.isRequired,
    location: RouterPropTypes.location.isRequired,
    history: RouterPropTypes.history.isRequired,
    setFilter: func.isRequired,
    fetchAccounts: func.isRequired,
    fetchAverageFacingsData: func.isRequired,
    fetchTotalFacingsData: func.isRequired,
    fetchAddedFacingsData: func.isRequired,
    fetchPrimaryPlacementData: func.isRequired,
    fetchShelfPlacementData: func.isRequired,
    fetchSecondaryPlacementData: func.isRequired,
    fetchPosPlacementData: func.isRequired,
    fetchVoidsData: func.isRequired,
    fetchTagsInstalledData: func.isRequired,
    fetchProductsOnPromoData: func.isRequired,
    fetchFilterOptions: func.isRequired,
    fetchAccountMerchandisingImages: func.isRequired,
    resetMerchandisingStore: func.isRequired,
    clearMerchandisingImages: func.isRequired,
    openAccountPopupId: number,
    childrenPermissions: arrayOf(string).isRequired,
    filterPermissions: arrayOf(string).isRequired,
  };

  static defaultProps = {
    openAccountPopupId: null,
  };

  componentDidMount() {
    const { fetchFilterOptions } = this.props;

    fetchFilterOptions();
  }

  componentDidUpdate(prevProps) {
    const {
      fetchingFilterOptions,
      filterOptions,
      location,
    } = this.props;

    if (prevProps.fetchingFilterOptions && !fetchingFilterOptions) {
      const defaultBrandName = filterOptions.brands[0].name;
      const { earliestDate } = filterOptions.brands.find(
        brandInfo => brandInfo.name === defaultBrandName,
      );
      const defaultAccountsSource = filterOptions.accountsSources[0];
      const defaultPromo = filterOptions.promo[0];
      const defaultTags = filterOptions.tags[0];

      AccountsFilterModel.setDefaultParams({
        defaultBrand: defaultBrandName,
        defaultStartDate: earliestDate,
        defaultAccountsSource,
        defaultPromo,
        defaultTags,
      });
      const filterModel = AccountsFilterModel.parseQueryString(
        location.search,
      );

      this.setAccountFilters(filterModel);
    }
  }

  componentWillUnmount() {
    const { resetMerchandisingStore } = this.props;
    resetMerchandisingStore();
  }

  setAccountFilters = (filterModel) => {
    const {
      setFilter,
      location,
      history,
      fetchAccounts,
      fetchAverageFacingsData,
      fetchAddedFacingsData,
      fetchTotalFacingsData,
      fetchPrimaryPlacementData,
      fetchShelfPlacementData,
      fetchSecondaryPlacementData,
      fetchPosPlacementData,
      fetchVoidsData,
      fetchTagsInstalledData,
      fetchProductsOnPromoData,
      openAccountPopupId,
      fetchAccountMerchandisingImages,
      clearMerchandisingImages,
      childrenPermissions,
    } = this.props;

    const modelQueryString = filterModel.getQueryStringFilter();

    clearMerchandisingImages();

    if (openAccountPopupId) {
      fetchAccountMerchandisingImages(openAccountPopupId);
    }

    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MAP) && fetchAccounts(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MERCHANDISING_STATUS) && fetchAverageFacingsData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_FACINGS_ADDED) && fetchAddedFacingsData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_PRIMARY_PLACEMENT) && fetchPrimaryPlacementData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_SECONDARY_PLACEMENT) && fetchSecondaryPlacementData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_POS_PLACEMENT) && fetchPosPlacementData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_PROMO) && fetchProductsOnPromoData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_TAGS) && fetchTagsInstalledData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_SHELF_PLACEMENT) && fetchShelfPlacementData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_VOIDS) && fetchVoidsData(filterModel); }
    { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_TOTAL_FACINGS) && fetchTotalFacingsData(filterModel); }
    setFilter(filterModel);
    history.push({
      pathname: location.pathname,
      search: modelQueryString,
      hash: location.hash,
    });
  };

  render() {
    const {
      filter,
      filterOptions,
      filterInitialized,
      childrenPermissions,
      filterPermissions,
    } = this.props;

    return (
      <div className="merchandising-page">
        <Skeleton
          loading={!filterInitialized}
          className="merchandising-page__skeleton"
        >
          <AccountsFilter
            applyFilter={this.setAccountFilters}
            initialFilterModel={filter}
            filterOptions={filterOptions}
            permissions={filterPermissions}
          />
        </Skeleton>
        <div className="merchandising-page__inner">
          { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MAP) && <MerchandisingMap /> }
          { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MAP) && <SummarySection /> }
          { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_TOTAL_FACINGS) && <TotalFacingsChart /> }
          { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MERCHANDISING_STATUS) && <AverageFacingsChart /> }
          { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_FACINGS_ADDED) && <AddedFacingsChart /> }
          <MetricsOnDateCharts />
          { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_VOIDS) && <VoidsCharts /> }
        </div>
      </div>
    );
  }
}

export default withNavigation()(MerchandisingPage);
