/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const posPlacementDataSelector = state => state.merchandising.posPlacementData;

export const currentPosPlacementNamesSelector = createSelector(
  posPlacementDataSelector,
  posPlacementData => posPlacementData.map(posplacement => posplacement.placement),
);

export const colorMapSelector = createSelector(
  currentPosPlacementNamesSelector,
  currentPosPlacementNames => colorGenerator(currentPosPlacementNames),
);
