class VisitInfo {
  constructor({ representative, value, percentage }) {
    this.representative = representative;
    this.value = value;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new VisitInfo({
      representative: obj.Name,
      value: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default VisitInfo;
