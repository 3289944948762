// accounts page filter permissions
const ACCOUNTS_FILTERS_DATE_PERIOD = 'ACCOUNTS_FILTERS_DATE_PERIOD';
const ACCOUNTS_FILTERS_ACCOUNT = 'ACCOUNTS_FILTERS_ACCOUNT';
const ACCOUNTS_FILTERS_ADDRESS = 'ACCOUNTS_FILTERS_ADDRESS';
const ACCOUNTS_FILTERS_CITY = 'ACCOUNTS_FILTERS_CITY';
const ACCOUNTS_FILTERS_STATE = 'ACCOUNTS_FILTERS_STATE';
const ACCOUNTS_FILTERS_ZIP = 'ACCOUNTS_FILTERS_ZIP';
const ACCOUNTS_FILTERS_BANNER = 'ACCOUNTS_FILTERS_BANNER';
const ACCOUNTS_FILTERS_CHAIN = 'ACCOUNTS_FILTERS_CHAIN';
const ACCOUNTS_FILTERS_CHANNEL = 'ACCOUNTS_FILTERS_CHANNEL';
const ACCOUNTS_FILTERS_AREA = 'ACCOUNTS_FILTERS_AREA';
const ACCOUNTS_FILTERS_COUNTY = 'ACCOUNTS_FILTERS_COUNTY';
const ACCOUNTS_FILTERS_REGION = 'ACCOUNTS_FILTERS_REGION';
const ACCOUNTS_FILTERS_REP_ACCOUNT = 'ACCOUNTS_FILTERS_REP_ACCOUNT';
const ACCOUNTS_FILTERS_REP_METRIC = 'ACCOUNTS_FILTERS_REP_METRIC';
const ACCOUNTS_FILTERS_DATA_SOURCE = 'ACCOUNTS_FILTERS_DATA_SOURCE';
const ACCOUNTS_FILTERS_NO_ACTIVITY = 'ACCOUNTS_FILTERS_NO_ACTIVITY';
const ACCOUNTS_FILTERS_FREQUENCY = 'ACCOUNTS_FILTERS_FREQUENCY';
const ACCOUNTS_FILTERS_NO_PHOTO = 'ACCOUNTS_FILTERS_NO_PHOTO';
const ACCOUNTS_FILTERS_STATUS = 'ACCOUNTS_FILTERS_STATUS';
const ACCOUNTS_FILTERS_DISTRIBUTORS = 'ACCOUNTS_FILTERS_DISTRIBUTORS';

export const accountsFiltersPermissionTypes = {
  ACCOUNTS_FILTERS_DATE_PERIOD,
  ACCOUNTS_FILTERS_ACCOUNT,
  ACCOUNTS_FILTERS_ADDRESS,
  ACCOUNTS_FILTERS_CITY,
  ACCOUNTS_FILTERS_STATE,
  ACCOUNTS_FILTERS_ZIP,
  ACCOUNTS_FILTERS_CHAIN,
  ACCOUNTS_FILTERS_BANNER,
  ACCOUNTS_FILTERS_CHANNEL,
  ACCOUNTS_FILTERS_AREA,
  ACCOUNTS_FILTERS_COUNTY,
  ACCOUNTS_FILTERS_REGION,
  ACCOUNTS_FILTERS_REP_ACCOUNT,
  ACCOUNTS_FILTERS_REP_METRIC,
  ACCOUNTS_FILTERS_DATA_SOURCE,
  ACCOUNTS_FILTERS_NO_ACTIVITY,
  ACCOUNTS_FILTERS_FREQUENCY,
  ACCOUNTS_FILTERS_NO_PHOTO,
  ACCOUNTS_FILTERS_STATUS,
  ACCOUNTS_FILTERS_DISTRIBUTORS,
};

// merchandising page filter permissions
const MERCHANDISING_FILTERS_DATE_PERIOD = 'MERCHANDISING_FILTERS_DATE_PERIOD';
const MERCHANDISING_FILTERS_ACCOUNT = 'MERCHANDISING_FILTERS_ACCOUNT';
const MERCHANDISING_FILTERS_ADDRESS = 'MERCHANDISING_FILTERS_ADDRESS';
const MERCHANDISING_FILTERS_CITY = 'MERCHANDISING_FILTERS_CITY';
const MERCHANDISING_FILTERS_STATE = 'MERCHANDISING_FILTERS_STATE';
const MERCHANDISING_FILTERS_ZIP = 'MERCHANDISING_FILTERS_ZIP';
const MERCHANDISING_FILTERS_CHAIN = 'MERCHANDISING_FILTERS_CHAIN';
const MERCHANDISING_FILTERS_BANNER = 'MERCHANDISING_FILTERS_BANNER';
const MERCHANDISING_FILTERS_CHANNEL = 'MERCHANDISING_FILTERS_CHANNEL';
const MERCHANDISING_FILTERS_AREA = 'MERCHANDISING_FILTERS_AREA';
const MERCHANDISING_FILTERS_COUNTY = 'MERCHANDISING_FILTERS_COUNTY';
const MERCHANDISING_FILTERS_REGION = 'MERCHANDISING_FILTERS_REGION';
const MERCHANDISING_FILTERS_SKUS = 'MERCHANDISING_FILTERS_SKUS';
const MERCHANDISING_FILTERS_SKUS_NUM = 'MERCHANDISING_FILTERS_SKUS_NUM';
const MERCHANDISING_FILTERS_FACINGS_NUM = 'MERCHANDISING_FILTERS_FACINGS_NUM';
const MERCHANDISING_FILTERS_REP_ACCOUNT = 'MERCHANDISING_FILTERS_REP_ACCOUNT';
const MERCHANDISING_FILTERS_REP_METRIC = 'MERCHANDISING_FILTERS_REP_METRIC';
const MERCHANDISING_FILTERS_DATA_SOURCE = 'MERCHANDISING_FILTERS_DATA_SOURCE';
const MERCHANDISING_FILTERS_NO_ACTIVITY = 'MERCHANDISING_FILTERS_NO_ACTIVITY';
const MERCHANDISING_FILTERS_FREQUENCY = 'MERCHANDISING_FILTERS_FREQUENCY';
const MERCHANDISING_FILTERS_DUPLICATE_PHOTO = 'MERCHANDISING_FILTERS_DUPLICATE_PHOTO';
const MERCHANDISING_FILTERS_PRIMARY = 'MERCHANDISING_FILTERS_PRIMARY';
const MERCHANDISING_FILTERS_SECONDARY = 'MERCHANDISING_FILTERS_SECONDARY';
const MERCHANDISING_FILTERS_LOCATION = 'MERCHANDISING_FILTERS_LOCATION';
const MERCHANDISING_FILTERS_TYPE = 'MERCHANDISING_FILTERS_TYPE';
const MERCHANDISING_FILTERS_TAGS = 'MERCHANDISING_FILTERS_TAGS';
const MERCHANDISING_FILTERS_PRICE = 'MERCHANDISING_FILTERS_PRICE';
const MERCHANDISING_FILTERS_PROMO = 'MERCHANDISING_FILTERS_PROMO';
const MERCHANDISING_FILTERS_COMPETITORS = 'MERCHANDISING_FILTERS_COMPETITORS';

export const merchandisingFiltersPermissionTypes = {
  MERCHANDISING_FILTERS_DATE_PERIOD,
  MERCHANDISING_FILTERS_ACCOUNT,
  MERCHANDISING_FILTERS_ADDRESS,
  MERCHANDISING_FILTERS_CITY,
  MERCHANDISING_FILTERS_STATE,
  MERCHANDISING_FILTERS_ZIP,
  MERCHANDISING_FILTERS_CHAIN,
  MERCHANDISING_FILTERS_BANNER,
  MERCHANDISING_FILTERS_CHANNEL,
  MERCHANDISING_FILTERS_AREA,
  MERCHANDISING_FILTERS_COUNTY,
  MERCHANDISING_FILTERS_REGION,
  MERCHANDISING_FILTERS_SKUS,
  MERCHANDISING_FILTERS_SKUS_NUM,
  MERCHANDISING_FILTERS_FACINGS_NUM,
  MERCHANDISING_FILTERS_REP_ACCOUNT,
  MERCHANDISING_FILTERS_REP_METRIC,
  MERCHANDISING_FILTERS_DATA_SOURCE,
  MERCHANDISING_FILTERS_NO_ACTIVITY,
  MERCHANDISING_FILTERS_FREQUENCY,
  MERCHANDISING_FILTERS_DUPLICATE_PHOTO,
  MERCHANDISING_FILTERS_PRIMARY,
  MERCHANDISING_FILTERS_SECONDARY,
  MERCHANDISING_FILTERS_LOCATION,
  MERCHANDISING_FILTERS_TYPE,
  MERCHANDISING_FILTERS_TAGS,
  MERCHANDISING_FILTERS_PRICE,
  MERCHANDISING_FILTERS_PROMO,
  MERCHANDISING_FILTERS_COMPETITORS,
};

// sales page filters permissions
const SALES_FILTERS_DATE_PERIOD = 'SALES_FILTERS_DATE_PERIOD';
const SALES_FILTERS_ACCOUNT = 'SALES_FILTERS_ACCOUNT';
const SALES_FILTERS_ADDRESS = 'SALES_FILTERS_ADDRESS';
const SALES_FILTERS_CITY = 'SALES_FILTERS_CITY';
const SALES_FILTERS_STATE = 'SALES_FILTERS_STATE';
const SALES_FILTERS_ZIP = 'SALES_FILTERS_ZIP';
const SALES_FILTERS_CHAIN = 'SALES_FILTERS_CHAIN';
const SALES_FILTERS_BANNER = 'SALES_FILTERS_BANNER';
const SALES_FILTERS_CHANNEL = 'SALES_FILTERS_CHANNEL';
const SALES_FILTERS_AREA = 'SALES_FILTERS_AREA';
const SALES_FILTERS_COUNTY = 'SALES_FILTERS_COUNTY';
const SALES_FILTERS_REGION = 'SALES_FILTERS_REGION';
const SALES_FILTERS_SKUS = 'SALES_FILTERS_SKUS';
const SALES_FILTERS_DISTRIBUTORS = 'SALES_FILTERS_DISTRIBUTORS';
const SALES_FILTERS_REP_ACCOUNT = 'SALES_FILTERS_REP_ACCOUNT';
const SALES_FILTERS_REP_METRIC = 'SALES_FILTERS_REP_METRIC';
const SALES_FILTERS_DATA_SOURCE = 'SALES_FILTERS_DATA_SOURCE';
const SALES_FILTERS_NO_ACTIVITY = 'SALES_FILTERS_NO_ACTIVITY';
const SALES_FILTERS_FREQUENCY = 'SALES_FILTERS_FREQUENCY';

export const salesFiltersPermissionTypes = {
  SALES_FILTERS_DATE_PERIOD,
  SALES_FILTERS_ACCOUNT,
  SALES_FILTERS_ADDRESS,
  SALES_FILTERS_CITY,
  SALES_FILTERS_STATE,
  SALES_FILTERS_ZIP,
  SALES_FILTERS_CHAIN,
  SALES_FILTERS_BANNER,
  SALES_FILTERS_CHANNEL,
  SALES_FILTERS_AREA,
  SALES_FILTERS_COUNTY,
  SALES_FILTERS_REGION,
  SALES_FILTERS_SKUS,
  SALES_FILTERS_DISTRIBUTORS,
  SALES_FILTERS_REP_ACCOUNT,
  SALES_FILTERS_REP_METRIC,
  SALES_FILTERS_DATA_SOURCE,
  SALES_FILTERS_NO_ACTIVITY,
  SALES_FILTERS_FREQUENCY,
};

// reports page filters permissions
const REPORTS_FILTERS_DATE_PERIOD = 'REPORTS_FILTERS_DATE_PERIOD';
const REPORTS_FILTERS_ACCOUNT = 'REPORTS_FILTERS_ACCOUNT';
const REPORTS_FILTERS_ADDRESS = 'REPORTS_FILTERS_ADDRESS';
const REPORTS_FILTERS_CITY = 'REPORTS_FILTERS_CITY';
const REPORTS_FILTERS_STATE = 'REPORTS_FILTERS_STATE';
const REPORTS_FILTERS_ZIP = 'REPORTS_FILTERS_ZIP';
const REPORTS_FILTERS_CHAIN = 'REPORTS_FILTERS_CHAIN';
const REPORTS_FILTERS_BANNER = 'REPORTS_FILTERS_BANNER';
const REPORTS_FILTERS_CHANNEL = 'REPORTS_FILTERS_CHANNEL';
const REPORTS_FILTERS_AREA = 'REPORTS_FILTERS_AREA';
const REPORTS_FILTERS_COUNTY = 'REPORTS_FILTERS_COUNTY';
const REPORTS_FILTERS_REGION = 'REPORTS_FILTERS_REGION';
const REPORTS_FILTERS_MERCH_SKUS = 'REPORTS_FILTERS_MERCH_SKUS';
const REPORTS_FILTERS_SKUS_NUM = 'REPORTS_FILTERS_SKUS_NUM';
const REPORTS_FILTERS_FACINGS_NUM = 'REPORTS_FILTERS_FACINGS_NUM';
const REPORTS_FILTERS_SALES_SKUS = 'REPORTS_FILTERS_SALES_SKUS';
const REPORTS_FILTERS_DISTRIBUTORS = 'REPORTS_FILTERS_DISTRIBUTORS';
const REPORTS_FILTERS_REP_ACCOUNT = 'REPORTS_FILTERS_REP_ACCOUNT';
const REPORTS_FILTERS_REP_METRIC = 'REPORTS_FILTERS_REP_METRIC';
const REPORTS_FILTERS_DATA_SOURCE = 'REPORTS_FILTERS_DATA_SOURCE';
const REPORTS_FILTERS_NO_ACTIVITY = 'REPORTS_FILTERS_NO_ACTIVITY';
const REPORTS_FILTERS_FREQUENCY = 'REPORTS_FILTERS_FREQUENCY';

export const reportsFiltersPermissionTypes = {
  REPORTS_FILTERS_DATE_PERIOD,
  REPORTS_FILTERS_ACCOUNT,
  REPORTS_FILTERS_ADDRESS,
  REPORTS_FILTERS_CITY,
  REPORTS_FILTERS_STATE,
  REPORTS_FILTERS_ZIP,
  REPORTS_FILTERS_CHAIN,
  REPORTS_FILTERS_BANNER,
  REPORTS_FILTERS_CHANNEL,
  REPORTS_FILTERS_AREA,
  REPORTS_FILTERS_COUNTY,
  REPORTS_FILTERS_REGION,
  REPORTS_FILTERS_MERCH_SKUS,
  REPORTS_FILTERS_SKUS_NUM,
  REPORTS_FILTERS_FACINGS_NUM,
  REPORTS_FILTERS_SALES_SKUS,
  REPORTS_FILTERS_DISTRIBUTORS,
  REPORTS_FILTERS_REP_ACCOUNT,
  REPORTS_FILTERS_REP_METRIC,
  REPORTS_FILTERS_DATA_SOURCE,
  REPORTS_FILTERS_NO_ACTIVITY,
  REPORTS_FILTERS_FREQUENCY,
};

// management page filters permissions
const MANAGEMENT_FILTERS_DATE_PERIOD = 'MANAGEMENT_FILTERS_DATE_PERIOD';
const MANAGEMENT_FILTERS_ACCOUNT = 'MANAGEMENT_FILTERS_ACCOUNT';
const MANAGEMENT_FILTERS_ADDRESS = 'MANAGEMENT_FILTERS_ADDRESS';
const MANAGEMENT_FILTERS_CITY = 'MANAGEMENT_FILTERS_CITY';
const MANAGEMENT_FILTERS_STATE = 'MANAGEMENT_FILTERS_STATE';
const MANAGEMENT_FILTERS_ZIP = 'MANAGEMENT_FILTERS_ZIP';
const MANAGEMENT_FILTERS_CHAIN = 'MANAGEMENT_FILTERS_CHAIN';
const MANAGEMENT_FILTERS_BANNER = 'MANAGEMENT_FILTERS_BANNER';
const MANAGEMENT_FILTERS_CHANNEL = 'MANAGEMENT_FILTERS_CHANNEL';
const MANAGEMENT_FILTERS_AREA = 'MANAGEMENT_FILTERS_AREA';
const MANAGEMENT_FILTERS_COUNTY = 'MANAGEMENT_FILTERS_COUNTY';
const MANAGEMENT_FILTERS_REGION = 'MANAGEMENT_FILTERS_REGION';
const MANAGEMENT_FILTERS_MERCH_SKUS = 'MANAGEMENT_FILTERS_MERCH_SKUS';
const MANAGEMENT_FILTERS_SKUS_NUM = 'MANAGEMENT_FILTERS_SKUS_NUM';
const MANAGEMENT_FILTERS_FACINGS_NUM = 'MANAGEMENT_FILTERS_FACINGS_NUM';
const MANAGEMENT_FILTERS_SALES_SKUS = 'MANAGEMENT_FILTERS_SALES_SKUS';
const MANAGEMENT_FILTERS_DISTRIBUTORS = 'MANAGEMENT_FILTERS_DISTRIBUTORS';
const MANAGEMENT_FILTERS_REP_ACCOUNT = 'MANAGEMENT_FILTERS_REP_ACCOUNT';
const MANAGEMENT_FILTERS_REP_METRIC = 'MANAGEMENT_FILTERS_REP_METRIC';
const MANAGEMENT_FILTERS_DATA_SOURCE = 'MANAGEMENT_FILTERS_DATA_SOURCE';
const MANAGEMENT_FILTERS_NO_ACTIVITY = 'MANAGEMENT_FILTERS_NO_ACTIVITY';
const MANAGEMENT_FILTERS_FREQUENCY = 'MANAGEMENT_FILTERS_FREQUENCY';
const MANAGEMENT_FILTERS_REPS_INACTIVE = 'MANAGEMENT_FILTERS_REPS_INACTIVE';

export const managementFiltersPermissionTypes = {
  MANAGEMENT_FILTERS_DATE_PERIOD,
  MANAGEMENT_FILTERS_ACCOUNT,
  MANAGEMENT_FILTERS_ADDRESS,
  MANAGEMENT_FILTERS_CITY,
  MANAGEMENT_FILTERS_STATE,
  MANAGEMENT_FILTERS_ZIP,
  MANAGEMENT_FILTERS_CHAIN,
  MANAGEMENT_FILTERS_BANNER,
  MANAGEMENT_FILTERS_CHANNEL,
  MANAGEMENT_FILTERS_AREA,
  MANAGEMENT_FILTERS_COUNTY,
  MANAGEMENT_FILTERS_REGION,
  MANAGEMENT_FILTERS_MERCH_SKUS,
  MANAGEMENT_FILTERS_SKUS_NUM,
  MANAGEMENT_FILTERS_FACINGS_NUM,
  MANAGEMENT_FILTERS_SALES_SKUS,
  MANAGEMENT_FILTERS_DISTRIBUTORS,
  MANAGEMENT_FILTERS_REP_ACCOUNT,
  MANAGEMENT_FILTERS_REP_METRIC,
  MANAGEMENT_FILTERS_DATA_SOURCE,
  MANAGEMENT_FILTERS_NO_ACTIVITY,
  MANAGEMENT_FILTERS_FREQUENCY,
  MANAGEMENT_FILTERS_REPS_INACTIVE,
};

// t&e page filters permissions
const TIME_EFFORTS_FILTERS_DATE_PERIOD = 'TIME_EFFORTS_FILTERS_DATE_PERIOD';
const TIME_EFFORTS_FILTERS_ACCOUNT = 'TIME_EFFORTS_FILTERS_ACCOUNT';
const TIME_EFFORTS_FILTERS_ADDRESS = 'TIME_EFFORTS_FILTERS_ADDRESS';
const TIME_EFFORTS_FILTERS_CITY = 'TIME_EFFORTS_FILTERS_CITY';
const TIME_EFFORTS_FILTERS_STATE = 'TIME_EFFORTS_FILTERS_STATE';
const TIME_EFFORTS_FILTERS_ZIP = 'TIME_EFFORTS_FILTERS_ZIP';
const TIME_EFFORTS_FILTERS_CHAIN = 'TIME_EFFORTS_FILTERS_CHAIN';
const TIME_EFFORTS_FILTERS_BANNER = 'TIME_EFFORTS_FILTERS_BANNER';
const TIME_EFFORTS_FILTERS_CHANNEL = 'TIME_EFFORTS_FILTERS_CHANNEL';
const TIME_EFFORTS_FILTERS_AREA = 'TIME_EFFORTS_FILTERS_AREA';
const TIME_EFFORTS_FILTERS_COUNTY = 'TIME_EFFORTS_FILTERS_COUNTY';
const TIME_EFFORTS_FILTERS_REGION = 'TIME_EFFORTS_FILTERS_REGION';
const TIME_EFFORTS_FILTERS_DATA_SOURCE = 'TIME_EFFORTS_FILTERS_DATA_SOURCE';
const TIME_EFFORTS_FILTERS_NO_ACTIVITY = 'TIME_EFFORTS_FILTERS_NO_ACTIVITY';
const TIME_EFFORTS_FILTERS_FREQUENCY = 'TIME_EFFORTS_FILTERS_FREQUENCY';
const TIME_EFFORTS_FILTERS_REPRESENTATIVE = 'TIME_EFFORTS_FILTERS_REPRESENTATIVE';
const TIME_EFFORTS_FILTERS_REPS_INACTIVE = 'TIME_EFFORTS_FILTERS_REPS_INACTIVE';

export const timeEffortsFiltersPermissionTypes = {
  TIME_EFFORTS_FILTERS_DATE_PERIOD,
  TIME_EFFORTS_FILTERS_ACCOUNT,
  TIME_EFFORTS_FILTERS_ADDRESS,
  TIME_EFFORTS_FILTERS_CITY,
  TIME_EFFORTS_FILTERS_STATE,
  TIME_EFFORTS_FILTERS_ZIP,
  TIME_EFFORTS_FILTERS_CHAIN,
  TIME_EFFORTS_FILTERS_BANNER,
  TIME_EFFORTS_FILTERS_CHANNEL,
  TIME_EFFORTS_FILTERS_AREA,
  TIME_EFFORTS_FILTERS_COUNTY,
  TIME_EFFORTS_FILTERS_REGION,
  TIME_EFFORTS_FILTERS_DATA_SOURCE,
  TIME_EFFORTS_FILTERS_NO_ACTIVITY,
  TIME_EFFORTS_FILTERS_FREQUENCY,
  TIME_EFFORTS_FILTERS_REPRESENTATIVE,
  TIME_EFFORTS_FILTERS_REPS_INACTIVE,
};

// schedule page filters permissions
const SCHEDULE_FILTERS_DATE_PERIOD = 'SCHEDULE_FILTERS_DATE_PERIOD';
const SCHEDULE_FILTERS_ACCOUNT = 'SCHEDULE_FILTERS_ACCOUNT';
const SCHEDULE_FILTERS_ADDRESS = 'SCHEDULE_FILTERS_ADDRESS';
const SCHEDULE_FILTERS_CITY = 'SCHEDULE_FILTERS_CITY';
const SCHEDULE_FILTERS_STATE = 'SCHEDULE_FILTERS_STATE';
const SCHEDULE_FILTERS_ZIP = 'SCHEDULE_FILTERS_ZIP';
const SCHEDULE_FILTERS_CHAIN = 'SCHEDULE_FILTERS_CHAIN';
const SCHEDULE_FILTERS_BANNER = 'SCHEDULE_FILTERS_BANNER';
const SCHEDULE_FILTERS_CHANNEL = 'SCHEDULE_FILTERS_CHANNEL';
const SCHEDULE_FILTERS_AREA = 'SCHEDULE_FILTERS_AREA';
const SCHEDULE_FILTERS_COUNTY = 'SCHEDULE_FILTERS_COUNTY';
const SCHEDULE_FILTERS_REGION = 'SCHEDULE_FILTERS_REGION';
const SCHEDULE_FILTERS_DATA_SOURCE = 'SCHEDULE_FILTERS_DATA_SOURCE';
const SCHEDULE_FILTERS_FREQUENCY = 'SCHEDULE_FILTERS_FREQUENCY';
const SCHEDULE_FILTERS_REPRESENTATIVE = 'SCHEDULE_FILTERS_REPRESENTATIVE';
const SCHEDULE_FILTERS_REPS_INACTIVE = 'SCHEDULE_FILTERS_REPS_INACTIVE';

export const scheduleFiltersPermissionTypes = {
  SCHEDULE_FILTERS_DATE_PERIOD,
  SCHEDULE_FILTERS_ACCOUNT,
  SCHEDULE_FILTERS_ADDRESS,
  SCHEDULE_FILTERS_CITY,
  SCHEDULE_FILTERS_STATE,
  SCHEDULE_FILTERS_ZIP,
  SCHEDULE_FILTERS_CHAIN,
  SCHEDULE_FILTERS_BANNER,
  SCHEDULE_FILTERS_CHANNEL,
  SCHEDULE_FILTERS_AREA,
  SCHEDULE_FILTERS_COUNTY,
  SCHEDULE_FILTERS_REGION,
  SCHEDULE_FILTERS_DATA_SOURCE,
  SCHEDULE_FILTERS_FREQUENCY,
  SCHEDULE_FILTERS_REPRESENTATIVE,
  SCHEDULE_FILTERS_REPS_INACTIVE,
};

// database page filters permissions
const DATABASE_FILTERS_ACCOUNT = 'DATABASE_FILTERS_ACCOUNT';
const DATABASE_FILTERS_ADDRESS = 'DATABASE_FILTERS_ADDRESS';
const DATABASE_FILTERS_CITY = 'DATABASE_FILTERS_CITY';
const DATABASE_FILTERS_STATE = 'DATABASE_FILTERS_STATE';
const DATABASE_FILTERS_ZIP = 'DATABASE_FILTERS_ZIP';
const DATABASE_FILTERS_CHAIN = 'DATABASE_FILTERS_CHAIN';
const DATABASE_FILTERS_BANNER = 'DATABASE_FILTERS_BANNER';
const DATABASE_FILTERS_CHANNEL = 'DATABASE_FILTERS_CHANNEL';
const DATABASE_FILTERS_AREA = 'DATABASE_FILTERS_AREA';
const DATABASE_FILTERS_COUNTY = 'DATABASE_FILTERS_COUNTY';
const DATABASE_FILTERS_REGION = 'DATABASE_FILTERS_REGION';
const DATABASE_FILTERS_ACCOUNT_SOURCE = 'DATABASE_FILTERS_ACCOUNT_SOURCE';
const DATABASE_FILTERS_DATA_SOURCE = 'DATABASE_FILTERS_DATA_SOURCE';
const DATABASE_FILTERS_FREQUENCY = 'DATABASE_FILTERS_FREQUENCY';
const DATABASE_FILTERS_REPRESENTATIVE = 'DATABASE_FILTERS_REPRESENTATIVE';

export const databaseFiltersPermissionTypes = {
  DATABASE_FILTERS_ACCOUNT,
  DATABASE_FILTERS_ADDRESS,
  DATABASE_FILTERS_CITY,
  DATABASE_FILTERS_STATE,
  DATABASE_FILTERS_ZIP,
  DATABASE_FILTERS_CHAIN,
  DATABASE_FILTERS_BANNER,
  DATABASE_FILTERS_CHANNEL,
  DATABASE_FILTERS_AREA,
  DATABASE_FILTERS_COUNTY,
  DATABASE_FILTERS_REGION,
  DATABASE_FILTERS_ACCOUNT_SOURCE,
  DATABASE_FILTERS_DATA_SOURCE,
  DATABASE_FILTERS_FREQUENCY,
  DATABASE_FILTERS_REPRESENTATIVE,
};
