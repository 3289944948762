class TopAccount {
  constructor({
    name, address, totalSales, totalCases,
  }) {
    this.name = name;
    this.address = address;
    this.totalSales = totalSales;
    this.totalCases = totalCases;
  }

  static parse(obj) {
    return new TopAccount({
      name: obj.AccountName,
      address: obj.Address,
      totalSales: Math.round(obj.TotalSales),
      totalCases: obj.TotalCases,
    });
  }
}

export default TopAccount;
