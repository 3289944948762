/* eslint-disable prefer-template */
import React, { Fragment } from 'react';
import { arrayOf, instanceOf } from 'prop-types';
import { Divider } from 'antd';

import BarChart from 'common/components/BarChart';
import SectionHeading from 'common/components/SectionHeading';

import TotalSale from 'salesModule/models/TotalSale';
import { transformDataForTooltip } from 'salesModule/tools/tooltipFormatter';
import { bottomTicksFormatter } from 'salesModule/tools/bottomTicksFormatter';

import { colorGenerator } from 'common/utilities/colorGenerator';

const HEADING = 'Sales';
const VALUE_SIGN = '$';

const TotalSalesChart = ({ totalSales }) => {
  const shouldRenderChart = totalSales && totalSales.length > 0;

  const totalSalesKeys = TotalSale.getTotalSalesKeys();
  const colorMap = colorGenerator(totalSalesKeys);
  const labelFormatter = label => `${VALUE_SIGN}${label.toLocaleString()}`;


  return (
    shouldRenderChart && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <BarChart
          data={totalSales}
          keys={totalSalesKeys}
          indexes="dateInterval"
          colorMap={colorMap}
          valueSign={VALUE_SIGN}
          labelFormatter={labelFormatter}
          bottomTicksFormatter={bottomTicksFormatter}
          transformDataForTooltip={transformDataForTooltip}
        />
        <Divider />
      </Fragment>
    )
  );
};

TotalSalesChart.propTypes = {
  totalSales: arrayOf(instanceOf(TotalSale)).isRequired,
};

export default TotalSalesChart;
