/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { databasePagePermissionTypes } from 'userModule/constants/permissions';


const databasePagePermissionSelector = state => state.session.permissions[databasePagePermissionTypes.DATABASE_PAGE];

export const permissionsSelector = createSelector(
  databasePagePermissionSelector,
  databasePagePermission => databasePagePermission.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  databasePagePermissionSelector,
  databasePagePermission => databasePagePermission.Filters || [],
);
