class BrandScorecard {
  constructor({ scorecardID, scorecardFilename }) {
    this.scorecardID = scorecardID;
    this.scorecardFilename = scorecardFilename;
  }

  static parse(obj) {
    return new BrandScorecard({
      scorecardID: obj.ID,
      scorecardFilename: obj.Filename,
    });
  }
}

export default BrandScorecard;
