import React, { PureComponent } from 'react';
import { func, bool } from 'prop-types';
import { Select } from 'antd';

import './index.scss';

const { Option } = Select;

class MetricSelect extends PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    shouldRenderMetricSelect: bool.isRequired,
  }

  render() {
    const { onChange, shouldRenderMetricSelect } = this.props;
    return (
      shouldRenderMetricSelect && (
        <div className="metric-select">
          <p className="metric-select__text">Metric</p>
          <div className="metric-select__input">
            <Select
              defaultValue="cases"
              style={{ width: 120 }}
              onChange={onChange}
            >
              <Option value="cases">Cases</Option>
              <Option value="dollars">Dollars</Option>
            </Select>
          </div>
        </div>
      )
    );
  }
}

export default MetricSelect;
