import { connect } from 'react-redux';

import { permissionsSelector } from 'scheduleModule/selectors/schedulePageSelectors';
import ScheduleReportsSection from 'scheduleModule/components/ScheduleReportsSection';

export default connect(
  state => ({
    isScheduleWeeklyReportDownloading:
      state.loading.effects.schedule.downloadScheduleWeeklyReport,
    isScheduleMonthlyReportDownloading:
      state.loading.effects.schedule.downloadScheduleMonthlyReport,
    isScheduleDailyReportDownloading:
      state.loading.effects.schedule.downloadScheduleDailyReport,
    isFilterOptionsFetching:
      state.loading.effects.schedule.fetchFilterOptions,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadScheduleDailyReport: () => dispatch.schedule.downloadScheduleDailyReport(),
    onDownloadScheduleWeeklyReport: () => dispatch.schedule.downloadScheduleWeeklyReport(),
    onDownloadScheduleMonthlyReport: () => dispatch.schedule.downloadScheduleMonthlyReport(),
  }),
)(ScheduleReportsSection);
