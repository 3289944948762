import React, { Fragment } from 'react';
import { instanceOf, arrayOf, string } from 'prop-types';

import PieChart from 'common/components/PieChart';
import SectionHeading from 'common/components/SectionHeading';

import PosPlacement from 'merchandisingModule/models/PosPlacement';

const HEADING = 'POS Placement';

const PosPlacementChart = ({ posPlacementData, colorMap, disabledKeys }) => {
  const pieChartData = new Array(posPlacementData.length)
    .fill(null)
    .map((_, i) => ({
      id: posPlacementData[i].placement,
      label: posPlacementData[i].placement,
      value: posPlacementData[i].amount,
      percentage: posPlacementData[i].percentage,
    }));

  const labelFormatter = label => `${label.value} (${label.percentage}%)`;

  const shouldRenderChart = posPlacementData.length > 0 && pieChartData[0].id;

  return shouldRenderChart && (
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <PieChart
        data={pieChartData.filter(
          pieData => !disabledKeys.includes(pieData.id),
        )}
        colorMap={colorMap}
        labelFormatter={labelFormatter}
      />
    </Fragment>
  );
};

PosPlacementChart.propTypes = {
  posPlacementData: arrayOf(instanceOf(PosPlacement)).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
};

export default PosPlacementChart;
