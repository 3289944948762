class Sku {
  constructor({ name, brand }) {
    this.name = name;
    this.brand = brand;
  }

  static parse(obj) {
    return new Sku({
      name: obj.Name,
      brand: obj.Brand,
    });
  }
}

export default Sku;
