/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import { bool, string } from 'prop-types';
import { Icon } from 'antd';

import Menu from 'common/containers/MenuContainer';
import BurgerMenu from 'common/containers//BurgerMenuContainer';
import Logo from './Logo';

import './index.scss';

class Header extends PureComponent {
  static propTypes = {
    authorized: bool.isRequired,
    brandFullName: string,
  };

  static defaultProps = {
    brandFullName: '',
  };

  state = {
    isMenuOpen: false,
  };

  onMenuIconClicked = () => {
    this.setState({
      isMenuOpen: true,
    });
  };

  onMenuOpen = (isOpen) => {
    this.setState({
      isMenuOpen: isOpen,
    });
  };

  render() {
    const {
      authorized,
      brandFullName,
    } = this.props;

    const { isMenuOpen } = this.state;

    return (
      <header className="header">
        <div className="header__inner">
          <Logo />
          <div className="header__info">
            {brandFullName && (
              <div className="header__welcome">
                {`Welcome, ${brandFullName}!`}
              </div>
            )}
            {authorized && (
            <>
              <div className="header__menu">
                <Menu />
              </div>
              <div className="header__burger-menu">
                <div className="header__burger-menu-icon">
                  <Icon type="menu-fold" onClick={this.onMenuIconClicked} />
                </div>
                <BurgerMenu
                  isOpen={isMenuOpen}
                  onChange={this.onMenuOpen}
                />
              </div>
            </>
            )}
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
