import React, { PureComponent } from 'react';
import Drawer from 'react-motion-drawer';
import { bool, func, instanceOf } from 'prop-types';
import { Icon } from 'antd';

import NavigationLink from 'common/components/Navigation/NavigationLink';

import {
  ACCOUNTS,
  MERCHANDISING,
  SALES,
  REPORTS,
  MANAGEMENT,
  UDS,
  SCHEDULE,
  DATABASE,
  ADMIN,
} from 'common/constants/routes';

import {
  accountsPagePermissionTypes,
  merchandisingPagePermissionTypes,
  salesPagePermissionTypes,
  reportsPagePermissionTypes,
  managementPagePermissionTypes,
  timeEffortsPagePermissionTypes,
  schedulePagePermissionTypes,
  databasePagePermissionTypes,
  adminPagePermissionTypes,
} from 'userModule/constants/permissions';

import Permissions from 'userModule/models/Permissions';

import './index.scss';

class BurgerMenu extends PureComponent {
  static propTypes = {
    permissions: instanceOf(Permissions).isRequired,
    isOpen: bool.isRequired,
    onChange: func.isRequired,
    logOut: func.isRequired,
  };

  onLinkClicked = () => {
    const { onChange } = this.props;
    onChange(false);
  }

  render() {
    const {
      onChange, isOpen, logOut, permissions,
    } = this.props;

    return (
      <Drawer
        right
        open={isOpen}
        onChange={onChange}
        className="drawer"
      >
        <nav className="drawer__navigation">
          {permissions[accountsPagePermissionTypes.ACCOUNTS_PAGE].Permissions
          && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={ACCOUNTS}>Accounts</NavigationLink>}
          {permissions[merchandisingPagePermissionTypes.MERCHANDISING_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={MERCHANDISING}>Merchandising</NavigationLink>}
          {permissions[salesPagePermissionTypes.SALES_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={SALES}>Sales</NavigationLink>}
          {permissions[reportsPagePermissionTypes.REPORTS_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={REPORTS}>Reports</NavigationLink>}
          {permissions[managementPagePermissionTypes.MANAGEMENT_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={MANAGEMENT}>Management</NavigationLink>}
          {permissions[timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={UDS}>Time &#38; Efforts</NavigationLink>}
          {permissions[schedulePagePermissionTypes.SCHEDULE_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={SCHEDULE}>Schedule</NavigationLink>}
          {permissions[databasePagePermissionTypes.DATABASE_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={DATABASE}>Database</NavigationLink>}
        </nav>
        <div className="drawer-settings">
          {permissions[adminPagePermissionTypes.ADMIN_PAGE].Permissions
            && <NavigationLink onClick={this.onLinkClicked} className="drawer__link" activeClassName="drawer__link_active" to={ADMIN}>Admin</NavigationLink>}
          <a
            onClick={logOut}
            href="/"
            className="drawer__link drawer__link_log-out"
          >
            <Icon type="logout" style={{ fontSize: '14px' }} />
            {' '}
            Log Out
          </a>
        </div>
      </Drawer>
    );
  }
}

export default BurgerMenu;
