import React from 'react';
import { string, func, bool } from 'prop-types';
import { Tag } from 'antd';
import classnames from 'classnames';

import './index.scss';

const LegendItem = ({
  name, color, isDisabled, isActive, onClick,
}) => {
  const className = classnames('legend-tag', {
    'legend-tag--disabled': isDisabled,
  });
  const legendItemInner = (
    <div className="legend-item__inner">
      <svg width="12" height="12">
        <rect width="12" height="12" style={{ fill: color }} />
      </svg>
      <p className="legend-item__name">{name}</p>
    </div>
  );

  return (
    onClick
        && (isActive ? (
          <Tag
            className={className}
            color={color}
            onClick={() => onClick(name)}
          >
            {name}
          </Tag>
        ) : (
          <div className="legend-item legend-item--inactive">
            {legendItemInner}
          </div>
        ))
  );
};

LegendItem.propTypes = {
  name: string,
  color: string.isRequired,
  isDisabled: bool.isRequired,
  onClick: func.isRequired,
};

LegendItem.defaultProps = {
  name: '',
};

export default LegendItem;
