import React, { PureComponent, Fragment } from 'react';
import {
  arrayOf, instanceOf, string,
} from 'prop-types';

import PieChart from 'common/components/PieChart';
import SectionHeading from 'common/components/SectionHeading';

import AccountsByDistributorOnDate from 'accountsModule/models/AccountsByDistributorOnDate';

const HEADING = 'New Accounts by Distributor';

class AccountsByDistributorOnDateChart extends PureComponent {
  render() {
    const {
      accountsByDistributor, colorMap, disabledKeys,
    } = this.props;

    const pieChartData = new Array(accountsByDistributor.length)
      .fill(null)
      .map((_, i) => ({
        id: accountsByDistributor[i].distributor,
        label: accountsByDistributor[i].distributor,
        value: accountsByDistributor[i].amount,
        percentage: accountsByDistributor[i].percentage,
      }));

    const labelFormatter = label => `${label.value} (${label.percentage}%)`;

    return (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <div className="accounts-pie-chart">
          <PieChart
            data={pieChartData.filter(
              pieData => !disabledKeys.includes(pieData.id),
            )}
            colorMap={colorMap}
            labelFormatter={labelFormatter}
          />
        </div>
      </Fragment>
    );
  }
}

/* eslint-disable react/no-unused-prop-types */
AccountsByDistributorOnDateChart.propTypes = {
  accountsByDistributor: arrayOf(instanceOf(AccountsByDistributorOnDate))
    .isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
};

export default AccountsByDistributorOnDateChart;
