// accounts page permissions
const ACCOUNTS_PAGE = 'Accounts';
const ACCOUNTS_PAGE_MAP = 'ACCOUNTS_PAGE_MAP';
const ACCOUNTS_PAGE_MAP_REPORT = 'ACCOUNTS_PAGE_MAP_REPORT';
const ACCOUNTS_PAGE_ACCOUNT_STATUS = 'ACCOUNTS_PAGE_ACCOUNT_STATUS';
const ACCOUNTS_PAGE_CHANNELS = 'ACCOUNTS_PAGE_CHANNELS';
const ACCOUNTS_PAGE_NO_INTEREST = 'ACCOUNTS_PAGE_NO_INTEREST';
const ACCOUNTS_PAGE_DISTRIBUTORS = 'ACCOUNTS_PAGE_DISTRIBUTORS';
const ACCOUNTS_PAGE_AUTH_CHANNELS = 'ACCOUNTS_PAGE_AUTH_CHANNELS';

// merchandising page permissions
const MERCHANDISING_PAGE = 'Merchandising';
const MERCHANDISING_PAGE_MAP = 'MERCHANDISING_PAGE_MAP';
const MERCHANDISING_PAGE_MAP_REPORT = 'MERCHANDISING_PAGE_MAP_REPORT';
const MERCHANDISING_PAGE_MERCHANDISING_STATUS = 'MERCHANDISING_PAGE_MERCHANDISING_STATUS';
const MERCHANDISING_PAGE_FACINGS_ADDED = 'MERCHANDISING_PAGE_FACINGS_ADDED';
const MERCHANDISING_PAGE_PRIMARY_PLACEMENT = 'MERCHANDISING_PAGE_PRIMARY_PLACEMENT';
const MERCHANDISING_PAGE_SECONDARY_PLACEMENT = 'MERCHANDISING_PAGE_SECONDARY_PLACEMENT';
const MERCHANDISING_PAGE_POS_PLACEMENT = 'MERCHANDISING_PAGE_POS_PLACEMENT';
const MERCHANDISING_PAGE_SHELF_PLACEMENT = 'MERCHANDISING_PAGE_SHELF_PLACEMENT';
const MERCHANDISING_PAGE_TAGS = 'MERCHANDISING_PAGE_TAGS';
const MERCHANDISING_PAGE_PROMO = 'MERCHANDISING_PAGE_PROMO';
const MERCHANDISING_PAGE_VOIDS = 'MERCHANDISING_PAGE_VOIDS';
const MERCHANDISING_PAGE_TOTAL_FACINGS = 'MERCHANDISING_PAGE_TOTAL_FACINGS';

// sales page permissions
const SALES_PAGE = 'Sales';
const SALES_PAGE_MAP = 'SALES_PAGE_MAP';
const SALES_PAGE_MAP_REPORT = 'SALES_PAGE_MAP_REPORT';
const SALES_PAGE_TOTAL = 'SALES_PAGE_TOTAL';
const SALES_PAGE_TOTAL_SKU = 'SALES_PAGE_TOTAL_SKU';
const SALES_PAGE_TOTAL_DISTRIBUTOR = 'SALES_PAGE_TOTAL_DISTRIBUTOR';
const SALES_PAGE_TOP_ACCOUNTS = 'SALES_PAGE_TOP_ACCOUNTS';

// reports page permissions
const REPORTS_PAGE = 'Reports';
const REPORTS_PAGE_ACCOUNT_LIST_REPORT = 'REPORTS_PAGE_ACCOUNT_LIST_REPORT';
const REPORTS_PAGE_ACCOUNT_STATUS_REPORT = 'REPORTS_PAGE_ACCOUNT_STATUS_REPORT';
const REPORTS_PAGE_MERCHANDISING_REPORT = 'REPORTS_PAGE_MERCHANDISING_REPORT';
const REPORTS_PAGE_VOIDS_REPORT = 'REPORTS_PAGE_VOIDS_REPORT';
const REPORTS_PAGE_ACCOUNTS_IMAGES_REPORT = 'REPORTS_PAGE_ACCOUNTS_IMAGES_REPORT';
const REPORTS_PAGE_SALES_REPORT = 'REPORTS_PAGE_SALES_REPORT';
const REPORTS_PAGE_SALES_BRAND_DISTRIBUTOR_REPORT = 'REPORTS_PAGE_SALES_BRAND_DISTRIBUTOR_REPORT';
const REPORTS_PAGE_SALES_SKU_DISTRIBUTOR_REPORT = 'REPORTS_PAGE_SALES_SKU_DISTRIBUTOR_REPORT';
const REPORTS_PAGE_SALES_BRAND_ACCOUNT_REPORT = 'REPORTS_PAGE_SALES_BRAND_ACCOUNT_REPORT';
const REPORTS_PAGE_SALES_SKU_ACCOUNT_REPORT = 'REPORTS_PAGE_SALES_SKU_ACCOUNT_REPORT';
const REPORTS_PAGE_CHAIN_REPORT = 'REPORTS_PAGE_CHAIN_REPORT';
const REPORTS_PAGE_SUMMARY_WEEKLY_REPORT = 'REPORTS_PAGE_SUMMARY_WEEKLY_REPORT';
const REPORTS_PAGE_SUMMARY_MONTHLY_REPORT = 'REPORTS_PAGE_SUMMARY_MONTHLY_REPORT';
const REPORTS_PAGE_WEEKLY_TABLES = 'REPORTS_PAGE_WEEKLY_TABLES';
const REPORTS_PAGE_MONTHLY_TABLES = 'REPORTS_PAGE_MONTHLY_TABLES';

// management page permissions
const MANAGEMENT_PAGE = 'Management';
const MANAGEMENT_PAGE_WEEKLY_BRAND_REPORT = 'MANAGEMENT_PAGE_WEEKLY_BRAND_REPORT';
const MANAGEMENT_PAGE_MONTHLY_BRAND_REPORT = 'MANAGEMENT_PAGE_MONTHLY_BRAND_REPORT';
const MANAGEMENT_PAGE_WEEKLY_ALL_REPORT = 'MANAGEMENT_PAGE_WEEKLY_ALL_REPORT';
const MANAGEMENT_PAGE_MONTHLY_ALL_REPORT = 'MANAGEMENT_PAGE_MONTHLY_ALL_REPORT';
const MANAGEMENT_PAGE_ACTIVE_REPORT = 'MANAGEMENT_PAGE_ACTIVE_REPORT';
const MANAGEMENT_PAGE_DEAD_REPORT = 'MANAGEMENT_PAGE_DEAD_REPORT';
const MANAGEMENT_PAGE_DEAD_HOT_REPORT = 'MANAGEMENT_PAGE_DEAD_HOT_REPORT';
const MANAGEMENT_PAGE_TOTAL_SALES_REPORT = 'MANAGEMENT_PAGE_TOTAL_SALES_REPORT';

// schedule page permissions
const SCHEDULE_PAGE = 'Schedule';
const SCHEDULE_PAGE_ASM_SCHEDULE = 'SCHEDULE_PAGE_ASM_SCHEDULE';
const SCHEDULE_PAGE_SUMMARY = 'SCHEDULE_PAGE_SUMMARY';
const SCHEDULE_PAGE_DAILY_REPORT = 'SCHEDULE_PAGE_DAILY_REPORT';
const SCHEDULE_PAGE_WEEKLY_REPORT = 'SCHEDULE_PAGE_WEEKLY_REPORT';
const SCHEDULE_PAGE_MONTHLY_REPORT = 'SCHEDULE_PAGE_MONTHLY_REPORT';
const SCHEDULE_PAGE_METRICS_DAILY_REPORT = 'SCHEDULE_PAGE_METRICS_DAILY_REPORT';
const SCHEDULE_PAGE_METRICS_WEEKLY_REPORT = 'SCHEDULE_PAGE_METRICS_WEEKLY_REPORT';
const SCHEDULE_PAGE_METRICS_MONTHLY_REPORT = 'SCHEDULE_PAGE_METRICS_MONTHLY_REPORT';
const SCHEDULE_PAGE_DAILY_BARS = 'SCHEDULE_PAGE_DAILY_BARS';

// database page permissions
const DATABASE_PAGE = 'Database';
const DATABASE_PAGE_MASTER_ACCOUNTS_LIST = 'DATABASE_PAGE_MASTER_ACCOUNTS_LIST';

// admin page permissions
const ADMIN_PAGE = 'Admin';
const ADMIN_PAGE_USERS = 'ADMIN_PAGE_USERS';
const ADMIN_PAGE_USERS_CREATE = 'ADMIN_PAGE_USERS_CREATE';
const ADMIN_PAGE_USERS_EDIT = 'ADMIN_PAGE_USERS_EDIT';
const ADMIN_PAGE_USERS_DELETE = 'ADMIN_PAGE_USERS_DELETE';
const ADMIN_PAGE_CHAIN = 'ADMIN_PAGE_CHAIN';
const ADMIN_PAGE_TARGET = 'ADMIN_PAGE_TARGET';
const ADMIN_PAGE_USERS_INFO = 'ADMIN_PAGE_USERS_INFO';
const ADMIN_PAGE_USERS_PERMISSIONS = 'ADMIN_PAGE_USERS_PERMISSIONS';
const ADMIN_PAGE_USERS_AUTOREPORT = 'ADMIN_PAGE_USERS_AUTOREPORT';
const ADMIN_PAGE_BRANDS = 'ADMIN_PAGE_BRANDS';
const ADMIN_PAGE_BRANDS_DELETE = 'ADMIN_PAGE_BRANDS_DELETE';
const ADMIN_PAGE_BRANDS_ARCHIVE = 'ADMIN_PAGE_BRANDS_ARCHIVE';

// t&e page permissions
const TIME_EFFORTS_PAGE = 'TimeAndEfforts';
const TIME_EFFORTS_PAGE_DAILY_REPORT = 'TIME_EFFORTS_PAGE_DAILY_REPORT';
const TIME_EFFORTS_PAGE_WEEKLY_REPORT = 'TIME_EFFORTS_PAGE_WEEKLY_REPORT';
const TIME_EFFORTS_PAGE_MONTHLY_REPORT = 'TIME_EFFORTS_PAGE_MONTHLY_REPORT';

export const accountsPagePermissionTypes = {
  ACCOUNTS_PAGE,
  ACCOUNTS_PAGE_MAP,
  ACCOUNTS_PAGE_MAP_REPORT,
  ACCOUNTS_PAGE_ACCOUNT_STATUS,
  ACCOUNTS_PAGE_CHANNELS,
  ACCOUNTS_PAGE_NO_INTEREST,
  ACCOUNTS_PAGE_DISTRIBUTORS,
  ACCOUNTS_PAGE_AUTH_CHANNELS,
};

export const merchandisingPagePermissionTypes = {
  MERCHANDISING_PAGE,
  MERCHANDISING_PAGE_MAP,
  MERCHANDISING_PAGE_MAP_REPORT,
  MERCHANDISING_PAGE_MERCHANDISING_STATUS,
  MERCHANDISING_PAGE_FACINGS_ADDED,
  MERCHANDISING_PAGE_PRIMARY_PLACEMENT,
  MERCHANDISING_PAGE_SECONDARY_PLACEMENT,
  MERCHANDISING_PAGE_POS_PLACEMENT,
  MERCHANDISING_PAGE_SHELF_PLACEMENT,
  MERCHANDISING_PAGE_TAGS,
  MERCHANDISING_PAGE_PROMO,
  MERCHANDISING_PAGE_VOIDS,
  MERCHANDISING_PAGE_TOTAL_FACINGS,
};

export const salesPagePermissionTypes = {
  SALES_PAGE,
  SALES_PAGE_MAP,
  SALES_PAGE_MAP_REPORT,
  SALES_PAGE_TOTAL,
  SALES_PAGE_TOTAL_DISTRIBUTOR,
  SALES_PAGE_TOTAL_SKU,
  SALES_PAGE_TOP_ACCOUNTS,
};

export const reportsPagePermissionTypes = {
  REPORTS_PAGE,
  REPORTS_PAGE_ACCOUNT_LIST_REPORT,
  REPORTS_PAGE_ACCOUNT_STATUS_REPORT,
  REPORTS_PAGE_MERCHANDISING_REPORT,
  REPORTS_PAGE_VOIDS_REPORT,
  REPORTS_PAGE_ACCOUNTS_IMAGES_REPORT,
  REPORTS_PAGE_SALES_REPORT,
  REPORTS_PAGE_SALES_BRAND_DISTRIBUTOR_REPORT,
  REPORTS_PAGE_SALES_SKU_DISTRIBUTOR_REPORT,
  REPORTS_PAGE_SALES_BRAND_ACCOUNT_REPORT,
  REPORTS_PAGE_SALES_SKU_ACCOUNT_REPORT,
  REPORTS_PAGE_CHAIN_REPORT,
  REPORTS_PAGE_SUMMARY_WEEKLY_REPORT,
  REPORTS_PAGE_SUMMARY_MONTHLY_REPORT,
  REPORTS_PAGE_WEEKLY_TABLES,
  REPORTS_PAGE_MONTHLY_TABLES,
};

export const managementPagePermissionTypes = {
  MANAGEMENT_PAGE,
  MANAGEMENT_PAGE_WEEKLY_BRAND_REPORT,
  MANAGEMENT_PAGE_MONTHLY_BRAND_REPORT,
  MANAGEMENT_PAGE_WEEKLY_ALL_REPORT,
  MANAGEMENT_PAGE_MONTHLY_ALL_REPORT,
  MANAGEMENT_PAGE_ACTIVE_REPORT,
  MANAGEMENT_PAGE_DEAD_REPORT,
  MANAGEMENT_PAGE_DEAD_HOT_REPORT,
  MANAGEMENT_PAGE_TOTAL_SALES_REPORT,
};

export const timeEffortsPagePermissionTypes = {
  TIME_EFFORTS_PAGE,
  TIME_EFFORTS_PAGE_DAILY_REPORT,
  TIME_EFFORTS_PAGE_WEEKLY_REPORT,
  TIME_EFFORTS_PAGE_MONTHLY_REPORT,
};

export const schedulePagePermissionTypes = {
  SCHEDULE_PAGE,
  SCHEDULE_PAGE_SUMMARY,
  SCHEDULE_PAGE_ASM_SCHEDULE,
  SCHEDULE_PAGE_DAILY_REPORT,
  SCHEDULE_PAGE_WEEKLY_REPORT,
  SCHEDULE_PAGE_MONTHLY_REPORT,
  SCHEDULE_PAGE_METRICS_DAILY_REPORT,
  SCHEDULE_PAGE_METRICS_WEEKLY_REPORT,
  SCHEDULE_PAGE_METRICS_MONTHLY_REPORT,
  SCHEDULE_PAGE_DAILY_BARS,
};

export const databasePagePermissionTypes = {
  DATABASE_PAGE,
  DATABASE_PAGE_MASTER_ACCOUNTS_LIST,
};

export const adminPagePermissionTypes = {
  ADMIN_PAGE,
  ADMIN_PAGE_USERS,
  ADMIN_PAGE_USERS_CREATE,
  ADMIN_PAGE_USERS_EDIT,
  ADMIN_PAGE_USERS_DELETE,
  ADMIN_PAGE_USERS_INFO,
  ADMIN_PAGE_USERS_PERMISSIONS,
  ADMIN_PAGE_CHAIN,
  ADMIN_PAGE_TARGET,
  ADMIN_PAGE_USERS_AUTOREPORT,
  ADMIN_PAGE_BRANDS,
  ADMIN_PAGE_BRANDS_DELETE,
  ADMIN_PAGE_BRANDS_ARCHIVE,
};
