import { connect } from 'react-redux';

import { permissionsSelector } from 'scheduleModule/selectors/schedulePageSelectors';
import SummaryReportsSection from 'scheduleModule/components/SummaryReportsSection';

export default connect(
  state => ({
    isMetricsDailyReportDownloading:
      state.loading.effects.schedule.downloadMetricsDailyReport,
    isMetricsWeeklyReportDownloading:
      state.loading.effects.schedule.downloadMetricsWeeklyReport,
    isMetricsMonthlyReportDownloading:
      state.loading.effects.schedule.downloadMetricsMonthlyReport,
    isFilterOptionsFetching:
      state.loading.effects.schedule.fetchFilterOptions,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadMetricsDailyReport: () => dispatch.schedule.downloadMetricsDailyReport(),
    onDownloadMetricsWeeklyReport: () => dispatch.schedule.downloadMetricsWeeklyReport(),
    onDownloadMetricsMonthlyReport: () => dispatch.schedule.downloadMetricsMonthlyReport(),
  }),
)(SummaryReportsSection);
