/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const sortedFilterOptionSecondaryPlacementsSelector = state => state.merchandising.filterOptions.secondaryPlacements.sort(
  (secondaryPlacementsFirst, secondaryPlacementsNext) => secondaryPlacementsFirst.sortOrder
    - secondaryPlacementsNext.sortOrder,
);

const accountsSelector = state => state.merchandising.accounts;

export const secondaryPlacementsSelectors = state => state.merchandising.filterOptions.secondaryPlacements.map(
  secondaryPlacement => secondaryPlacement.name,
);

export const colorMapSelector = createSelector(
  sortedFilterOptionSecondaryPlacementsSelector,
  filterOptionSecondaryPlacements => new Map(filterOptionSecondaryPlacements.reduce(
    (
      filterOptionSecondaryPlacementsColorMap,
      filterOptionSecondaryPlacement,
    ) => {
      filterOptionSecondaryPlacementsColorMap.push([
        filterOptionSecondaryPlacement.name,
        filterOptionSecondaryPlacement.color,
      ]);
      return filterOptionSecondaryPlacementsColorMap;
    },
    [],
  )),
);

export const shouldRenderMapSelector = createSelector(
  accountsSelector,
  accounts => accounts && accounts.length > 0,
);
