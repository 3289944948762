import DateInterval from 'salesModule/models/DateInterval';
import TotalSale from 'salesModule/models/TotalSale';
import TotalCase from 'salesModule/models/TotalCase';
import TotalSaleBySku from 'salesModule/models/TotalSaleBySku';
import TotalCaseBySku from 'salesModule/models/TotalCaseBySku';
import TotalSaleByDistributors from 'salesModule/models/TotalSaleByDistributors';
import TotalCaseByDistributors from 'salesModule/models/TotalCaseByDistributors';

/* eslint-disable import/prefer-default-export */
export const totalSalesCasesParser = (obj) => {
  const totalSales = [];
  const totalCases = [];
  const totalSalesBySku = [];
  const totalCasesBySku = [];
  const totalSalesByDistributors = [];
  const totalCasesByDistributors = [];

  obj.SalesIntervals.forEach((saleObj) => {
    const dateInterval = DateInterval.parse(saleObj, obj.IntervalType);

    totalSales.push(
      TotalSale.parse(dateInterval.stringify(), saleObj.Totals),
    );
    totalCases.push(
      TotalCase.parse(dateInterval.stringify(), saleObj.Totals),
    );

    totalSalesBySku.push(
      TotalSaleBySku.parse(dateInterval.stringify(), saleObj.Totals),
    );
    totalCasesBySku.push(
      TotalCaseBySku.parse(dateInterval.stringify(), saleObj.Totals),
    );

    totalSalesByDistributors.push(
      TotalSaleByDistributors.parse(
        dateInterval.stringify(),
        saleObj.Totals,
      ),
    );
    totalCasesByDistributors.push(
      TotalCaseByDistributors.parse(
        dateInterval.stringify(),
        saleObj.Totals,
      ),
    );
  });

  return {
    totalSales,
    totalCases,
    totalSalesBySku,
    totalCasesBySku,
    totalSalesByDistributors,
    totalCasesByDistributors,
  };
};
