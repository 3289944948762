class Summary {
  constructor({
    stores,
    forms,
    totalAccounts,
    totalAccountsPercentage,
    hours,
    mileage,
  }) {
    this.stores = stores;
    this.forms = forms;
    this.totalAccounts = totalAccounts;
    this.totalAccountsPercentage = totalAccountsPercentage;
    this.hours = hours;
    this.mileage = mileage;
  }

  static parse(obj) {
    return new Summary({
      stores: obj.StoresVisited,
      forms: obj.FormsSubmitted,
      totalAccounts: obj.TotalAccounts,
      totalAccountsPercentage: obj.TotalAccountsPercentage,
      hours: obj.HoursWorked,
      mileage: obj.MileageDriven,
    });
  }
}

export default Summary;
