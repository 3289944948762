import React from 'react';

import './index.scss';
import { string, number } from 'prop-types';

const BarChartTooltip = ({
  title, color, index, value, valueSign,
}) => (
  <div className="bar-chart-tooltip">
    <div className="bar-chart-tooltip__data-container">
      <div className="bar-chart-tooltip__title-container">
        <svg width="12" height="12">
          <rect width="12" height="12" fill={color} />
        </svg>
        <p className="bar-chart-tooltip__title">{title}</p>
      </div>
      <div className="bar-chart-tooltip__item">
        <span className="bar-chart-tooltip__index">
          {index}
:
        </span>
        <span className="bar-chart-tooltip__value">
          {valueSign}
          {value.toLocaleString()}
        </span>
      </div>
    </div>
  </div>
);

BarChartTooltip.propTypes = {
  title: string.isRequired,
  color: string.isRequired,
  index: string.isRequired,
  value: number.isRequired,
  valueSign: string.isRequired,
};

export default BarChartTooltip;
