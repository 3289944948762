import React, { PureComponent } from 'react';
import { bool, func } from 'prop-types';
import { Button } from 'antd';
import classnames from 'classnames';

import './index.scss';

class FilterButtons extends PureComponent {
  static propTypes = {
    isFilterToggled: bool.isRequired,
    isDefaultEnabled: bool.isRequired,

    revertFilters: func.isRequired,
    onSetDefaultFilters: func.isRequired,
  };

  render() {
    const {
      isFilterToggled, isDefaultEnabled, revertFilters, onSetDefaultFilters,
    } = this.props;

    const applyBtnClassName = classnames('filter-button', {
      'filter-button_attention': isFilterToggled,
    });

    const revertBtnProps = {
      disabled: !isFilterToggled,
    };

    const defaultBtnProps = {
      disabled: !isDefaultEnabled,
    };

    const revertBtnClassName = classnames(
      'filter-button',
      {
        'filter-button_revert': isFilterToggled,
      },
    );

    const defaultBtnClassName = classnames(
      'filter-button',
      {
        'filter-button_default': isDefaultEnabled,
      },
    );

    return (
      <>
        <Button
          className={applyBtnClassName}
          type="primary"
          icon="search"
          htmlType="submit"
          block
        >
              Apply
        </Button>
        <Button
          className={revertBtnClassName}
          type="ghost"
          icon="reload"
          block
          onClick={revertFilters}
          {...revertBtnProps}
        >
              Revert
        </Button>
        <Button
          className={defaultBtnClassName}
          type="ghost"
          icon="delete"
          block
          onClick={onSetDefaultFilters}
          {...defaultBtnProps}
        >
              Default
        </Button>
      </>
    );
  }
}

export default FilterButtons;
