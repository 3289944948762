import moment from 'moment';

import {
  DATE_FORMAT,
  MONTH_FORMAT,
  YEAR_FORMAT,
} from 'common/constants/dateConstants';

/* eslint-disable import/prefer-default-export, react/jsx-filename-extension */
export const bottomTicksFormatter = (dateInterval) => {
  const dateIntervalObject = JSON.parse(dateInterval);
  switch (dateIntervalObject.interval) {
  case 'day':
    return moment(dateIntervalObject.start).format(DATE_FORMAT);
  case 'week':
    return moment(dateIntervalObject.start).format(
      DATE_FORMAT,
    );
  case 'month':
    return `${moment(dateIntervalObject.start)
      .locale('en-us')
      .format(MONTH_FORMAT)} ${moment(
      dateIntervalObject.start,
    ).format(YEAR_FORMAT)}`;
  case 'year':
    return `${moment(dateIntervalObject.start).format(
      YEAR_FORMAT,
    )} - ${moment(dateIntervalObject.end).format(
      YEAR_FORMAT,
    )} `;
  default:
    return moment(dateIntervalObject.start).format(DATE_FORMAT);
  }
};
