import { connect } from 'react-redux';

import AsmScheduleTable from 'scheduleModule/components/AsmScheduleTable';
import { visitEventsColorsMap } from 'scheduleModule/constants/visitColors';
import { filteredAsmScheduleDataSelector } from 'scheduleModule/selectors/asmScheduleTableSelectors';

export default connect(
  state => ({
    asmScheduleData: filteredAsmScheduleDataSelector(state),
    visitEventsColorsMap,

    selectedEvents: state.schedule.selectedEvents,
  }),
  (dispatch, ownProps) => ({
    goNextWeek: ownProps.goNextWeek,
    goPrevWeek: ownProps.goPrevWeek,

    onEventSelect: dispatch.schedule.setSelectedEvents,
  }),
)(AsmScheduleTable);
