import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';

import * as models from 'store/models';

const loadingPlugin = createLoadingPlugin({});

const store = init({
  models,
  plugins: [loadingPlugin],
});

export default store;
