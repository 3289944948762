const FACINGS_ADDED = 'Facings Added';
class AddedFacings {
  constructor({ addedFacings, date }) {
    this[FACINGS_ADDED] = addedFacings;
    this.date = date;
  }

  static parse(obj) {
    return new AddedFacings({
      addedFacings: obj.FacingsAdded,
      date: obj.DateStart,
    });
  }

  static getKeys() {
    return [FACINGS_ADDED];
  }
}

export default AddedFacings;
