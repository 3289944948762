class Summary {
  constructor({
    totalOrders, accountOrders, totalAmount, totalCases,
  }) {
    this.totalOrders = totalOrders;
    this.accountOrders = accountOrders;
    this.totalAmount = totalAmount;
    this.totalCases = totalCases;
  }

  static parse(obj) {
    return new Summary({
      totalOrders: obj.OrdersSubmitted,
      accountOrders: obj.UniqueAccountOrders,
      totalAmount: obj.TotalAmount,
      totalCases: obj.TotalCasesCount,
    });
  }
}
export default Summary;
