/* eslint-disable no-param-reassign */

import produce from 'immer';
import fileDownload from 'js-file-download';
import moment from 'moment';

import {
  REPORTS_FILTERS_ENDPOINT,
  SCORECARDS_WEEK,
  SCORECARDS_MONTH,
  REPORT_ALL_VISITS,
  REPORT_ALL_STATUSES_ENDPOINT,
  REPORT_ACTIVE_ACCOUNTS,
  REPORT_VOID_REPORTS,
  SCORECARDS_DOWNLOAD,
  SCORECARDS_WEEK_REPORT,
  SCORECARDS_MONTH_REPORT,
  SALES_REPORT,
  SALES_DISTRIBUTOR_ALL_REPORT,
  SALES_BY_DISTRIBUTOR_BY_SKU,
  SALES_BY_BRAND,
  SALES_BY_ACCOUNT_BY_SKU,
  MERHCHANDISING_ACCOUNT_IMAGES_REPORT,
} from 'common/constants/apiRoutes';

import ajax from 'common/utilities/ajax';

import FilterOptions from 'reportsModule/models/FilterOptions';
import AccountsFilter from 'reportsModule/models/AccountsFilter';
import Scorecard from 'reportsModule/models/Scorecard';

import { showError } from 'common/helpers/notificationManager';
import { DATE_FORMAT } from 'common/constants/dateConstants';

const startOfCurrentWeek = moment().startOf('week').locale('en-us').format(DATE_FORMAT);

const initialState = {
  filter: new AccountsFilter({}),
  initialized: false,
  filterOptions: new FilterOptions({
    brands: [],
    channelTypes: {},
    cities: {},
    states: {},
    chains: {},
    representatives: {},
    representativesMetric: [],
    territories: {},
    accountsSources: [],
    distributors: [],
    salesSkus: [],
    merchandisingSkus: [],
    regions: {},
    counties: {},
    banners: {},
    frequencies: [],
    scorecardsByBrand: {},
  }),
  scorecardsWeeklyData: [],
  scorecardsMonthlyData: [],
  merchandisingAccountImagesReportDate: startOfCurrentWeek,
  selectedBrand: null,
};

export default {
  state: initialState,
  reducers: {
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    setWeeklyScorecards: produce((state, scorecardsWeeklyData) => {
      state.scorecardsWeeklyData = scorecardsWeeklyData;
    }),
    setMonthlyScorecards: produce((state, scorecardsMonthlyData) => {
      state.scorecardsMonthlyData = scorecardsMonthlyData;
    }),
    setMerchandisingAccountImagesReportDate: produce((state, startDate) => {
      state.merchandisingAccountImagesReportDate = startDate;
    }),
    setSelectedBrand: produce((state, brand) => {
      state.selectedBrand = brand;
    }),
    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(REPORTS_FILTERS_ENDPOINT);
      const filterOptionsModel = FilterOptions.parse(response.data);
      dispatch.reports.setFilterOptions(filterOptionsModel);
    },
    async fetchWeekScorecards(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCORECARDS_WEEK, config);
      const scorecardsWeeklyData = response.data.map(Scorecard.parseWeek);
      dispatch.reports.setWeeklyScorecards(scorecardsWeeklyData);
    },
    async fetchMonthScorecards(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCORECARDS_MONTH, config);
      const scorecardsMonthlyData = response.data.map(
        Scorecard.parseMonth,
      );
      dispatch.reports.setMonthlyScorecards(scorecardsMonthlyData);
    },

    async downloadScorecardsWeekReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(SCORECARDS_WEEK_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download scorecards week report',
        });
      }
    },

    async downloadScorecardsMonthReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(SCORECARDS_MONTH_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download scorecards month report',
        });
      }
    },

    async downloadScorecards(scorecardID) {
      try {
        const response = await ajax.get(SCORECARDS_DOWNLOAD, {
          responseType: 'blob',
          params: {
            id: scorecardID,
          },
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download scorecards',
        });
      }
    },

    async downloadVoidReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(REPORT_VOID_REPORTS, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download void report',
        });
      }
    },
    async downloadMerchandisingReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(REPORT_ALL_VISITS, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download merchandising report',
        });
      }
    },

    async downloadAccountStatusReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(REPORT_ALL_STATUSES_ENDPOINT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download account status report',
        });
      }
    },

    async downloadAccountList(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(REPORT_ACTIVE_ACCOUNTS, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download accounts list',
        });
      }
    },

    async downloadRawSalesReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(SALES_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download sales report',
        });
      }
    },

    async downloadSalesReportByDistributor(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(SALES_DISTRIBUTOR_ALL_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download sales report',
        });
      }
    },
    async downloadSkuSalesReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(SALES_BY_DISTRIBUTOR_BY_SKU, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download sales report',
        });
      }
    },
    async downloadBrandSalesReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(SALES_BY_BRAND, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download sales report',
        });
      }
    },
    async downloadAccountSalesReport(_, state) {
      const { filter } = state.reports;

      try {
        const response = await ajax.get(SALES_BY_ACCOUNT_BY_SKU, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download sales report',
        });
      }
    },

    async downloadMerchandisingAccountImagesReport(_, state) {
      const { filter } = state.reports;

      const config = {
        params: {
          ...filter.getApiParams(),
          dateStartReport: state.reports.merchandisingAccountImagesReportDate,
        },
      };

      try {
        const response = await ajax.get(MERHCHANDISING_ACCOUNT_IMAGES_REPORT, config);

        const merchandisingImagesReportDownloadLink = response.data.DownloadLink;

        const fileName = response.data.FileName;

        const reportResponse = await fetch(merchandisingImagesReportDownloadLink, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        });

        const reportFile = await reportResponse.blob();

        fileDownload(reportFile, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download merchandising account images report',
        });
      }
    },
  }),
};
