/* eslint-disable no-return-assign, no-param-reassign */

import React, { Fragment } from 'react';
import { arrayOf, instanceOf } from 'prop-types';
import uuidv4 from 'uuid/v4';

import { Table } from 'antd';

import SectionHeading from 'common/components/SectionHeading';


import TopAccount from 'salesModule/models/TopAccount';

import './index.scss';

const HEADING = 'Top 10 Accounts';

const columns = [
  {
    title: 'Account',
    dataIndex: 'name',
    className:
      'top-accounts-table__cell top-accounts-table__cell--left',
    align: 'center',
  },
  {
    title: 'Amount',
    dataIndex: 'totalSales',
    className: 'top-accounts-table__cell',
    align: 'center',
    sorter: (a, b) => a.totalSales - b.totalSales,
    render: text => `$${text}`,
  },
  {
    title: 'Cases',
    dataIndex: 'totalCases',
    className: 'top-accounts-table__cell',
    align: 'center',
    sorter: (a, b) => a.totalCases - b.totalCases,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    className:
      'top-accounts-table__cell top-accounts-table__cell--left',
    align: 'center',
  },
];

const TopAccountsTable = ({ topAccounts }) => {
  const topAccountsWithKeys = topAccounts.map((topAccount) => {
    topAccount.key = uuidv4();
    return topAccount;
  });

  const shouldRenderTable = topAccounts && topAccounts.length > 0;

  return (
    shouldRenderTable && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <div className="top-accounts-table">
          <Table
            dataSource={topAccountsWithKeys}
            columns={columns}
            pagination={false}
            bordered
          />
        </div>
      </Fragment>
    )
  );
};

TopAccountsTable.propTypes = {
  topAccounts: arrayOf(instanceOf(TopAccount)).isRequired,
};

export default (TopAccountsTable);
