import React from 'react';
import { instanceOf } from 'prop-types';
import { isNil } from 'lodash';
import { Divider } from 'antd';

import Summary from 'salesModule/models/Summary';
import SectionHeading from 'common/components/SectionHeading';

import './index.scss';

const SummarySection = ({ summary }) => {
  const {
    totalOrders,
    totalAmount,
    accountOrders,
    totalCases,
  } = summary;

  const shouldRenderSummarySection = !isNil(totalOrders)
  && !isNil(totalAmount)
  && !isNil(accountOrders)
  && !isNil(totalCases);

  const totalOrdersAmount = totalOrders?.toLocaleString();
  const totalAmountNumber = `$${totalAmount?.toLocaleString()}`;
  const accountOrdersAmount = accountOrders?.toLocaleString();
  const totalCasesAmount = totalCases?.toLocaleString();

  return (
    shouldRenderSummarySection && (
      <div className="sales-summary-wrapper">
        <Divider />
        <SectionHeading>Summary</SectionHeading>
        <div className="sales-summary">
          <div className="sales-summary__inner">
            <div className="sales-summary__card">
              <div className="sales-summary__card-head">
                {totalOrdersAmount}
              </div>
              <div className="sales-summary__card-body">Orders</div>
            </div>
            <div className="sales-summary__card">
              <div className="sales-summary__card-head">
                {accountOrdersAmount}
              </div>
              <div className="sales-summary__card-body">
              Accounts Ordered
              </div>
            </div>
            <div className="sales-summary__card">
              <div className="sales-summary__card-head">
                {totalAmountNumber}
              </div>
              <div className="sales-summary__card-body">Sales</div>
            </div>
            <div className="sales-summary__card">
              <div className="sales-summary__card-head">
                {totalCasesAmount}
              </div>
              <div className="sales-summary__card-body">Cases</div>
            </div>
          </div>
        </div>
        <Divider />
      </div>
    )
  );
};

SummarySection.propTypes = {
  summary: instanceOf(Summary).isRequired,
};

export default SummarySection;
