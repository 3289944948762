import React from 'react';
import {
  Input, Button, Icon, Form as AntForm, Alert,
} from 'antd';
import { Form, Field, Formik } from 'formik';
import {
  func,
  bool,
} from 'prop-types';

import './index.scss';

const FormItem = AntForm.Item;

const iconStyle = { color: 'rgba(0,0,0,.25)' };

const LoginForm = ({ onLogin, loginError }) => (
  <Formik
    onSubmit={values => onLogin({ username: values.username, password: values.password })
    }
    render={() => (
      <Form className="login-form">
        <div className="login-form__title">Login</div>
        {loginError && (
          <div className="login-form__alert-container">
            <Alert
              type="error"
              message="Invalid username or password"
            />
          </div>
        )}
        <Field name="username">
          {({ field }) => (
            <FormItem>
              <Input
                {...field}
                prefix={<Icon type="user" style={iconStyle} />}
                placeholder="Username"
              />
            </FormItem>
          )}
        </Field>
        <Field name="password">
          {({ field }) => (
            <FormItem>
              <Input.Password
                {...field}
                prefix={<Icon type="lock" style={iconStyle} />}
                placeholder="Password"
              />
            </FormItem>
          )}
        </Field>
        <div className="login-form__log-in-button-container">
          <Button
            size="large"
            className="login-form__log-in-button"
            block
            type="primary"
            htmlType="submit"
          >
            Sign in
          </Button>
        </div>
      </Form>
    )}
  />
);

LoginForm.propTypes = {
  onLogin: func.isRequired,
  loginError: bool.isRequired,
};

export default LoginForm;
