const TOTAL_FACINGS = 'Total Facings';

class TotalFacing {
  constructor({ date, totalFacings }) {
    this.date = date;
    this[TOTAL_FACINGS] = totalFacings;
  }

  static fieldNames = [TOTAL_FACINGS];

  static parse(obj) {
    return new TotalFacing({
      totalFacings: obj.TotalFacings,
      date: obj.Date,
    });
  }
}

export default TotalFacing;
