/* eslint-disable react/destructuring-assignment, react/no-did-update-set-state */
import React, { Fragment, PureComponent } from 'react';
import Legend from 'common/components/Legend';
import { Divider } from 'antd';

const withLegend = ({
  keys,
  legendItemsTitle,
  isLegendActive,
  disabledKeysByDefault = 'disabledFieldsByDefault',
  shouldRenderLegend = true,
  withDivider = 'withDivider',
}) => (WrappedComponent) => {
  class WithLegend extends PureComponent {
    state = {
      disabledKeys: [],
      isLoading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps[disabledKeysByDefault] !== this.props[disabledKeysByDefault]) {
        this.setState({
          disabledKeys: this.props[disabledKeysByDefault] || [],
        });
      }
    }

    disableKey = key => this.setState((state) => {
      const newDisabledKeys = [];
      const { disabledKeys } = state;
      if (
        !disabledKeys.includes(key)
        && disabledKeys.length !== this.props[keys].length - 1
      ) {
        newDisabledKeys.push(...disabledKeys, key);
      }
      return {
        ...state,
        isLoading: true,
        disabledKeys: newDisabledKeys,
      };
    });


    enableKey = key => this.setState((state) => {
      const { disabledKeys } = state;
      return {
        ...state,
        isLoading: true,
        disabledKeys: disabledKeys.filter(
          disableKey => disableKey !== key,
        ),
      };
    });

    filterKeys = (key) => {
      const { disabledKeys } = this.state;
      if (disabledKeys.includes(key)) {
        this.enableKey(key);
      } else {
        this.disableKey(key);
      }

      // Setting spinner here
      setTimeout(() => {
        this.setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }, 0);
    };

    render() {
      const { disabledKeys, isLoading } = this.state;
      const { colorMap } = this.props;
      const shouldRender = this.props[shouldRenderLegend] === undefined ? true : this.props[shouldRenderLegend];

      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            disabledKeys={disabledKeys}
            isLoading={isLoading}
          />
          {shouldRender && (
            <Legend
              legendItemsMap={colorMap}
              disabledItems={disabledKeys}
              legendItemsTitle={legendItemsTitle}
              isActive={isLegendActive}
              onItemClick={this.filterKeys}
            />
          )}
          {this.props[withDivider] && <Divider />}
        </Fragment>
      );
    }
  }

  WithLegend.displayName = `WithLegend(${WrappedComponent.displayName
    || WrappedComponent.name
    || 'Component'})`;

  return WithLegend;
};

export default withLegend;
