/* eslint-disable no-unused-expressions, no-lone-blocks */
import React, { PureComponent } from 'react';
import { Skeleton } from 'antd';
import {
  instanceOf, func, bool, arrayOf, string,
} from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';

import withNavigation from 'common/hocs/withNavigation';

import AccountsFilterModel from 'salesModule/models/AccountsFilter';
import FilterOptions from 'salesModule/models/FilterOptions';

import MetricSelect from 'salesModule/containers/MetricSelectContainer';
import SalesMap from 'salesModule/containers/SalesMapContainer';
import SummarySection from 'salesModule/containers/SummarySectionContainer';
import TotalSalesChart from 'salesModule/containers/TotalSalesChartContainer';
import TotalCasesChart from 'salesModule/containers/TotalCasesChartContainer';
import TotalSalesBySkuChart from 'salesModule/containers/TotalSalesBySkuChartContainer';
import TotalCasesBySkuChart from 'salesModule/containers/TotalCasesBySkuChartContainer';
import TotalSalesByDistributorsChart from 'salesModule/containers/TotalSalesByDistributorsChartContainer';
import TotalCasesByDistributorsChart from 'salesModule/containers/TotalCasesByDistributorsChartContainer';
import TopAccountsTable from 'salesModule/containers/TopAccountsTableContainer';

import { salesPagePermissionTypes } from 'userModule/constants/permissions';
import AccountsFilter from '../AccountsFilter';

import './index.scss';


class SalesPage extends PureComponent {
  static propTypes = {
    filterOptions: instanceOf(FilterOptions).isRequired,
    fetchAccountsData: func.isRequired,
    fetchTotalSales: func.isRequired,
    fetchFilterOptions: func.isRequired,
    fetchingFilterOptions: bool.isRequired,
    setAccountFilters: func.isRequired,
    accountsFilter: instanceOf(AccountsFilterModel).isRequired,
    accountsFilterInitialized: bool.isRequired,
    location: RouterPropTypes.location.isRequired,
    history: RouterPropTypes.history.isRequired,
    resetSalesStore: func.isRequired,
    childrenPermissions: arrayOf(string).isRequired,
    filterPermissions: arrayOf(string).isRequired,
  };

  state = {
    isDollarsView: false,
  };

  componentDidMount() {
    const { fetchFilterOptions } = this.props;
    fetchFilterOptions();
  }

  componentDidUpdate(prevProps) {
    const { fetchingFilterOptions, filterOptions, location } = this.props;

    if (prevProps.fetchingFilterOptions && !fetchingFilterOptions) {
      const defaultBrandName = filterOptions.brands[0].name;
      const { earliestDate } = filterOptions.brands.find(
        brandInfo => brandInfo.name === defaultBrandName,
      );
      const defaultAccountsSource = filterOptions.accountsSources[0];

      AccountsFilterModel.setDefaultParams({
        defaultBrand: defaultBrandName,
        defaultStartDate: earliestDate,
        defaultAccountsSource,
      });
      const filterModel = AccountsFilterModel.parseQueryString(
        location.search,
      );

      this.setAccountFilters(filterModel);
    }
  }

  componentWillUnmount() {
    const { resetSalesStore } = this.props;
    resetSalesStore();
  }

  setAccountFilters = (filterModel) => {
    const {
      setAccountFilters,
      fetchAccountsData,
      fetchTotalSales,
      location,
      history,
      childrenPermissions,
    } = this.props;

    const modelQueryString = filterModel.getQueryStringFilter();

    { childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_MAP,
      salesPagePermissionTypes.SALES_PAGE_TOP_ACCOUNTS) && fetchAccountsData(filterModel); }

    { childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOTAL,
      salesPagePermissionTypes.SALES_PAGE_TOTAL_SKU,
      salesPagePermissionTypes.SALES_PAGE_TOTAL_DISTRIBUTOR) && fetchTotalSales(filterModel); }

    setAccountFilters(filterModel);
    history.push({
      pathname: location.pathname,
      search: modelQueryString,
      hash: location.hash,
    });
  };

  handleViewChange = (value) => {
    if (value === 'cases') {
      return this.setState({
        isDollarsView: false,
      });
    }

    return this.setState({
      isDollarsView: true,
    });
  };

  render() {
    const {
      accountsFilterInitialized,
      accountsFilter,
      filterOptions,
      childrenPermissions,
      filterPermissions,
    } = this.props;

    const { isDollarsView } = this.state;

    return (
      <div className="sales-page">
        <Skeleton
          loading={!accountsFilterInitialized}
          className="sales-page__skeleton"
        >
          <AccountsFilter
            applyFilter={this.setAccountFilters}
            initialFilterModel={accountsFilter}
            filterOptions={filterOptions}
            permissions={filterPermissions}
          />
        </Skeleton>
        <div className="sales-page__inner">
          { childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_MAP) && <SalesMap /> }
          { childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_MAP) && <SummarySection /> }
          <MetricSelect onChange={this.handleViewChange} />
          {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOTAL) && isDollarsView && (
            <div className="sales-page__chart">
              <TotalSalesChart />
            </div>
          )}
          {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOTAL) && !isDollarsView && (
            <div className="sales-page__chart">
              <TotalCasesChart />
            </div>
          )}
          {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOTAL_SKU) && isDollarsView && (
            <div className="sales-page__chart">
              <TotalSalesBySkuChart />
            </div>
          )}
          {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOTAL_SKU) && !isDollarsView && (
            <div className="sales-page__chart">
              <TotalCasesBySkuChart />
            </div>
          )}
          {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOTAL_DISTRIBUTOR) && isDollarsView && (
            <div className="sales-page__chart">
              <TotalSalesByDistributorsChart />
            </div>
          )}
          {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOTAL_DISTRIBUTOR) && !isDollarsView && (
            <div className="sales-page__chart">
              <TotalCasesByDistributorsChart />
            </div>
          )}
          {childrenPermissions.includes(salesPagePermissionTypes.SALES_PAGE_TOP_ACCOUNTS) && (
            <div className="sales-page__chart">
              <TopAccountsTable />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withNavigation()(SalesPage);
