import React, { PureComponent } from 'react';
import { node } from 'prop-types';

import './index.scss';

class SectionHeading extends PureComponent {
    static propTypes = {
      children: node.isRequired,
    };

    render() {
      const { children } = this.props;

      return <h3 className="section-heading">{children}</h3>;
    }
}

export default SectionHeading;
