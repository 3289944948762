class CompetitorInfo {
  constructor({ competitor, amount, percentage }) {
    this.competitor = competitor;
    this.amount = amount;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new CompetitorInfo({
      competitor: obj.Competitor,
      amount: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default CompetitorInfo;
