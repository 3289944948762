import { connect } from 'react-redux';
import { permissionsSelector, filterPermissionsSelector } from 'reportsModule/selectors/reportsPageSelectors';

import ReportsPage from 'reportsModule/components/ReportsPage';

export default connect(
  state => ({
    userInfo: state.session.userInfo,
    fetchingFilterOptions: state.loading.effects.reports.fetchFilterOptions,
    filterInitialized: state.reports.initialized,
    filter: state.reports.filter,
    filterOptions: state.reports.filterOptions,
    scorecardsWeeklyData: state.reports.scorecardsWeeklyData,
    scorecardsMonthlyData: state.reports.scorecardsMonthlyData,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.reports.fetchFilterOptions,
    setFilter: dispatch.reports.setFilter,
    setSelectedBrand: dispatch.reports.setSelectedBrand,
    fetchWeekScorecards: dispatch.reports.fetchWeekScorecards,
    fetchMonthScorecards: dispatch.reports.fetchMonthScorecards,
    resetReportsStore: dispatch.reports.clear,
  }),
)(ReportsPage);
