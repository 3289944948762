class Brand {
  constructor({
    brand,
    isActive,
  }) {
    this.brand = brand;
    this.isActive = isActive;
  }

  static parse(obj) {
    return new Brand({
      brand: obj.Brand,
      isActive: obj.Active,
    });
  }
}

export default Brand;
