import React, { Fragment } from 'react';
import { instanceOf } from 'prop-types';
import { Divider } from 'antd';
import { isNil } from 'lodash';

import Summary from 'scheduleModule/models/Summary';

import './index.scss';

const SummarySection = ({ summary }) => {
  const {
    planned,
    visited,
    visitedPercentage,
    missed,
    missedPercentage,
    unplanned,
    hours,
    miles,
  } = summary;

  const shouldRenderSummary = !isNil(planned)
    && !isNil(visited)
    && !isNil(visitedPercentage)
    && !isNil(missed)
    && !isNil(missedPercentage)
    && !isNil(unplanned)
    && !isNil(hours)
    && !isNil(miles);

  const plannedAmount = planned?.toLocaleString();
  const visitedAmount = `${String(visited)} (${String(
    visitedPercentage,
  )}%)`;
  const missedAmount = `${String(missed)} (${String(
    missedPercentage,
  )}%)`;
  const unplannedAmount = unplanned?.toLocaleString();
  const hoursAmount = hours?.toLocaleString();
  const mileageAmount = miles?.toLocaleString();

  return (
    shouldRenderSummary && (
      <Fragment>
        <h2 className="schedule-summary__heading">Summary</h2>
        <div className="schedule-summary">
          <div className="schedule-summary__inner">
            <div className="schedule-summary__card">
              <div className="schedule-summary__card-head">
                {plannedAmount}
              </div>
              <div className="schedule-summary__card-body">
                Planned Visits
              </div>
            </div>
            <div className="schedule-summary__card">
              <div className="schedule-summary__card-head">
                {visitedAmount}
              </div>
              <div className="schedule-summary__card-body">
                Accounts Visited
              </div>
            </div>
            <div className="schedule-summary__card">
              <div className="schedule-summary__card-head">
                {missedAmount}
              </div>
              <div className="schedule-summary__card-body">
                Missed Visits
              </div>
            </div>
            <div className="schedule-summary__card">
              <div className="schedule-summary__card-head">
                {unplannedAmount}
              </div>
              <div className="schedule-summary__card-body">
                Unplanned Visits
              </div>
            </div>
            <div className="schedule-summary__card">
              <div className="schedule-summary__card-head">
                {hoursAmount}
              </div>
              <div className="schedule-summary__card-body">
                Hours Worked
              </div>
            </div>
            <div className="schedule-summary__card">
              <div className="schedule-summary__card-head">
                {mileageAmount}
              </div>
              <div className="schedule-summary__card-body">
                Mileage Driven
              </div>
            </div>
          </div>
          <Divider />
        </div>
      </Fragment>
    )
  );
};

SummarySection.propTypes = {
  summary: instanceOf(Summary).isRequired,
};

export default SummarySection;
