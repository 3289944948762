/* eslint-disable react/prefer-stateless-function, react/destructuring-assignment, no-nested-ternary */
import React, { PureComponent } from 'react';
import { instanceOf, func } from 'prop-types';
import {
  Input, Button, Icon, Form, Select, Switch,
} from 'antd';

import FilterOptions from 'adminModule/models/FilterOptions';
import User from 'adminModule/models/User';

const { Option } = Select;

const FormItem = Form.Item;

const iconStyle = { color: 'rgba(0,0,0,.25)' };

const ownerRoleValues = [
  {
    value: 1,
    label: 'Admin',
  },
  {
    value: 0,
    label: 'User',
  },
];

const adminRoleValues = [
  {
    value: 0,
    label: 'User',
  },
];

const CommonOption = ({ key, value, label }) => (
  <Option key={key} value={value}>
    {label}
  </Option>
);

const formItemLayout = {
  colon: false,
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 18 },
  },
};

const submitButtonLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 8 },
    md: { span: 18, offset: 6 },
  },
};

class EditForm extends PureComponent {
  static propTypes = {
    filterOptions: instanceOf(FilterOptions).isRequired,
    currentUser: instanceOf(User).isRequired,
    onEdit: func.isRequired,
  };

  state = {
    isAllBrandsSelected: this.props.currentUser.isAllBrandsAvaliable,
    selectedBrands: this.props.currentUser.brands,
  };

  onSwitchToAllBrands = (isChecked) => {
    const {
      form: { resetFields },
      filterOptions: { brands },
    } = this.props;

    resetFields(['allBrands', 'brands']);

    this.setState(() => ({
      isAllBrandsSelected: isChecked,
      selectedBrands: isChecked ? brands.sort() : [],
    }));
  };

  handleSelectBrandsChange = (value) => {
    const {
      form: { resetFields },
      filterOptions: { brands },
    } = this.props;

    this.setState(() => ({
      isAllBrandsSelected: value.length === brands.length,
      selectedBrands: value.sort(),
    }));

    resetFields(['allBrands', 'brands']);
  };

  handleSelectBrandsSort = () => {
    const {
      form: { resetFields },
    } = this.props;

    resetFields(['allBrands', 'brands']);

    this.setState(prevState => ({ selectedBrands: prevState.selectedBrands.sort() }));
  };

  submitForm = (e) => {
    e.preventDefault();

    const {
      form: { validateFields, resetFields },
      onEdit,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        onEdit({
          username: values.username,
          password: values.password,
          active: values.active,
          allBrands: values.allBrands,
          brands: values.brands,
          brandFullName: values.brandFullName,
          isAdmin: Boolean(values.isAdmin),
        });
        resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      filterOptions,
      isSuperAdmin,
      isAdmin,
      currentUser,
    } = this.props;

    const {
      login,
      isActive,
      password,
      brandFullName,
    } = currentUser;

    const { isAllBrandsSelected, selectedBrands } = this.state;
    return (
      <Form onSubmit={this.submitForm}>
        <FormItem {...formItemLayout} label="Username">
          {getFieldDecorator('username', {
            initialValue: login,
            rules: [
              {
                required: true,
                message: 'Username is required!',
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={iconStyle} />}
              disabled
            />,
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Password">
          {getFieldDecorator('password', {
            initialValue: password,
            rules: [
              {
                required: true,
                message: 'Password is required!',
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={iconStyle} />}
            />,
          )}
        </FormItem>
        {isSuperAdmin && (
          <FormItem {...formItemLayout} label="Role">
            {getFieldDecorator('isAdmin', {
              initialValue: (currentUser.isAdmin ? 1 : 0),
            })(
              <Select>
                {ownerRoleValues.map(role => CommonOption({
                  key: role.label,
                  value: role.value,
                  label: role.label,
                }))}
              </Select>,
            )}
          </FormItem>
        )}
        {isAdmin
          && !isSuperAdmin && (
          <FormItem {...formItemLayout} label="Role">
            {getFieldDecorator('isAdmin', {
              initialValue: (currentUser.isAdmin ? 1 : 0),
            })(
              <Select>
                {adminRoleValues.map(role => CommonOption({
                  key: role.label,
                  value: role.value,
                  label: role.label,
                }))}
              </Select>,
            )}
          </FormItem>
        )}
        <FormItem {...formItemLayout} label="Company">
          {getFieldDecorator('brandFullName', {
            initialValue: brandFullName,
            rules: [
              {
                required: true,
                message: 'Company name is required!',
              },
            ],
          })(
            <Input
              prefix={<Icon type="team" style={iconStyle} />}
              placeholder="Please input a company name"
            />,
          )}
        </FormItem>
        <Form.Item {...formItemLayout} label="Active User">
          {getFieldDecorator('active', {
            valuePropName: 'checked',
            initialValue: isActive,
          })(<Switch />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="All Brands">
          {getFieldDecorator('allBrands', {
            valuePropName: 'checked',
            initialValue: isAllBrandsSelected,
            value: isAllBrandsSelected,
          })(<Switch onChange={this.onSwitchToAllBrands} />)}
        </Form.Item>
        <FormItem {...formItemLayout} label="Brands">
          {filterOptions.brands
            && getFieldDecorator('brands', {
              initialValue: selectedBrands,
              values: selectedBrands,
              rules: [
                {
                  required: true,
                  message: 'Brands are required!',
                  type: 'array',
                },
              ],
            })(
              <Select
                placeholder="Please select brands"
                mode="multiple"
                allowClear
                onChange={this.handleSelectBrandsChange}
                onBlur={this.handleSelectBrandsSort}
              >
                {filterOptions.brands.map(brand => CommonOption({
                  key: brand,
                  value: brand,
                  label: brand,
                }))}
              </Select>,
            )}

        </FormItem>
        <FormItem {...submitButtonLayout}>
          <Button
            className="edit-form__edit-button"
            icon="form"
            type="primary"
            htmlType="submit"
          >
            Edit user
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(EditForm);

export default WrappedForm;
