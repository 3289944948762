class AmountByChannel {
  constructor({ channel, amount, percentage }) {
    this.channel = channel;
    this.amount = amount;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new AmountByChannel({
      channel: obj.Channel,
      amount: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default AmountByChannel;
