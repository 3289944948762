/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const shelfPlacementDataSelector = state => state.merchandising.shelfPlacementData;

export const currentShelfPlacementNamesSelector = createSelector(
  shelfPlacementDataSelector,
  shelfPlacementData => shelfPlacementData.map(shelfPlacement => shelfPlacement.placement),
);

export const colorMapSelector = createSelector(
  currentShelfPlacementNamesSelector,
  currentShelfPlacementNames => colorGenerator(currentShelfPlacementNames),
);
