import { connect } from 'react-redux';
import { permissionsSelector, filterPermissionsSelector } from 'udsModule/selectors/udsPageSelectors';

import UdsPage from 'udsModule/components/UdsPage';

export default connect(
  state => ({
    userInfo: state.session.userInfo,
    fetchingFilterOptions: state.loading.effects.uds.fetchFilterOptions,
    isFilterInitialized: state.uds.initialized,
    filter: state.uds.filter,
    filterOptions: state.uds.filterOptions,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.uds.fetchFilterOptions,
    setFilter: dispatch.uds.setFilter,
    fetchAsmUdsData: dispatch.uds.fetchAsmUdsData,
    fetchBrandUdsData: dispatch.uds.fetchBrandUdsData,
    fetchTimeEffortsData: dispatch.uds.fetchTimeEffortsData,
    fetchTimeEffortsWeekData: dispatch.uds.fetchTimeEffortsWeekData,
    fetchTimeEffortsMonthData: dispatch.uds.fetchTimeEffortsMonthData,
    resetUdsStore: dispatch.uds.clear,
  }),
)(UdsPage);
