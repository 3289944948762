class ScheduleAccount {
  constructor({ name, address, status }) {
    this.name = name;
    this.address = address;
    this.status = status;
  }

  static parse(obj) {
    return new ScheduleAccount({
      name: obj.StoreName,
      address: obj.Address,
      status: obj.Status,
    });
  }
}

export default ScheduleAccount;
