import { connect } from 'react-redux';

import DownloadAccountsButton from 'common/components/DownloadAccountsButton';

export default connect(
  state => ({
    isDownloadingAccounts:
      state.loading.effects.merchandising.downloadMerchandisingPageReport,
  }),
  dispatch => ({
    onDownloadAccounts: () => dispatch.merchandising.downloadMerchandisingPageReport(),
  }),
)(DownloadAccountsButton);
