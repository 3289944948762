import React, { Fragment } from 'react';
import { Divider, Button } from 'antd';

import SectionHeading from 'common/components/SectionHeading';
import {
  bool, func, arrayOf, string,
} from 'prop-types';
import { reportsPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

const HEADING = 'Sales Reports';

const SalesReportsSection = ({
  onDownloadRawSalesReport,
  isRawSalesReportDownloading,
  onDownloadSalesReportByDistributor,
  isSalesReportByDistributorDownloading,
  onDownloadSkuSalesReport,
  isSkuSalesReportDownloading,
  onDownloadBrandSalesReports,
  isBrandSalesReportDownloading,
  onDownloadAccountSalesReports,
  isAccountSalesReportDownloading,
  childrenPermissions,
}) => (
  <Fragment>
    <SectionHeading>{HEADING}</SectionHeading>
    <section className="sales-reports-section">
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_SALES_REPORT) && (
        <div className="sales-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadRawSalesReport}
            loading={isRawSalesReportDownloading}
          >
            Sales Report
          </Button>
        </div>
      )}
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_SALES_BRAND_DISTRIBUTOR_REPORT) && (
        <div className="sales-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadSalesReportByDistributor}
            loading={isSalesReportByDistributorDownloading}
          >
            Brand Sales by Distributor
          </Button>
        </div>
      )}
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_SALES_SKU_DISTRIBUTOR_REPORT) && (
        <div className="sales-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadSkuSalesReport}
            loading={isSkuSalesReportDownloading}
          >
            SKU Sales by Distributor
          </Button>
        </div>
      )}
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_SALES_BRAND_ACCOUNT_REPORT) && (
        <div className="sales-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadBrandSalesReports}
            loading={isBrandSalesReportDownloading}
          >
            Brand Sales by Account
          </Button>
        </div>
      )}
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_SALES_SKU_ACCOUNT_REPORT) && (
        <div className="sales-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadAccountSalesReports}
            loading={isAccountSalesReportDownloading}
          >
            SKU Sales by Account
          </Button>
        </div>
      )}
    </section>
    <Divider />
  </Fragment>
);

SalesReportsSection.propTypes = {
  onDownloadRawSalesReport: func.isRequired,
  isRawSalesReportDownloading: bool.isRequired,
  onDownloadSalesReportByDistributor: func.isRequired,
  isSalesReportByDistributorDownloading: bool.isRequired,
  onDownloadSkuSalesReport: func.isRequired,
  isSkuSalesReportDownloading: bool.isRequired,
  onDownloadBrandSalesReports: func.isRequired,
  isBrandSalesReportDownloading: bool.isRequired,
  onDownloadAccountSalesReports: func.isRequired,
  isAccountSalesReportDownloading: bool.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default SalesReportsSection;
