const GOLD = 'gold';
const GREEN = 'green';
const RED = 'red';
const BLUE = 'blue';

const colorHexMap = new Map([
  [GOLD, '#ffe58f'],
  [GREEN, '#b7eb8f'],
  [RED, '#ffa39e'],
  [BLUE, '#91d5ff'],
]);

export const visitEventsColorsMap = new Map([
  ['Upcoming', GOLD],
  ['Visited', GREEN],
  ['Missed', RED],
  ['Unplanned', BLUE],
]);


export const visitEventsColorsHexMap = new Map([
  ['Upcoming', colorHexMap.get(GOLD)],
  ['Visited', colorHexMap.get(GREEN)],
  ['Missed', colorHexMap.get(RED)],
  ['Unplanned', colorHexMap.get(BLUE)],
]);
