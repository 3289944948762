/* eslint-disable no-shadow */
import React, { PureComponent } from 'react';
import {
  func, bool, instanceOf, arrayOf, string,
} from 'prop-types';
import { location, history } from 'react-router-prop-types';
import { Skeleton } from 'antd';

import withNavigation from 'common/hocs/withNavigation';

import FilterOptions from 'udsModule/models/FilterOptions';
import AccountsFilterModel from 'udsModule/models/AccountsFilter';

import AccountsFilter from 'udsModule/components/AccountsFilter';

import TimeEffortsReportsSection from 'udsModule/containers/TimeEffortsReportsSectionContainer';

import './index.scss';

class UdsPage extends PureComponent {
    static propTypes = {
      filterOptions: instanceOf(FilterOptions).isRequired,
      filter: instanceOf(AccountsFilterModel).isRequired,
      fetchingFilterOptions: bool.isRequired,
      isFilterInitialized: bool.isRequired,
      location: location.isRequired,
      history: history.isRequired,
      setFilter: func.isRequired,
      fetchFilterOptions: func.isRequired,
      resetUdsStore: func.isRequired,
      filterPermissions: arrayOf(string).isRequired,
    };

    componentDidMount() {
      const { fetchFilterOptions } = this.props;
      fetchFilterOptions();
    }

    componentDidUpdate(prevProps) {
      const { fetchingFilterOptions, filterOptions, location } = this.props;

      if (prevProps.fetchingFilterOptions && !fetchingFilterOptions) {
        const defaultBrandName = filterOptions.brands[0].name;
        const { earliestDate } = filterOptions.brands.find(
          brandInfo => brandInfo.name === defaultBrandName,
        );
        const defaultAccountsSource = filterOptions.accountsSources[0];

        AccountsFilterModel.setDefaultParams({
          defaultBrand: defaultBrandName,
          defaultStartDate: earliestDate,
          defaultAccountsSource,
        });
        const filterModel = AccountsFilterModel.parseQueryString(
          location.search,
        );

        this.setAccountFilters(filterModel);
      }
    }

    componentWillUnmount() {
      const { resetUdsStore } = this.props;
      resetUdsStore();
    }

    setAccountFilters = (filterModel) => {
      const {
        setFilter,
        location,
        history,
      } = this.props;
      const ignore = [];

      const modelQueryString = filterModel.getQueryStringFilter(ignore);

      setFilter(filterModel);
      history.push({
        pathname: location.pathname,
        search: modelQueryString,
        hash: location.hash,
      });
    };

    render() {
      const {
        filter,
        filterOptions,
        isFilterInitialized,
        filterPermissions,
      } = this.props;

      return (
        <div className="uds-page">
          <Skeleton
            loading={!isFilterInitialized}
            className="uds-page__skeleton"
          >
            <AccountsFilter
              applyFilter={this.setAccountFilters}
              initialFilterModel={filter}
              filterOptions={filterOptions}
              permissions={filterPermissions}
            />
          </Skeleton>
          <div className="uds-page__inner">
            <TimeEffortsReportsSection />
          </div>
        </div>
      );
    }
}

export default withNavigation()(UdsPage);
