class Account {
  constructor({
    id,
    address,
    latitude,
    longitude,
    name,
    primaryPlacementPictureUrl,
    secondaryPlacementPictureUrl,
    storePictureUrl,
    date,
    authorisedDate,
    totalVisits,
    status,
    representative,
    nextScheduledDate,
    secondaryPlacementCount,
    secondaryPlacementCountPercentage,
  }) {
    this.id = id;
    this.address = address;
    this.latitude = latitude;
    this.longitude = longitude;
    this.name = name;
    this.primaryPlacementPictureUrl = primaryPlacementPictureUrl;
    this.secondaryPlacementPictureUrl = secondaryPlacementPictureUrl;
    this.storePictureUrl = storePictureUrl;
    this.date = date;
    this.authorisedDate = authorisedDate;
    this.totalVisits = totalVisits;
    this.status = status;
    this.representative = representative;
    this.nextScheduledDate = nextScheduledDate;
    this.secondaryPlacementCount = secondaryPlacementCount;
    this.secondaryPlacementCountPercentage = secondaryPlacementCountPercentage;
  }

  static parse(obj) {
    return new Account({
      id: obj.ID,
      name: obj.Name,
      address: obj.Address,
      latitude: obj.Latitude,
      longitude: obj.Longitude,
      primaryPlacementPictureUrl:
        obj.MerchandisingInfo.PrimaryPlacementImageLink,
      date: obj.MerchandisingInfo.Date,
      authorisedDate: obj.MerchandisingInfo.DateAuthorized,
      storePictureUrl: obj.StorePicLink,
      secondaryPlacementPictureUrl:
        obj.MerchandisingInfo.SecondaryPlacementImageLink,
      totalVisits: obj.MerchandisingInfo.TotalVisits,
      status: obj.MerchandisingInfo.Status,
      representative: obj.RepresentativeName,
      nextScheduledDate: obj.NextScheduledVisit,
      secondaryPlacementCount: obj.MerchandisingInfo.SecondaryPlacementsCount,
      secondaryPlacementCountPercentage: obj.MerchandisingInfo.SecondaryPlacementsCountPercentage,
    });
  }

  getCoordinates() {
    return [this.latitude, this.longitude];
  }
}

export default Account;
