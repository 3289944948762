import moment from 'moment';

class WorkStats {
  constructor({
    date,
    representative,
    minutesWorked,
    miles,
    workdayStart,
    workdayEnd,
    firstVisit,
    lastVisit,
    minutesTravel,
    minutesAtPlace,
    minutesAtPause,
    visits,
  }) {
    this.date = date;
    this.representative = representative;
    this.minutesWorked = minutesWorked;
    this.miles = miles;
    this.workdayStart = workdayStart;
    this.workdayEnd = workdayEnd;
    this.firstVisit = firstVisit;
    this.lastVisit = lastVisit;
    this.minutesTravel = minutesTravel;
    this.minutesAtPlace = minutesAtPlace;
    this.minutesAtPause = minutesAtPause;
    this.visits = visits;
  }

  static parseTime(date) {
    return moment(date).isValid() ? moment(date).format('hh:mm A') : '';
  }

  static parseMinutes(minutes) {
    return moment
      .utc()
      .startOf('day')
      .add(minutes, 'minutes')
      .format('HH:mm');
  }

  static parse(obj) {
    return new WorkStats({
      date: obj.Date,
      representative: obj.Representative,
      minutesWorked: WorkStats.parseMinutes(obj.MinutesWorked),
      miles: obj.MileageDriven,
      workdayStart: WorkStats.parseTime(obj.WorkdayStart),
      workdayEnd: WorkStats.parseTime(obj.WorkdayEnd),
      firstVisit: WorkStats.parseTime(obj.FirstVisit),
      lastVisit: WorkStats.parseTime(obj.LastVisit),
      minutesTravel: WorkStats.parseMinutes(obj.MinutesInTravel),
      minutesAtPlace: WorkStats.parseMinutes(obj.MinutesAtPlace),
      minutesAtPause: WorkStats.parseMinutes(obj.MinutesInPause),
      visits: obj.VisitCount,
    });
  }

    static fieldNamesMap = new Map([
      ['date', 'Date'],
      ['minutesWorked', 'Time Worked'],
      ['miles', 'Mileage Driven'],
      ['workdayStart', 'Workday Start'],
      ['workdayEnd', 'Workday End'],
      ['firstVisit', 'First Visit'],
      ['lastVisit', 'Last Visit'],
      ['minutesTravel', 'Time in Travel'],
      ['minutesAtPlace', 'Time at Place'],
      ['minutesAtPause', 'Time at Pause'],
      ['visits', 'Visits'],
    ]);

    static columnTitles = [
      'date',
      'minutesWorked',
      'miles',
      'workdayStart',
      'workdayEnd',
      'firstVisit',
      'lastVisit',
      'minutesTravel',
      'minutesAtPlace',
      'minutesAtPause',
      'visits',
    ];
}

export default WorkStats;
