import { notification } from 'antd';

const createNotification = (type, message, description) => notification[type]({
  message,
  description,
});

const showWarning = ({ message, description }) => createNotification('warning', message, description);

const showError = ({ message, description }) => createNotification('error', message, description);

const showSuccess = ({ message, description }) => createNotification('success', message, description);

export { showSuccess, showWarning, showError };
