import { connect } from 'react-redux';
import { permissionsSelector } from 'reportsModule/selectors/reportsPageSelectors';

import AccountReportsSection from 'reportsModule/components/AccountReportsSection';

export default connect(
  state => ({
    isAccountListDownloading:
      state.loading.effects.reports.downloadAccountList,
    isAccountStatusDownloading:
      state.loading.effects.reports.downloadAccountStatusReport,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadAccountList: () => dispatch.reports.downloadAccountList(),
    onDownloadAccountStatusReport: () => dispatch.reports.downloadAccountStatusReport(),
  }),
)(AccountReportsSection);
