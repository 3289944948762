import React, { Fragment } from 'react';
import { arrayOf, instanceOf, string } from 'prop-types';
import uuidv4 from 'uuid/v4';
import moment from 'moment';

import Scorecard from 'reportsModule/models/Scorecard';

import {
  DATE_FORMAT,
  WEEK_NUMBER_FORMAT,
} from 'common/constants/dateConstants';

import './index.scss';

const dateFormatter = (scorecard) => {
  if (scorecard.period === 'Week') {
    return (
      <Fragment>
        <p className="scorecards-table__head-cell-row">
          Week
          {' '}
          {moment(scorecard.startDate)
            .locale('en-us')
            .format(WEEK_NUMBER_FORMAT)}
        </p>
        <p className="scorecards-table__head-cell-row">
          {moment(scorecard.startDate).format(DATE_FORMAT)}
        </p>
      </Fragment>
    );
  }
  if (scorecard.period === 'Month') {
    return (
      <Fragment>
        <p className="scorecards-table__head-cell-row">
          Month
          {' '}
          {moment(scorecard.startDate).format('M')}
        </p>
        <p className="scorecards-table__head-cell-row">
          {moment(scorecard.startDate).format(DATE_FORMAT)}
        </p>
      </Fragment>
    );
  }
  return null;
};

const ScorecardsTable = ({ scorecardsData, fields }) => {
  const tableBody = fields.map(leftTitle => (
    <tr className="scorecards-table__body-row" key={uuidv4()}>
      <td className="scorecards-table__body-cell scorecards-table__body-left scorecards-table__body-cell_fixed" title={Scorecard.fieldNamesMap.get(leftTitle)}>
        {Scorecard.fieldNamesMap.get(leftTitle)}
      </td>
      {scorecardsData.map(scorecard => (
        <td
          key={uuidv4()}
          className="scorecards-table__body-cell scorecards-table__body-cell_nofixed"
        >
          {scorecard[leftTitle]}
        </td>
      ))}
    </tr>
  ));

  const table = (
    <table>
      <thead className="scorecards-table__head">
        <tr className="scorecards-table__head-row">
          <th className="scorecards-table__head-cell scorecards-table__head-left scorecards-table__head-cell_fixed" />
          {scorecardsData.map(scorecard => (
            <th
              key={uuidv4()}
              className="scorecards-table__head-cell scorecards-table__head-cell_nofixed"
            >
              {dateFormatter(scorecard)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="scorecards-table__body">{tableBody}</tbody>
    </table>
  );

  return (
    <div className="scorecards-table">
      <div className="scorecards-table__fixed-table-wrapper">
        {table}
        <div className="magic" />
      </div>
      <div className="scorecards-table__scrolled-table-wrapper">
        {table}
      </div>
    </div>
  );
};

ScorecardsTable.propTypes = {
  scorecardsData: arrayOf(instanceOf(Scorecard)).isRequired,
  fields: arrayOf(string).isRequired,
};

export default React.memo(ScorecardsTable);
