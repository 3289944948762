import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  secondaryPlacementsSelectors,
  shouldRenderMapSelector,
} from 'merchandisingModule/selectors/merchandisingMapSelectors';
import { permissionsSelector } from 'merchandisingModule/selectors/merchandisingPageSelectors';

import MerchandisingMap from 'merchandisingModule/components/MerchandisingMap';

export default connect(state => ({
  accounts: state.merchandising.accounts,
  filterCriteria: secondaryPlacementsSelectors(state),
  secondaryPlacements: state.merchandising.filterOptions.secondaryPlacements,
  colorMap: colorMapSelector(state),
  shouldRenderMap: shouldRenderMapSelector(state),
  childrenPermissions: permissionsSelector(state),
}))(
  withLegend({
    keys: 'filterCriteria',
    legendItemsTitle: 'Active Secondary Placement',
    withDivider: 'shouldRenderMap',
    shouldRenderLegend: 'shouldRenderMap',
  })(MerchandisingMap),
);
