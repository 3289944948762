class UserInfo {
  constructor({
    isSuperAdmin,
    isAdmin,
    brandFullName,
  }) {
    this.isSuperAdmin = isSuperAdmin;
    this.isAdmin = isAdmin;
    this.brandFullName = brandFullName;
  }

  static parse(obj) {
    return new UserInfo({
      isSuperAdmin: obj.superadmin,
      isAdmin: obj.admin,
      brandFullName: obj.brandFullName,
    });
  }
}

export default UserInfo;
