import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentPrimaryPlacementNamesSelector,
} from 'merchandisingModule/selectors/primaryPlacementSelectors';

import PrimaryPlacementChart from 'merchandisingModule/components/PrimaryPlacementChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  primaryPlacementNames: currentPrimaryPlacementNamesSelector(state),
  primaryPlacementData: state.merchandising.primaryPlacementData,
}))(
  withLegend({
    keys: 'primaryPlacementNames',
    isLegendActive: false,
  })(PrimaryPlacementChart),
);
