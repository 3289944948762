import React, { Fragment } from 'react';
import {
  bool, func, arrayOf, string,
} from 'prop-types';
import { Divider, Button } from 'antd';
import { timeEffortsPagePermissionTypes } from 'userModule/constants/permissions';

import SectionHeading from 'common/components/SectionHeading';

import './index.scss';

const HEADING = 'Time & Efforts Reports';

const TimeEffortsReportsSection = ({
  isTimeEffortsReportDownloading,
  onDownloadTimeAndEffortsReport,
  isTimeEffortsWeekReportDownloading,
  onDownloadTimeEffortsWeekReport,
  isTimeEffortsMonthReportDownloading,
  onDownloadTimeEffortsMonthReport,
  shouldRenderReportsSection,
  childrenPermissions,
}) => (
  shouldRenderReportsSection && (
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <section className="uds-reports-section">
        {childrenPermissions.includes(timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE_DAILY_REPORT) && (
          <div className="uds-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadTimeAndEffortsReport}
              loading={isTimeEffortsReportDownloading}
            >
              Time &#38; Efforts Report - Daily
            </Button>
          </div>
        )}
        {childrenPermissions.includes(timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE_WEEKLY_REPORT) && (
          <div className="uds-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadTimeEffortsWeekReport}
              loading={isTimeEffortsWeekReportDownloading}
            >
              Time &#38; Efforts Report - Weekly
            </Button>
          </div>
        )}
        {childrenPermissions.includes(timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE_MONTHLY_REPORT) && (
          <div className="uds-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadTimeEffortsMonthReport}
              loading={isTimeEffortsMonthReportDownloading}
            >
              Time &#38; Efforts Report - Monthly
            </Button>
          </div>
        )}
      </section>
      <Divider />
    </Fragment>
  )
);

TimeEffortsReportsSection.propTypes = {
  onDownloadTimeAndEffortsReport: func.isRequired,
  isTimeEffortsReportDownloading: bool.isRequired,
  isTimeEffortsWeekReportDownloading: bool.isRequired,
  onDownloadTimeEffortsWeekReport: func.isRequired,
  isTimeEffortsMonthReportDownloading: bool.isRequired,
  onDownloadTimeEffortsMonthReport: func.isRequired,
  shouldRenderReportsSection: bool.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default TimeEffortsReportsSection;
