import React, { Fragment } from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';

import SectionHeading from 'common/components/SectionHeading';

import MerchandisingStatus from 'merchandisingModule/models/MerchandisingStatus';
import TimeLineChart from 'common/components/TimeLineChart';

const HEADING = 'Average Facings / SKUs per Door';

const AverageFacingsChart = ({
  averageFacingsData,
  colorMap,
  disabledKeys,
  shouldRenderChart,
}) => {
  const lines = MerchandisingStatus.fieldNames;

  const colorMapLower = new Map();

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of colorMap) {
    colorMapLower.set(key.toLowerCase(), value);
  }

  const lineChartData = lines.map(line => ({
    id: MerchandisingStatus.labelsMap.get(line),
    color: colorMapLower.get(line),
    data: averageFacingsData.map(merchandisingStatus => ({
      x: merchandisingStatus.date,
      y: merchandisingStatus[line],
    })),
  }));

  return (
    shouldRenderChart && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <TimeLineChart
          data={lineChartData.filter(
            lineData => !disabledKeys.includes(lineData.id),
          )}
          colorMap={colorMap}
        />
      </Fragment>
    )
  );
};

/* eslint-disable react/no-unused-prop-types */
AverageFacingsChart.propTypes = {
  averageFacingsData: arrayOf(instanceOf(MerchandisingStatus)).isRequired,
  averageFacingsTitles: arrayOf(string).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
  shouldRenderChart: bool.isRequired,
};

export default AverageFacingsChart;
