/* eslint-disable prefer-template */
import React, { Fragment } from 'react';
import { arrayOf, instanceOf } from 'prop-types';
import { Divider } from 'antd';

import BarChart from 'common/components/BarChart';
import SectionHeading from 'common/components/SectionHeading';

import TotalCase from 'salesModule/models/TotalCase';

import { transformDataForTooltip } from 'salesModule/tools/tooltipFormatter';
import { bottomTicksFormatter } from 'salesModule/tools/bottomTicksFormatter';

import { colorGenerator } from 'common/utilities/colorGenerator';

const HEADING = 'Cases';

const TotalCasesChart = ({ totalCases }) => {
  const shouldRenderChart = totalCases && totalCases.length > 0;

  const totalCasesKeys = TotalCase.getTotalCasesKeys();
  const colorMap = colorGenerator(totalCasesKeys);

  return (
    shouldRenderChart
    && (
      <Fragment>
        <SectionHeading>{HEADING}</SectionHeading>
        <BarChart
          data={totalCases}
          keys={totalCasesKeys}
          indexes="dateInterval"
          colorMap={colorMap}
          bottomTicksFormatter={bottomTicksFormatter}
          transformDataForTooltip={transformDataForTooltip}
        />
        <Divider />
      </Fragment>
    )
  );
};

TotalCasesChart.propTypes = {
  totalCases: arrayOf(instanceOf(TotalCase)).isRequired,
};

export default TotalCasesChart;
