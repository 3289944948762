/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorMapper } from 'common/utilities/colorMapper';

import { TOTAL_ACCOUNTS_FIELD_NAME } from 'accountsModule/constants/chartConstants';

const filterOptionStatusesSelector = state => state.accounts.filterOptions.statuses;

const sortedFilterOptionStatusesSelector = createSelector(
  filterOptionStatusesSelector,
  statuses => statuses.sort((statusFirst, statusNext) => statusFirst.sortOrder - statusNext.sortOrder),
);

const colorSchemeSelector = state => state.accounts.filterOptions.createColorScheme();
const accountStatusesSelector = state => state.accounts.accountStatuses;
const accountStatusesDataSelector = state => state.accounts.accountStatusesData;

export const totalStatusesSelector = createSelector(
  [accountStatusesSelector, sortedFilterOptionStatusesSelector],
  (accountStatuses, filterOptionStatuses) => {
    const currentStatuses = filterOptionStatuses.reduce(
      (acc, filterOptionStatus) => {
        if (accountStatuses.includes(filterOptionStatus.name)) {
          acc.push(filterOptionStatus.name);
        }
        return acc;
      },
      [],
    );
    return [TOTAL_ACCOUNTS_FIELD_NAME, ...currentStatuses];
  },
);

export const colorMapSelector = createSelector(
  [totalStatusesSelector, colorSchemeSelector],
  (totalStatuses, colorScheme) => colorMapper(totalStatuses, colorScheme),
);

export const disabledStatusesSelector = createSelector(
  sortedFilterOptionStatusesSelector,
  statuses => statuses.filter(status => status.disabled).map(status => status.name),
);

export const shouldRenderChartSelector = createSelector(
  accountStatusesDataSelector,
  accountStatusesData => accountStatusesData && accountStatusesData.length > 0,
);
