/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import {
  bool, func, string, arrayOf,
} from 'prop-types';
import {
  Divider, Button, DatePicker, LocaleProvider,
} from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';
import moment from 'moment';

import SectionHeading from 'common/components/SectionHeading';
import FieldTitle from 'common/components/FieldTitle';

import './index.scss';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import { showError } from 'common/helpers/notificationManager';

import { reportsPagePermissionTypes } from 'userModule/constants/permissions';


const { WeekPicker } = DatePicker;

const HEADING = 'Merchandising Reports';

const WEEK_PICKER_FORMAT = 'YYYY - [Week] w - M/D';

const MerchandisingReportsSection = ({
  onDownloadMerchandisingReport,
  isMerchandisingReportDownloading,
  onDownloadVoidsReport,
  isVoidsReportDownloading,
  onDownloadMerchandisingAccountImagesReport,
  isMerchandisingAccountImagesReportDownloading,
  merchandisingAccountImagesReportDate,
  setMerchandisingAccountImagesReportDate,
  childrenPermissions,
}) => {
  const onReportDateChange = (date) => {
    setMerchandisingAccountImagesReportDate(moment(date).isValid() ? moment(date).startOf('week').locale('en-us').format(DATE_FORMAT) : null);
  };

  const onMerchandisingAccountsImagesReportClicked = () => {
    if (!moment(merchandisingAccountImagesReportDate).isValid()) {
      showError({
        message: 'Error',
        description: 'Incorrect start date for Account images report!',
      });
    } else onDownloadMerchandisingAccountImagesReport();
  };

  const disableDate = current => current > moment().endOf('isoWeek');

  return (
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <section className="merchandising-reports-section">
        {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_MERCHANDISING_REPORT) && (
          <div className="merchandising-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadMerchandisingReport}
              loading={isMerchandisingReportDownloading}
            >
              Merchandising Report
            </Button>
          </div>
        )}
        {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_VOIDS_REPORT) && (
          <div className="merchandising-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadVoidsReport}
              loading={isVoidsReportDownloading}
            >
              Voids Report
            </Button>
          </div>
        )}
      </section>
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_ACCOUNTS_IMAGES_REPORT)
        && (
          <section className="merchandising-images-report-section">
            <div className="merchandising-images-report-section__date-picker-container">
              <FieldTitle>Select Week</FieldTitle>
              <LocaleProvider locale={en_US}>
                <WeekPicker
                  onChange={onReportDateChange}
                  value={merchandisingAccountImagesReportDate ? moment(merchandisingAccountImagesReportDate) : null}
                  format={WEEK_PICKER_FORMAT}
                  showToday={false}
                  disabledDate={disableDate}
                  placeholder="Select Week"
                  allowClear={false}
                  suffixIcon={<div />}
                />
              </LocaleProvider>
            </div>
            <div className="merchandising-images-report-section__report">
              <FieldTitle>&nbsp;</FieldTitle>
              <Button
                type="primary"
                icon="file"
                onClick={onMerchandisingAccountsImagesReportClicked}
                loading={isMerchandisingAccountImagesReportDownloading}
              >
                Account Images
              </Button>
            </div>
          </section>
        )}
      <Divider />
    </Fragment>
  );
};

MerchandisingReportsSection.propTypes = {
  onDownloadMerchandisingReport: func.isRequired,
  isMerchandisingReportDownloading: bool.isRequired,
  onDownloadVoidsReport: func.isRequired,
  isVoidsReportDownloading: bool.isRequired,
  onDownloadMerchandisingAccountImagesReport: func.isRequired,
  isMerchandisingAccountImagesReportDownloading: bool.isRequired,
  setMerchandisingAccountImagesReportDate: func.isRequired,
  merchandisingAccountImagesReportDate: string.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default MerchandisingReportsSection;
