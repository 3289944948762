import moment from 'moment';

class ReportMetrics {
  constructor({
    amountOfDays,
    startsOnDate,
    to,
    cc,
    repeatDays,
    repeatPeriod,
    weekDay,
    monthRepetition,
    customMonthDayRepetition,
    customMonthWeekDayRepetition,
    customMonthWeekDayNumberRepetition,
    endsOnDate,
    endsAfterNumber,
    numberOfPeriods,
    chains,
    banners,
    channels,
    regions,
    counties,
    areas,
    accountReps,
    entireMarketplace,
    marketMetrics,
    filteredMetrics,
    marketplaceBeforePhoto,
    marketplaceAfterPhoto,
    marketplaceSecondaryPhoto,
    marketplaceSecondaryPhotoTypes,
    filteredBeforePhoto,
    filteredAfterPhoto,
    filteredSecondaryPhoto,
    filteredSecondaryPhotoTypes,
  }) {
    this.amountOfDays = amountOfDays;
    this.startsOnDate = startsOnDate;
    this.to = to;
    this.cc = cc;
    this.repeatDays = repeatDays;
    this.repeatPeriod = repeatPeriod;
    this.weekDay = weekDay;
    this.monthRepetition = monthRepetition;
    this.customMonthDayRepetition = customMonthDayRepetition;
    this.customMonthWeekDayRepetition = customMonthWeekDayRepetition;
    this.customMonthWeekDayNumberRepetition = customMonthWeekDayNumberRepetition;
    this.endsOnDate = endsOnDate;
    this.endsAfterNumber = endsAfterNumber;
    this.numberOfPeriods = numberOfPeriods;
    this.chains = chains;
    this.banners = banners;
    this.channels = channels;
    this.regions = regions;
    this.counties = counties;
    this.areas = areas;
    this.accountReps = accountReps;
    this.entireMarketplace = entireMarketplace;
    this.marketMetrics = marketMetrics;
    this.filteredMetrics = filteredMetrics;
    this.marketplaceBeforePhoto = marketplaceBeforePhoto;
    this.marketplaceAfterPhoto = marketplaceAfterPhoto;
    this.marketplaceSecondaryPhoto = marketplaceSecondaryPhoto;
    this.marketplaceSecondaryPhotoTypes = marketplaceSecondaryPhotoTypes;
    this.filteredBeforePhoto = filteredBeforePhoto;
    this.filteredAfterPhoto = filteredAfterPhoto;
    this.filteredSecondaryPhoto = filteredSecondaryPhoto;
    this.filteredSecondaryPhotoTypes = filteredSecondaryPhotoTypes;
  }

  static parse(obj) {
    return new ReportMetrics({
      amountOfDays: obj.AmountOfDays,
      startsOnDate: moment(obj.StartsOn).isValid() ? moment(obj.StartsOn) : moment(),
      to: obj.EmailsTo,
      cc: obj.EmailsCC,
      repeatDays: obj.RepeatEvery,
      repeatPeriod: obj.RepeatEveryType,
      weekDay: obj.WeekDayname,
      monthRepetition: obj.MonthRepeat,
      customMonthDayRepetition: obj.MonthDays,
      customMonthWeekDayRepetition: obj.MonthDayname,
      customMonthWeekDayNumberRepetition: obj.MonthDaynumber,
      endsOnDate: moment(obj.EndsOn).isValid() ? moment(obj.EndsOn) : null,
      endsAfterNumber: obj.EndsAfter,
      numberOfPeriods: obj.NumberOfPeriods,
      chains: obj.Chains,
      banners: obj.Banners,
      channels: obj.Channels,
      regions: obj.Regions,
      counties: obj.Counties,
      areas: obj.Areas,
      accountReps: obj.AccountRepresentatives,
      entireMarketplace: obj.EntireMarketplace,
      marketMetrics: obj.MarketplaceMetricsIncluded,
      filteredMetrics: obj.FilteredMetricsIncluded,
      marketplaceBeforePhoto: obj.MarketplaceBeforePhotoIncluded,
      marketplaceAfterPhoto: obj.MarketplaceAfterPhotoIncluded,
      marketplaceSecondaryPhoto: obj.MarketplaceSecondaryPhotoIncluded,
      marketplaceSecondaryPhotoTypes: obj.MarketplaceSecondaryPhotoTypes,
      filteredBeforePhoto: obj.FilteredBeforePhotoIncluded,
      filteredAfterPhoto: obj.FilteredAfterPhotoIncluded,
      filteredSecondaryPhoto: obj.FilteredSecondaryPhotoIncluded,
      filteredSecondaryPhotoTypes: obj.FilteredSecondaryPhotoTypes,
    });
  }
}

export default ReportMetrics;
