import React from 'react';
import {
  instanceOf, func, string, arrayOf, bool,
} from 'prop-types';

import LegendItem from './LegendItem';

import './index.scss';

const Legend = ({
  legendItemsMap,
  isActive,
  onItemClick,
  disabledItems,
  legendItemsTitle,
}) => {
  const legendElements = [...legendItemsMap].map((nameColorPair) => {
    const [name, color] = nameColorPair;
    return (
      onItemClick
      && disabledItems && (
        <LegendItem
          key={name}
          name={name}
          color={color}
          isDisabled={disabledItems.includes(name)}
          isActive={isActive}
          onClick={onItemClick}
        />
      )
    );
  });

  return (
    <div className="legend">
      {legendItemsTitle && (
        <p className="legend__items-title">
          {legendItemsTitle}
          :
          {' '}
        </p>
      )}
      {legendElements}
    </div>
  );
};

Legend.propTypes = {
  legendItemsMap: instanceOf(Map).isRequired,
  isActive: bool,
  disabledItems: arrayOf(string),
  onItemClick: func,
  legendItemsTitle: string,
};

Legend.defaultProps = {
  onItemClick: () => { },
  isActive: true,
  disabledItems: [],
  legendItemsTitle: '',
};

export default Legend;
