import React from 'react';
import {
  Select, Icon, Input, InputNumber,
} from 'antd';
import {
  number,
  string,
  func,
  arrayOf,
  object,
  instanceOf,
} from 'prop-types';

import FieldTitle from 'common/components/FieldTitle';
import RangePicker from 'common/components/RangePicker';
import NumberRange from 'common/components/NumberRange';
import PeriodPicker from 'common/components/PeriodPicker';

import FilterOptions from 'merchandisingModule/models/FilterOptions';

import { DATE_FORMAT } from 'common/constants/dateConstants';

import { merchandisingFiltersPermissionTypes } from 'userModule/constants/filterPermissions';

import './index.scss';

const { Option } = Select;

const CommonOption = option => (
  <Option key={option} value={option} title={option}>
    {option}
  </Option>
);

const searchIcon = <Icon type="search" style={{ fontSize: '12px' }} />;

const FiltersBoard = ({
  disabledDate,
  dateRange,
  name,
  address,
  brand,
  channelTypes,
  cities,
  states,
  chains,
  representatives,
  representativesMetric,
  territories,
  notVisitedIn,
  skuNames,
  numberOfSkus,
  numberOfFacings,
  accountsSource,
  dublicateImages,
  regions,
  counties,
  banners,
  frequencies,
  zipCode,
  primary,
  secondary,
  locations,
  types,
  tags,
  prices,
  onChangeField,
  filterOptions,
  permissions,
}) => {
  const onChangeNumber = (value) => {
    if (!Number(value) || Number.isNaN(value)) {
      onChangeField('notVisitedIn', 0);

      return;
    }
    onChangeField('notVisitedIn', Math.abs(value));
  };

  const onChangeZipCode = (value) => {
    const numRegExp = /^\d+$/;
    if (numRegExp.test(value)) {
      return onChangeField('zipCode', value);
    }
    return '';
  };

  const shouldRenderAdminFilters = permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DATA_SOURCE)
    || permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_NO_ACTIVITY)
    || permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_FREQUENCY)
    || permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DUPLICATE_PHOTO);

  return (
    <div className="merchandising-filters-board">
      <div className="merchandising-filters-board__wrapper merchandising-filters-board_users-section">
        <div className="merchandising-filters-board__filter merchandising-filters-board__filter_dropdown">
          <FieldTitle>Brand</FieldTitle>
          <Select
            className="merchandising-filters-board__select"
            onChange={value => onChangeField('brand', value)}
            value={brand}
            dropdownMatchSelectWidth={false}
          >
            {filterOptions.brands
              .map(b => b.name)
              .map(CommonOption)}
          </Select>
        </div>
        <div className="merchandising-filters-board__filter merchandising-filters-board__filter_date-picker">
          <RangePicker
            format={DATE_FORMAT}
            disabledDate={disabledDate}
            value={dateRange}
            onChange={value => onChangeField('dateRange', value)}
          />
        </div>
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DATE_PERIOD) && (
          <div className="merchandising-filters-board__filter">
            <FieldTitle>&nbsp;</FieldTitle>
            <PeriodPicker
              onChange={value => onChangeField('periodRange', value)}
            />
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_ACCOUNT) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_search">
            <FieldTitle>Account</FieldTitle>
            <Input
              value={name}
              onChange={e => onChangeField('name', e.target.value)}
              placeholder="Search name"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_ADDRESS) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_search">
            <FieldTitle>Address</FieldTitle>
            <Input
              value={address}
              onChange={e => onChangeField('address', e.target.value)}
              placeholder="Search address"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_CITY) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>City</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('cities', value)}
              value={cities}
              maxTagCount={2}
              placeholder="Select cities"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.cities[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_STATE) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>State</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('states', value)}
              value={states}
              maxTagCount={2}
              placeholder="Select states"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.states[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_ZIP) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_search">
            <FieldTitle>Zip Code</FieldTitle>
            <Input
              value={zipCode}
              onChange={e => onChangeZipCode(e.target.value)}
              placeholder="Search zip code"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_CHAIN) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Chain</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('chains', value)}
              value={chains}
              maxTagCount={2}
              placeholder="Select chains"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.chains[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_BANNER) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Banner</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('banners', value)
              }
              value={banners}
              maxTagCount={2}
              placeholder="Select banners"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.banners[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_CHANNEL) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Channel</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('channelTypes', value)
              }
              value={channelTypes}
              maxTagCount={2}
              placeholder="Select channels"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.channelTypes[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_REGION) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Region</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('regions', value)
              }
              value={regions}
              maxTagCount={2}
              placeholder="Select regions"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.regions[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_COUNTY) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>County</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('counties', value)
              }
              value={counties}
              maxTagCount={2}
              placeholder="Select counties"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.counties[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_AREA) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Area</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('territories', value)
              }
              value={territories}
              maxTagCount={2}
              placeholder="Select area"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.territories[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_SKUS)
          && (
            <>
              <div className="merchandising-filters-board__break" />
              <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect merchandising-filters-board__filter_multiselect-double">
                <FieldTitle>SKUs - Merchandising</FieldTitle>
                <Select
                  mode="multiple"
                  className="merchandising-filters-board__select"
                  onChange={value => onChangeField('skuNames', value)}
                  value={skuNames}
                  maxTagCount={2}
                  placeholder="Select SKUs"
                  dropdownMatchSelectWidth={false}
                >
                  {filterOptions.skus
                    .filter(sku => sku.brand === brand)
                    .map(sku => CommonOption(sku.name))}
                </Select>
              </div>
            </>
          )
        }
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_SKUS_NUM)
          && (
            <div className="merchandising-filters-board__filter merchandising-filters-board__filter_number">
              <FieldTitle># of SKUs</FieldTitle>
              <NumberRange
                value={numberOfSkus}
                onChange={value => onChangeField('numberOfSkus', value)}
              />
            </div>
          )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_FACINGS_NUM) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_number">
            <FieldTitle># of Facings</FieldTitle>
            <NumberRange
              value={numberOfFacings}
              onChange={value => onChangeField('numberOfFacings', value)
              }
            />
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_REP_ACCOUNT) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Rep - Account</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('representatives', value)
              }
              value={representatives}
              maxTagCount={2}
              placeholder="Select representatives"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.representatives[brand].map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_REP_METRIC) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Rep - Metric</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('representativesMetric', value)
              }
              value={representativesMetric}
              maxTagCount={2}
              placeholder="Select representatives"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.representativesMetric.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_TAGS) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_dropdown">
            <FieldTitle>Tags</FieldTitle>
            <Select
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('tags', value)}
              value={tags}
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.tags.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_PRIMARY) && (
          <>
            {/* <div className="merchandising-filters-board__break" /> */}
            <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
              <FieldTitle>Primary</FieldTitle>
              <Select
                mode="multiple"
                className="merchandising-filters-board__select"
                onChange={value => onChangeField('primary', value)}
                value={primary}
                maxTagCount={2}
                placeholder="Select primary"
                dropdownMatchSelectWidth={false}
              >
                {filterOptions.primary.map(CommonOption)}
              </Select>
            </div>
          </>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_SECONDARY) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Secondary</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('secondary', value)}
              value={secondary}
              maxTagCount={2}
              placeholder="Select secondary"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.secondary.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_LOCATION) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Shelf Locations</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('locations', value)}
              value={locations}
              maxTagCount={2}
              placeholder="Select locations"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.locations.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_TYPE) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>POS</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('types', value)}
              value={types}
              maxTagCount={2}
              placeholder="Select types"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.types.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_PRICE) && (
          <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
            <FieldTitle>Prices</FieldTitle>
            <Select
              mode="multiple"
              className="merchandising-filters-board__select"
              onChange={value => onChangeField('prices', value)}
              value={prices}
              maxTagCount={2}
              placeholder="Select prices"
              dropdownMatchSelectWidth={false}
            >
              {filterOptions.prices[brand] ? filterOptions.prices[brand].map(CommonOption) : []}
            </Select>
          </div>
        )}
      </div>
      {shouldRenderAdminFilters && (
        <div className="merchandising-filters-board__wrapper merchandising-filters-board__admins-section">
          {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DATA_SOURCE) && (
            <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
              <FieldTitle>Data Source</FieldTitle>
              <Select
                className="merchandising-filters-board__select"
                onChange={value => onChangeField('accountsSource', value)
                }
                value={accountsSource}
                dropdownMatchSelectWidth={false}
              >
                {filterOptions.accountsSources.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_NO_ACTIVITY) && (
            <div
              className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect"
            >
              <FieldTitle>No Activity</FieldTitle>
              <InputNumber
                min={0}
                value={notVisitedIn}
                onChange={value => onChangeNumber(value)}
                formatter={value => `${value} days`}
                parser={value => value.replace('days', '').trim()}
              />
            </div>
          )}
          {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_FREQUENCY) && (
            <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
              <FieldTitle>Frequency</FieldTitle>
              <Select
                mode="multiple"
                className="merchandising-filters-board__select"
                onChange={value => onChangeField('frequencies', value)
                }
                value={frequencies}
                maxTagCount={2}
                placeholder="Select frequencies"
                dropdownMatchSelectWidth={false}
              >
                {filterOptions.frequencies.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(merchandisingFiltersPermissionTypes.MERCHANDISING_FILTERS_DUPLICATE_PHOTO) && (
            <div className="merchandising-filters-board__filter merchandising-filters-board__filter_multiselect">
              <FieldTitle>Duplicate Photo</FieldTitle>
              <Select
                mode="multiple"
                className="merchandising-filters-board__select"
                onChange={value => onChangeField('dublicateImages', value)
                }
                value={dublicateImages}
                maxTagCount={2}
                placeholder="Select duplicates"
                dropdownMatchSelectWidth={false}
              >
                {filterOptions.dublicateImages.map(CommonOption)}
              </Select>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FiltersBoard.propTypes = {
  disabledDate: func.isRequired,
  dateRange: arrayOf(object).isRequired,
  name: string.isRequired,
  address: string.isRequired,
  brand: string.isRequired,
  channelTypes: arrayOf(string).isRequired,
  cities: arrayOf(string).isRequired,
  states: arrayOf(string).isRequired,
  chains: arrayOf(string).isRequired,
  representatives: arrayOf(string).isRequired,
  representativesMetric: arrayOf(string).isRequired,
  territories: arrayOf(string).isRequired,
  notVisitedIn: number.isRequired,
  skuNames: arrayOf(string).isRequired,
  numberOfSkus: string.isRequired,
  numberOfFacings: string.isRequired,
  regions: arrayOf(string).isRequired,
  counties: arrayOf(string).isRequired,
  banners: arrayOf(string).isRequired,
  frequencies: arrayOf(string).isRequired,
  zipCode: string.isRequired,
  accountsSource: string.isRequired,
  dublicateImages: arrayOf(string).isRequired,
  primary: arrayOf(string).isRequired,
  secondary: arrayOf(string).isRequired,
  locations: arrayOf(string).isRequired,
  types: arrayOf(string).isRequired,
  tags: string.isRequired,
  prices: arrayOf(string).isRequired,
  filterOptions: instanceOf(FilterOptions).isRequired,
  onChangeField: func.isRequired,
  permissions: arrayOf(string).isRequired,
};

export default React.memo(FiltersBoard);
