import React, { Fragment } from 'react';
import { Divider, Button, Skeleton } from 'antd';

import SectionHeading from 'common/components/SectionHeading';
import {
  bool, func, arrayOf, string,
} from 'prop-types';
import { managementPagePermissionTypes } from 'userModule/constants/permissions';


import './index.scss';

const HEADING = 'Account Reports';

const ActiveAccountReportsSection = ({
  isActiveAccountsReportDownloading,
  onDownloadActiveAccountsReport,
  isDeadAccountsHotReportDownloading,
  onDownloadDeadAccountsHotReport,
  isDeadAccountsReportDownloading,
  onDownloadDeadAccountsReport,
  isAccountsTotalSalesReportDownloading,
  onDownloadAccountsTotalSalesReport,
  isFilterOptionsFetching,
  childrenPermissions,
}) => (
  <Skeleton loading={isFilterOptionsFetching}>
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <section className="active-reports-section">
        {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_ACTIVE_REPORT) && (
          <div className="active-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadActiveAccountsReport}
              loading={isActiveAccountsReportDownloading}
            >
            Active Accounts
            </Button>
          </div>
        )}
        {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_DEAD_REPORT) && (
          <div className="active-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadDeadAccountsReport}
              loading={isDeadAccountsReportDownloading}
            >
            Dead Accounts
            </Button>
          </div>
        )}
        {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_DEAD_HOT_REPORT) && (
          <div className="active-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadDeadAccountsHotReport}
              loading={isDeadAccountsHotReportDownloading}
            >
            Dead Accounts w/ Hot Leads
            </Button>
          </div>
        )}
        {childrenPermissions.includes(managementPagePermissionTypes.MANAGEMENT_PAGE_TOTAL_SALES_REPORT) && (
          <div className="active-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadAccountsTotalSalesReport}
              loading={isAccountsTotalSalesReportDownloading}
            >
            Accounts Total Sales
            </Button>
          </div>
        )}
      </section>
      <Divider />
    </Fragment>
  </Skeleton>
);

ActiveAccountReportsSection.propTypes = {
  isActiveAccountsReportDownloading: bool.isRequired,
  onDownloadActiveAccountsReport: func.isRequired,
  isDeadAccountsReportDownloading: bool.isRequired,
  onDownloadDeadAccountsReport: func.isRequired,
  isDeadAccountsHotReportDownloading: bool.isRequired,
  onDownloadDeadAccountsHotReport: func.isRequired,
  isAccountsTotalSalesReportDownloading: bool.isRequired,
  onDownloadAccountsTotalSalesReport: func.isRequired,
  isFilterOptionsFetching: bool.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default ActiveAccountReportsSection;
