/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const secondaryPlacementDataSelector = state => state.merchandising.secondaryPlacementData;

export const currentSecondaryPlacementNamesSelector = createSelector(
  secondaryPlacementDataSelector,
  secondaryPlacementData => secondaryPlacementData.map(
    secondaryPlacement => secondaryPlacement.placement,
  ),
);

export const colorMapSelector = createSelector(
  currentSecondaryPlacementNamesSelector,
  currentShelfPlacementNames => colorGenerator(currentShelfPlacementNames),
);
