/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { colorGenerator } from 'common/utilities/colorGenerator';

const primaryPlacementDataSelector = state => state.merchandising.primaryPlacementData;

export const currentPrimaryPlacementNamesSelector = createSelector(
  primaryPlacementDataSelector,
  primaryPlacementData => primaryPlacementData.map(
    primaryplacement => primaryplacement.placement,
  ),
);

export const colorMapSelector = createSelector(
  currentPrimaryPlacementNamesSelector,
  currentPrimaryPlacementNames => colorGenerator(currentPrimaryPlacementNames),
);
