import VisitInfo from './VisitInfo';

class VisitsData {
  constructor({ date, visits }) {
    this.date = date;
    this.visits = visits;
  }

  static parse(obj) {
    return new VisitsData({
      date: obj.DateStart,
      visits: obj.VisitsInfo.map(VisitInfo.parse),
    });
  }
}

export default VisitsData;
