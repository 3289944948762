import { connect } from 'react-redux';

import { permissionsSelector } from 'adminModule/selectors/adminPageSelectors';
import BrandsList from '../components/BrandsList';

export default connect(
  state => ({
    filterOptions: state.admin.filterOptions,
    brands: state.admin.brands,
    childrenPermissions: permissionsSelector(state),
    permissions: state.session.permissions,
  }),
  dispatch => ({
    deleteBrand: dispatch.admin.deleteBrand,
    getBrands: dispatch.admin.getBrands,
    archiveBrand: dispatch.admin.archiveBrand,
  }),
)(BrandsList);
