import React from 'react';
import {
  func, arrayOf, instanceOf, string,
} from 'prop-types';
import { Icon } from 'antd';
import moment from 'moment';
import 'rc-calendar/assets/index.css';

import Calendar from 'rc-calendar';

import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';

import FieldTitle from 'common/components/FieldTitle';

import './index.scss';

const PICKER_STYLE = {
  boxSizing: 'border-box',
  position: 'relative',
  display: 'block',
  lineHeight: 1.5,
};

class RangePicker extends React.PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    value: arrayOf(instanceOf(moment)).isRequired,
    disabledDate: func.isRequired,
    format: string.isRequired,
  };

  state = {
    endDate: null,
    startDate: null,
  }

  static getDerivedStateFromProps(props) {
    const {
      value: [startValue, endValue],
    } = props;
    return {
      startDate: startValue,
      endDate: endValue,
    };
  }


  endCalendarContainerRef = React.createRef();

  startCalendarContainerRef = React.createRef();

  getEndCalendarContainer = () => this.endCalendarContainerRef.current;

  getStartCalendarContainer = () => this.startCalendarContainerRef.current;

  onStartChange = (v) => {
    this.setState({ startDate: v }, () => {
      const { onChange, value } = this.props;
      onChange([v, value[1]]);
    });
  };

  onEndChange = (v) => {
    this.setState({ endDate: v }, () => {
      const { onChange, value } = this.props;
      onChange([value[0], v]);
    });
  };

  disabledStartDate = (startValue) => {
    const {
      disabledDate,
    } = this.props;

    const { endDate } = this.state;

    if (!startValue || !endDate) {
      return false;
    }

    return (
      disabledDate(startValue)
      || startValue.valueOf() > endDate.valueOf()
    );
  };

  disabledEndDate = (endValue) => {
    const {
      disabledDate,
    } = this.props;
    const { startDate } = this.state;
    if (!endValue || !startDate) {
      return false;
    }
    return (
      disabledDate(endValue) || endValue.valueOf() <= startDate.valueOf()
    );
  };

  render() {
    const {
      format,
    } = this.props;

    const endDateCalendar = (
      <Calendar
        locale={enUS}
        format={format}
        disabledDate={this.disabledEndDate}
        focusablePanel={false}
        showWeekNumber
        showToday={false}
        clearIcon={<Icon type="close-circle" theme="filled" />}
      />
    );

    const startDateCalendar = (
      <Calendar
        locale={enUS}
        format={format}
        disabledDate={this.disabledStartDate}
        focusablePanel={false}
        showWeekNumber
        showToday={false}
        clearIcon={<Icon type="close-circle" theme="filled" />}
      />
    );

    /* use state for uncontrolled components */
    const { startDate, endDate } = this.state;

    return (
      <div className="range-picker">
        <div className="range-picker__item">
          <FieldTitle>Start Date</FieldTitle>
          <div style={PICKER_STYLE}>
            <DatePicker
              animation="slide-up"
              calendar={startDateCalendar}
              value={startDate}
              getCalendarContainer={this.getStartCalendarContainer}
              onChange={this.onStartChange}
              style={{ zIndex: 1001 }}
            >
              {
                ({ value }) => (
                  <span>
                    <input
                      placeholder="Start Date"
                      readOnly
                      tabIndex="-1"
                      className="ant-calendar-picker-input ant-input"
                      value={value && value.isValid() ? value.format(format) : ''}
                    />
                    <div ref={this.startCalendarContainerRef} />
                  </span>
                )
              }
            </DatePicker>
          </div>
        </div>
        <div className="range-picker__item">
          <FieldTitle>End Date</FieldTitle>
          <div style={PICKER_STYLE}>
            <DatePicker
              animation="slide-up"
              calendar={endDateCalendar}
              value={endDate}
              getCalendarContainer={this.getEndCalendarContainer}
              onChange={this.onEndChange}
              style={{ zIndex: 1001 }}
            >
              {
                ({ value }) => (
                  <span>
                    <input
                      placeholder="End Date"
                      readOnly
                      tabIndex="-1"
                      className="ant-calendar-picker-input ant-input"
                      value={value && value.isValid() ? value.format(format) : ''}
                    />
                    <div ref={this.endCalendarContainerRef} />
                  </span>
                )
              }
            </DatePicker>
          </div>
        </div>
      </div>
    );
  }
}

export default RangePicker;
