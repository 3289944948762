/* eslint-disable no-param-reassign */

import produce from 'immer';
import qs from 'qs';

import {
  saveToken,
  clearToken,
  getUserInfo,
  getToken,
} from 'userModule/helpers/sessionHelper';
import UserInfo from 'userModule/models/UserInfo';
import Permissions from 'userModule/models/Permissions';
import { LOG_IN_ENDPOINT, AUTH_TEST } from 'common/constants/apiRoutes';
import ajax, { setAuthToken } from 'common/utilities/ajax';


const initialState = {
  authorized: false,
  userInfo: null,
  permissions: null,
  error: null,
  isLoadingToken: true,
};

export default {
  state: initialState,
  reducers: {
    setIsLoadingToken: produce((state, isLoadingToken) => {
      state.isLoadingToken = isLoadingToken;
    }),
    setAuthorized: produce((state, authorized) => {
      state.authorized = authorized;
    }),
    setError: produce((state, error) => {
      state.error = error;
    }),
    setUserInfo: produce((state, userInfo) => {
      state.userInfo = userInfo;
    }),
    setPermissions: produce((state, permissions) => {
      state.permissions = permissions;
    }),
  },
  effects: dispatch => ({
    async authenticate() {
      const localToken = getToken();
      setAuthToken(localToken);
      try {
        const response = await ajax.get(AUTH_TEST);
        setAuthToken(localToken);
        const tokenInfo = getUserInfo();
        dispatch.session.setUserInfo(UserInfo.parse(tokenInfo));
        dispatch.session.setPermissions(Permissions.parse(response.data.Permissions));
        dispatch.session.setAuthorized(true);
        dispatch.session.setIsLoadingToken(false);
        dispatch.session.setError(null);
      } catch (error) {
        dispatch.session.setIsLoadingToken(false);
        dispatch.session.logOut();
      }
    },
    async logIn({ username, password }) {
      try {
        const response = await ajax.post(
          LOG_IN_ENDPOINT,
          qs.stringify({
            login: username,
            password,
          }),
        );
        saveToken({
          token: response.data.Token,
          tokenType: response.data.TokenType,
        });
        const tokenInfo = getUserInfo();
        setAuthToken(getToken());
        dispatch.session.setUserInfo(UserInfo.parse(tokenInfo));
        dispatch.session.setPermissions(Permissions.parse(response.data.Permissions));
        dispatch.session.setAuthorized(true);
        dispatch.session.setError(null);
      } catch (error) {
        dispatch.session.setError(error);
      }
    },
    logOut() {
      dispatch.session.setAuthorized(false);
      dispatch.session.setUserInfo(null);
      dispatch.accounts.clear();
      dispatch.sales.clear();
      setAuthToken(null);
      clearToken();
    },
  }),
};
