import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import window from 'global';

import store from './store';
import App from './App';

import 'antd/dist/antd.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

import '../public/reset.css';
import 'common/styles/base.scss';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>,
  window.document.getElementById('root'),
);
