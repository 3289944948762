import { connect } from 'react-redux';
import { permissionsSelector, filterPermissionsSelector } from 'managementModule/selectors/managementPageSelectors';

import ManagementPage from 'managementModule/components/ManagementPage';

export default connect(
  state => ({
    userInfo: state.session.userInfo,
    fetchingFilterOptions:
      state.loading.effects.management.fetchFilterOptions,
    isFilterInitialized: state.management.initialized,
    filter: state.management.filter,
    filterOptions: state.management.filterOptions,
    representativesBrandMonthlyData:
      state.management.representativesBrandMonthlyData,
    representativesBrandWeeklyData:
      state.management.representativesBrandWeeklyData,
    representativesAllBrandsWeeklyData:
      state.management.representativesAllBrandsWeeklyData,
    representativesAllBrandsMonthlyData:
      state.management.representativesAllBrandsMonthlyData,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.management.fetchFilterOptions,
    setFilter: dispatch.management.setFilter,
    fetchBrandRespresentativesWeekly:
      dispatch.management.fetchBrandRespresentativesWeekly,
    fetchBrandRespresentativesMonthly:
      dispatch.management.fetchBrandRespresentativesMonthly,
    fetchAllBrandsRespresentativesWeekly:
      dispatch.management.fetchAllBrandsRespresentativesWeekly,
    fetchAllBrandsRespresentativesMonthly:
      dispatch.management.fetchAllBrandsRespresentativesMonthly,
    resetManagementStore: dispatch.management.clear,
  }),
)(ManagementPage);
