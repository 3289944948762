/* eslint-disable no-param-reassign, no-bitwise */

import produce from 'immer';
import qs from 'qs';
import fileDownload from 'js-file-download';

import FilterOptions from 'adminModule/models/FilterOptions';
import User from 'adminModule/models/User';
import Brand from 'adminModule/models/Brand';
import { reportScheduleParamsMapper } from 'adminModule/helpers/reportScheduleParamsMapper';

import {
  REGISTRATION_ENDPOINT,
  REGISTRATION_FILTERS_ENDPOINT,
  USERS_LIST_ENDPOINT,
  BRANDS_LIST_ENDPOINT,
  BRANDS_ARCHIVE_ENDPOINT,
  BRANDS_DELETE_ENDPOINT,
  SCORECARDS_UPLOAD,
  SCORECARDS_DOWNLOAD,
  DELETE_ENDPOINT,
  EDIT_ENDPOINT,
  SCORECARD_DELETE,
  TARGET_DATE_ENDPOINT,
  PERMISSIONS_ENPOINT,
  REPORT_SCHEDULE_ENDPOINT,
  REPORT_SCHEDULE_TEST_ENDPOINT,
} from 'common/constants/apiRoutes';
import ajax from 'common/utilities/ajax';

import { showError, showSuccess } from 'common/helpers/notificationManager';

const initialState = {
  error: null,
  message: '',
  filterOptions: new FilterOptions({
    brands: [],
    representatives: [],
    targetEarliestDate: null,
    scorecardsByBrand: {},
  }),
  users: [],
  scorecardsBrand: null,
  uploadScorecardFiles: [],
  target: {
    brands: [],
    representatives: [],
    date: null,
    targetNumber: 0,
  },
  filesLoading: false,
};

export default {
  state: initialState,
  reducers: {
    setError: produce((state, error) => {
      state.error = error;
    }),
    setMessage: produce((state, message) => {
      state.message = message;
    }),
    setFilterOptions: produce((state, filterOptions) => {
      state.filterOptions = filterOptions;
    }),
    setScorecardsBrand: produce((state, brand) => {
      state.scorecardsBrand = brand;
    }),
    setUploadScorecardFiles: produce((state, files) => {
      const newFiles = [];
      files.forEach((file) => {
        if (!state.uploadScorecardFiles.find(uploadedFile => uploadedFile.name === file.name)) {
          newFiles.push(file);
        }
        return newFiles;
      });
      state.uploadScorecardFiles = [...state.uploadScorecardFiles, ...newFiles];
    }),
    deleteUploadScorecardFile: produce((state, filename) => {
      const newFiles = state.uploadScorecardFiles.filter(uploadedFile => uploadedFile.name !== filename);
      state.uploadScorecardFiles = newFiles;
    }),
    setUsers: produce((state, users) => {
      state.users = users;
    }),
    setBrands: produce((state, brands) => {
      state.brands = brands;
    }),
    setTargetBrands: produce((state, brands) => {
      state.target = {
        ...state.target,
        brands,
      };
    }),
    setTargetRepresentatives: produce((state, representatives) => {
      state.target = {
        ...state.target,
        representatives,
      };
    }),
    setTargetDate: produce((state, date) => {
      state.target = {
        ...state.target,
        date,
      };
    }),
    setTargetNumber: produce((state, targetNumber) => {
      state.target = {
        ...state.target,
        targetNumber,
      };
    }),
    setFilesLoading: produce((state, loading) => {
      state.filesLoading = loading;
    }),
    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(REGISTRATION_FILTERS_ENDPOINT);
      const filterOptions = FilterOptions.parse(response.data);
      dispatch.admin.setFilterOptions(filterOptions);

      if (response.data.ScorecardFilenames.length > 0) {
        const defaultScorecardsBrand = response.data.ScorecardFilenames[0].Brand;

        dispatch.admin.setScorecardsBrand(defaultScorecardsBrand);
      }
    },
    async registerUser({
      username,
      password,
      allBrands,
      brands,
      brandFullName,
      isAdmin,
    }) {
      try {
        const response = await ajax.post(
          REGISTRATION_ENDPOINT,
          qs.stringify(
            {
              login: username,
              password,
              allBrandsAccess: allBrands,
              brands,
              brandFullName,
              isAdmin,
            },
            { indices: false },
          ),
        );
        showSuccess({
          message: 'Success',
          description: 'User has been successfully created!',
        });
        const users = response.data.map(User.parse);
        dispatch.admin.setUsers(users);
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
    async editUser({
      username,
      password,
      active,
      allBrands,
      brands,
      brandFullName,
      isAdmin,
    }) {
      try {
        const response = await ajax.post(
          EDIT_ENDPOINT,
          qs.stringify(
            {
              login: username,
              password,
              active,
              allBrandsAccess: allBrands,
              brands,
              brandFullName,
              isAdmin,
            },
            { indices: false },
          ),
        );
        showSuccess({
          message: 'Success',
          description: 'User has been successfully edited!',
        });
        const users = response.data.map(User.parse);
        dispatch.admin.setUsers(users);
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
    async deleteUser(userInfo) {
      try {
        const response = await ajax.post(
          DELETE_ENDPOINT,
          qs.stringify({
            login: userInfo.user,
          }),
        );
        showSuccess({
          message: 'Success',
          description: 'User has been successfully deleted!',
        });
        const users = response.data.map(User.parse);
        dispatch.admin.setUsers(users);
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
    async archiveBrand(brandInfo) {
      try {
        const response = await ajax.post(
          BRANDS_ARCHIVE_ENDPOINT,
          qs.stringify({
            brandName: brandInfo.brand,
            active: brandInfo.isActive,
          }),
        );
        const brands = response.data.map(Brand.parse);
        dispatch.admin.setBrands(brands);

        const desc = brandInfo.isActive ? 'Brand has been successfully archived!' : 'Brand has been successfully unarchived!';

        showSuccess({
          message: 'Success',
          description: desc,
        });
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
    async deleteBrand(brandInfo) {
      try {
        const response = await ajax.post(
          BRANDS_DELETE_ENDPOINT,
          qs.stringify({
            brandName: brandInfo.brand,
          }),
        );
        const brands = response.data.map(Brand.parse);
        dispatch.admin.setBrands(brands);

        const desc = 'Brand has been successfully deleted!';

        showSuccess({
          message: 'Success',
          description: desc,
        });
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
    async changePermissions({
      login,
      permissions,
    }) {
      try {
        const response = await ajax.post(
          PERMISSIONS_ENPOINT,
          qs.stringify(
            {
              login,
              permissions,
            },
            { indices: false },
          ),
        );
        showSuccess({
          message: 'Success',
          description: 'User\'s permissions has been successfully edited!',
        });
        const users = response.data.map(User.parse);
        dispatch.admin.setUsers(users);
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
    async testScheduledReport({ user, params }) {
      const mappedParams = reportScheduleParamsMapper(user, params);
      try {
        const response = await ajax.post(
          REPORT_SCHEDULE_TEST_ENDPOINT,
          qs.stringify(mappedParams, { indices: false }),
        );

        const scheduledTestDownloadLink = response.data.DownloadLink;

        const fileName = response.data.FileName;

        const reportResponse = await fetch(scheduledTestDownloadLink, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        });

        const reportFile = await reportResponse.blob();

        fileDownload(reportFile, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download scheduled report example',
        });
      }
    },
    async changeReportSchedule({ user, params }) {
      const mappedParams = reportScheduleParamsMapper(user, params);
      try {
        const response = await ajax.post(
          REPORT_SCHEDULE_ENDPOINT,
          qs.stringify(mappedParams, { indices: false }),
        );
        showSuccess({
          message: 'Success',
          description: 'Report has been successfully scheduled!',
        });
        const users = response.data.map(User.parse);
        dispatch.admin.setUsers(users);
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
    async getUsers() {
      try {
        const response = await ajax.get(USERS_LIST_ENDPOINT);
        const users = response.data.map(User.parse);
        dispatch.admin.setUsers(users);
      } catch (error) {
        dispatch.admin.setError(error);
      }
    },
    async getBrands() {
      try {
        const response = await ajax.get(BRANDS_LIST_ENDPOINT);
        const brands = response.data.map(Brand.parse);
        dispatch.admin.setBrands(brands);
      } catch (error) {
        dispatch.admin.setError(error);
      }
    },
    async uploadScorecards(_, state) {
      const { uploadScorecardFiles, scorecardsBrand } = state.admin;

      dispatch.admin.setFilesLoading(true);

      let promisesCount = 0;

      uploadScorecardFiles.map((uploadScorecardFile) => {
        const body = new FormData();

        body.append('brand', scorecardsBrand);
        body.append('file', uploadScorecardFile);

        return ajax({ method: 'post', url: SCORECARDS_UPLOAD, data: body })
          .then((response) => {
            const newScorecardsByBrand = FilterOptions.parseScorecardsByBrand(response.data);
            dispatch.admin.setFilterOptions(new FilterOptions({
              ...state.admin.filterOptions,
              scorecardsByBrand: newScorecardsByBrand,
            }));
            dispatch.admin.deleteUploadScorecardFile(uploadScorecardFile.name);

            if (promisesCount === uploadScorecardFiles.length - 1) {
              dispatch.admin.setFilesLoading(false);
            }
            promisesCount += 1;
          })
          .catch((error) => {
            promisesCount += 1;
            showError({
              message: `Failed to upload ${uploadScorecardFile.name}`,
              description: error.response.data,
            });
          });
      });
    },
    async downloadScorecard(scorecardID) {
      try {
        const response = await ajax.get(
          SCORECARDS_DOWNLOAD, {
            responseType: 'blob',
            params: {
              id: scorecardID,
            },
          },
        );

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download scorecards.',
        });
      }
    },
    async deleteScorecard(scorecardID, state) {
      try {
        const response = await ajax.post(
          SCORECARD_DELETE,
          qs.stringify({
            id: scorecardID,
          }),
        );

        const newScorecardsByBrand = FilterOptions.parseScorecardsByBrand(response.data);

        dispatch.admin.setFilterOptions(new FilterOptions({
          ...state.admin.filterOptions,
          scorecardsByBrand: newScorecardsByBrand,
        }));

        showSuccess({
          message: 'Success',
          description: 'File successfully deleted.',
        });
      } catch (error) {
        showError({
          message: 'Failed to delete file.',
          description: error.response.data,
        });
      }
    },

    async changeTarget(_, state) {
      const {
        brands,
        representatives,
        date,
        targetNumber,
      } = state.admin.target;
      try {
        await ajax.post(
          TARGET_DATE_ENDPOINT,
          qs.stringify(
            {
              brands,
              representativeIDs: representatives,
              date,
              target: targetNumber,
            },
            { indices: false },
          ),
        );
        showSuccess({
          message: 'Success',
          description: 'Brand target date has been changed!',
        });
      } catch (error) {
        showError({
          message: 'Error',
          description: error.response.data,
        });
      }
    },
  }),
};
