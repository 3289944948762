import { connect } from 'react-redux';

import TotalSalesByDistributorsChart from 'salesModule/components/TotalSalesByDistributorsChart';
import { distributorsAndColorsSalesSelector, shouldRenderSalesChartSelector } from 'salesModule/selectors/totalSalesCasesByDistributorSelector';

export default connect(
  (state) => {
    const { distributors, distributorsColorMap } = distributorsAndColorsSalesSelector(state);
    const shouldRenderChart = shouldRenderSalesChartSelector(state);

    return {
      totalSalesByDistributors: state.sales.totalSalesByDistributorsData,
      distributors,
      shouldRenderChart,
      withDivider: shouldRenderChart,
      colorMap: distributorsColorMap,
    };
  },
)(TotalSalesByDistributorsChart);
