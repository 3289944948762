/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { colorGenerator } from 'common/utilities/colorGenerator';


const totalSalesBySkuSelector = state => state.sales.totalSalesBySkuData;
const totalCasesBySkuSelector = state => state.sales.totalCasesBySkuData;

export const skuNamesAndColorsSalesSelector = createSelector(
  totalSalesBySkuSelector,
  (totalSalesBySku) => {
    const currentSkuNames = totalSalesBySku.length > 0 ? totalSalesBySku[0].getSkuNames() : [];
    const currentSkuNamesColorMap = colorGenerator(currentSkuNames);

    return {
      skuNames: currentSkuNames,
      skuNamesColorMap: currentSkuNamesColorMap,
    };
  },
);

export const skuNamesAndColorsCasesSelector = createSelector(
  totalCasesBySkuSelector,
  (totalCasesBySku) => {
    const currentSkuNames = totalCasesBySku.length > 0 ? totalCasesBySku[0].getSkuNames() : [];
    const currentSkuNamesColorMap = colorGenerator(currentSkuNames);

    return {
      skuNames: currentSkuNames,
      skuNamesColorMap: currentSkuNamesColorMap,
    };
  },
);

export const shouldRenderCasesChartSelector = createSelector(
  totalCasesBySkuSelector,
  totalCasesBySku => totalCasesBySku && totalCasesBySku.length > 0,
);

export const shouldRenderSalesChartSelector = createSelector(
  totalSalesBySkuSelector,
  totalSalesBySku => totalSalesBySku && totalSalesBySku.length > 0,
);
