/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { managementPagePermissionTypes } from 'userModule/constants/permissions';

const managementPagePermissionSelector = state => state.session.permissions[managementPagePermissionTypes.MANAGEMENT_PAGE];

export const permissionsSelector = createSelector(
  managementPagePermissionSelector,
  managementPagePermission => managementPagePermission.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  managementPagePermissionSelector,
  managementPagePermission => managementPagePermission.Filters || [],
);
