/* eslint-disable no-param-reassign */

import produce from 'immer';
import fileDownload from 'js-file-download';

import {
  SCHEDULE_FILTERS_ENDPOINT,
  SCHEDULE_ASM_ENDPOINT,
  SCHEDULE_DAILY_REPORT,
  SCHEDULE_WEEKLY_REPORT,
  SCHEDULE_MONTHLY_REPORT,
  METRICS_DAILY_REPORT,
  METRICS_WEEKLY_REPORT,
  METRICS_MONTHLY_REPORT,
  SCHEDULE_ASM_DAILY_ENDPOINT,
  SCHEDULE_ASM_WEEKLY_ENDPOINT,
  SCHEDULE_ASM_MONTHLY_ENDPOINT,
  SCHEDULE_SUMMARY_ENDPOINT,
  SCHEDULE_DAILY_BARS_ENDPOINT,
  SCHEDULE_REPRESENTATIVES_METRICS_ENDPOINT,
} from 'common/constants/apiRoutes';

import ajax from 'common/utilities/ajax';

import FilterOptions from 'scheduleModule/models/FilterOptions';
import AccountsFilter from 'scheduleModule/models/AccountsFilter';
import ScheduleData from 'scheduleModule/models/ScheduleData';
import AsmScheduleData from 'scheduleModule/models/AsmScheduleData';
import Summary from 'scheduleModule/models/Summary';
import AsmScheduleDailyBar from 'scheduleModule/models/AsmScheduleDailyBar';
import VisitsData from 'scheduleModule/models/VisitsData';

import { showError } from 'common/helpers/notificationManager';

const initialState = {
  filter: new AccountsFilter({}),
  summary: new Summary({
    planned: null,
    visited: null,
    visitedPercentage: null,
    missed: null,
    missedPercentage: null,
    unplanned: null,
    hours: null,
    miles: null,
  }),
  initialized: false,
  filterOptions: new FilterOptions({
    brands: [],
    channelTypes: {},
    cities: {},
    states: {},
    chains: {},
    representatives: {},
    territories: {},
    accountsSources: [],
    distributors: [],
    salesSkus: [],
    merchandisingSkus: [],
    earliestDate: null,
    regions: {},
    counties: {},
    banners: {},
    frequencies: [],
    inactiveReps: [],
  }),
  asmScheduleData: [],
  asmScheduleDailyData: [],
  asmScheduleWeeklyData: [],
  asmScheduleMonthlyData: [],

  selectedEvents: [],
  asmScheduleDailyFilteredMetrics: [],
  asmScheduleWeeklyFilteredMetrics: [],
  asmScheduleMonthlyFilteredMetrics: [],

  asmScheduleDailyBars: [],
  representativesVisitsData: {},
  visitStatuses: [],
  selectedVisitStatus: '',
};

export default {
  state: initialState,
  reducers: {
    setFilter: produce((state, accountsFilterModel) => {
      state.filter = accountsFilterModel;
      state.initialized = true;
    }),
    setFilterOptions: produce((state, filters) => {
      state.filterOptions = filters;
    }),
    setAsmScheduleData: produce((state, asmScheduleData) => {
      state.asmScheduleData = asmScheduleData;
    }),
    setAsmScheduleDailyData: produce((state, asmScheduleDailyData) => {
      state.asmScheduleDailyData = asmScheduleDailyData;
    }),
    setAsmScheduleWeeklyData: produce((state, asmScheduleWeeklyData) => {
      state.asmScheduleWeeklyData = asmScheduleWeeklyData;
    }),
    setAsmScheduleMonthlyData: produce((state, asmScheduleMonthlyData) => {
      state.asmScheduleMonthlyData = asmScheduleMonthlyData;
    }),
    setSelectedEvents: produce((state, event) => {
      state.selectedEvents = state.selectedEvents.includes(event)
        ? state.selectedEvents.filter(selectedEvent => selectedEvent !== event)
        : [...state.selectedEvents, event];
    }),
    setSummary: produce((state, summaryData) => {
      state.summary = summaryData;
    }),

    setAsmScheduleDailyFilteredMetrics: produce((state, filteredMetrics) => {
      state.asmScheduleDailyFilteredMetrics = filteredMetrics;
    }),
    setAsmScheduleWeeklyFilteredMetrics: produce((state, filteredMetrics) => {
      state.asmScheduleWeeklyFilteredMetrics = filteredMetrics;
    }),
    setAsmScheduleMonthlyFilteredMetrics: produce((state, filteredMetrics) => {
      state.asmScheduleMonthlyFilteredMetrics = filteredMetrics;
    }),

    setAsmScheduleDailyBars: produce((state, asmScheduleDailyBarsData) => {
      state.asmScheduleDailyBars = asmScheduleDailyBarsData;
    }),
    setRepresentativesVisitsData: produce((state, representativesVisitsData) => {
      state.representativesVisitsData = representativesVisitsData;
    }),
    setVisitStatuses: produce((state, visitStatuses) => {
      state.visitStatuses = visitStatuses;
    }),
    setSelectedVisitStatus: produce((state, selectedVisitStatus) => {
      state.selectedVisitStatus = selectedVisitStatus;
    }),

    clear: () => initialState,
  },
  effects: dispatch => ({
    async fetchFilterOptions() {
      const response = await ajax.get(SCHEDULE_FILTERS_ENDPOINT);
      const filterOptionsModel = FilterOptions.parse(response.data);
      dispatch.schedule.setFilterOptions(filterOptionsModel);
    },

    async fetchAsmScheduleDailyBars(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCHEDULE_DAILY_BARS_ENDPOINT, config);
      const asmScheduleDailyBarsData = response.data.map(AsmScheduleDailyBar.parse);
      dispatch.schedule.setAsmScheduleDailyBars(asmScheduleDailyBarsData);
    },

    async fetchScheduleRepresentativesMetrics(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCHEDULE_REPRESENTATIVES_METRICS_ENDPOINT, config);
      const visitStatuses = Object.keys(response.data);
      const representativesVisitsData = visitStatuses
        .reduce((acc, visitStatus) => {
          acc[visitStatus] = response.data[visitStatus].map(VisitsData.parse);
          return acc;
        }, {});
      dispatch.schedule.setVisitStatuses(visitStatuses);
      dispatch.schedule.setSelectedVisitStatus(visitStatuses[0]);
      dispatch.schedule.setRepresentativesVisitsData(representativesVisitsData);
    },

    async fetchAsmScheduleData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCHEDULE_ASM_ENDPOINT, config);
      const asmScheduleData = Object.keys(response.data).map(asmName => ScheduleData.parse(asmName, response.data[asmName]));
      dispatch.schedule.setAsmScheduleData(asmScheduleData);
    },

    async fetchAsmScheduleDailyData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCHEDULE_ASM_DAILY_ENDPOINT, config);
      const asmScheduleDailyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData
            .map(metricsDataItem => AsmScheduleData.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);
      dispatch.schedule.setAsmScheduleDailyData(asmScheduleDailyData);
    },

    async fetchAsmScheduleWeeklyData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCHEDULE_ASM_WEEKLY_ENDPOINT, config);
      const asmScheduleWeeklyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData
            .map(metricsDataItem => AsmScheduleData.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);
      dispatch.schedule.setAsmScheduleWeeklyData(asmScheduleWeeklyData);
    },

    async fetchAsmScheduleMonthlyData(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCHEDULE_ASM_MONTHLY_ENDPOINT, config);
      const asmScheduleMonthlyData = Object.keys(response.data).map((name) => {
        const metrics = response.data[name];
        const metricsNames = Object.keys(metrics);
        return metricsNames.map((metricName) => {
          const metricsData = metrics[metricName];
          return metricsData
            .map(metricsDataItem => AsmScheduleData.parse(metricsDataItem, name, metricName));
        });
      }).reduce((acc, cur) => [...acc, cur], []);
      dispatch.schedule.setAsmScheduleMonthlyData(asmScheduleMonthlyData);
    },

    async fetchSummary(filterModel) {
      const config = {
        params: filterModel.getApiParams(),
      };
      const response = await ajax.get(SCHEDULE_SUMMARY_ENDPOINT, config);
      const summaryData = Summary.parse(response.data);
      dispatch.schedule.setSummary(summaryData);
    },

    async downloadScheduleDailyReport(_, state) {
      const { filter } = state.schedule;

      try {
        const response = await ajax.get(SCHEDULE_DAILY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download schedule daily report',
        });
      }
    },

    async downloadScheduleWeeklyReport(_, state) {
      const { filter } = state.schedule;

      try {
        const response = await ajax.get(SCHEDULE_WEEKLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download schedule weekly report',
        });
      }
    },

    async downloadScheduleMonthlyReport(_, state) {
      const { filter } = state.schedule;

      try {
        const response = await ajax.get(SCHEDULE_MONTHLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download schedule monthly report',
        });
      }
    },

    async downloadMetricsDailyReport(_, state) {
      const { filter } = state.schedule;

      try {
        const response = await ajax.get(METRICS_DAILY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download schedule daily report',
        });
      }
    },

    async downloadMetricsWeeklyReport(_, state) {
      const { filter } = state.schedule;

      try {
        const response = await ajax.get(METRICS_WEEKLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download schedule daily report',
        });
      }
    },

    async downloadMetricsMonthlyReport(_, state) {
      const { filter } = state.schedule;

      try {
        const response = await ajax.get(METRICS_MONTHLY_REPORT, {
          responseType: 'blob',
          params: filter.getApiParams(),
        });

        const fileName = response.headers['content-disposition']
          .match(/".+"/)[0]
          .replace(/"/g, '');

        fileDownload(response.data, fileName);
      } catch (error) {
        showError({
          message: 'Error',
          description: 'Failed to download schedule daily report',
        });
      }
    },

  }),
};
