import { connect } from 'react-redux';
import { permissionsSelector } from 'reportsModule/selectors/reportsPageSelectors';

import ChainReportsSection from 'reportsModule/components/ChainReportsSection';

export default connect(
  state => ({
    isScoreCardsDownloading: state.loading.effects.reports.downloadScorecards,
    childrenPermissions: permissionsSelector(state),
    selectedBrand: state.reports.selectedBrand,
    scorecardsByBrand: state.reports.filterOptions.scorecardsByBrand,
  }),
  dispatch => ({
    onDownloadScorecards: scorecardID => dispatch.reports.downloadScorecards(scorecardID),
  }),
)(ChainReportsSection);
