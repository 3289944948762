import React from 'react';
import { node } from 'prop-types';

import './index.scss';

const Page = ({ children }) => (
  <main className="page">
    <div className="page__inner-container">{children}</div>
  </main>
);

Page.propTypes = {
  children: node,
};

Page.defaultProps = {
  children: () => {},
};

export default Page;
