/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { timeEffortsPagePermissionTypes } from 'userModule/constants/permissions';


const udsPagePermissionSelector = state => state.session.permissions[timeEffortsPagePermissionTypes.TIME_EFFORTS_PAGE];

export const permissionsSelector = createSelector(
  udsPagePermissionSelector,
  udsPagePermission => udsPagePermission.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  udsPagePermissionSelector,
  udsPagePermission => udsPagePermission.Filters || [],
);
