import React, { PureComponent, Fragment } from 'react';
import {
  instanceOf, arrayOf, string,
} from 'prop-types';

import PieChart from 'common/components/PieChart';
import SectionHeading from 'common/components/SectionHeading';

import AmountByChannel from 'accountsModule/models/AmountByChannel';

class AccountsByChannelTypeChart extends PureComponent {
  render() {
    const {
      accountsByChannelType,
      colorMap,
      disabledKeys,
      heading,
    } = this.props;

    const pieChartData = new Array(accountsByChannelType.length)
      .fill(null)
      .map((_, i) => ({
        id: accountsByChannelType[i].channel,
        label: accountsByChannelType[i].channel,
        value: accountsByChannelType[i].amount,
        percentage: accountsByChannelType[i].percentage,
      }));

    const labelFormatter = label => `${label.value} (${label.percentage}%)`;

    return (
      <Fragment>
        <SectionHeading>{heading}</SectionHeading>
        <div className="accounts-pie-chart">
          <PieChart
            data={pieChartData.filter(
              pieData => !disabledKeys.includes(pieData.id),
            )}
            colorMap={colorMap}
            labelFormatter={labelFormatter}
          />
        </div>
      </Fragment>
    );
  }
}

AccountsByChannelTypeChart.propTypes = {
  accountsByChannelType: arrayOf(instanceOf(AmountByChannel)).isRequired,
  colorMap: instanceOf(Map).isRequired,
  disabledKeys: arrayOf(string).isRequired,
  heading: string.isRequired,
};

export default AccountsByChannelTypeChart;
