class Account {
  constructor({
    id,
    address,
    latitude,
    longitude,
    name,
    storePictureUrl,
    totalSales,
    totalOrders,
    totalCases,
    lastOrderDate,
    authorisedDate,
    visits,
    status,
    representative,
    nextScheduledDate,
  }) {
    this.id = id;
    this.address = address;
    this.latitude = latitude;
    this.longitude = longitude;
    this.name = name;
    this.storePictureUrl = storePictureUrl;
    this.totalSales = totalSales;
    this.totalOrders = totalOrders;
    this.totalCases = totalCases;
    this.lastOrderDate = lastOrderDate;
    this.authorisedDate = authorisedDate;
    this.status = status;
    this.visits = visits;
    this.representative = representative;
    this.nextScheduledDate = nextScheduledDate;
  }

  static parse(obj) {
    return new Account({
      id: obj.ID,
      name: obj.Name,
      address: obj.Address,
      latitude: obj.Latitude,
      longitude: obj.Longitude,
      storePictureUrl: obj.StorePicLink,
      totalSales: obj.TotalAmount,
      totalOrders: `${obj.TotalOrders} (${obj.TotalOrdersPercentage}%)`,
      totalCases: obj.TotalCasesCount,
      lastOrderDate: obj.LastOrderDate,
      authorisedDate: obj.AuthorizedDate,
      visits: obj.MerchVisits,
      status: obj.Status,
      representative: obj.RepresentativeName,
      nextScheduledDate: obj.NextScheduledVisit,
    });
  }

  getCoordinates() {
    return [this.latitude, this.longitude];
  }
}

export default Account;
