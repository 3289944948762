import React from 'react';
import { Divider } from 'antd';
import {
  instanceOf, bool, arrayOf,
} from 'prop-types';

import VoidsBySku from 'merchandisingModule/models/VoidsBySku';

import SectionHeading from 'common/components/SectionHeading';
import VoidsChart from 'merchandisingModule/components/VoidsChart';

import { colorGenerator } from 'common/utilities/colorGenerator';


const VoidsCharts = ({ shouldRenderPieCharts, voidsData }) => {
  const voidsCharts = voidsData.map((voidData) => {
    const voidsTitle = voidData.voidsBySku.map(voids => voids.status);
    const colorMap = colorGenerator(voidsTitle);
    return (
      <VoidsChart
        title={voidData.name}
        voidsBySku={voidData.voidsBySku}
        voidsTitle={voidsTitle}
        colorMap={colorMap}
        key={voidData.name}
      />
    );
  });
  return shouldRenderPieCharts && (
    <article className="merchandising-page__pie-charts-container">
      <SectionHeading>Voids</SectionHeading>
      <section className="merchandising-page__scrollable-charts">
        {voidsCharts}
      </section>
      <Divider />
    </article>
  );
};

VoidsCharts.propTypes = {
  voidsData: arrayOf(instanceOf(VoidsBySku)).isRequired,
  shouldRenderPieCharts: bool.isRequired,
};

export default VoidsCharts;
