import { connect } from 'react-redux';

import { permissionsSelector, filterPermissionsSelector } from 'merchandisingModule/selectors/merchandisingPageSelectors';
import MerchandisingPage from 'merchandisingModule/components/MerchandisingPage';

export default connect(
  state => ({
    userInfo: state.session.userInfo,
    loading: false,
    fetchingFilterOptions:
      state.loading.effects.merchandising.fetchFilterOptions,
    filterInitialized: state.merchandising.initialized,
    filter: state.merchandising.filter,
    filterOptions: state.merchandising.filterOptions,
    accounts: state.merchandising.accounts,
    tagsInstalledData: state.merchandising.tagsInstalledData,
    productsOnPromoData: state.merchandising.productsOnPromoData,
    openAccountPopupId: state.merchandising.openAccountPopupId,
    permissions: state.session.permissions,
    childrenPermissions: permissionsSelector(state),
    filterPermissions: filterPermissionsSelector(state),
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.merchandising.fetchFilterOptions,
    setFilter: dispatch.merchandising.setFilter,
    fetchAccounts: dispatch.merchandising.fetchAccounts,
    fetchAverageFacingsData: dispatch.merchandising.fetchAverageFacingsData,
    fetchAddedFacingsData: dispatch.merchandising.fetchAddedFacingsData,
    fetchTotalFacingsData: dispatch.merchandising.fetchTotalFacingsData,
    fetchPrimaryPlacementData: dispatch.merchandising.fetchPrimaryPlacementData,
    fetchShelfPlacementData: dispatch.merchandising.fetchShelfPlacementData,
    fetchSecondaryPlacementData:
      dispatch.merchandising.fetchSecondaryPlacementData,
    fetchPosPlacementData: dispatch.merchandising.fetchPosPlacementData,
    fetchCompetitorsData: dispatch.merchandising.fetchCompetitorsData,
    fetchRetailPricesData: dispatch.merchandising.fetchRetailPricesData,
    fetchVoidsData: dispatch.merchandising.fetchVoidsData,
    fetchTagsInstalledData: dispatch.merchandising.fetchTagsInstalledData,
    fetchProductsOnPromoData: dispatch.merchandising.fetchProductsOnPromoData,
    resetMerchandisingStore: dispatch.merchandising.clear,
    clearMerchandisingImages: dispatch.merchandising.clearMerchandisingImages,
    fetchAccountMerchandisingImages: (openAccountPopupId) => {
      dispatch.merchandising.fetchAccountMerchandisingImages(
        openAccountPopupId,
      );
    },
  }),
)(MerchandisingPage);
