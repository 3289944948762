import Voids from './Voids';

class VoidsBySku {
  constructor({ name, voidsBySku }) {
    this.name = name;
    this.voidsBySku = voidsBySku;
  }

  static parse(name, info) {
    return new VoidsBySku({
      name,
      voidsBySku: info.map(voids => Voids.parse(voids)),
    });
  }
}

export default VoidsBySku;
