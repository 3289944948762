/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import {
  arrayOf, instanceOf, bool, string,
} from 'prop-types';
import { Marker, Popup } from 'react-leaflet';
import moment from 'moment';
import L from 'leaflet';

import { Spin } from 'antd';

import LeafletMap from 'common/components/LeafletMap';

import Account from 'merchandisingModule/models/Account';
import SecondaryPlacement from 'merchandisingModule/models/SecondaryPlacementOnMap';

import AccountPopupInfo from 'merchandisingModule/containers/MerchandisingPopupContainer';
import DownloadMerchandisingButton from 'merchandisingModule/containers/DownloadMerchandisingButtonContainer';

import { merchandisingPagePermissionTypes } from 'userModule/constants/permissions';

import {
  PRIMARY_PICTURE,
  SECONDARY_PICTURE,
} from 'merchandisingModule/constants/merchandising-constants';

import './index.scss';

class MerchandisingMap extends PureComponent {
  static propTypes = {
    accounts: arrayOf(instanceOf(Account)).isRequired,
    isLoading: bool.isRequired,
    filterCriteria: arrayOf(string).isRequired,
    shouldRenderMap: bool.isRequired,
    disabledKeys: arrayOf(string).isRequired,
    childrenPermissions: arrayOf(string).isRequired,
    secondaryPlacements: arrayOf(instanceOf(SecondaryPlacement)).isRequired,
  };

  getMarkers = () => {
    const {
      accounts,
      disabledKeys,
      filterCriteria,
      secondaryPlacements,
    } = this.props;

    const [
      WITH_SECONDARY_PLACEMENT,
      NO_SECONDARY_PLACEMENT,
      NONE_SECONDARY_PLACEMENT,
    ] = filterCriteria;

    const markerMap = secondaryPlacements.reduce(
      (markersMap, secondaryPlacement) => markersMap.set(
        secondaryPlacement.name,
        secondaryPlacement.image,
      ),
      new Map(),
    );

    let filteredAccounts = accounts;

    if (disabledKeys.includes(WITH_SECONDARY_PLACEMENT)) {
      filteredAccounts = filteredAccounts.filter(
        account => !account.secondaryPlacementPictureUrl,
      );
    }

    if (disabledKeys.includes(NO_SECONDARY_PLACEMENT)) {
      filteredAccounts = filteredAccounts
        .filter(account => Boolean(account.secondaryPlacementPictureUrl) || account.secondaryPlacementCount === 0);
    }

    if (disabledKeys.includes(NONE_SECONDARY_PLACEMENT)) {
      filteredAccounts = filteredAccounts
        .filter(account => Boolean(account.secondaryPlacementPictureUrl) || account.secondaryPlacementCount > 0);
    }

    return filteredAccounts.map(account => (
      <Marker
        icon={L.icon({
          iconUrl: markerMap.get(
            account.secondaryPlacementPictureUrl
              ? WITH_SECONDARY_PLACEMENT
              : account.secondaryPlacementCount > 0
                ? NO_SECONDARY_PLACEMENT
                : NONE_SECONDARY_PLACEMENT,
          ),
          iconSize: [36, 36],
          iconAnchor: [18, 36],
          popupAnchor: [0, -25],
        })}
        key={account.id}
        position={account.getCoordinates()}
      >
        <Popup>
          <AccountPopupInfo
            accountId={account.id}
            name={account.name}
            address={account.address}
            storePictureUrl={account.storePictureUrl}
            date={moment(account.date)}
            authorisedDate={moment(account.authorisedDate)}
            status={account.status}
            totalVisits={account.totalVisits}
            representative={account.representative}
            nextScheduledDate={moment(account.nextScheduledDate)}
            secondaryPlacement={`${account.secondaryPlacementCount} (${account.secondaryPlacementCountPercentage}%)`}
            picturesList={[
              {
                title: 'Primary Placement',
                type: PRIMARY_PICTURE,
                pictureUrl: account.primaryPlacementPictureUrl,
              },
              {
                title: 'Secondary Placement',
                type: SECONDARY_PICTURE,
                pictureUrl: account.secondaryPlacementPictureUrl,
              },
            ]}
          />
        </Popup>
      </Marker>
    ));
  };

  render() {
    const { shouldRenderMap, isLoading, childrenPermissions } = this.props;

    return (
      shouldRenderMap && (
        <div className="merchandising-map">
          <h2 className="merchandising-map__heading">
            <span>Merchandising Map</span>
            { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_MAP_REPORT) && <DownloadMerchandisingButton /> }
          </h2>
          {isLoading && (
            <Spin spinning={isLoading}>
              <div className="accounts-map__spinner-inner" />
            </Spin>
          )}
          {!isLoading && <LeafletMap>{this.getMarkers()}</LeafletMap>}
        </div>
      )
    );
  }
}

export default MerchandisingMap;
