import { connect } from 'react-redux';
import {
  colorMapSelector,
  sortedAccountStatusesSelector,
  accountStatusesOnDateDataSelector,
} from 'accountsModule/selectors/accountStatusOnDateChartSelectors';

import withLegend from 'common/hocs/withLegend';

import AccountStatusOnDateChart from 'accountsModule/components/AccountStatusOnDateChart';

export default connect(state => ({
  accountStatusesOnDateData: accountStatusesOnDateDataSelector(state),
  accountStatuses: sortedAccountStatusesSelector(state),
  colorMap: colorMapSelector(state),
}))(
  withLegend({
    keys: 'accountStatuses',
    isLegendActive: false,
  })(AccountStatusOnDateChart),
);
