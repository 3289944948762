/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { schedulePagePermissionTypes } from 'userModule/constants/permissions';


const schedulePagePermissionSelector = state => state.session.permissions[schedulePagePermissionTypes.SCHEDULE_PAGE];

export const permissionsSelector = createSelector(
  schedulePagePermissionSelector,
  schedulePagePermission => schedulePagePermission.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  schedulePagePermissionSelector,
  schedulePagePermission => schedulePagePermission.Filters || [],
);
