import React from 'react';
import { string, func, bool } from 'prop-types';
import { List, Icon } from 'antd';

import './index.scss';

const ListItem = List.Item;

const BrandScorecardsList = ({
  selectedBrand,
  scorecardsByBrand,
  onDelete,
  onDownload,

  isUploading,
  isDownLoading,
  isDeleting,
}) => {
  const listItems = scorecardsByBrand[selectedBrand] || [];

  return (
    <div className="scorecards-list">
      <List
        size="small"
        bordered
        loading={isUploading || isDeleting || isDownLoading}
        dataSource={listItems}
        renderItem={item => (
          <ListItem>
            <div className="scorecards-list__item">
              <p className="scorecards-list__item-name" title={item.scorecardFilename}>
                {item.scorecardFilename}
              </p>
              <div className="scorecards-list__item-icons">
                <div className="scorecards-list__item-icon">
                  <Icon
                    type="download"
                    onClick={() => onDownload(item.scorecardID)}
                  />
                </div>
                <div className="scorecards-list__item-icon">
                  <Icon
                    theme="twoTone"
                    type="delete"
                    twoToneColor="#ff1c1c"
                    onClick={() => onDelete(item.scorecardID)}
                  />
                </div>
              </div>
            </div>
          </ListItem>
        )}
      />
    </div>
  );
};

BrandScorecardsList.propTypes = {
  selectedBrand: string,
  onDelete: func.isRequired,
  onDownload: func.isRequired,
  isUploading: bool.isRequired,
  isDownLoading: bool.isRequired,
  isDeleting: bool.isRequired,
};

BrandScorecardsList.defaultProps = {
  selectedBrand: null,
};

export default BrandScorecardsList;
