/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { adminPagePermissionTypes } from 'userModule/constants/permissions';


const adminPagePermissionSelector = state => state.session.permissions[adminPagePermissionTypes.ADMIN_PAGE];

export const permissionsSelector = createSelector(
  adminPagePermissionSelector,
  adminPagePermission => adminPagePermission.Permissions || [],
);
