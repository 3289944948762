import React from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const FieldTitle = ({ className, children, ...props }) => (
  <div {...props} className={classnames(className, 'field-title')}>
    {children}
  </div>
);

FieldTitle.propTypes = {
  children: node.isRequired,
  className: string,
};

FieldTitle.defaultProps = {
  className: null,
};

export default FieldTitle;
