import { connect } from 'react-redux';

import { permissionsSelector } from 'adminModule/selectors/adminPageSelectors';
import UsersList from '../components/UsersList';

export default connect(
  state => ({
    filterOptions: state.admin.filterOptions,
    users: state.admin.users,
    userInfo: state.session.userInfo,
    isUsersListFetching: state.loading.effects.admin.getUsers,
    isScheduledReportDownloading: state.loading.effects.admin.testScheduledReport,
    childrenPermissions: permissionsSelector(state),
    permissions: state.session.permissions,
  }),
  dispatch => ({
    fetchFilterOptions: dispatch.admin.fetchFilterOptions,
    registerUser: dispatch.admin.registerUser,
    deleteUser: dispatch.admin.deleteUser,
    getUsers: dispatch.admin.getUsers,
    editUser: dispatch.admin.editUser,
    changePermissions: dispatch.admin.changePermissions,
    changeReportSchedule: dispatch.admin.changeReportSchedule,
    testScheduledReport: dispatch.admin.testScheduledReport,
  }),
)(UsersList);
