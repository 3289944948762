/* eslint-disable no-param-reassign */
import DistributorCase from 'salesModule/models/DistributorCase';

class TotalCaseByDistributors {
  constructor({ dateInterval, casesByDistributors }) {
    this.dateInterval = dateInterval;
    this.casesByDistributors = casesByDistributors;
  }

  static parse(dateInterval, obj) {
    const { TotalCasesByDistributor } = obj;
    const casesByDistributors = Object.keys(TotalCasesByDistributor).map(
      distributorName => new DistributorCase({
        name: distributorName,
        amount:
          Math.round(TotalCasesByDistributor[distributorName])
          || 0,
      }),
    );
    return new TotalCaseByDistributors({
      dateInterval,
      casesByDistributors,
    });
  }

  getDistributorsNames() {
    return this.casesByDistributors.map(
      distributorCase => distributorCase.name,
    );
  }

  getDistributorsData() {
    return this.casesByDistributors.reduce(
      (distributorsData, distributor) => {
        distributorsData[distributor.name] = distributor.amount;
        distributorsData.dateInterval = this.dateInterval;
        return distributorsData;
      },
      {},
    );
  }
}

export default TotalCaseByDistributors;
