import React, { PureComponent } from 'react';
import moment from 'moment';

import LineChart from 'common/components/LineChart';

import {
  DATE_FORMAT,
  DAY_FORMAT,
} from 'common/constants/dateConstants';

class TimeLineChart extends PureComponent {
  state = {
    maxTicks: 0,
  }

  componentDidMount() {
    this.updateMaxTicks();
    window.addEventListener('resize', this.updateMaxTicks);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMaxTicks);
  }

  updateMaxTicks = () => {
    if (window.innerWidth > 768) {
      return this.setState({
        maxTicks: 30,
      });
    }

    if (window.innerWidth <= 768 && window.innerWidth > 640) {
      return this.setState({
        maxTicks: 20,
      });
    }

    if (window.innerWidth <= 640 && window.innerWidth > 480) {
      return this.setState({
        maxTicks: 12,
      });
    }

    return this.setState({
      maxTicks: 5,
    });
  }

  render() {
    const { data } = this.props;
    const { maxTicks } = this.state;
    let getTick = tick => moment(tick).format(DATE_FORMAT);

    const allSundayTicks = data[0].data.filter(
      tickData => moment(tickData.x).format(DAY_FORMAT) === 'Sunday',
    );

    const numberOfSundayTicksInterval = Math.ceil(
      allSundayTicks.length / maxTicks,
    );

    const allTicks = allSundayTicks
      .filter((_, i) => i % numberOfSundayTicksInterval === 0)
      .map(tickData => tickData.x);

    getTick = (tick) => {
      if (allTicks.includes(tick)) {
        return `${moment(tick).format(DATE_FORMAT)}`;
      }

      return '';
    };

    return <LineChart {...this.props} data={data} getTick={getTick} />;
  }
}

export default TimeLineChart;
