/* eslint-disable no-param-reassign */
import DistributorSale from 'salesModule/models/DistributorSale';

class TotalSaleByDistributor {
  constructor({ dateInterval, salesByDistributors }) {
    this.dateInterval = dateInterval;
    this.salesByDistributors = salesByDistributors;
  }

  static parse(dateInterval, obj) {
    const { TotalsByDistributor } = obj;
    const salesByDistributors = Object.keys(TotalsByDistributor).map(
      distributorName => new DistributorSale({
        name: distributorName,
        amount:
          Math.round(TotalsByDistributor[distributorName]) || 0,
      }),
    );
    return new TotalSaleByDistributor({
      dateInterval,
      salesByDistributors,
    });
  }

  getDistributorsNames() {
    return this.salesByDistributors.map(
      distributorSale => distributorSale.name,
    );
  }

  getDistributorsData() {
    return this.salesByDistributors.reduce(
      (distributorsData, distributor) => {
        distributorsData[distributor.name] = distributor.amount;
        distributorsData.dateInterval = this.dateInterval;
        return distributorsData;
      },
      {},
    );
  }
}

export default TotalSaleByDistributor;
