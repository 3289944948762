import React from 'react';
import { instanceOf, func } from 'prop-types';

import { Link } from 'react-router-dom';

import {
  Menu, Dropdown, Avatar, Icon,
} from 'antd';

import { adminPagePermissionTypes } from 'userModule/constants/permissions';
import { ADMIN } from 'common/constants/routes';

import UserInfo from 'userModule/models/UserInfo';
import Permissions from 'userModule/models/Permissions';

import './index.scss';

const { Item, Divider } = Menu;

const HeaderMenu = ({
  userInfo, logOut, permissions,
}) => {
  const logOutUser = (e) => {
    e.preventDefault();
    logOut();
  };

  const { isAdmin } = userInfo;

  const avatarStyle = {
    color: '#696969',
    fontWeight: 500,
    backgroundColor: '#e8e8e8',
    verticalAlign: 'middle',
  };

  const menuComponent = (
    <Menu className="menu">
      {permissions[adminPagePermissionTypes.ADMIN_PAGE].Permissions
        && (
          <Item>
            <Link to={ADMIN} className="menu__item-inner">
              <Icon type="setting" />
              {' '}
                Admin
            </Link>
          </Item>
        )
      }
      {permissions[adminPagePermissionTypes.ADMIN_PAGE].Permissions && <Divider />}
      <Item>
        <a
          onClick={logOutUser}
          className="menu__item-inner menu__item--logout"
          href="/"
        >
          <Icon type="logout" style={{ fontSize: '14px' }} />
          {' '}
          Log Out
        </a>
      </Item>
    </Menu>
  );

  return (
    <nav className="menu-wrapper">
      <Dropdown overlay={menuComponent} placement="bottomRight" trigger={['click']}>
        <Avatar className="menu__avatar" style={avatarStyle} size="large">
          {isAdmin ? 'Admin' : 'User'}
        </Avatar>
      </Dropdown>
    </nav>
  );
};

HeaderMenu.propTypes = {
  logOut: func.isRequired,
  userInfo: instanceOf(UserInfo).isRequired,
  permissions: instanceOf(Permissions).isRequired,
};

export default HeaderMenu;
