import { connect } from 'react-redux';

import ScorecardsSection from 'adminModule/components/ScorecardsSection';

export default connect(
  state => ({
    isUploadDisabled: !state.admin.uploadScorecardFiles.length > 0,
    selectedBrand: state.admin.scorecardsBrand,
    brands: state.admin.filterOptions.brands,

    isUploading: state.loading.effects.admin.uploadScorecards,

    isFilterOptionsFetching: state.loading.effects.admin.fetchFilterOptions,
  }),
  dispatch => ({
    onUpload: () => dispatch.admin.uploadScorecards(),
    onChangeBrand: dispatch.admin.setScorecardsBrand,
  }),
)(ScorecardsSection);
