import React, { Fragment } from 'react';
import { Divider, Button } from 'antd';

import SectionHeading from 'common/components/SectionHeading';

import {
  bool, func, arrayOf, string,
} from 'prop-types';
import { reportsPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

const HEADING = 'Account Reports';

const AccountReportsSection = ({
  onDownloadAccountList,
  isAccountListDownloading,
  onDownloadAccountStatusReport,
  isAccountStatusDownloading,
  childrenPermissions,
}) => (
  <Fragment>
    <SectionHeading>{HEADING}</SectionHeading>
    <section className="account-reports-section">
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_ACCOUNT_LIST_REPORT) && (
        <div className="account-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadAccountList}
            loading={isAccountListDownloading}
          >
            Account List
          </Button>
        </div>
      )}
      {childrenPermissions.includes(reportsPagePermissionTypes.REPORTS_PAGE_ACCOUNT_STATUS_REPORT) && (
        <div className="account-reports-section__download-button-container">
          <Button
            type="primary"
            icon="file"
            onClick={onDownloadAccountStatusReport}
            loading={isAccountStatusDownloading}
          >
            Account Status Report
          </Button>
        </div>
      )}
    </section>
    <Divider />
  </Fragment>
);

AccountReportsSection.propTypes = {
  onDownloadAccountList: func.isRequired,
  isAccountListDownloading: bool.isRequired,
  onDownloadAccountStatusReport: func.isRequired,
  isAccountStatusDownloading: bool.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default AccountReportsSection;
