/* eslint-disable no-return-assign, no-param-reassign */

import React from 'react';
import {
  arrayOf, instanceOf, string, bool,
} from 'prop-types';
import { Divider } from 'antd';

import SectionHeading from 'common/components/SectionHeading';

import Scorecard from 'reportsModule/models/Scorecard';

import ScorecardTable from 'reportsModule/components/ScorecardsTable';

import './index.scss';

const ScorecardsTables = ({
  scorecardsData,
  title,
  shouldRenderTables,
}) => (
  shouldRenderTables && (
    <div className="scorecards">
      <SectionHeading>{title}</SectionHeading>
      <div className="scorecards__tables-wrapper">
        <div className="scorecards__table-wrapper">
          <SectionHeading>Time and Efforts</SectionHeading>
          <ScorecardTable
            scorecardsData={scorecardsData}
            fields={Scorecard.timeEfforts}
          />
        </div>
        <div className="scorecards__table-wrapper">
          <SectionHeading>Account Status</SectionHeading>
          <ScorecardTable
            scorecardsData={scorecardsData}
            fields={Scorecard.accountFields}
          />
        </div>
        <div className="scorecards__table-wrapper">
          <SectionHeading>Merchandising</SectionHeading>
          <ScorecardTable
            scorecardsData={scorecardsData}
            fields={Scorecard.merchandisingFields}
          />
        </div>
        <div className="scorecards__table-wrapper">
          <SectionHeading>Sales</SectionHeading>
          <ScorecardTable
            scorecardsData={scorecardsData}
            fields={Scorecard.salesFields}
          />
        </div>
      </div>
      <Divider />
    </div>
  )
);

/* eslint-disable react/no-unused-prop-types */
ScorecardsTables.propTypes = {
  scorecardsData: arrayOf(instanceOf(Scorecard)).isRequired,
  title: string.isRequired,
  shouldRenderTables: bool.isRequired,
};

export default ScorecardsTables;
