import React /* , { Fragment } */ from 'react';
import {
  Select, Icon, Input,
} from 'antd';
import {
  string,
  func,
  arrayOf,
  instanceOf,
} from 'prop-types';

import FieldTitle from 'common/components/FieldTitle';

import FilterOptions from 'databaseModule/models/FilterOptions';
import { databaseFiltersPermissionTypes } from 'userModule/constants/filterPermissions';

import './index.scss';

const { Option } = Select;

const CommonOption = option => (
  <Option key={option} value={option} title={option}>
    {option}
  </Option>
);

const searchIcon = <Icon type="search" style={{ fontSize: '12px' }} />;


const FiltersBoard = ({
  name,
  address,
  channels,
  cities,
  states,
  chains,
  representatives,
  territories,
  accountsSource,
  regions,
  counties,
  banners,
  frequencies,
  activeAccounts,
  zipCode,
  onChangeField,
  filterOptions,
  permissions,
}) => {
  const shouldRenderAdminFilters = permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_DATA_SOURCE)
  || permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_FREQUENCY)
  || permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_REPRESENTATIVE);

  const onChangeZipCode = (value) => {
    const numRegExp = /^\d+$/;
    if (numRegExp.test(value)) {
      return onChangeField('zipCode', value);
    }
    return '';
  };

  return (
    <div className="database-filters-board">
      <div className="database-filters-board__wrapper database-filters-board_users-section">
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_ACCOUNT) && (
          <div className="database-filters-board__filter database-filters-board__filter_search">
            <FieldTitle>Account</FieldTitle>
            <Input
              value={name}
              onChange={e => onChangeField('name', e.target.value)}
              placeholder="Search name"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_ADDRESS) && (
          <div className="database-filters-board__filter database-filters-board__filter_search">
            <FieldTitle>Address</FieldTitle>
            <Input
              value={address}
              onChange={e => onChangeField('address', e.target.value)}
              placeholder="Search address"
              suffix={searchIcon}
            />
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_CITY) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>City</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('cities', value)}
              value={cities}
              maxTagCount={2}
              placeholder="Select cities"
            >
              {filterOptions.cities.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_STATE) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>State</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('states', value)}
              value={states}
              maxTagCount={2}
              placeholder="Select states"
            >
              {filterOptions.states.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_ZIP) && (
          <div className="database-filters-board__filter database-filters-board__filter_search">
            <FieldTitle>Zip Code</FieldTitle>
            <Input
              value={zipCode}
              onChange={e => onChangeZipCode(e.target.value)}
              placeholder="Search zip code"
              suffix={searchIcon}
            />
          </div>
        )}
        <div className="database-filters-board__break" />
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_CHAIN) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>Chain</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('chains', value)}
              value={chains}
              maxTagCount={2}
              placeholder="Select chains"
            >
              {filterOptions.chains.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_BANNER) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>Banner</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('banners', value)}
              value={banners}
              maxTagCount={2}
              placeholder="Select banners"
            >
              {filterOptions.banners.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_CHANNEL) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>Channel</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('channels', value)}
              value={channels}
              maxTagCount={2}
              placeholder="Select channels"
            >
              {filterOptions.channels.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_REGION) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>Region</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('regions', value)}
              value={regions}
              maxTagCount={2}
              placeholder="Select regions"
            >
              {filterOptions.regions.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_COUNTY) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>County</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('counties', value)}
              value={counties}
              maxTagCount={2}
              placeholder="Select counties"
            >
              {filterOptions.counties.map(CommonOption)}
            </Select>
          </div>
        )}
        {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_AREA) && (
          <div className="database-filters-board__filter database-filters-board__filter_multiselect">
            <FieldTitle>Area</FieldTitle>
            <Select
              mode="multiple"
              className="database-filters-board__select"
              onChange={value => onChangeField('territories', value)}
              value={territories}
              maxTagCount={2}
              placeholder="Select area"
            >
              {filterOptions.territories.map(CommonOption)}
            </Select>
          </div>
        )}
      </div>
      {shouldRenderAdminFilters && (
        <div className="database-filters-board__wrapper database-filters-board__admins-section">
          {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_DATA_SOURCE) && (
            <div className="database-filters-board__filter database-filters-board__filter_multiselect database-filters-board__filter_multiselect-short">
              <FieldTitle>Account Source</FieldTitle>
              <Select
                className="database-filters-board__select"
                onChange={value => onChangeField('activeAccounts', value)}
                value={activeAccounts}
              >
                {filterOptions.activeAccounts.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_DATA_SOURCE) && (
            <div className="database-filters-board__filter database-filters-board__filter_multiselect database-filters-board__filter_multiselect-short">
              <FieldTitle>Data Source</FieldTitle>
              <Select
                className="database-filters-board__select"
                onChange={value => onChangeField('accountsSource', value)}
                value={accountsSource}
              >
                {filterOptions.accountsSources.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_FREQUENCY) && (
            <div className="database-filters-board__filter database-filters-board__filter_multiselect database-filters-board__filter_multiselect-short">
              <FieldTitle>Frequency</FieldTitle>
              <Select
                mode="multiple"
                className="database-filters-board__select"
                onChange={value => onChangeField('frequencies', value)}
                value={frequencies}
                maxTagCount={2}
                placeholder="Select frequencies"
              >
                {filterOptions.frequencies.map(CommonOption)}
              </Select>
            </div>
          )}
          {permissions.includes(databaseFiltersPermissionTypes.DATABASE_FILTERS_REPRESENTATIVE) && (
            <div className="database-filters-board__filter database-filters-board__filter_multiselect database-filters-board__filter_multiselect-short">
              <FieldTitle>Representative</FieldTitle>
              <Select
                mode="multiple"
                className="database-filters-board__select"
                onChange={value => onChangeField('representatives', value)}
                value={representatives}
                maxTagCount={2}
                placeholder="Select representatives"
              >
                {filterOptions.representatives.map(CommonOption)}
              </Select>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FiltersBoard.propTypes = {
  name: string.isRequired,
  address: string.isRequired,
  channels: arrayOf(string).isRequired,
  cities: arrayOf(string).isRequired,
  states: arrayOf(string).isRequired,
  chains: arrayOf(string).isRequired,
  representatives: arrayOf(string).isRequired,
  territories: arrayOf(string).isRequired,
  accountsSource: string.isRequired,
  regions: arrayOf(string).isRequired,
  counties: arrayOf(string).isRequired,
  banners: arrayOf(string).isRequired,
  frequencies: arrayOf(string).isRequired,
  activeAccounts: string.isRequired,
  zipCode: string.isRequired,
  filterOptions: instanceOf(FilterOptions).isRequired,
  onChangeField: func.isRequired,
  permissions: arrayOf(string).isRequired,
};

export default FiltersBoard;
