/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { accountsPagePermissionTypes } from 'userModule/constants/permissions';

const accountStatusesDataSelector = state => state.accounts.accountStatusesData;
const accountsByChannelTypeSelector = state => state.accounts.accountsByChannelType;
const accountsByDistributorOnDateSelector = state => state.accounts.accountsByDistributorOnDate;
const accountsPagePermissionsSelector = state => state.session.permissions[accountsPagePermissionTypes.ACCOUNTS_PAGE];

export const shouldNotRenderPieChartsSelector = createSelector(
  [accountStatusesDataSelector,
    accountsByChannelTypeSelector,
    accountsByDistributorOnDateSelector,
  ],
  (accountStatusesData,
    accountsByChannelType,
    accountsByDistributorOnDate) => accountStatusesData.length === 0
    && accountsByChannelType.length === 0
    && accountsByDistributorOnDate.length === 0,
);

export const permissionsSelector = createSelector(
  accountsPagePermissionsSelector,
  accountsPagePermissions => accountsPagePermissions.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  accountsPagePermissionsSelector,
  accountsPagePermissions => accountsPagePermissions.Filters || [],
);
