export const SALES = '/sales';
export const LOGIN = '/login';
export const ACCOUNTS = '/accounts';
export const REPORTS = '/reports';
export const MERCHANDISING = '/merchandising';
export const ADMIN = '/admin';
export const REGISTER_USER = '/register-user';
export const MANAGEMENT = '/management';
export const UDS = '/uds';
export const SCHEDULE = '/schedule';
export const DATABASE = '/database';
