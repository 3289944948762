import React from 'react';
import { node, number, arrayOf } from 'prop-types';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, TileLayer } from 'react-leaflet';

import './index.scss';

const LeafletMap = ({ children, center, zoom }) => (
  <div className="leaflet-map">
    <Map
      className="leaflet-map__map"
      center={center}
      zoom={zoom}
      maxZoom={19}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      />
      <MarkerClusterGroup>
        {children}
      </MarkerClusterGroup>
    </Map>
  </div>
);

LeafletMap.propTypes = {
  center: arrayOf(number),
  zoom: number,
  children: node.isRequired,
};

LeafletMap.defaultProps = {
  center: [33.922657, -118.0995512],
  zoom: 8,
};

export default LeafletMap;
