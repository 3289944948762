import { connect } from 'react-redux';

import LoginPage from '../components/LoginPage';

const mapState = state => ({
  loginError: Boolean(state.session.error),
});

const mapDispatch = dispatch => ({
  logIn: dispatch.session.logIn,
});

export default connect(
  mapState,
  mapDispatch,
)(LoginPage);
