import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import {
  arrayOf, any, string, instanceOf, func,
} from 'prop-types';

const PieChart = ({
  data, height, minWidth, colorMap, labelFormatter,
}) => {
  const colorBy = ({ id }) => colorMap.get(id);
  return (
    <div style={{ height, minWidth }} className="pie-chart">
      <ResponsivePie
        data={data}
        colorBy={colorBy}
        margin={{
          top: 10,
          right: 40,
          bottom: 10,
          left: 40,
        }}
        padAngle={0.5}
        innerRadius={0}
        cornerRadius={3}
        colors="set2"
        borderWidth={1}
        borderColor="inherit:darker(0.3)"
        enableRadialLabels={false}
        sliceLabel={labelFormatter}
        slicesLabelsSkipAngle={15}
        slicesLabelsTextColor="inherit"
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

PieChart.propTypes = {
  data: arrayOf(any).isRequired,
  height: string,
  minWidth: string,
  colorMap: instanceOf(Map).isRequired,
  labelFormatter: func,
};

PieChart.defaultProps = {
  height: '250px',
  minWidth: 'auto',
  labelFormatter: label => `${label.value}`,
};

export default PieChart;
