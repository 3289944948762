import React from 'react';
import { func, bool } from 'prop-types';

import LoginForm from '../LoginForm';

import './index.scss';

const LoginPage = ({ logIn, loginError }) => (
  <div className="login-page">
    <div className="login-page__login-form-container">
      <LoginForm onLogin={logIn} loginError={loginError} />
    </div>
  </div>
);

LoginPage.propTypes = {
  logIn: func.isRequired,
  loginError: bool,
};

LoginPage.defaultProps = {
  loginError: false,
};

export default LoginPage;
