import React, { PureComponent } from 'react';
import {
  func, instanceOf, bool, arrayOf, string,
} from 'prop-types';

import FilterOptions from 'databaseModule/models/FilterOptions';
import AccountsFilterModel from 'databaseModule/models/AccountsFilter';
import FilterButtons from 'common/components/FilterButtons';

import FiltersBoard from './FiltersBoard';

import './index.scss';

class AccountsFilter extends PureComponent {
  static propTypes = {
    applyFilter: func.isRequired,
    isAdmin: bool.isRequired,
    filterOptions: instanceOf(FilterOptions).isRequired,
    initialFilterModel: instanceOf(AccountsFilterModel).isRequired, // eslint-disable-line react/no-unused-prop-types
    permissions: arrayOf(string).isRequired,
  };

  static getStateFromFilterModel(filterModel) {
    return {
      name: filterModel.name,
      address: filterModel.address,
      channels: filterModel.channels,
      cities: filterModel.cities,
      states: filterModel.states,
      chains: filterModel.chains,
      representatives: filterModel.representatives,
      territories: filterModel.territories,
      accountsSource: filterModel.accountsSource,
      activeAccounts: filterModel.activeAccounts,
      regions: filterModel.regions,
      counties: filterModel.counties,
      banners: filterModel.banners,
      frequencies: filterModel.frequencies,
      zipCode: filterModel.zipCode,
    };
  }

  constructor(props) {
    super(props);

    const { initialFilterModel } = props;

    this.state = {
      ...AccountsFilter.getStateFromFilterModel(initialFilterModel),
      isFilterToggled: false,

      isDefaultEnabled: !initialFilterModel.isModelEqualWithDefault(),
    };
  }


  onChangeField = (fieldName, value) => {
    const newState = {
      ...this.state,
      [fieldName]: value,
      isFilterToggled: true,
    };

    const newFilterModel = new AccountsFilterModel(newState);
    this.setState({
      [fieldName]: value,
      isFilterToggled: true,
      isDefaultEnabled: !newFilterModel.isModelEqualWithDefault(),
    });
  };

  onApply = () => {
    const {
      state: {
        name,
        address,
        channels,
        cities,
        states,
        chains,
        representatives,
        territories,
        accountsSource,
        activeAccounts,
        regions,
        counties,
        banners,
        frequencies,
        zipCode,
      },
      props: { applyFilter },
    } = this;

    const filterModel = new AccountsFilterModel({
      name,
      address,
      channels,
      cities,
      states,
      chains,
      representatives,
      territories,
      accountsSource,
      activeAccounts,
      regions,
      counties,
      banners,
      frequencies,
      zipCode,
    });

    const normalizedFilter = filterModel.getNormalizedFilter();
    const newState = AccountsFilter.getStateFromFilterModel(
      normalizedFilter,
    );
    applyFilter(normalizedFilter);
    this.setState(() => ({
      ...newState,
      isFilterToggled: false,
      isDefaultEnabled: !filterModel.isModelEqualWithDefault(),
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.onApply();
  };

  onSetDefaultFilters = () => {
    const defaultFilterModel = new AccountsFilterModel(
      AccountsFilterModel.defaultParams,
    );

    const newState = AccountsFilter.getStateFromFilterModel(
      defaultFilterModel,
    );

    this.setState(() => ({
      ...newState,
      isFilterToggled: true,
      isDefaultEnabled: !defaultFilterModel.isModelEqualWithDefault(),
    }));
  };

  revertFilters = () => {
    const { initialFilterModel } = this.props;
    this.setState({
      ...AccountsFilter.getStateFromFilterModel(initialFilterModel),
      isFilterToggled: false,
      isDefaultEnabled: !initialFilterModel.isModelEqualWithDefault(),
    });
  };

  render() {
    const {
      name,
      address,
      channels,
      cities,
      states,
      chains,
      representatives,
      territories,
      accountsSource,
      regions,
      counties,
      banners,
      frequencies,
      zipCode,
      activeAccounts,
      isFilterToggled,
      isDefaultEnabled,
    } = this.state;

    const { isAdmin, filterOptions, permissions } = this.props;

    return (
      <form onSubmit={this.onSubmit} className="database-page-filter">
        <div className="database-page-filter-inner">
          <div className="database-page-filter__filters-container">
            <FiltersBoard
              isAdmin={isAdmin}
              rowGutter={AccountsFilter.rowGutter}
              disabledDate={this.disabledDate}
              name={name}
              address={address}
              channels={channels}
              cities={cities}
              states={states}
              chains={chains}
              representatives={representatives}
              territories={territories}
              accountsSource={accountsSource}
              activeAccounts={activeAccounts}
              regions={regions}
              counties={counties}
              banners={banners}
              frequencies={frequencies}
              zipCode={zipCode}
              filterOptions={filterOptions}
              onChangeField={this.onChangeField}
              permissions={permissions}
            />
          </div>
          <div
            className="database-page-filter__buttons-container"
          >
            <FilterButtons
              isFilterToggled={isFilterToggled}
              isDefaultEnabled={isDefaultEnabled}
              revertFilters={this.revertFilters}
              onSetDefaultFilters={this.onSetDefaultFilters}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default AccountsFilter;
