export const Periods = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  THIS_QUARTER: 'THIS_QUARTER',
  LAST_QUARTER: 'LAST_QUARTER',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  ALL_TIME: 'ALL_TIME',
};

export const PeriodLabels = {
  [Periods.TODAY]: 'Today',
  [Periods.YESTERDAY]: 'Yesterday',
  [Periods.THIS_WEEK]: 'This Week',
  [Periods.LAST_WEEK]: 'Last Week',
  [Periods.THIS_MONTH]: 'This Month',
  [Periods.LAST_MONTH]: 'Last Month',
  [Periods.THIS_QUARTER]: 'This Quarter',
  [Periods.LAST_QUARTER]: 'Last Quarter',
  [Periods.THIS_YEAR]: 'This Year',
  [Periods.LAST_YEAR]: 'Last Year',
  [Periods.ALL_TIME]: 'All Time',
};
