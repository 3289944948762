/* eslint-disable no-shadow */
import React, { PureComponent } from 'react';
import {
  func, bool, instanceOf, arrayOf, string,
} from 'prop-types';
import { location, history } from 'react-router-prop-types';
import { Skeleton } from 'antd';
import moment from 'moment';

import withNavigation from 'common/hocs/withNavigation';

import FilterOptions from 'scheduleModule/models/FilterOptions';
import AccountsFilterModel from 'scheduleModule/models/AccountsFilter';

import AccountsFilter from 'scheduleModule/components/AccountsFilter';
import AsmScheduleTable from 'scheduleModule/containers/AsmScheduleTableContainer';
import ScheduleReportsSection from 'scheduleModule/containers/ScheduleReportsSectionContainer';
import SummaryReportsSection from 'scheduleModule/containers/SummaryReportsSectionContainer';
import SummarySection from 'scheduleModule/containers/SummaryContainer';
import AsmScheduleDailyBars from 'scheduleModule/containers/AsmScheduleDailyBarsContainer';

import { schedulePagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class SchedulePage extends PureComponent {
    static propTypes = {
      filterOptions: instanceOf(FilterOptions).isRequired,
      fetchAsmScheduleData: func.isRequired,
      fetchSummary: func.isRequired,
      fetchAsmScheduleDailyBars: func.isRequired,
      filter: instanceOf(AccountsFilterModel).isRequired,
      fetchingFilterOptions: bool.isRequired,
      isFilterInitialized: bool.isRequired,
      location: location.isRequired,
      history: history.isRequired,
      setFilter: func.isRequired,
      fetchFilterOptions: func.isRequired,
      resetScheduleStore: func.isRequired,
      childrenPermissions: arrayOf(string).isRequired,
      filterPermissions: arrayOf(string).isRequired,
    };

    accountsFilter = React.createRef()

    componentDidMount() {
      const { fetchFilterOptions } = this.props;
      fetchFilterOptions();
    }

    componentDidUpdate(prevProps) {
      const { fetchingFilterOptions, filterOptions, location } = this.props;

      if (prevProps.fetchingFilterOptions && !fetchingFilterOptions) {
        const defaultAccountsSource = filterOptions.accountsSources[0];

        const prevMonday = moment().startOf('isoWeek');

        AccountsFilterModel.setDefaultParams({
          defaultStartDate: prevMonday,
          defaultAccountsSource,
        });
        const filterModel = AccountsFilterModel.parseQueryString(
          location.search,
        );

        this.setAccountFilters(filterModel);
      }
    }

    componentWillUnmount() {
      const { resetScheduleStore } = this.props;
      resetScheduleStore();
    }

    setAccountFilters = (filterModel) => {
      const {
        setFilter,
        location,
        history,
        fetchAsmScheduleData,
        fetchSummary,
        fetchAsmScheduleDailyBars,
      } = this.props;
      const ignore = [];

      const modelQueryString = filterModel.getQueryStringFilter(ignore);

      fetchAsmScheduleData(filterModel);
      fetchSummary(filterModel);
      fetchAsmScheduleDailyBars(filterModel);

      setFilter(filterModel);
      history.push({
        pathname: location.pathname,
        search: modelQueryString,
        hash: location.hash,
      });
    };

    goPrevWeek = () => this.accountsFilter.current.goPrevWeek();

    goNextWeek = () => this.accountsFilter.current.goNextWeek();

    render() {
      const {
        filter,
        filterOptions,
        isFilterInitialized,
        childrenPermissions,
        filterPermissions,
      } = this.props;

      return (
        <div className="schedule-page">
          <Skeleton
            loading={!isFilterInitialized}
            className="schedule-page__skeleton"
          >
            <AccountsFilter
              applyFilter={this.setAccountFilters}
              initialFilterModel={filter}
              filterOptions={filterOptions}
              ref={this.accountsFilter}
              permissions={filterPermissions}
            />
          </Skeleton>
          <div className="schedule-page__inner">
            <ScheduleReportsSection />
            <SummaryReportsSection />
            {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_SUMMARY) && <SummarySection />}
            {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_ASM_SCHEDULE) && <AsmScheduleTable goPrevWeek={this.goPrevWeek} goNextWeek={this.goNextWeek} />}
            {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_DAILY_BARS) && <AsmScheduleDailyBars />}
          </div>
        </div>
      );
    }
}

export default withNavigation()(SchedulePage);
