/* eslint-disable no-unused-expressions, no-lone-blocks */
import React, { PureComponent } from 'react';
import {
  instanceOf, bool, arrayOf, string,
} from 'prop-types';
import { Divider } from 'antd';


import moment from 'moment';

import SectionHeading from 'common/components/SectionHeading';

import AccountsFilterModel from 'merchandisingModule/models/AccountsFilter';
import PrimaryPlacementChart from 'merchandisingModule/containers/PrimaryPlacementChartContainer';
import ShelfPlacementChart from 'merchandisingModule/containers/ShelfPlacementChartContainer';
import SecondaryPlacementChart from 'merchandisingModule/containers/SecondaryPlacementChartContainer';
import PosPlacementChart from 'merchandisingModule/containers/PosPlacementChartContainer';
import TagsInstalledChart from 'merchandisingModule/containers/TagsInstalledChartContainer';
import ProductsOnPromoChart from 'merchandisingModule/containers/ProductsOnPromoChartContainer';

import { DATE_FORMAT } from 'common/constants/dateConstants';
import { merchandisingPagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

class MetricsOnDateCharts extends PureComponent {
  static propTypes = {
    fetchingFilterOptions: bool.isRequired,
    filter: instanceOf(AccountsFilterModel).isRequired,
    childrenPermissions: arrayOf(string).isRequired,
  };

  render() {
    const {
      filter,
      fetchingFilterOptions,
      childrenPermissions,
    } = this.props;

    const formattedChartDate = filter.endDate && moment(filter.endDate).format(DATE_FORMAT);

    return (
      !fetchingFilterOptions && (
        <article className="merchandising-metrics-pies">
          <SectionHeading>{`Metrics as of ${formattedChartDate}`}</SectionHeading>
          <section className="merchandising-metrics-pies-wrapper">
            { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_PRIMARY_PLACEMENT) && (
              <div className="merchandising-metrics-pies__chart">
                <PrimaryPlacementChart />
              </div>
            )}
            { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_SHELF_PLACEMENT) && (
              <div className="merchandising-metrics-pies__chart">
                <ShelfPlacementChart />
              </div>
            )}
            { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_SECONDARY_PLACEMENT) && (
              <div className="merchandising-metrics-pies__chart">
                <SecondaryPlacementChart />
              </div>
            )}
            { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_POS_PLACEMENT) && (
              <div className="merchandising-metrics-pies__chart">
                <PosPlacementChart />
              </div>
            )}
            { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_TAGS) && (
              <div className="merchandising-metrics-pies__chart">
                <TagsInstalledChart />
              </div>
            )}
            { childrenPermissions.includes(merchandisingPagePermissionTypes.MERCHANDISING_PAGE_PROMO) && (
              <div className="merchandising-metrics-pies__chart">
                <ProductsOnPromoChart />
              </div>
            )}
          </section>
          <Divider />
        </article>
      )
    );
  }
}

export default MetricsOnDateCharts;
