class Scorecard {
  constructor({
    merchForms,
    reorders,
    merchStores,
    facings,
    avgFacings,
    facingsTotal,
    avgSkus,
    secondaryPlacement,
    startDate,
    endDate,
    period,
    discounted,
    hotLeads,
    hoursWorked,
    mileageDriven,
    newAccounts,
    ordersSubmitted,
    statusFormsSubmitted,
    statusStoresVisited,
    totalAccounts,
    totalAmount,
    totalCasesCount,
    uniqueAccountOrders,
    newAccountsUnique,
    newAccountsChain,
    newAccountsChainUnique,
    hotLeadsUnique,
    discountedUnique,
    secondaryPlacementNew,
    warmLeads,
    warmLeadsUnique,
  }) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.period = period;
    this.facings = facings;
    this.avgFacings = avgFacings;
    this.facingsTotal = facingsTotal;
    this.merchForms = merchForms;
    this.reorders = reorders;
    this.merchStores = merchStores;
    this.avgSkus = avgSkus;
    this.secondaryPlacement = secondaryPlacement;
    this.secondaryPlacementNew = secondaryPlacementNew;

    this.hoursWorked = hoursWorked;
    this.mileageDriven = mileageDriven;
    this.newAccounts = newAccounts;
    this.newAccountsUnique = newAccountsUnique;
    this.newAccountsChain = newAccountsChain;
    this.newAccountsChainUnique = newAccountsChainUnique;
    this.hotLeads = hotLeads;
    this.hotLeadsUnique = hotLeadsUnique;
    this.warmLeads = warmLeads;
    this.warmLeadsUnique = warmLeadsUnique;
    this.discounted = discounted;
    this.discountedUnique = discountedUnique;
    this.ordersSubmitted = ordersSubmitted;
    this.statusFormsSubmitted = statusFormsSubmitted;
    this.statusStoresVisited = statusStoresVisited;
    this.totalAccounts = totalAccounts;
    this.totalAmount = totalAmount;
    this.totalCasesCount = totalCasesCount;
    this.uniqueAccountOrders = uniqueAccountOrders;
  }

    static fieldNamesMap = new Map([
      ['statusFormsSubmitted', 'Visits'],
      ['statusStoresVisited', 'Accounts Visited'],
      ['hoursWorked', 'Hours Worked'],
      ['mileageDriven', 'Mileage Driven'],
      ['totalAccounts', 'Authorized Accounts'],
      ['newAccounts', 'New Accounts - UDS (All)'],
      ['newAccountsUnique', 'New Accounts - UDS (New)'],
      ['newAccountsChain', 'New Accounts - Chain (All)'],
      ['newAccountsChainUnique', 'New Accounts - Chain (New)'],
      ['hotLeads', 'Hot Leads (All)'],
      ['hotLeadsUnique', 'Hot Leads (New)'],
      ['warmLeads', 'Warm Leads (All)'],
      ['warmLeadsUnique', 'Warm Leads (New)'],
      ['discounted', 'Discontinued (All)'],
      ['discountedUnique', 'Discontinued (New)'],

      ['merchForms', 'Merchandising Visits'],
      ['merchStores', 'Accounts Merchandised'],
      ['facings', 'Facings (Added)'],
      ['facingsTotal', 'Facings (Total)'],
      ['avgFacings', 'Facings (Avg)'],
      ['avgSkus', 'SKUs (Avg)'],
      ['reorders', 'Reorders'],
      ['secondaryPlacement', 'Secondary Placements (All)'],
      ['secondaryPlacementNew', 'Secondary Placement (New)'],

      ['ordersSubmitted', 'Orders'],
      ['uniqueAccountOrders', 'Accounts Ordered'],
      ['totalAmount', 'Sales'],
      ['totalCasesCount', 'Cases'],
    ]);

    static accountFields = [
      'statusFormsSubmitted',
      'statusStoresVisited',
      'totalAccounts',
      'newAccounts',
      'newAccountsUnique',
      'newAccountsChain',
      'newAccountsChainUnique',
      'hotLeads',
      'hotLeadsUnique',
      'warmLeads',
      'warmLeadsUnique',
      'discounted',
      'discountedUnique',
    ];

    static merchandisingFields = [
      'merchForms',
      'merchStores',
      'reorders',
      'secondaryPlacement',
      'secondaryPlacementNew',
      'avgSkus',
      'avgFacings',
      'facingsTotal',
      'facings',
    ];

    static salesFields = [
      'ordersSubmitted',
      'uniqueAccountOrders',
      'totalAmount',
      'totalCasesCount',
    ];

    static timeEfforts = ['hoursWorked', 'mileageDriven'];

    static parseWeek(obj) {
      return new Scorecard({
        period: 'Week',
        merchForms: obj.MerchFormsSubmitted.toLocaleString(),
        reorders: `${String(obj.ReordersPlaced)} (${String(
          obj.ReordersPercentage,
        )}%)`,

        merchStores: obj.MerchStoresVisited.toLocaleString(),
        facings: obj.FacingsAdded.toLocaleString(),
        facingsTotal: obj.FacingsTotal.toLocaleString(),
        avgFacings: obj.AvgNumberOfFacings.toLocaleString(),
        avgSkus: obj.AvgNumberOfSKUs.toLocaleString(),
        secondaryPlacement: `${String(obj.SecondaryPlacements)} (${String(
          obj.SecondaryPlacementsPercentage,
        )}%)`,
        startDate: obj.DateStart,
        endDate: obj.DateEnd,

        discounted: obj.Discontinued.toLocaleString(),
        hotLeads: obj.HotLeads.toLocaleString(),
        hotLeadsUnique: obj.HotLeadsUnique.toLocaleString(),
        warmLeads: obj.WarmLeads.toLocaleString(),
        warmLeadsUnique: obj.WarmLeadsUnique.toLocaleString(),
        hoursWorked: obj.HoursWorked.toLocaleString(),
        mileageDriven: obj.MileageDriven.toLocaleString(),
        newAccounts: obj.NewAccounts.toLocaleString(),
        ordersSubmitted: obj.OrdersSubmitted.toLocaleString(),
        statusFormsSubmitted: obj.StatusFormsSubmitted.toLocaleString(),
        statusStoresVisited: obj.StatusStoresVisited.toLocaleString(),
        totalAccounts: `${String(obj.TotalAccounts)} (${String(
          obj.TotalAccountsPercentage,
        )}%)`,
        totalAmount: `$${obj.TotalAmount.toLocaleString()}`,
        totalCasesCount: obj.TotalCasesCount.toLocaleString(),
        uniqueAccountOrders: obj.UniqueAccountOrders.toLocaleString(),

        newAccountsUnique: obj.NewAccountsUnique.toLocaleString(),
        newAccountsChain: obj.NewAccountsChain.toLocaleString(),
        newAccountsChainUnique: obj.NewAccountsChainUnique.toLocaleString(),
        discountedUnique: obj.DiscontinuedUnique.toLocaleString(),

        secondaryPlacementNew: `${String(
          obj.SecondaryPlacementsUnique,
        )} (${String(obj.SecondaryPlacementsUniquePercentage)}%)`,
      });
    }

    static parseMonth(obj) {
      return new Scorecard({
        period: 'Month',
        merchForms: obj.MerchFormsSubmitted.toLocaleString(),
        reorders: `${String(obj.ReordersPlaced)} (${String(
          obj.ReordersPercentage,
        )}%)`,

        merchStores: obj.MerchStoresVisited.toLocaleString(),
        facings: obj.FacingsAdded.toLocaleString(),
        facingsTotal: obj.FacingsTotal.toLocaleString(),
        avgFacings: obj.AvgNumberOfFacings.toLocaleString(),
        avgSkus: obj.AvgNumberOfSKUs.toLocaleString(),
        secondaryPlacement: `${String(obj.SecondaryPlacements)} (${String(
          obj.SecondaryPlacementsPercentage,
        )}%)`,
        startDate: obj.DateStart,
        endDate: obj.DateEnd,

        discounted: obj.Discontinued.toLocaleString(),
        hotLeads: obj.HotLeads.toLocaleString(),
        hotLeadsUnique: obj.HotLeadsUnique.toLocaleString(),
        warmLeads: obj.WarmLeads.toLocaleString(),
        warmLeadsUnique: obj.WarmLeadsUnique.toLocaleString(),
        hoursWorked: obj.HoursWorked.toLocaleString(),
        mileageDriven: obj.MileageDriven.toLocaleString(),
        newAccounts: obj.NewAccounts.toLocaleString(),
        ordersSubmitted: obj.OrdersSubmitted.toLocaleString(),
        statusFormsSubmitted: obj.StatusFormsSubmitted.toLocaleString(),
        statusStoresVisited: obj.StatusStoresVisited.toLocaleString(),
        totalAccounts: `${String(obj.TotalAccounts)} (${String(
          obj.TotalAccountsPercentage,
        )}%)`,
        totalAmount: `$${obj.TotalAmount.toLocaleString()}`,
        totalCasesCount: obj.TotalCasesCount.toLocaleString(),
        uniqueAccountOrders: obj.UniqueAccountOrders.toLocaleString(),

        newAccountsUnique: `${obj.NewAccountsUnique.toLocaleString()}`,
        newAccountsChain: obj.NewAccountsChain.toLocaleString(),
        newAccountsChainUnique: obj.NewAccountsChainUnique.toLocaleString(),
        discountedUnique: obj.DiscontinuedUnique.toLocaleString(),

        secondaryPlacementNew: `${String(
          obj.SecondaryPlacementsUnique,
        )} (${String(obj.SecondaryPlacementsUniquePercentage)}%)`,
      });
    }
}

export default Scorecard;
