import React, { PureComponent } from 'react';
import {
  bool, string, func, arrayOf,
} from 'prop-types';
import {
  Button, Select, Skeleton,
} from 'antd';
import classnames from 'classnames';

import FieldTitle from 'common/components/FieldTitle';
import SectionHeading from 'common/components/SectionHeading';

import ScorecardsDropzone from 'adminModule/containers/ScorecardsDropzoneContainer';
import BrandScorecardsList from 'adminModule/containers/BrandScorecardsListContainer';

import './index.scss';

const CommonOption = option => (
  <Select.Option key={option} value={option}>
    {option}
  </Select.Option>
);

const HEADING = 'Chain Scorecard';

class ScorecardsSection extends PureComponent {
  static propTypes = {
    selectedBrand: string,
    brands: arrayOf(string),
    onChangeBrand: func.isRequired,
    onUpload: func.isRequired,
    isUploadDisabled: bool.isRequired,
    isUploading: bool.isRequired,
    isFilterOptionsFetching: bool.isRequired,
  };

  static defaultProps = {
    brands: [],
    selectedBrand: null,
  };

  render() {
    const {
      onUpload,
      selectedBrand,
      brands,
      onChangeBrand,
      isUploadDisabled,
      isUploading,
      isFilterOptionsFetching,
    } = this.props;

    return (
      <Skeleton loading={isFilterOptionsFetching}>
        <SectionHeading>{HEADING}</SectionHeading>
        <div className="scorecards-section">
          <div className="scorecards-section__interactive-wrapper">
            <div className="scorecards-section__buttons">
              <div className="scorecards-section__select-container">
                <FieldTitle>Brand</FieldTitle>
                <Select
                  onChange={onChangeBrand}
                  value={selectedBrand}
                  className="scorecards-section__select"
                >
                  {brands.map(CommonOption)}
                </Select>
              </div>
              <div className="scorecards-section__button-container">
                <FieldTitle>&nbsp;</FieldTitle>
                <Button
                  type="primary"
                  icon="upload"
                  onClick={onUpload}
                  loading={isUploading}
                  className={classnames({
                    'scorecards-section__upload-button_active': !isUploadDisabled,
                  })}
                  disabled={isUploadDisabled}
                >
                Upload
                </Button>
              </div>
            </div>
            <div className="scorecards-section__dropzone-container">
              <ScorecardsDropzone />
            </div>
          </div>
          <div className="scorecards-section__list-wrapper">
            <BrandScorecardsList />
          </div>
        </div>
      </Skeleton>
    );
  }
}

export default ScorecardsSection;
