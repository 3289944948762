/* eslint-disable react/prefer-stateless-function, react/destructuring-assignment, no-nested-ternary */
import React, { PureComponent } from 'react';
import {
  func,
} from 'prop-types';

import {
  Button,
  Input,
} from 'antd';


import './index.scss';

class DownloadEmailReportForm extends PureComponent {
  static propTypes = {
    onSubmitEmail: func.isRequired,
  }

  state = {
    inputText: '',
  }

  submitForm = (e) => {
    e.preventDefault();
    const { onSubmitEmail } = this.props;
    const { inputText } = this.state;
    onSubmitEmail(inputText);
  };


  onInputChange = (e) => {
    this.setState({
      inputText: e.currentTarget.value,
    });
  }

  render() {
    const { inputText } = this.state;
    return (
      <div className="email-report-form">
        <div className="email-report-form__info">
          <p className="email-report-form">
            Please provide an email address to get notified when your download is ready
          </p>

        </div>
        <Input onChange={this.onInputChange} />
        <div className="email-report-form__submit-button-container">
          <Button
            className="email-report-form__button_submit"
            icon="form"
            type="primary"
            htmlType="submit"
            disabled={!inputText.includes('@')}
            onClick={this.submitForm}
          >
            Send
          </Button>
        </div>
      </div>
    );
  }
}

export default DownloadEmailReportForm;
