/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { merchandisingPagePermissionTypes } from 'userModule/constants/permissions';

const retailPricesDataSelector = state => state.merchandising.retailPricesData;
const voidsDataSelector = state => state.merchandising.voidsData;
const merchandisingPagePermissionsSelector = state => state.session.permissions[merchandisingPagePermissionTypes.MERCHANDISING_PAGE];

export const permissionsSelector = createSelector(
  merchandisingPagePermissionsSelector,
  merchandisingPagePermissions => merchandisingPagePermissions.Permissions || [],
);

export const filterPermissionsSelector = createSelector(
  merchandisingPagePermissionsSelector,
  merchandisingPagePermissions => merchandisingPagePermissions.Filters || [],
);


export const shouldRenderRetailPricesCharts = createSelector(
  retailPricesDataSelector,
  retailPricesData => retailPricesData && retailPricesData.length > 0,
);

export const shouldRenderVoidsCharts = createSelector(
  voidsDataSelector,
  voidsData => voidsData && voidsData.length > 0,
);
