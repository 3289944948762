import React, { Fragment } from 'react';
import { Divider, Button, Skeleton } from 'antd';

import SectionHeading from 'common/components/SectionHeading';
import {
  bool, func, arrayOf, string,
} from 'prop-types';
import { schedulePagePermissionTypes } from 'userModule/constants/permissions';

import './index.scss';

const HEADING = 'Schedule Reports';

const ScheduleReportsSection = ({
  isScheduleWeeklyReportDownloading,
  onDownloadScheduleWeeklyReport,
  isScheduleMonthlyReportDownloading,
  onDownloadScheduleMonthlyReport,
  isScheduleDailyReportDownloading,
  onDownloadScheduleDailyReport,
  isFilterOptionsFetching,
  childrenPermissions,
}) => (
  <Skeleton loading={isFilterOptionsFetching}>
    <Fragment>
      <SectionHeading>{HEADING}</SectionHeading>
      <section className="schedule-reports-section">
        {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_DAILY_REPORT) && (
          <div className="schedule-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadScheduleDailyReport}
              loading={isScheduleDailyReportDownloading}
            >
            Schedule Report - Daily
            </Button>
          </div>
        )}
        {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_WEEKLY_REPORT) && (
          <div className="schedule-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadScheduleWeeklyReport}
              loading={isScheduleWeeklyReportDownloading}
            >
            Schedule Report - Weekly
            </Button>
          </div>
        )}
        {childrenPermissions.includes(schedulePagePermissionTypes.SCHEDULE_PAGE_MONTHLY_REPORT) && (
          <div className="schedule-reports-section__download-button-container">
            <Button
              type="primary"
              icon="file"
              onClick={onDownloadScheduleMonthlyReport}
              loading={isScheduleMonthlyReportDownloading}
            >
            Schedule Report - Monthly
            </Button>
          </div>
        )}
      </section>
      <Divider />
    </Fragment>
  </Skeleton>
);

ScheduleReportsSection.propTypes = {
  isScheduleWeeklyReportDownloading: bool.isRequired,
  onDownloadScheduleWeeklyReport: func.isRequired,
  isScheduleMonthlyReportDownloading: bool.isRequired,
  onDownloadScheduleMonthlyReport: func.isRequired,
  isScheduleDailyReportDownloading: bool.isRequired,
  onDownloadScheduleDailyReport: func.isRequired,
  isFilterOptionsFetching: bool.isRequired,
  childrenPermissions: arrayOf(string).isRequired,
};

export default ScheduleReportsSection;
