import { connect } from 'react-redux';
import withLegend from 'common/hocs/withLegend';

import {
  colorMapSelector,
  currentDistributorsOnDateSelector,
} from 'accountsModule/selectors/accountsByDistributorOnDateSelectors';

import AccountsByDistributorOnDateChart from 'accountsModule/components/AccountsByDistributorOnDateChart';

export default connect(state => ({
  colorMap: colorMapSelector(state),
  distributorNames: currentDistributorsOnDateSelector(state),
  accountsByDistributor: state.accounts.accountsByDistributorOnDate,
}))(
  withLegend({
    keys: 'distributorNames',
    isLegendActive: false,
  })(AccountsByDistributorOnDateChart),
);
