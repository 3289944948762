/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import {
  Icon, Menu, Dropdown, Button,
} from 'antd';
import { func } from 'prop-types';

import { PeriodLabels, Periods } from 'common/constants/periodConstants';

import './index.scss';

const buttonStyle = {
  color: '#8c8c8c',
};

class PeriodPicker extends PureComponent {
    static propTypes = {
      onChange: func.isRequired,
    };

    onItemClick = ({ key }) => {
      const { onChange } = this.props;
      onChange(key);
    };

    render() {
      const menu = (
        <Menu onClick={this.onItemClick}>
          {Object.values(Periods).map(period => (
            <Menu.Item key={period}>{PeriodLabels[period]}</Menu.Item>
          ))}
        </Menu>
      );
      return (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="period-picker__button">
            <Icon type="caret-down" style={buttonStyle} />
          </Button>
        </Dropdown>
      );
    }
}

export default PeriodPicker;
