class RetailPrice {
  constructor({ price, amount, percentage }) {
    this.price = price;
    this.amount = amount;
    this.percentage = percentage;
  }

  static parse(obj) {
    return new RetailPrice({
      price: obj.RetailPrice,
      amount: obj.Amount,
      percentage: obj.Percentage,
    });
  }
}

export default RetailPrice;
