import { connect } from 'react-redux';
import { permissionsSelector } from 'reportsModule/selectors/reportsPageSelectors';

import MerchandisingReportsSection from 'reportsModule/components/MerchandisingReportsSection';

export default connect(
  state => ({
    isMerchandisingReportDownloading: state.loading.effects.reports.downloadMerchandisingReport,
    isVoidsReportDownloading: state.loading.effects.reports.downloadVoidReport,
    isMerchandisingAccountImagesReportDownloading: state.loading.effects.reports.downloadMerchandisingAccountImagesReport,
    merchandisingAccountImagesReportDate: state.reports.merchandisingAccountImagesReportDate,
    childrenPermissions: permissionsSelector(state),
  }),
  dispatch => ({
    onDownloadMerchandisingReport: () => dispatch.reports.downloadMerchandisingReport(),
    onDownloadVoidsReport: () => dispatch.reports.downloadVoidReport(),
    onDownloadMerchandisingAccountImagesReport: () => dispatch.reports.downloadMerchandisingAccountImagesReport(),
    setMerchandisingAccountImagesReportDate: dispatch.reports.setMerchandisingAccountImagesReportDate,
  }),
)(MerchandisingReportsSection);
